import React, { Component } from 'react'
import './assets/css/notfound.css'
import { Link } from 'react-router-dom'


class NotFound extends Component {
    render() {
        return (
            <section className="content">
                <div className="wrap">
                    <div className="_404">
                        404
                    </div>
                    <div className="text-center notfound">
                        Page non trouvée <br /> 
                        <span>Cliquez <Link to="/">ici</Link> pour retourner à la page d'accueil</span>
                    </div>
                </div>
            </section>
        )
    }
}

export default NotFound;