import React, { Component } from 'react'
import Posts from '../publications/Posts'
import { withRouter } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import Global from '../../inc/Global';
import Axios from 'axios';
import Item from '../admin/Users/Item';
import ItemCommunity from '../../components/community/Item';
import SearchForm from '../search-form/SearchForm'
import BeatLoader from "react-spinners/BeatLoader";
import configs from '../../inc/helpers';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'


class SearchResulats extends Component {
    constructor(props){
        super(props)
        this.state = {
            search: null,
            results: [],
            activeTab: 1,
            loading: false
        }

        this.handleJoinCommunity = this.handleJoinCommunity.bind(this);
    }

    componentDidMount() {
        this.setState({
            search: this.props.match.params.string
        }, ()=>{
            this.getResults()
        }) 
    } 

    componentDidUpdate() {
        if( this.props.match.params.string !== this.state.search ){
            this.setState({
                search: this.props.match.params.string
            }, ()=>{
                this.getResults()
            }) 
        } 
    } 

    getResults(){
        this.setState({
            results: {},
            loading: true,
            activeTab: 1
        }, ()=>{
            let data = new FormData();
            data.append('query', this.state.search)
            Axios
            .post(Global.API_URL+"/api/searchs", data)
            .then((res) => {
                if( !Array.isArray(res.data) ){
                    let activeTab = 1
                    if( res.data.post.length == 0 ){
                        if(res.data.user.length > 0){
                            activeTab = 2
                        }else{
                            if(res.data.group.length > 0){
                                activeTab = 3
                            }else{
                                if(res.data.Docgroup.length > 0){
                                    activeTab = 4
                                }else{
                                    activeTab = 1
                                }
                            }
                        }
                    }
                    this.setState({
                        results: res.data,
                        activeTab: activeTab,
                        loading: false
                    })
                }else{
                    this.setState({
                        results: {
                            post:[],
                            user:[],
                            group:[],
                            Docgroup:[],
                        },
                        activeTab: 1,
                        loading: false
                    })
                }
            })
        })
        
    }

    handleJoinCommunity(ev){
        var target = ev.target;
        if(target.hasAttribute('disabled')){
            return;
        }

        target.classList.add('on');
        const targetId = target.getAttribute('data-id');
        const targetType = target.getAttribute('data-type');
        
        var path
        if(targetType === 'public'){
            path = "/rejoindrepub"
        }else{
            path = "/rejoindreprivate"
        }
        const data = {
            email : this.props.auth.user.username,
            id: targetId
        } 

        Axios
        .post(Global.API_URL+path, data)
        .then((res) => {
            if(res.data.message === "Demand sent"){
                target.innerHTML = "Demande envoyée"
            }
            target.classList.remove('on');
            target.setAttribute('disabled', true);
        }).catch((err) => {
            // console.log('Error : '+err.message)
            target.classList.remove('on');
        });
        
    }

    render() {
        return (
            // <Posts role="ROLE_ADMIN" parent="page_home" community="0" />
            <div className="search-results content">  
                <SearchForm referer="results" value={this.props.match.params.string} />

                {
                    !this.state.loading &&
                    <div className="tabs"> 
                        <ul>
                            <li className={this.state.activeTab === 1 ? 'active' : ''} onClick={()=>{this.setState({activeTab: 1})}}>
                                Publications
                                {
                                    this.state.results?.post?.length > 0 &&
                                    <small> ({this.state.results.post.length})</small>
                                }
                            </li>
                            <li className={this.state.activeTab === 2 ? 'active' : ''} onClick={()=>{this.setState({activeTab: 2})}}>
                                Contacts
                                {
                                    this.state.results?.user?.length > 0 &&
                                    <small> ({this.state.results.user.length})</small>
                                }
                            </li>
                            <li className={this.state.activeTab === 3 ? 'active' : ''} onClick={()=>{this.setState({activeTab: 3})}}>
                                Communautés
                                {
                                    this.state.results?.group?.length > 0 &&
                                    <small> ({this.state.results.group.length})</small>
                                }
                            </li>
                            <li className={this.state.activeTab === 4 ? 'active' : ''} onClick={()=>{this.setState({activeTab: 4})}}>
                                Documents
                                {
                                    this.state.results?.Docgroup?.length > 0 &&
                                    <small> ({this.state.results.Docgroup.length})</small>
                                }
                            </li>
                        </ul>
                    </div>
                } 

                {
                    this.state.loading &&
                    <center style={{padding:20}}>
                        <BeatLoader color={"#aaa"} loading={true} size="10"/>
                    </center>
                }
                
                
                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                {
                    this.state.results?.post?.length > 0 && this.state.activeTab === 1 &&
                    <div className="results results-posts"> 
                        <Posts role="ROLE_ADMIN" searchResults={this.state.results.post} api_url="/api/publication" parent="page_search" community="0" /> 
                    </div>
                }
                {
                    this.state.results?.post?.length === 0 && this.state.activeTab === 1 &&
                    <center className="no-resualt"> 
                        <p>Aucune publication trouvée</p>
                    </center>
                }
                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}




                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                {
                    this.state.results?.user?.length > 0 && this.state.activeTab === 2 &&
                    <div className="results results-users"> 
                        <div className="users-list">  
                            <div className="_ursl" id='smalCardUser'>
                                
                                <ul>
                                    {this.state.results.user.map((user, i) =>
                                        <Item 
                                        key={user.id} 
                                        ID={user.id}
                                        Name={user.profile.prenom}
                                        LastName={user.profile.nom}
                                        Photo={user.images?.image}
                                        Job={user.profile.fonction}
                                        PhoneNum={user.profile.telephone}
                                        Email={user.email} 
                                        casecoche={false} 
                                        />
                                    )}
                                </ul>
                            </div> 
                        </div> 
                    </div>
                }
                {
                    this.state.results?.user?.length === 0 && this.state.activeTab === 2 &&
                    <center className="no-resualt"> 
                        <p>Aucun contact trouvé</p>
                    </center>
                }
                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}





                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                {
                    this.state.results?.group?.length > 0  && this.state.activeTab === 3 &&
                    <div className="results results-groups"> 
                            <div className="_ursl _cmlt">
                                <ul>
                                    {/* The below comment is with Paginations */}
                                    {/* {this.state.communities.slice(0, this.state.limit).map((community, i) => */}
                                    {this.state.results.group.map((community, i) =>
                                        <ItemCommunity
                                            key         ={community.id}
                                            ID          ={community.id}
                                            Color       ={community.couleur}
                                            Invitation  ={[]}
                                            Logo        ={community.logo}
                                            NBAdmin     ={0}
                                            NBUser      ={0}
                                            Cover       ={community.photocouverture}
                                            Subject     ={community.sujet} 
                                            Title       ={community.titre} 
                                            Type        ={community.typegroupe} 
                                            UserEmail   ={null}
                                            UserID      ={null} 
                                            Role        ={community.Role}
                                            isMyCommunity= { community.roleuser == 'notnull' ? true : false}
                                            NbPostNonLu ={0}
                                            handleJoinCommunity= {this.handleJoinCommunity} />
                                    )}
                                </ul>
                            </div> 
                    </div>
                }
                {
                    this.state.results?.group?.length === 0 && this.state.activeTab === 3 &&
                    <center className="no-resualt"> 
                        <p>Aucune communauté trouvée</p>
                    </center>
                }
                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}




                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}
                {
                    this.state.results?.Docgroup?.length > 0 && this.state.activeTab === 4 &&
                    <div className="results results-documents documents">
                        <div className="_docs-list">
                            <ul> 
                                {this.state.results.Docgroup.map((el, index)=>{ 
                                    const ext = el.file ? el.file.substring(el.file.lastIndexOf(".")+1).toLowerCase() : null;
                                    const fileType = configs.GetFileIcon(ext,true)
                                    return (
                                        <li key={"file-" + index} className="doc-item"> 
                                            {
                                                (fileType == 'video' || fileType == 'image') && 
                                                <div className="doc-info">
                                                    <div className="d-flex align-center">
                                                        <figure className="doc-icon">
                                                            <div  onClick={()=>{this.setState({lightBox:{open: true, type: fileType, url: Global.API_URL +"/" + el.file}})}}>
                                                                <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={el.file} />         
                                                            </div>
                                                        </figure>
                                                        <div className="">
                                                            <span className="doc-name" onClick={()=>{this.setState({lightBox:{open: true, type: fileType, url: Global.API_URL +"/" + el.file}})}}>
                                                                {/* {el.file} */}
                                                                {el.documentreelname}
                                                            </span><br />
                                                            <span className="doc-author">{el?.prenom?.toLowerCase()} {el?.nom?.toLowerCase()}<br /></span>
                                                            <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                        </div>
                                                    </div>
                                                </div> 
                                            }  
                                            {
                                                fileType == 'file' &&
                                                <div className="doc-info">
                                                    <div className="d-flex align-center">
                                                        <figure className="doc-icon">
                                                            <a href={Global.API_URL +"/" + el.file} target="_blank">
                                                                <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={el.file} />         
                                                            </a>
                                                        </figure>
                                                        <div className="">
                                                            <span className="doc-name"><a target="_blank" href={Global.API_URL +"/" + el.file} download>
                                                                {el.documentreelname}
                                                                {/* {el.file} */}
                                                            </a></span><br />
                                                            <span className="doc-author">{el?.prenom?.toLowerCase()} {el?.nom?.toLowerCase()}<br /></span>
                                                            <span className="doc-date">{configs.timeSince(el.createdAt)}</span>
                                                        </div>
                                                    </div>
                                                </div> 
                                            } 
                                        </li>
                                    ) 
                                })}
                            </ul>
                        </div>
                    </div>
                }
                {
                    this.state.results?.Docgroup?.length === 0 && this.state.activeTab === 4 &&
                    <center className="no-resualt"> 
                        <p>Aucun document trouvé</p>
                    </center>
                }
                {/* ////////////////////////////////////////////////////////////////////////////////////////// */}

                {
                    this.state.lightBox?.type === 'image' && this.state.lightBox?.open &&
                    <Lightbox
                        mainSrc={this.state.lightBox.url} 
                        onCloseRequest={()=>{this.setState({lightBox:{open: false}})}}
                        reactModalStyle={{zIndex:100000}}  
                        animationDisabled={true}
                    />
                } 
                {   this.state.lightBox?.type === 'video' && this.state.lightBox?.open &&
                    <div className="ReactImageVideoLightbox">
                        <ReactImageVideoLightbox
                            data={[{ url: this.state.lightBox.url, type: 'video', altTag: '' }]}
                            startIndex={0}
                            showResourceCount={false}
                            onCloseCallback={()=>{this.setState({lightBox:{open: false}})}} 
                        />
                    </div>
                }
            </div>
        )
    }
}

SearchResulats.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(SearchResulats));