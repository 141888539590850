import React, { useState,useEffect } from 'react';
import Gallery from './template-parts/Gallery.js';

import Linkify from 'react-linkify';


import Moment from 'react-moment';
import CustomPopHoverUser from './template-parts/CustomPopHoverUser.js';

// Set the locale for every react-moment instance to French.
// Moment.globalLocale = 'FR';

const PostEvent = ({post, auth}) => {
    const [eventPost, setEventPost] = useState(post)
    const [openedGalleryID, setOpenedGalleryID] = useState({
        id : false, 
        postId : false
    })
    useEffect(() => {
        Moment.globalLocale = 'fr';
    },[])
    const handleOpenGallery=(ID)=>{
        setOpenedGalleryID( {id : ID, postId : eventPost.id})
    }
    const handleCloseGallery= () =>{
        setOpenedGalleryID({id : false, postId : false})
    }
  


    return(
        <>
           <div className="eventContainer">
                <div className="eventHeader">
                    <div className="eventHeaderImg">
                        {
                            eventPost.imag?.length>0 &&
                            <div className="p">  
                                <div className="attachments">
                                    <Gallery
                                        images={eventPost.imag}
                                        handleOpenGallery={(e)=>{handleOpenGallery(e)}}
                                        handleCloseGallery={()=>{handleCloseGallery()}}
                                        OpenedGalleryID={openedGalleryID} 
                                        PostID={eventPost.id} /> 
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="eventBody">
                    <div className="dayEvent">
                        <div className="dayMonth">
                            <Moment format="dddd">
                                {eventPost.datedebut}
                            </Moment>&nbsp;
                            <Moment format="DD">
                                {eventPost.datedebut}
                            </Moment>
                        </div>
                        <div className="eventMonth">
                            <Moment format="MMMM">
                                {eventPost.datedebut}
                            </Moment>
                        </div>
                    </div>
                    <div className="EventInfo">
                        {
                            eventPost.text &&
                            <div className="EventTitle">
                                <p>
                                   {eventPost.text}
                                </p> 
                            </div>
                        }
                        <div className="EventOwner">
                            <p>
                                Créé par : 
                                <span className="ownerName">
                                    {eventPost?.users?.profile?.prenom} {eventPost?.users?.profile?.nom}
                                    <CustomPopHoverUser user={eventPost?.users} />
                                </span>
                            </p> 
                        </div>
                        <div className="EventPlace">
                            <p>
                                <span className="icon"><ion-icon name="location-outline"></ion-icon></span>
                                <span className="place">
                                    {eventPost?.lieu}
                                </span>
                            </p> 
                        </div>
                        <div className="EventDate">
                            {
                                eventPost.datedebut &&
                                <div className="DateStart">
                                    <p>
                                        <span className="icon"><ion-icon name="time-outline"></ion-icon></span>
                                        <span className="texteStart">Début : </span>
                                        <span className="date">
                                        <Moment format="DD/MM/YYYY">
                                            {eventPost.datedebut}
                                        </Moment>
                                        &nbsp;à&nbsp; 
                                        <Moment format="HH:mm">
                                            {eventPost.datedebut}
                                        </Moment>
                                        </span>
                                    </p> 
                                </div>  
                            }
                            {
                                eventPost.datefin &&
                                <div className="DateEnd">
                                    <p>
                                        <span className="texteStart">Fin : </span>
                                        <span className="date">
                                            <Moment format="DD/MM/YYYY">
                                                {eventPost.datefin}
                                            </Moment>
                                            &nbsp;à&nbsp; 
                                            <Moment format="HH:mm">
                                                {eventPost.datefin}
                                            </Moment>
                                        </span>
                                    </p> 
                                </div>  
                            }
                        </div>
                        {
                            eventPost.description && 
                            <div className="EventDescription">
                                <p>
                                <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                            {decoratedText}
                                        </a>
                                    )}> 
                                    {eventPost.description}
                                </Linkify>
                                </p> 
                            </div>
                        }
                    </div>
                </div>
           </div>
        </>
    )
}

export default PostEvent