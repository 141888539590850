import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import Posts from '../publications/Posts';
import { Link } from 'react-router-dom';

class SavedPosts extends Component {
    
    constructor(props){ 
        super(props)
    } 
    render() {
        
        return (  
            <div style={{width:'100%'}}>
                <div className="content">
                    <div className="header-list">
                        <div className="d-flex jc-space-b align-center">
                            <h3 className="h3"> 
                                <center>
                                    <Link onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                            <span className="fa fa-long-arrow-left"></span>
                                    </Link> 
                                    Mes enregistrements
                                </center>
                            </h3>  
                        </div>
                    </div> 
                </div>
                <div className="content saved_posts my_saved_posts"> 
                    <Posts role="ROLE_ADMIN" parent="saved_posts" api_url="/api/getpostsaved" community="0" />
                </div>  
            </div>
            
        )
    }
}
 
SavedPosts.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(SavedPosts));
