import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {logoutUser,logoutUserCach} from '../../actions/authentication'


import './ClearCachForce.scss'

class ClearCachForce extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading: false
        }
        this.handleReturn = this.handleReturn.bind(this); 

    }

    handleReturn = async event => {
        this.props.history.push("/");
    }
    componentDidMount(){
        // console.log(this.props.auth);
        // localStorage.clear();
        this.props.logoutUserCach(this.props.history);
        this.setState({loading: true})
    }
    render() { 
        return ( 
            <>
                <div className='ClearCachForce'>
                    {   !this.state.loading ? 
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <circle 
                                    cx="50" cy="50" r="30"
                                    fill="transparent"
                                    stroke-width="8px"
                                    stroke-dasharray="160"
                                    />
                            </svg>
                        :
                            <span onClick={(e)=>{this.handleReturn(e)}} className="button lg"><span className="fa fa-spinner fa-spin"></span> Aller à la page d'accueil </span>
                    }
                </div>  
            </>   
         );
    }
}
 




ClearCachForce.propTypes = {
    logoutUserCach: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { logoutUser , logoutUserCach})(withRouter(ClearCachForce));

