import React, { Component } from 'react'

export default class Comments extends Component {
    render() {
        return (
            <span className={this.props.className ? this.props.className : ''}>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    xlink="http://www.w3.org/1999/xlink" 
                    version="1.1" 
                    x="0px" y="0px" 
                    viewBox="0 0 1000 1000" 
                    enableBackground="new 0 0 1000 1000" 
                    space="preserve" 
                    style={{height: this.props.height, fill: this.props.color}}>
                    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                    <g>
                        <path d="M898.2,745H500L193.8,959.3V745h-91.9C51.1,745,10,703.8,10,653.2V132.5c0-50.8,41.1-91.8,91.8-91.8h796.3c50.8,0,91.9,41.1,91.9,91.8v520.7C990,703.8,948.8,745,898.2,745z M928.8,147.8c0-25.3-20.6-45.9-45.9-45.9H117.2c-25.3,0-45.9,20.6-45.9,45.9v490c0,25.4,20.6,45.9,45.9,45.9H255v153.2l214.3-153.2h413.4c25.3,0,45.9-20.5,45.9-45.9L928.8,147.8L928.8,147.8z"/>
                    </g>
                </svg> 
            </span>
        )
    }
}
