import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Documents from './Documents';

class Library extends Component {
    constructor(props){
        super(props);

        this.state = {
            folder : this.props.match.params.id ? this.props.match.params.id : false
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.id !== this.props.match.params.id){
            this.setState({
                folder : this.props.match.params.id ? this.props.match.params.id : false
            })
        }
    }
    render() {
        const { folder } = this.state;
        return (
            <div className="" style={{width : '100%'}}>
                <Documents folder={folder} />
            </div>
        )
    }
}

Library.propTypes = {
    auth : PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth : state.auth
});

export default connect(mapStateToProps, {})(withRouter(Library));