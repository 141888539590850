import Axios from 'axios';
import Pusher from 'pusher-js';
import React, { Component } from 'react'
import {Link} from 'react-router-dom' 
import Global from '../../inc/Global';
import configs from '../../inc/helpers';
import BeatLoader from "react-spinners/BeatLoader";
import ReactTooltip from 'react-tooltip';
import { motion } from "framer-motion"
import GlobalContext from '../../context/GlobalContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash.debounce';
import { Avatar, Box, Skeleton } from '@mantine/core';



class Notifications extends Component {

    static contextType = GlobalContext;


    constructor(){
        super()

        this.state = {
            loading: false,
            showNotifications: false,
            notificationsCounter: 0,
            notifications: [],
            hasMore:true
        }   

        this._getNotifications = this._getNotifications.bind(this);

        // this.getNotificationsCounter();
        // this.initPusher();

    }
    // initPusher(){
    //     let pusherClient = new Pusher(Global.PUSHER.app_key, { cluster: 'eu' }); 
    //     const channel = pusherClient.subscribe(Global.PUSHER.channel);
    //     channel.bind(Global.PUSHER.event,(data) => {
    //         console.log('here notif');
    //         this.getNotificationsCounter();
    //     });

    // }
    _getNotifications(){

        Axios
        .post(Global.API_URL+"/api/notif/load", {
            nb:20,
            start:  this.state.notifications.length,
        })
        .then((res) => {
            if( res.data.status ){
                const {getCount} = this.context;
                getCount()
                this.setState({
                    hasMore: res.data.notification.length==0? false : true ,
                    loading: false,
                    notifications:  [...this.state.notifications, ...res.data.notification],
                    notificationsCounter: 0
                })
            }
        })

    }
    getNotifications(){
        this.setState({
            showNotifications: !this.state.showNotifications, 
            showActionsButtons: false, 
            loading: true
        }, ()=>{
            if( this.state.showNotifications ){
                this._getNotifications()
            }
        }) 
    }


    // getNotificationsCounter(){
    //     Axios
    //     .get(Global.API_URL+"/api/notif/count", {})
    //     .then((res) => {

    //         if( res.data.state ){
    //             this.setState({
    //                 notificationsCounter: res.data.nombrenotif
    //             })
    //         }
    //     })
    // }

    setNotificationAsReaded(index, notification){
        let notifications = this.state.notifications;
        notifications[index].lue = 1;
        console.log(notifications);
        this.setState({
            showNotifications: notification.idpost ? false : true,
            notifications: notifications
        })
        Axios.post(Global.API_URL+"/api/notif/"+notification.id, {}).then((res) => {

        })
    }

    setAllNotificationsAsReaded(){
        let notifications = this.state.notifications;
        notifications.map(n=> n.lue = 1)
        this.setState({
            notifications: notifications,
            showActionsButtons: false,
            notificationsCounter: 0
        })
        Axios.post(Global.API_URL+"/api/marquelu", {}).then((res) => {})
    }

    forceCloseNotif(){
        this.setState({showNotifications:  false })
    } 
    deleteAllNotifications(){ 
        this.setState({
            notifications: [],
            showActionsButtons: false,
            showNotifications: false,
            notificationsCounter: 0
        })
        Axios.delete(Global.API_URL+"/api/deletenotif", {}).then((res) => {})
    }

    render() {
        const {notif} = this.context;
        return (
            <div className="notifications" id="notifications">
                {
                    this.state.showNotifications &&
                    <div className="notifications-overley" onClick={()=>{this.setState({showNotifications: false, showActionsButtons: false})}}></div>
                }  
                
                <button data-tip data-for="toggleNotifications" className={`toggle-notifications ${this.state.showNotifications ? 'active' : ''}`} onClick={()=>{this.getNotifications()}}>
                    <i className="fa fa-bell" aria-hidden="true"></i> 

                    {
                        notif.nombrenotif > 0 &&
                        <span className="badge">{notif.nombrenotif}</span>
                    } 
                </button>
                <ReactTooltip id="toggleNotifications" place="bottom" type="dark" effect="solid">
                    Notifications
                </ReactTooltip>
                <div className={`notifications-content notifications-app ${this.state.showNotifications ? 'active' : ''}`}>
                    {
                        this.state.notifications.length > 0 &&
                        <h2>
                            Notifications 
                            <motion.button  
                            whileHover={{scale : 1.1}}
                            whileTap={{scale : 0.9}}
                            className='notifSettingBtn' onClick={()=>{this.setState({showActionsButtons: !this.state.showActionsButtons})}}>
                                <ion-icon name="settings-sharp"></ion-icon>    
                            </motion.button> 
                            
                            {
                                this.state.showActionsButtons &&
                                <ul className="actions">
                                    <li onClick={()=>{this.setAllNotificationsAsReaded()}}><i className="fa fa-check" aria-hidden="true"></i> Marquer comme lues</li>
                                    <li onClick={()=>{this.deleteAllNotifications()}}><i className="fa fa-trash-o" aria-hidden="true"></i> Supprimer toutes les notifications</li>
                                </ul>
                            }
                        </h2>
                    }
                    
                    {
                        // <Link to={{
                        //     pathname : `/communaute/${this.state.id}/invitations`,
                        //     state : {id : this.state.id}
                        // }}>Invitation en attente</Link>
                        this.state.notifications.length > 0 &&
                        <ul className="notifications-list" id="scrollableDiv" style={{ height: "100%", overflowY: "scroll" }} >
                             <InfiniteScroll
                                    dataLength={this.state.notifications.length}
                                    next={(e)=>{this._getNotifications()}}
                                    hasMore={this.state.hasMore }
                                    scrollableTarget="scrollableDiv"
                                    loader={ 
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ margin: 1 }}>
                                                <Skeleton variant="circular" 
                                                    style={{
                                                        borderRadius: '50%',
                                                        width: '50px',
                                                        height: '50px',
                                                        marginRight: '10px'
                                                        }}
                                                    >
                                                    <Avatar />
                                                </Skeleton>
                                            </Box>
                                            <Box sx={{ width: '100%' }}>
                                                <Skeleton width="100%" height="50px">
                                                </Skeleton>
                                            </Box>
                                        </Box>
                                    }
                                    >

                                        {this.state.notifications.map((notification, key)=>{
                                            return(
                                                <li className={notification.lue === 0 ? 'active' : ''}>
                                                    {
                                                        notification.pusheraction[0] =='groups' ||  notification.pusheraction[0] =='joincom' ? 
                                                        <>
                                                            {
                                                                notification.pusheraction[0] =='groups'  ? 
                                                                <Link to={notification.pusheraction[0] =='groups' ? `/communaute/${notification.idgroup}` : `/communaute/${notification.idgroup}` }  onClick={()=>{this.setNotificationAsReaded(key, notification)}}>
                                                                        <figure 
                                                                            style={notification.imageauteur ? {backgroundImage: 'url('+ Global.API_URL + notification.imageauteur+'t)'} : {}}
                                                                        >
                                                                            {!notification.imageauteur ? notification.nomauteur.charAt(0) : ''}
                                                                        </figure>
                                                                        <div>
                                                                            <p dangerouslySetInnerHTML={{__html: notification.Description}}></p>
                                                                            <abbr>{configs.timeSince(notification.createdAt)}</abbr>
                                                                        </div>
                                                                    </Link>
                                                                    :
                                                                    

                                                                    <Link
                                                                    reloadDocument
                                                                    to={{
                                                                        pathname : `/communaute/${notification.idgroup}/invitations`,
                                                                        state : {id : notification.idgroup}
                                                                    }}
                                                                    onClick={()=>{this.setNotificationAsReaded(key, notification);this.forceCloseNotif()}}>
                                                                        <figure 
                                                                            style={notification.imageauteur ? {backgroundImage: 'url('+ Global.API_URL + notification.imageauteur+')'} : {}}
                                                                        >
                                                                            {!notification.imageauteur ? notification.nomauteur.charAt(0) : ''}
                                                                        </figure>
                                                                        <div className='abd'>
                                                                            <p dangerouslySetInnerHTML={{__html: notification.Description}}></p>
                                                                            <abbr>{configs.timeSince(notification.createdAt)}</abbr>
                                                                        </div>    
                                                                    </Link>
                                                                    
                                                            }
                                                        </>
                                                            :
                                                            <Link to={notification.idpost ? `/communaute/${notification.idgroup}/post/${notification.idpost}` : '#'} onClick={()=>{this.setNotificationAsReaded(key, notification)}}>
                                                                <figure 
                                                                    style={notification.imageauteur ? {backgroundImage: 'url('+ Global.API_URL + notification.imageauteur+')'} : {}}
                                                                >
                                                                    {!notification.imageauteur ? notification.nomauteur.charAt(0) : ''}
                                                                </figure>
                                                                <div>
                                                                    <p dangerouslySetInnerHTML={{__html: notification.Description}}></p>
                                                                    <abbr>{configs.timeSince(notification.createdAt)}</abbr>
                                                                </div>
                                                            </Link>
                                                    }
                                                </li> 
                                            )
                                        })}
                            </InfiniteScroll>

                        </ul>
                    }

                    {
                        this.state.notifications.length === 0 &&
                        <>
                            <center className="no-result">
                            {
                                !this.state.loading &&
                                <span>Aucune notification</span>
                            }
                            </center>
                            {
                                this.state.loading &&
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ margin: 1 }}>
                                        <Skeleton variant="circular" 
                                            style={{
                                                borderRadius: '50%',
                                                width: '50px',
                                                height: '50px',
                                                marginRight: '10px'
                                                }}
                                            >
                                            <Avatar />
                                        </Skeleton>
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Skeleton width="100%" height="50px">
                                        </Skeleton>
                                    </Box>
                                </Box>
                            } 
                        </>
                    } 
                        
                </div>
            </div>
        )
    }
}

export default Notifications;