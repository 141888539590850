import Axios from 'axios';
import React, { useEffect, useState } from 'react'  


import BeatLoader from "react-spinners/BeatLoader";
import Global from '../../inc/Global';
import configs from '../../inc/helpers.js'



const NotificationParam = (props)=> {


    //State 
    const [isAllNotifActive, setAllNotifActive] = useState(false) 
    const [isChatfActive, setChatfActive] = useState(false) 
    const [isAllComunitiesActive, setAllComunitiesActive] = useState(false) 
    const [listing, setListing] = useState([]) 
    const [isLoaded, setLoaded] = useState(false)
    const [isLoadedCom, setLoadedCom] = useState(true)
    const [isStatuLoaded, setStatuLoaded] = useState(true)



    useEffect(() => { 
        getListing()
        showCoSection()
    }, []); 

    const getListing = ()=>{
        Axios.post(`${Global.API_URL}/api/admin/getstatus/2`,[]).then((res) => {
            if( res.data.status ){
                setAllNotifActive(res?.data?.state[0]?.notifapplication)
                setChatfActive(res?.data?.state[0]?.notifmessagerie)
                setAllComunitiesActive(res?.data?.state[0]?.notifgroup) 
            }
            setLoaded(true)
        })
    } 
    const getListCom = ()=>{
        return new Promise((resolve, reject)=>{
            Axios.post(`${Global.API_URL}/api/admin/getstatus/1`,[]).then((res) => {
                if( res.data[0].status ){
                    setListing(res?.data)
                    resolve(res.data)
                }
                setLoaded(true)
            })
            .catch(
                (error) =>{
                    reject(error)
                }
            )
        })
    } 
    const changeStatuAPiCall = (type,state,idgroup=null)=>{
        setStatuLoaded(false)
        let data ={
            type: type,
            action: state? "desactivation" : "activation",
            idgroup: idgroup,
        }
        return new Promise((resolve, reject)=>{
            Axios
            .post(`${Global.API_URL}/api/admin/notifications`,data)
            .then(
                (res) => {
                if( res.data.status ){
                    resolve(res.data)
                }
                setStatuLoaded(true)
            })
            .catch(
                (error) =>{
                    reject(error)
                    getListing()
                    setListing([])
                    setStatuLoaded(true)
                }
            )
            
        })
    } 
   
    
    const changeAllNotifStatu = () =>{
        let OldState = isAllNotifActive
        var comunities = [...listing]
        setAllNotifActive(!isAllNotifActive)
        if (OldState) {
            setChatfActive(false)
            setAllComunitiesActive(false) 
            if (comunities.length>0) {
                comunities.forEach((res)=>{
                    res.stategroup = false
                });
                setListing( [...comunities])
            }
        }
        changeStatuAPiCall(10,OldState)
        .then(res=>{
            if (!OldState) {
                // setChatfActive(res.currentstatemessagerie.statemessagerie)
                // setAllComunitiesActive(res.currentstategrouptotal.statemessagerie ? true : false) 
                getListing()
                if (listing.length>0) {
                    if (listing.length>0) {
                        if (comunities.length>0) {
                            setLoadedCom(false)
                            getListCom().then((res)=>{
                                setLoadedCom(true)
                            })
                        }
                    }
                }
            }
        })
        .catch(
            (error) =>{
                console.log(error);
            }
        )
    }
    

    const changeGlobalNotifstatu = (type) =>{
        let curentState;
        let NewtState;
        switch (type) {
            case 8:
                curentState = isChatfActive;
                NewtState = !isChatfActive;
                setChatfActive(NewtState)
                if(isAllComunitiesActive || NewtState ){
                    setAllNotifActive(true)
                }
                else{
                    setAllNotifActive(false)
                }
                break;
            case 9:
                curentState = isAllComunitiesActive;
                NewtState = !isAllComunitiesActive;
                setAllComunitiesActive(NewtState)
                if(isChatfActive || NewtState ){
                    setAllNotifActive(true)
                }
                else{
                    setAllNotifActive(false)
                }
                
                break;
        
            default:
                break;
        }
        changeStatuAPiCall(type,curentState)
        .then(res=>{
            if (type==9) {
                var comunities = [...listing]
                if(comunities.length>0){
                    if (!NewtState) {
                        comunities.forEach((res)=>{
                            res.stategroup = false
                        });
                        setListing( [...comunities])
                    }else{
                        setLoadedCom(false)
                        getListCom().then((res)=>{
                            setLoadedCom(true)
                        })
                    }
                }
            }
        })
        .catch(
            (error) =>{
                console.log(error);
            }
        )
    }

    const showCoSection = () =>{
        if(listing.length > 0) {
            document.querySelector('.comunitiesList').classList.remove('active');
            const styles = {
                height: "0px"
            };
            let sections = document.querySelector('.comunitiesList');
            Object.assign(sections.style, styles);
            setListing([])
        }
        else{
            setLoadedCom(false)
            getListCom().then((res)=>{
                setTimeout(() => {
                    setLoadedCom(true)
                    document.querySelector('.comunitiesList').classList.add('active');
                    const styles = {
                        height: document.querySelector('.comunitiesListContent').clientHeight + "px"
                    };
                    let sections = document.querySelector('.comunitiesList');
                    Object.assign(sections.style, styles);
                }, 500);
            })
        }
    }
    const calculHowManyComActivated = (array) =>{
        let indexCom = 0
        return new Promise((resolve)=>{
            array.forEach((res,index)=>{
                if(res.stategroup) indexCom++;
                if (index==array.length-1) {
                    resolve(indexCom);
                }
            });
        })
    }


    const changeStatOfComunitie = (idCom) =>{
        var comunities = [...listing]
        var Oldcomunities = [...listing]
        let chatStatu= isChatfActive
        var ind = configs.getIndexWidthPropertyInsideProperty(comunities, 'groups','id', idCom)
        let oldStat = comunities[ind].stategroup
        let newStat = !oldStat
        comunities[ind].stategroup = newStat
        setListing( [...comunities])
        changeStatuAPiCall(0,oldStat,idCom)
        .then(res=>{
            calculHowManyComActivated(comunities).then((nbActive)=>{
                if (nbActive>0) {
                    setAllComunitiesActive(true)
                  }
                  else{
                    setAllComunitiesActive(false)
                    chatStatu ? setAllNotifActive(true) : setAllNotifActive(false)
                  }
            })
        })
        .catch(
            (error) =>{
                console.log(error);
                setListing( [...Oldcomunities])
            }
        )
    }

   


    if( !isLoaded ) return (
        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
    )
    return (
        <section className="content">

            <div className="wrap">

                <div className="header-list">
                    <div className="d-flex jc-space-b align-center">
                        <h3 className="h3"> 
                            <center>
                                <a onClick={()=>{window.history.back()}}>
                                        <span className="fa fa-long-arrow-left"></span>
                                </a> 
                                Paramètres des notifications
                            </center>
                        </h3>  
                    </div>
                </div> 


                <div className="_adminNotif">
                    
                    {/* {
                        !isStatuLoaded &&
                            <div className="loadingStatuChanges">
                                <center><BeatLoader color={"#fff"} loading={true} size="10"/></center>
                            </div>
                    } */}
                    <div className="row">
                        <div className="col">
                            <div className="_adminNotifRowCnt">
                                <div className="activate-option d-flex jc-space-b align-center">
                                    <label htmlFor="_allPltNotif">
                                        <h2 className="boxTitle"> Notifications push</h2>
                                        <p className="boxDescription">Activer ou désactiver toutes les notifications de l'application.</p>
                                    </label>

                                    <div className="param d-flex align-center">
                                        <div className="radio-button">
                                            <input type="checkbox" onChange={(e) => {changeAllNotifStatu()}}  name="_allPltNotif" id="_allPltNotif" checked={isAllNotifActive} />
                                            <label htmlFor="_allPltNotif">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !isAllNotifActive &&
                            <div className="loadingStatuChanges">
                            </div>
                    }
                    <div className="row">
                        <div className="col">
                            <div className="_adminNotifRowCnt">
                                <div className="activate-option d-flex jc-space-b align-center">
                                    <label htmlFor="_chatNotif">
                                        <h2 className="boxTitle">Discussions</h2>
                                        <p className="boxDescription">Activer ou désactiver les notifications de toutes les discussions</p>
                                    </label>

                                    <div className="param d-flex align-center">
                                        <div className="radio-button">
                                            <input type="checkbox" onChange={(e) => {changeGlobalNotifstatu(8)}}  name="_chatNotif" id="_chatNotif" checked={isChatfActive} />
                                            <label htmlFor="_chatNotif">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="_adminNotifRowCnt">
                                <div className="activate-option d-flex jc-space-b align-center">
                                    <div className="listDown" onClick={(e)=>{showCoSection()} }>
                                        <h2 className="boxTitle">
                                            Communautés 
                                            <span>
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </span>
                                        </h2>
                                        <p className="boxDescription">Activer ou désactiver les notifications dans toutes les communautés.</p>
                                    </div>

                                    <div className="param d-flex align-center">
                                        <div className="radio-button">
                                            <input type="checkbox" onChange={(e) => {changeGlobalNotifstatu(9)}}    name="_allComunitiesNotif" id="_allComunitiesNotif" checked={isAllComunitiesActive}/>
                                            <label htmlFor="_allComunitiesNotif">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {
                                    !isLoadedCom &&
                                        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
                                }
                                
                                <div className="comunitiesList">
                                    <div className="comunitiesListContent">
                                        {
                                            listing.map((item,key)=>{
                                                
                                                return(
                                                    <div className="_adminNotifRowCnt" key={key}>
                                                        <div className="activate-option d-flex jc-space-b align-center">
                                                            <label htmlFor={`_ComunitiesNotif-${item.groups.id}`}>
                                                                <h2 className="boxTitle">
                                                                    {item.groups.titre} 
                                                                </h2>
                                                            </label>

                                                            <div className="param d-flex align-center">
                                                                <div className="radio-button">
                                                                    <input type="checkbox" onChange={(e)=>{changeStatOfComunitie(item.groups.id)}} name={`_ComunitiesNotif-${item.groups.id}`} id={`_ComunitiesNotif-${item.groups.id}`} checked={item.stategroup}/>
                                                                    <label htmlFor={`_ComunitiesNotif-${item.groups.id}`}>
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>



                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}
export default NotificationParam
