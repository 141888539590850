import React, { createContext, useState } from 'react';
import Axios from 'axios';
import Pusher from 'pusher-js';
import Global from '../inc/Global';
import configs from '../inc/helpers';

const GlobalContext = createContext();

export function GlobalProvider({children},props){
    const [curentUser,setCurentUser] = useState(configs.getCurrentUser());
    const [notif,setNotif] = useState([]);
    const [dataPush,setDataPush] = useState(null);
    const [loadCount,setLoadCount] = useState(false);
    const [leftSideCom,setLeftSideCom] = useState(false);

    const initPusher=()=>{
        setCurentUser(configs.getCurrentUser());
        let pusherClient = new Pusher(Global.PUSHER.app_key, { cluster: 'eu' }); 
        const channel = pusherClient.subscribe(Global.PUSHER.channel);
        channel.bind(Global.PUSHER.event,(data) => {
            setDataPush(data);
            setLoadCount(true);
            if  (data.pusheraction[0] !== "vue" && data.pusheraction[0] !== "chat"){
                getCount();
            }
            if (data.pusheraction[0]==="posts"){
                getCommunities()
                .then((res) => {
                    setLeftSideCom(res);
                })
                .catch((err) => {
                    // console.log(err.message);
                });
            }
        });
    }

    const getCount = () =>{
        Axios
        .get(Global.API_URL+"/api/notif/count", {})
        .then((res) => {
            if( res.data.state ){
                setNotif(res.data);
            }
      })
    }
    const setloadCountToFalse = () =>{
        setLoadCount(false);
    }

    const getCommunities = async ()=>{

        const data = {
            nbCommunity : 99999999999,
            startPoint : 0,
            idUser : curentUser.user_id,
            type : '',
            hasNotif : true
        }
        return Axios
        .post(Global.API_URL+"/api/getcommunities", data)
    }

    
    return(
        <GlobalContext.Provider value={{
                    notif , 
                    dataPush ,
                    initPusher , 
                    getCount ,
                    loadCount, 
                    setloadCountToFalse,
                    getCommunities,
                    leftSideCom
            }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalContext;