import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Validation from '../../inc/Validation'
import SHA1 from 'sha1'
import axios from 'axios'
import Global from '../../inc/Global'
import logo from '../../assets/img/white-logo.png'
import InputField from '../InputField'
import classnames from 'classnames'
import Cookies from 'universal-cookie';


class ResetForgetPassword extends Component {

    constructor(props) {
        super(props);
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');
        const email = query.get('email');
        const cookies = new Cookies();
        this.state = {
            newpassword: "",
            repeatpassword: "",
            token : token,
            email : email,
            errors: {
                newpassword : true,
                repeatpassword : true
            },
            PasswordIsNotMatched: false,
            PasswordIsIncorrect: false,
            PasswordReseted: false,            
            cguCookie : cookies.get('cgu'),
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        //   if (this.state.cguCookie == '0') {
        //     this.props.history.push('/terminer-inscription/acceptez-les-conditions')
        // }
    }
    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
        if(!Validation.Password(e.target.value)){
            this.setState({
                errors : {
                    [e.target.name]: true,
                }
            })
        }else{
            this.setState({
                errors : {
                    [e.target.name]: false,
                }
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const passwords = {
            newpassword: this.state.newpassword,
            repeatpassword: this.state.repeatpassword,
        };

        if(!this.state.errors.newpassword && !this.state.errors.repeatpassword ){
            if(!Validation.CfrPassword(passwords.newpassword, passwords.repeatpassword)){
                this.setState({
                    PasswordIsNotMatched : true,
                    PasswordIsIncorrect : false
                })
            }else{
                this.setState({
                    PasswordIsNotMatched : false,
                    PasswordIsIncorrect : false
                })
                
                const data = {
                    token: this.state.token,
                    password: SHA1(this.state.newpassword)
                }
                axios
                .post(Global.API_URL+"/reset/password", data)
                .then((res) => {
                    if(res.data.status === "okey"){
                        this.setState({
                            PasswordReseted : true
                        });
                    }else{
                        this.setState({
                            PasswordReseted : false,
                        });
                        // console.log("Email Inconnu");
                        this.props.history.push('/login')
                    }
                })
                .catch((err) => {
                    this.setState({
                        PasswordReseted : false
                    });
                    // console.log('Une erreur s\'est produite lors de la réinitialisation du mot de passe, veuillez contacter l\'administrateur');
                });
            }
        }else{
            this.setState({
                PasswordIsIncorrect : true,
                PasswordIsNotMatched : false
            })
        }
    }
    
    render() {
        if ( this.props.auth.isAuthenticated) {
                this.props.history.push('/')
            return "";
        }
        const { errors, PasswordIsNotMatched, PasswordIsIncorrect, PasswordReseted} = this.state;
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="">
                                            <form id="_login_Form" onSubmit={this.handleSubmit}>
                                            <center className="logo-with-hashtag">
                                                    <a href="javascript:void(0)">
                                                        <img src={Global.APP_LOGO} alt="" />
                                                    </a>
                                                </center>
                                                <div className="bg-white-transparent text-center"> 
                                                    <div className="entry-welcome">
                                                        {!PasswordReseted && 
                                                            <div>
                                                                <div className="p">
                                                                <p>aa Pour plus de sécurité et de confidentialité,
                                                                    choisissez votre mot de passe (minimum 8 caractères,
                                                                    un chiffre et une lettre majuscule).
                                                                </p>
                                                                </div>
                                                            
                                                                <InputField 
                                                                    type="password" 
                                                                    id="_newpassword" 
                                                                    name="newpassword" 
                                                                    label="Le mot de passe" 
                                                                    fixedLabel="false" 
                                                                    classInput="" 
                                                                    classContainer={classnames({
                                                                        "redFieldError": errors.newpassword,
                                                                    })} 
                                                                    value={this.state.newpassword} 
                                                                    oninput={this.handleInputChange} 
                                                                    errorMsg={classnames({
                                                                        "Mot de passe est incorrect": errors.newpassword,
                                                                    })} />

                                                                <InputField 
                                                                    type="password" 
                                                                    id="_repeatpassword" 
                                                                    name="repeatpassword" 
                                                                    label="Confirmer le mot de passe" 
                                                                    fixedLabel="false" 
                                                                    classInput="" 
                                                                    classContainer={classnames({
                                                                        "redFieldError": errors.repeatpassword,
                                                                    })}  
                                                                    value={this.state.repeatpassword} 
                                                                    oninput={this.handleInputChange} 
                                                                    errorMsg={classnames({
                                                                        "Confirmer mot de passe est incorrect": errors.repeatpassword,
                                                                    })} />
                                                            </div>
                                                        }

                                                        {PasswordIsNotMatched && (
                                                            <div className="_alert _alert-danger">Le mot de passe ne correspond pas</div>
                                                        )}
                                                        {PasswordIsIncorrect && (
                                                            <div className="_alert _alert-danger">Erreur : Veuillez vérifier le mot de passe</div>
                                                        )}
                                                        {PasswordReseted && (
                                                            <div>
                                                                <br/>
                                                                <br/>
                                                                <div className="_alert _alert-success">La réinitialisation du mot de passe a réussi, cliquez <Link to="/login" >ici</Link> pour vous connecter</div>
                                                            </div>
                                                        )}
                                                            
                                                        <br />
                                                        <br />
                                                    </div>
                                                    {
                                                        !PasswordReseted &&
                                                        <div className="fixed-footer">
                                                            <input type="submit" className="button lg full m-t-10" value="Réinitialiser" />
                                                        </div>
                                                    }
                                                </div> 
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ResetForgetPassword.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(ResetForgetPassword);