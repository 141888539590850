import React, { Component } from 'react'
import Global from '../../../inc/Global';
import configs from '../../../inc/helpers'
import {Howl, Howler} from 'howler';
import { BeatLoader } from 'react-spinners';
export default class AudioView extends Component {
     
    constructor(props){
        super(props);
        if(!this.props.files || this.props.files.length === 0){
            this.state = {
                files : [],
                filesData:[],
                v:0,
                player : null,
                audioIdPlaying:null
            }
            return;
        }
        
        
        this.state = {
            files : this.props.files,
            filesData:[],
            v:0,
            player : null,
            audioIdPlaying:null
        }
        this.handlePlaySound = this.handlePlaySound.bind(this); 
        this.handleSeek = this.handleSeek.bind(this); 
        this.initSound = this.initSound.bind(this); 
        this.toggleSound = this.toggleSound.bind(this); 
        this.updateProgress = this.updateProgress.bind(this); 


    }
    componentDidMount(){
        if(!this.props.files || this.props.files.length === 0){
            return "";
        }
        var filesDatas = [];
        for(var $i = 0; $i < this.state.files.length; $i++){
            var Ext = configs.getExtention(this.state.files[$i]);  
            if (Ext==='m4a' || Ext==='mp3'|| Ext==='aac'|| Ext==='m4b'|| Ext==='opus' || Ext == 'wav') {
                var file = {};
                file['filename'] = this.state.files[$i];

                file['isPlaying'] = false;
                file['progress'] = 0;
                file['duration'] = 0;
                file['loading'] = false;

                file['link'] = Global.API_URL +"/" + this.state.files[$i];
                filesDatas.push(file);
            }
        }
        if (filesDatas.length>0) {
            this.setState({
                filesData: filesDatas
            })
        }

    }
    componentWillUnmount(){
        console.log('componentWillUnmount....');
        if (this.state.player) {
            this.state.player.stop()
        }

    }

    initSound(url,ind){
        let upStat = this.state.filesData
        upStat[ind].loading=true;
        this.setState({
            filesData : [...upStat]
        })
        this.state.player = new Howl({
            src :[url],
            html5: true,
            onplay:()=>{
                console.log('playing....');
                upStat[ind].isPlaying=true;
                upStat[ind].loading=false;
                upStat[ind].duration=this.state.player.duration();
                this.setState({
                    filesData : [...upStat]
                })
                this.updateProgress()
            }
          })
        this.state.player.play();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            files : nextProps.files
        })
    }
    handlePlaySound(url,ind,state){

        let oldInd = this.state.audioIdPlaying;
        if (this.state.audioIdPlaying==ind) {

            this.setState({
                audioIdPlaying:ind
            })

            if (!this.state.player) {
              this.initSound(url,ind)
            }else{
              this.toggleSound(state)
            }
          }else{
            this.setState({
                audioIdPlaying:ind
            })
            if (this.state.player) {
                let upStat = this.state.filesData
                upStat[oldInd].isPlaying = false
                this.setState({
                    filesData : [...upStat]
                })
                this.state.player.stop()
            }
            this.initSound(url,ind)
          }
    }
    handleSeek(e,ind){
        if (this.state.player && this.state.audioIdPlaying==ind) {
            let upStat = this.state.filesData
            upStat[ind].progress=e.target.value;
            console.log(ind);
            console.log(upStat);
            this.setState({
                filesData : [...upStat]
            })

            let newValue =  +e.target.value;
            let duration = this.state.player.duration();

            this.state.player.seek(duration * (newValue/100));
        }
    }
    toggleSound(state){
        let upStat = this.state.filesData
        upStat[this.state.audioIdPlaying].isPlaying = !state
        this.setState({
            filesData : [...upStat]
        })
        if (state) {
          this.state.player.pause();
        }else{
          this.state.player.play();
        }
    }
    updateProgress(){
        let seek = this.state.player.seek();
        let timing = (this.state.player.duration()- seek) /100
        let upStat = this.state.filesData
        upStat[this.state.audioIdPlaying].duration =timing.toFixed(2).toString().replace('.',':');
        upStat[this.state.audioIdPlaying].progress =  ( seek /  this.state.player.duration() ) * 100 || 0;
        this.setState({
            filesData : [...upStat]
        })
        setTimeout(() => {
          this.updateProgress()
        }, 1000);
    }
    render() {
        
        return (
            <div className="file">
                {this.state.filesData.map((file, index) => (
                    <div key={"file-"+index} className="d-flex align-center justify-content-between">
                        <div className="img-doc">
                                {
                                    file.isPlaying ?
                                    <div className="soundIcon" onClick={(e)=>{this.handlePlaySound(file.link,index,true)}}>
                                        <ion-icon name="pause-outline"  ></ion-icon>
                                    </div>
                                    :
                                    <div className="soundIcon" onClick={(e)=>{this.handlePlaySound(file.link,index,false)}}>
                                        <ion-icon name="play-outline"  ></ion-icon>
                                    </div>
                                }
                        </div>
                        <div className="nam-doc soundRang">
                        <div className="container">

                                <div className="range-slider">
                                    <input  className="rs-range" type="range" name={`range-${index}`} max="100" value={file.progress} onChange={(e)=>{this.handleSeek(e,index)}} />
                                </div>
                                
                            </div>
                        </div>
                        <div className="duration">
                            {file.loading ?
                                <BeatLoader color={"#aaa"} loading={true} size="4"/>
                                :
                                <>
                                    {
                                        file.duration ==0 ?
                                            <span >00:00</span>
                                        :
                                            <span >{file.duration}</span>
                                    }
                                </>
                            }
                            
                            
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}
