import React, { Component } from 'react'
import ReactDOM from "react-dom";
// import ProfileMenu from './ProfileMenu'
import {Link, NavLink} from 'react-router-dom'
import SearchForm from '../search-form/SearchForm'
import Notifications from './Notifications'
import ChatList from './Chat'
import BreakingNews from './BreakingNews'
import Global from '../../inc/Global'

import logo from '../../assets/img/logo.png'

import Posts from '../publications/Posts'
import ProfileMenu from './ProfileMenu';


var self

class Header extends Component {  

    constructor(props){ 
        super(props) 

        self = this

        this.state = {
            appLogo: Global.APP_LOGO
        }
    }

    static setAppLogo(logo){ 
        if( self ){
            self.setState({
                appLogo: logo
            })
        }
    }

    reloadIfImInPage(url){
        const curentUrl = this.props.location.pathname
        if(curentUrl!==url){
            this.props.history.push(url)
        }
        else{
            window.location.reload(false);
        }
    }

    render() {
        return (
            <>
                <header className="header" id="header">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b align-center header-flex">
                                <div className="brand">
                                    {
                                        this.state.appLogo &&
                                        <Link   to="/" onClick={(e)=>{e.preventDefault();window.location.href = "/"}}>
                                            <img src={this.state.appLogo} alt="Logo" /> 
                                            {/* <img  src={logo} alt="Wearelinked logo" /> */}
                                        </Link>
                                    }
                                    
                                    <SearchForm referer="header" />
                                </div> 

                                <div className="navmenu">
                                    <ul>
                                        <li>
                                            {/* <NavLink exact to="/" activeClassName="active"> */}
                                            <NavLink exact to="/" activeClassName="active"
                                            onClick={(e)=>{e.preventDefault();this.reloadIfImInPage('/')}}  >
                                                <i className="custom-icon custom-icon-home"></i>
                                                <span>Accueil</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink  exact to="/communautes" activeClassName="active">
                                                <i className="custom-icon custom-icon-communautes"></i>
                                                <span>Communautés</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/utilisateurs" activeClassName="active">
                                                <i className="custom-icon custom-icon-user"></i>
                                                <span>Contacts</span>
                                            </NavLink>
                                        </li> 
                                    </ul>
                                </div>

                                <div className="dropdowns d-flex align-center">
                                    {/* <div className="d-flex jc-space-b align-center"> */}
                                    <ChatList />
                                    <Notifications />
                                    <div className="d-flex jc-space-fe align-center">
                                        <ProfileMenu />

                                        {/* <MainMenu /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <BreakingNews />
            </>
        )
    }
}

export default Header;
