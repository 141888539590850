import React, { Component, useEffect, useState } from 'react'
import configs from '../../inc/helpers'
import Global from '../../inc/Global';
import Axios from 'axios';
import VideoView from '../publications/template-parts/VideoView.js';

//Owl carousel
import OwlCarousel, {r} from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; 
var _getListing
const RightSidebar = (props)=>{

    const [listing, setListing] = useState([])
    const [options, setOptions] = useState({
        autoplay:true,
        autoplayTimeout:4000,
        autoplayHoverPause:true,
        loop:true,
        autoHeight: false,
        items: 1,    
        margin: 0,
        nav: false,
        dots: false
    })
    /// Owl Carousel
    

    const getListing = ()=>{
        Axios.get(Global.API_URL + "/admin/getlist").then((res) => {
            if( res.data.status ) {
                setListing(res.data.result)
                setTimeout(() => {
                    setOptions({
                        ...options,
                        autoHeight: true
                    })
                }, 500);
            }  

        })
    } 

    useEffect(() => { 
        getListing()
        _getListing = getListing
    }, []); 

    return (
        <aside className="right-sidebar" id="right-sidebar">
            <div className="wrapper">
                {/* Temporary */}
                <div className="widget">
                    <div className="widget-content"> 
                        {
                            listing.length > 0 &&
                            <OwlCarousel options={options} events={{}} style={{maxWidth:330}}> 
                                {listing.map(item=>(
                                    <div>
                                        {
                                            item.imageplatform 
                                            ?
                                                <a href={item.link && item.link!='' ? item.link : ()=> {javascript: void(0);} } target="_blank">
                                                    <img src={`${Global.API_URL}/${item.imageplatform}`} style={{borderRadius: 0}}  alt=""/>
                                                </a>
                                            :
                                                <VideoView 
                                                    videoUrl={item.video ? Global.API_URL + "/" + item.video : ""} 
                                                    poster={item.thumbs ? Global.API_URL + "/" + item.thumbs : ""} 
                                                />
                                        }
                                        
                                    </div>
                                ))}
                            </OwlCarousel>
                        } 
                    </div>
                </div>
                {/* End Temporary */}

                {/* <Events /> */}

                {/* <Sondage /> */}
            </div>
        </aside>
    )
} 

RightSidebar._getListing = ()=>{ 
    _getListing()
}

export default RightSidebar;