import React, { Component } from 'react'
import { Link,NavLink } from 'react-router-dom'
import Global from '../../inc/Global.js'
import configs from '../../inc/helpers.js';

class ItemCommunity extends Component {
    render() {
        const { 
            ID,
            Color,
            Invitation,
            Logo,
            //NBAdmin,
            NBUser,
            Cover,
            Subject,
            Title,
            Type,
            UserEmail,
            //UserID,
            Role,
            NbPostNonLu,
            isMyCommunity,
            handleJoinCommunity,
            handleDelete
        } = this.props;
        
        const APIURL = Global.API_URL;
        
        return (
            <li className={"_ursl-listitem community-"+ID} id={"community-"+ID}>
                <div className="header-post">
                    <br />
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Logo ?
                                {backgroundImage: 'url('+ APIURL+ Logo +')' } :
                                {}
                            }> 
                            
                            {   
                               isMyCommunity || Type == 'public' || Role === "ROLE_ADMIN" || configs.isAdmin() ? 
                                  !isMyCommunity ? 
                                    Type === 'secret'  ? 
                                        <NavLink 
                                            to={{
                                                pathname : `/communaute/${ID}/parametres`,
                                                state : {
                                                    id : ID,
                                                    logo : Logo,
                                                    cover : Cover,
                                                    title : Title,
                                                    type : Type,
                                                    subject : Subject,
                                                    nbuser : NBUser,
                                                    invitation : 0,
                                                    email : UserEmail,
                                                    color : Color
                                                }
                                            }}
                                            className="a" 
                                        >{!Logo ? Title.charAt(0) :'' }</NavLink>  
                                            : 
                                        <Link to={"/communaute/" + ID} className="a" >{!Logo ? Title.charAt(0) :'' }</Link> 
                                    :
                                    <Link to={"/communaute/" + ID} className="a" >{!Logo ? Title.charAt(0) :'' }</Link> 
                               : 
                               !Logo ? Title.charAt(0) :''
                            }
                            {
                                NbPostNonLu > 0 &&
                                <span className="badge">{NbPostNonLu}</span>
                            }
                        </figure>
                        <div className="">
                            {/* <div className="username">
                                {   
                                   isMyCommunity || Type == 'public' ||  Role === "ROLE_ADMIN" || configs.isAdmin() ? <Link to={"/communaute/" + ID} >{Title}</Link> : Title
                                }
                            </div> */}
                            <div className="username">
                                {   
                                    isMyCommunity || Type == 'public' || Role === "ROLE_ADMIN" || configs.isAdmin() ? 
                                        !isMyCommunity ? 
                                            Type === 'secret'  ? 
                                                <NavLink 
                                                    to={{
                                                        pathname : `/communaute/${ID}/parametres`,
                                                        state : {
                                                            id : ID,
                                                            logo : Logo,
                                                            cover : Cover,
                                                            title : Title,
                                                            type : Type,
                                                            subject : Subject,
                                                            nbuser : NBUser,
                                                            invitation : 0,
                                                            email : UserEmail,
                                                            color : Color
                                                        }
                                                    }}
                                                    className="a" 
                                                >{Title}</NavLink>  
                                                    : 
                                                <Link to={"/communaute/" + ID} className="a" >{Title}</Link> 
                                            :
                                            <Link to={"/communaute/" + ID} className="a" >{Title}</Link> 
                                    : 
                                    Title
                                }
                            </div>
                            <div className="p">
                                <span>{Subject}</span>
                            </div>

                            <div className="_cmitem-ft d-flex jc-space-b align-center">
                                <div className="">
                                    {/* <span className="fa fa-users"></span> */}
                                    {
                                        NBUser > 0 &&
                                        <span>
                                            <span className="custom-icon custom-icon-users"></span>
                                            <span className="_nburs">
                                                {Math.abs(NBUser) > 999 ? Math.sign(NBUser)*((Math.abs(NBUser)/1000).toFixed(1)) + 'k' : Math.sign(NBUser)*Math.abs(NBUser)}
                                            </span>&nbsp;&nbsp;
                                        </span>
                                    }  
                                    {Type=== "private" && 
                                        <span className="custom-icon custom-icon-private"></span>
                                    }
                                    {Type=== "secret" && 
                                        <span className="custom-icon custom-icon-secret"></span>
                                    }
                                    
                                </div>
                                <div className="">
                                    { isMyCommunity   ?
                                        <span className="">
                                        {/* <span className="_nbof-members"> */}
                                            {/* {Math.abs(NBUser) > 999 ? Math.sign(NBUser)*((Math.abs(NBUser)/1000).toFixed(1)) + 'k' : Math.sign(NBUser)*Math.abs(NBUser)} */}
                                        </span>
                                        :
                                        <>
                                            {(Type=== "private" || Type=== "public") && 
                                                <button className="button" data-type={Type} data-id={ID} onClick={handleJoinCommunity}><i className="fa fa-spin fa-spinner"></i> Rejoindre </button>
                                            }
                                            {Type=== "secret" && 
                                                <span className=""></span>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </li>
        )
    }
}
export default ItemCommunity;