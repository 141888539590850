import React, { Component } from 'react'
import InputField from '../InputField'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Global from '../../inc/Global'
import { connect } from 'react-redux'
import axios from "axios";
import { Link } from 'react-router-dom'
import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../../inc/ImageLib';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import configs from '../../inc/helpers'
import SHA1 from 'sha1'


const imageMaxSize = 5242880 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})



class UpdateCommunity extends Component {
    constructor(props){
        super(props)
        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }
        
        const params = this.props.location.state;
       

        if(params.type === "public"){
            this.default = {value: 'public', label: 'Communauté publique'}
        }else if(params.type === "private"){
            this.default = {value: 'private', label: 'Communauté privée'}
        }else{
            this.default = {value: 'secret', label: 'Communauté secrète'}
        }
        // console.log(params)
        this.state = {
            title : params.title,
            subject : params.subject,
            type : params.type,
            typeObj : this.default,
            email : this.props.auth.user.username,
            selectedColor : params.color,
            step : 0,
            communityID : params.id,
            communityLogo : false,
            userProfil : "",
            username : "",
            userLastname : "",
            errors : {},
            logo : {
                imgSrc : null,
                dragIsActive : false,
                imgSrcExt: null,
                profilSrc : params.logo ? Global.API_URL + params.logo : null,
                imgFile : null,
                orgWidthAspect: null,
                crop :{ 
                    aspect: 1/1,
                    unit: '%'
                }
            },
            coverImg : {
                imgSrc : null,
                dragIsActive : false,
                imgSrcExt: null,
                profilSrc : params.cover ? Global.API_URL + params.cover : null,
                imgFile : null,
                orgWidthAspect: null,
                crop :{ 
                    aspect: 16/9,
                    unit: '%'
                }
            }
        }

        this.canvasLogoRef = React.createRef(null);
        this.canvasCoverImgRef = React.createRef(null);
        this.CreateBtn = React.createRef(null);
        this.formEditCom = React.createRef(null);
        
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedColor: event.target.value
        });
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleSelectChange = (type) => {
        // console.log(type.value)
        this.setState({ 
            type: type.value,
            typeObj: type,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        var Valid = true;
        var errors = {
            title : false,
            type : false,
            selectedColor : false,
        };

        if(!this.state.title){
            Valid = false;
            errors['title'] = true;
        }else{
            errors['title'] = false;
        }
        if(!this.state.subject){
            Valid = false;
            errors['subject'] = true;
        }else{
            errors['subject'] = false;
        }
        if(!this.state.type){
            Valid = false;
            errors['type'] = true;
        }else{
            errors['type'] = false;
        }
        
        // if(!this.state.selectedColor){
        //     Valid = false;
        //     errors['selectedColor'] = true;
        // }else{
        //     errors['selectedColor'] = false;
        // }
        
        this.setState({
            errors : errors
        })
        if(Valid){
            const btn = this.CreateBtn.current;
            btn.classList.add('on');
            var data = new FormData();
            data.append('cover',this.state.coverImg.imgFile);
            data.append('logo' ,this.state.logo.imgFile);
            data.append('email' ,this.state.email);
            data.append('titre' ,this.state.title);
            data.append('couleur' ,this.state.selectedColor);
            data.append('type' ,this.state.type);
            data.append('sujet' ,this.state.subject);
            data.append('idgroup' ,this.state.communityID);

            
                
            axios
            .post(Global.API_URL+"/allupdate", 
                data, 
                { headers: {
                    'content-type': 'multipart/form-data'
                    }
                }
            )
            .then((res) => {
                btn.classList.remove('on');
                this.props.history.push({
                    pathname :`/communaute/${res.data.groupe.id}/`,//parametres
                    state : {
                        id : res.data.groupe.id,
                        logo : res.data.groupe.logo,
                        cover : res.data.groupe.photocouverture,
                        title : res.data.groupe.titre,
                        type : res.data.groupe.typegroupe,
                        subject : res.data.groupe.sujet,
                        nbuser : res.data.groupe.users.length,
                        invitation : 0,
                        email : this.props.auth.user.username,
                        color : res.data.groupe.couleur
                    }
                });
            
            })
            .catch((err) => {
                // console.log(err.message);
                btn.classList.remove('on');
            });
            //this.CreateBtn.current.classList.remove('on')
        
            
            return;
        }
    }
    // handleCreateCommunity (event){
    //     event.preventDefault();
    //     const btn = this.CreateBtn.current;
    //     btn.classList.add('on');

    //     var data = new FormData();
    //     data.append('cover',this.state.coverImg.imgFile);
    //     data.append('logo' ,this.state.logo.imgFile);
    //     data.append('email' ,this.state.email);
    //     data.append('titre' ,this.state.title);
    //     data.append('couleur' ,this.state.selectedColor);
    //     data.append('type' ,this.state.type);
    //     data.append('sujet' ,this.state.subject);
    //     data.append('idgroup' ,this.state.communityID);
    //     // const dt = {
    //     //     title:this.state.title,
    //     //     sujet:this.state.subject,
    //     //     type:this.state.type,
    //     //     couleur: this.state.selectedColor,
    //     //     email:this.state.email
    //     // }
    //     // console.log(dt)
    //     if(!this.state.communityID){

           
    //         axios
    //         .post(Global.API_URL+"/creategroupall", 
    //         data, 
    //         { headers: {
    //             'content-type': 'multipart/form-data'
    //                 }
    //             }
    //         )
    //         .then((res) => {
                
    //             if(res.data.id){
    //                 this.props.history.goBack();
    //                 btn.classList.remove('on');
    //             }
               
    //         })
    //         .catch((err) => {
    //             // console.log(err.message);
    //             btn.classList.remove('on');
    //         });
    //     } else {
            
    //         const clr = this.state.selectedColor.replace("#", "%23");
    //         axios
    //         .post(Global.API_URL+"/allupdate/"+this.state.communityID+"/"+this.state.email+"/"+this.state.title+"/"+this.state.subject+"/"+clr+"/"+this.state.type, 
    //             data, 
    //             { headers: {
    //                 'content-type': 'multipart/form-data'
    //                 }
    //             }
    //         )
    //         .then((res) => {
    //             this.props.history.goBack();
    //         })
    //         .catch((err) => {
    //             // console.log(err.message);
    //             btn.classList.remove('on');
    //         });
    //         //this.CreateBtn.current.classList.remove('on')
    //     }
    // }
    goBack = (event) => {
        event.preventDefault();
        this.setState({
            step : this.state.step - 1
        })
    }
    // Upload Logo Functons
    handleOnCropLogoChange(crop){
        crop.aspect = 1;
        var logo = {...this.state.logo}
            logo.crop = crop;
            
        this.setState({
            logo : logo
        });
    }
    handleSaveLogoClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state.logo
        if (imgSrc) {
        
            const canvasRef = this.canvasLogoRef.current
        
            const {imgSrcExt} =  this.state.logo
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            
            const myFilename = "logo-community-"+ SHA1(new Date()) +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            var logo = {...this.state.logo}
                logo.profilSrc = imageData64;
                logo.imgFile = myNewCroppedFile;
                logo.imgSrc = null;
                logo.dragIsActive = false;
            await this.setState({
                logo : logo
            }); 
           
        }
    }

    handleOnCropLogoComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasLogoRef.current
        
        const {imgSrc}  = this.state.logo
        // console.log(this.state.logo.orgWidthAspect)
        const newCrop = {
          axpect : 1,
          x: (crop.x * this.state.logo.orgWidthAspect),
          y: (crop.y * this.state.logo.orgWidthAspect),
          width: (crop.width * this.state.logo.orgWidthAspect),
          height: (crop.height * this.state.logo.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    handleImageLogoLoaded = image => {
        var __Logo = {...this.state.logo}
            __Logo.orgWidthAspect = image.naturalWidth / image.width;
        
        // console.log(__Logo);
        

        if(image.width > image.height){
            
            __Logo.crop = {width: (image.height - 50), height: (image.height - 50), x :25, y: 25 }
        }else{
            __Logo.crop = {width: (image.width - 50), height: (image.width - 50), x :25, y: 25 }
        }
        
        const canvasRef = this.canvasLogoRef.current
        const {imgSrc}  = __Logo
        
        const newCrop = {
            axpect : 1,
            x: (__Logo.crop.x * __Logo.orgWidthAspect),
            y: (__Logo.crop.y * __Logo.orgWidthAspect),
            width: (__Logo.crop.width * __Logo.orgWidthAspect),
            height: (__Logo.crop.height * __Logo.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        // console.log(__Logo);
        this.setState({
            logo: __Logo
        });
        return false; // Return false when setting crop state in here.
    };
    
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.file.type
            const currentFileSize = currentFile.file.size
            
            if(currentFileSize > imageMaxSize) {
                alert("Ce fichier n'est pas autorisé, " + currentFileSize + " octets est trop grand")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("Ce fichier n'est pas autorisé. Seules les images sont autorisées.")
                return false
            }
            return true
        }
    }
    
    handleOndropLogo = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", ()=>{
                
                const myResult = myFileItemReader.result
                
                var logo = {...this.state.logo}
                    logo.imgSrc = myResult;
                    logo.imgSrcExt = extractImageFileExtensionFromBase64(myResult);
                this.setState({
                    logo : logo
                })

                
            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEnterLogo = (DragEvent) => {
        var logo = {...this.state.logo}
            logo.dragIsActive = true;
        this.setState({logo : logo});
    }
    handleOnDragLeaveLogo = (DragEvent) => {
        var logo = {...this.state.logo}
            logo.dragIsActive = false;
        this.setState({logo : logo});
    }
    handleLogoAcceptButton = (event) => {
        event.preventDefault();
        
        this.setState({
            step : this.state.step + 1
        })
    }
    //---------------------------------------------------

    handleOnCropcoverImgChange(crop){
        crop.aspect = 16 / 9;
        var coverImg = {...this.state.coverImg}
            coverImg.crop = crop;
        this.setState({
            coverImg : coverImg
        });
    }
    handleSavecoverImgClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state.coverImg
        if (imgSrc) {
        
            const canvasRef = this.canvasCoverImgRef.current
        
            const {imgSrcExt} =  this.state.coverImg
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            
            const myFilename = "cover-community-"+ SHA1(new Date()) +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            var coverImg = {...this.state.coverImg}
                coverImg.profilSrc = imageData64;
                coverImg.imgFile = myNewCroppedFile;
                coverImg.imgSrc = null;
                coverImg.dragIsActive = false;
            await this.setState({
                coverImg : coverImg
            }); 
            
           
        }
    }

    handleOnCropcoverImgComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasCoverImgRef.current
        const {imgSrc}  = this.state.coverImg
        
        const newCrop = {
            aspect : 16/9,
            x: (crop.x * this.state.coverImg.orgWidthAspect),
            y: (crop.y * this.state.coverImg.orgWidthAspect),
            width: (crop.width * this.state.coverImg.orgWidthAspect),
            height: (crop.height * this.state.coverImg.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    handleImagecoverImgLoaded = image => {
        var coverImg = {...this.state.coverImg}
            coverImg.orgWidthAspect = image.naturalWidth / image.width;
        
        if(image.width > image.height){
            coverImg.crop = {width: ((image.height * (16/9)) - 50), height: (image.height - 50), x :25, y: 25 }
        }else{
            coverImg.crop = {width: (image.width - 50), height: ((image.width * (9/16)) - 50), x :25, y: 25 }
        }
        
        const canvasRef = this.canvasCoverImgRef.current
        const {imgSrc}  = coverImg
        
        const newCrop = {
            aspect : 16 / 9,
            x: (coverImg.crop.x * coverImg.orgWidthAspect),
            y: (coverImg.crop.y * coverImg.orgWidthAspect),
            width: (coverImg.crop.width * coverImg.orgWidthAspect),
            height: (coverImg.crop.height * coverImg.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        this.setState({
            coverImg: coverImg
        });
        return false; // Return false when setting crop state in here.
    };
    
    handleOndropcoverImg = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", ()=>{
                
                const myResult = myFileItemReader.result
                
                var coverImg = {...this.state.coverImg}
                    coverImg.imgSrc = myResult;
                    coverImg.imgSrcExt = extractImageFileExtensionFromBase64(myResult);
                this.setState({
                    coverImg : coverImg
                })

                
            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEntercoverImg = (DragEvent) => {
        var coverImg = {...this.state.coverImg}
            coverImg.dragIsActive = true;
        this.setState({coverImg : coverImg});
    }
    handleOnDragLeavecoverImg = (DragEvent) => {
        var coverImg = {...this.state.coverImg}
            coverImg.dragIsActive = false;
        this.setState({coverImg : coverImg});
    }
    handlecoverImgAcceptButton = (event) => {
        event.preventDefault();
        
        this.setState({
            step : this.state.step + 1
        });
       
        axios
        .get(Global.API_URL + "/api/checkprofil/" + SHA1(new Date()))
        .then((res) => {
            this.setState({
                userProfil : res.data.image,
                username : res.data.prenom,
                userLastname : res.data.nom,
            })
        });
    }

    confirmRemoveCover(){
        this.setState({deleteCoverConfirmation: false})
        axios 
        .post(Global.API_URL + "/api/deletecover/" + this.state.communityID)
        .then((res) => {
            this.setState({
                coverImg: {
                    imgSrc : null,
                    dragIsActive : false,
                    imgSrcExt: null,
                    profilSrc : null,
                    imgFile : null,
                    orgWidthAspect: null,
                    crop :{ 
                        aspect: 16/9,
                        unit: '%'
                    }
                }
            })
        });
    }
    confirmRemoveLogo(){
        this.setState({deleteLogoConfirmation: false})

        this.setState({
                logo: {
                    imgSrc : null,
                    dragIsActive : false,
                    imgSrcExt: null,
                    profilSrc : null,
                    imgFile : null,
                    orgWidthAspect: null,
                    crop :{ 
                        aspect: 16/9,
                        unit: '%'
                    }
                }
            })
        // this.formEditCom.form.submit();
        
        axios 
        .post(`${Global.API_URL}/api/groups/${this.state.communityID}/delete-logo`)
        .then((res) => {

            if (res.data.status) {
                this.setState({
                    logo: {
                        imgSrc : null,
                        dragIsActive : false,
                        imgSrcExt: null,
                        profilSrc : null,
                        imgFile : null,
                        orgWidthAspect: null,
                        crop :{ 
                            aspect: 16/9,
                            unit: '%'
                        }
                    }
                })
            }
        });


    }
    render() {
            return (
                <div style={{width: '100%'}}>
                    <section className="content update-community">
                        <div className="wrap">
                            <div className="header-list">
                                <div className="d-flex jc-space-b align-center">
                                    <h3 className="h3"> 
                                        <center>
                                            <Link onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                                    <span className="fa fa-long-arrow-left"></span>
                                            </Link> 
                                            Modification de la communauté
                                        </center>
                                    </h3>  
                                </div>
                            </div> 
                            <div className="_cnu-tabs"> 
                                <div className="bg-white brts-10">
                                    <div className="tab _p-photo d-flex jc-center">
                                        <div className="select-profil" style={{width: '100%'}}>
                                            {this.state.coverImg.imgSrc && 
                                                <div className="_modal" id="CropImageModal">
                                                    <div className="_overlay"></div>
                                                    <div className="_modal-wrap">
                                                        <div className="_modal-content">
                                                            <div className="_modal-header">
                                                                <h2>Recadrer l'image</h2>
                                                            </div>
                                                            <div className="_modal-body">
                                                                <div className="d-flex f-wrap"> 
                                                                    <div className="rightSidebar">
                                                                        <canvas ref={this.canvasCoverImgRef}></canvas>
                                                                    </div>
                                                                    <div className="_modal-body-wrap crop">
                                                                        <ReactCrop 
                                                                            src={this.state.coverImg.imgSrc} 
                                                                            crop={this.state.coverImg.crop} 
                                                                            onChange={this.handleOnCropcoverImgChange.bind(this)} 
                                                                            onComplete={this.handleOnCropcoverImgComplete} 
                                                                            onImageLoaded={this.handleImagecoverImgLoaded}
                                                                            />
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="_modal-footer text-right">
                                                                <span onClick={this.handleSavecoverImgClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                            </div>
                                                        </div>   
                                                    </div>
                                                </div>
                                            }
                                            
                                            <Dropzone 
                                                accept={acceptedFileTypes} 
                                                onDrop={this.handleOndropcoverImg} 
                                                multiple={false} 
                                                maxSize={imageMaxSize}
                                                onDragEnter={this.handleOnDragEntercoverImg}
                                                onDragLeave={this.handleOnDragLeavecoverImg} >
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                    
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <span className="img cover brts-10" 
                                                            style={this.state.coverImg.profilSrc ? 
                                                                {backgroundImage: 'url('+this.state.coverImg.profilSrc+')' } : 
                                                                {backgroundColor: this.state.selectedColor }
                                                            }>
                                                            <span className="edit-icon fa fa-edit"></span>
                                                        </span>
                                                        
                                                    </div>
                                                </section>
                                            )}
                                            </Dropzone>

                                            {
                                                this.state.coverImg.profilSrc &&
                                                <div>
                                                    <span onClick={()=>{this.setState({deleteCoverConfirmation: true})}} className="remove-icon fa fa-trash"></span> 
                                                    {
                                                        this.state.deleteCoverConfirmation && 
                                                        <div className="_modal confirmation">
                                                            <div className="_overlay"></div>
                                                            <div className="_modal-wrap">
                                                                <div className="_modal-content">
                                                                    <div className="_modal-header">
                                                                        <h2>Êtes-vous sûr de vouloir supprimer la photo de couverture ?</h2>
                                                                    </div>
                                                                    <div className="_modal-body">
                                                                    <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                                        <button onClick={()=>{this.setState({deleteCoverConfirmation: false})}} className="button wt lg">Annuler</button>
                                                                        <button onClick={()=>{this.confirmRemoveCover()}} className="button lg">
                                                                            Supprimer
                                                                        </button>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                                    
                                            
                                            {/* <input type="file" className="ph-profil" id="ph-profil" name="ph-profil" onChange={this.onImageSelected.bind(this)} /> */}
                                        </div>
                                        <div className="select-profil logo">
                                            {this.state.logo.imgSrc && 
                                                <div className="_modal" id="CropImageModal">
                                                    <div className="_overlay"></div>
                                                    <div className="_modal-wrap">
                                                        <div className="_modal-content">
                                                            <div className="_modal-header">
                                                                <h2>Recadrer l'image</h2>
                                                            </div>
                                                            <div className="_modal-body">
                                                                <div className="d-flex f-wrap"> 
                                                                    <div className="rightSidebar">
                                                                        <canvas ref={this.canvasLogoRef}></canvas>
                                                                    </div>
                                                                    <div className="_modal-body-wrap crop">
                                                                        <ReactCrop 
                                                                            src={this.state.logo.imgSrc} 
                                                                            crop={this.state.logo.crop} 
                                                                            onChange={this.handleOnCropLogoChange.bind(this)} 
                                                                            onComplete={this.handleOnCropLogoComplete} 
                                                                            onImageLoaded={this.handleImageLogoLoaded}
                                                                            />
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="_modal-footer text-right">
                                                                <span onClick={this.handleSaveLogoClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                            </div>
                                                        </div>   
                                                    </div>
                                                </div>
                                            }
                                            
                                            <Dropzone
                                                accept={acceptedFileTypes}
                                                onDrop={this.handleOndropLogo}
                                                multiple={false}
                                                maxSize={imageMaxSize}
                                                onDragEnter={this.handleOnDragEnterLogo}
                                                onDragLeave={this.handleOnDragLeaveLogo} >
                                            {({getRootProps, getInputProps}) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <span className="img" data-srca={this.state.logo.profilSrc} 
                                                            style={this.state.logo.profilSrc ? 
                                                                {backgroundImage: 'url('+this.state.logo.profilSrc+')' } : 
                                                                {}
                                                            }>
                                                            {!this.state.logo.profilSrc? this.state.title.charAt(0) : ''}
                                                            <span className={ this.state.logo.profilSrc  ? "edit-icon fa fa-edit" : "edit-icon center fa fa-edit" }></span>
                                                            
                                                        </span>                                                        
                                                    </div>
                                                </section>
                                            )}
                                            </Dropzone>
                                            {
                                               this.state.logo.profilSrc &&
                                                    <span onClick={()=>{this.setState({deleteLogoConfirmation: true})}} className="remove-icon fa fa-trash"></span> 
                                            }
                                                {
                                                    this.state.deleteLogoConfirmation && 
                                                    <div className="_modal confirmation">
                                                        <div className="_overlay"></div>
                                                        <div className="_modal-wrap">
                                                            <div className="_modal-content">
                                                                <div className="_modal-header">
                                                                    <h2>Êtes-vous sûr de vouloir supprimer le logo de la communauté ?</h2>
                                                                </div>
                                                                <div className="_modal-body">
                                                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                                                    <button onClick={()=>{this.setState({deleteLogoConfirmation: false})}} className="button wt lg">Annuler</button>
                                                                    <button onClick={()=>{this.confirmRemoveLogo()}} className="button lg">
                                                                        Supprimer
                                                                    </button>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                    
                                            
                                            {/* <input type="file" className="ph-profil" id="ph-profil" name="ph-profil" onChange={this.onImageSelected.bind(this)} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="wrap">
                            <div className="_add-evso">

                                <div className="bg-white _add-evso-form">
                                    <form onSubmit={this.handleSubmit} id="_Form" ref={this.formEditCom}>
                                        <InputField 
                                            type="text" 
                                            id="_title" 
                                            name="title" 
                                            label="Titre de la communauté" 
                                            fixedLabel="false" 
                                            classInput="" 
                                            classContainer={classnames({
                                                " hasError ": this.state.errors.title,
                                            })} 
                                            value={this.state.title} 
                                            oninput={this.handleInputChange} 
                                            errorMsg={classnames({
                                                "Le titre est obligatoire": this.state.errors.title,
                                            })} />
                                        

                                        <InputField 
                                            type="textarea" 
                                            id="_subject" 
                                            name="subject" 
                                            label="Sujet" 
                                            fixedLabel="false" 
                                            classInput="" 
                                            classContainer={classnames({
                                                " hasError ": this.state.errors.subject,
                                            })} 
                                            value={this.state.subject} 
                                            oninput={this.handleInputChange} 
                                            errorMsg={classnames({
                                                "Le sujet est obligatoire": this.state.errors.subject,
                                            })} />
 
                                        {
                                            this.state.type !== 'defaut' &&
                                            <InputField 
                                                type="select" 
                                                id="_type" 
                                                name="type" 
                                                label="Type" 
                                                fixedLabel="true" 
                                                placeholder=""
                                                items={[
                                                    { value: 'public', label: 'Communauté publique' },
                                                    { value: 'private', label: 'Communauté privée' },
                                                    { value: 'secret', label: 'Communauté secrète' },
                                                ]}
                                                classInput="" 
                                                classContainer={classnames({
                                                    " hasError ": this.state.errors.type,
                                                })} 
                                                value={this.state.typeObj} 
                                                selectedValue={{ value: 'public', label: 'Communauté publique' }} 
                                                default={this.state.typeObj}
                                                oninput={this.handleSelectChange} 
                                                errorMsg={classnames({
                                                    "Le type est obligatoire": this.state.errors.type,
                                                })} />
                                        }
                                        

                                        {
                                            this.state.type == 'default' &&
                                            <div className="color-field-list">
                                                <label className="color_label">Couleur</label>
                                                <div className="color-list">
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_1" value="#ff8a80" checked={this.state.selectedColor === "#ff8a80"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_1" style={{backgroundColor: '#ff8a80'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_2" value="#ea80fc" checked={this.state.selectedColor === "#ea80fc"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_2" style={{backgroundColor: '#ea80fc'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_3" value="#b387ff" checked={this.state.selectedColor === "#b387ff"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_3" style={{backgroundColor: '#b387ff'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_4" value="#8c9eff" checked={this.state.selectedColor === "#8c9eff"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_4" style={{backgroundColor: '#8c9eff'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_5" value="#80d8fe" checked={this.state.selectedColor === "#80d8fe"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_5" style={{backgroundColor: '#80d8fe'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_6" value="#83ffff" checked={this.state.selectedColor === "#83ffff"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_6" style={{backgroundColor: '#83ffff'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_7" value="#fe1743" checked={this.state.selectedColor === "#fe1743"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_7" style={{backgroundColor: '#fe1743'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_8" value="#d500fa" checked={this.state.selectedColor === "#d500fa"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_8" style={{backgroundColor: '#d500fa'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_9" value="#6420ff" checked={this.state.selectedColor === "#6420ff"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_9" style={{backgroundColor: '#6420ff'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_10" value="#00affe" checked={this.state.selectedColor === "#00affe"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_10" style={{backgroundColor: '#00affe'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_11" value="#00afff" checked={this.state.selectedColor === "#00afff"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_11" style={{backgroundColor: '#00afff'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_12" value="#01e5fe" checked={this.state.selectedColor === "#01e5fe"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_12" style={{backgroundColor: '#01e5fe'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_13" value="#d50002" checked={this.state.selectedColor === "#d50002"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_13" style={{backgroundColor: '#d50002'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_14" value="#aa01fe" checked={this.state.selectedColor === "#aa01fe"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_14" style={{backgroundColor: '#aa01fe'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_15" value="#6301ea" checked={this.state.selectedColor === "#6301ea"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_15" style={{backgroundColor: '#6301ea'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_16" value="#0091ef" checked={this.state.selectedColor === "#0091ef"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_16" style={{backgroundColor: '#0091ef'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_17" value="#0091ea" checked={this.state.selectedColor === "#0091ea"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_17" style={{backgroundColor: '#0091ea'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_18" value="#00b8d4" checked={this.state.selectedColor === "#00b8d4"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_18" style={{backgroundColor: '#00b8d4'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_19" value="#b9f6ca" checked={this.state.selectedColor === "#b9f6ca"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_19" style={{backgroundColor: '#b9f6ca'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_20" value="#ffff8d" checked={this.state.selectedColor === "#ffff8d"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_20" style={{backgroundColor: '#ffff8d'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_21" value="#ffd181" checked={this.state.selectedColor === "#ffd181"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_21" style={{backgroundColor: '#ffd181'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_22" value="#ff9e81" checked={this.state.selectedColor === "#ff9e81"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_22" style={{backgroundColor: '#ff9e81'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_23" value="#bcaba4" checked={this.state.selectedColor === "#bcaba4"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_23" style={{backgroundColor: '#bcaba4'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_24" value="#bdbdbd" checked={this.state.selectedColor === "#bdbdbd"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_24" style={{backgroundColor: '#bdbdbd'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_25" value="#02e675" checked={this.state.selectedColor === "#02e675"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_25" style={{backgroundColor: '#02e675'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_26" value="#ffeb01" checked={this.state.selectedColor === "#ffeb01"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_26" style={{backgroundColor: '#ffeb01'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_27" value="#ff9201" checked={this.state.selectedColor === "#ff9201"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_27" style={{backgroundColor: '#ff9201'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_28" value="#ff3d00" checked={this.state.selectedColor === "#ff3d00"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_28" style={{backgroundColor: '#ff3d00'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_29" value="#795547" checked={this.state.selectedColor === "#795547"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_29" style={{backgroundColor: '#795547'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_30" value="#616161" checked={this.state.selectedColor === "#616161"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_30" style={{backgroundColor: '#616161'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_31" value="#01c853" checked={this.state.selectedColor === "#01c853"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_31" style={{backgroundColor: '#01c853'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_32" value="#ffd600" checked={this.state.selectedColor === "#ffd600"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_32" style={{backgroundColor: '#ffd600'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_33" value="#ff6d00" checked={this.state.selectedColor === "#ff6d00"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_33" style={{backgroundColor: '#ff6d00'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_34" value="#dd2c00" checked={this.state.selectedColor === "#dd2c00"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_34" style={{backgroundColor: '#dd2c00'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_35" value="#4d342f" checked={this.state.selectedColor === "#4d342f"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_35" style={{backgroundColor: '#4d342f'}}></label>
                                                    </span>
                                                    <span className="color">
                                                        <input type="radio" name="_community_color" id="_community_color_36" value="#212121" checked={this.state.selectedColor === "#212121"} onChange={this.handleOptionChange} />
                                                        <label htmlFor="_community_color_36" style={{backgroundColor: '#212121'}}></label>
                                                    </span>
                                                    
                                                </div>
                                                {this.state.errors.selectedColor &&
                                                    <div className="errorMsg">Couleur est Obligatoire</div>
                                                }
                                            </div>
                                        
                                        } 
                                    </form>
                                </div>
                                <div className="d-flex jc-space-b m-t-10">
                                    <Link to="" onClick={(e) => this.props.history.goBack() } className="button wt lg">Annuler</Link>
                                    <button ref={this.CreateBtn} type="submit" form="_Form" className="button lg"> <span className="fa fa-spin fa-spinner"></span> Modifier</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
    }
}


UpdateCommunity.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(UpdateCommunity);