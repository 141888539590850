import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authentication";
import configs from '../../inc/helpers'
import Global from '../../inc/Global';

class Annuler extends Component {

    onLogout (e){
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    }

    render() {
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">

                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <center className="logo-with-hashtag">
                                                    <a href="javascript:void(0)">
                                                        <img src={Global.APP_LOGO} alt="" />
                                                    </a>
                                                </center>
                                        
                                            <div className="p-10">
                                                <div className="tab _p-cancel d-flex align-center jc-center">
                                                    <label htmlFor="" className="">
                                                        Veuillez contacter la direction
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="_cnu-footer-tabs m-t-10">
                                                <div className="d-flex jc-space-b">
                                                    <Link onClick={this.onLogout.bind(this)} className="button lg wt">
                                                        Annuler
                                                    </Link>
                                                    <Link to="/terminer-inscription/acceptez-les-conditions/" className="button lg">
                                                        Continuer
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


Annuler.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { logoutUser })(withRouter(Annuler));