import algoliasearch from 'algoliasearch'; 
import config from '../config.json' 


const list_users = 'prod_users';
const list_groups = 'dev_groups';


class Algolia {
  search;
  Seachkey = config.algolia.algoliaSearchKey;
  alogliaId = config.algolia.alogoliaId;
  constructor() { 
    this.search= algoliasearch(this.alogliaId,this.Seachkey)
  }
  // call algolia func search on users
  async searchUser(searchText){
    let index = this.search.initIndex(list_users);
    const result =  await index.search(searchText).then(res=>{
      return res
    })
    let a = result.hits; 
    return await a
  }
  async searchUserPagination(searchText,page=0,hitsPerPage=20){
    let index = this.search.initIndex(list_users);
    index.setSettings({ 
      customRanking : ['asc(profile.prenom)']
     });
    const result =  await index.search(
      searchText,
      {
        page: page,
        hitsPerPage: hitsPerPage
      }
      ).then(res=>{
      return res
    })
    let a = result; 
    return await a
  }
  //call algolia func search on community (groups)
  async updateListGroup(searchText){
    let index = this.search.initIndex(list_groups);
    const result =  await index.search(searchText).then(res=>{
      return res
    })
    let a = result.hits;
    return await a
  }
}

export default new Algolia();
