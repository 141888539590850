import React, { Component } from 'react'
import Validation from '../../../inc/Validation';

export default class VideoView extends Component {
    render() {
        const { videoUrl, poster, autoPlay , convertedFromMovToMp4 } = this.props;
        
        if(!videoUrl || !Validation.Url(videoUrl)) {
            return "";
        }
        function detectBrowser() { 
            if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
                return 'Opera';
            } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
                return 'Chrome';
            } else if(navigator.userAgent.indexOf("Safari") != -1) {
                return 'Safari';
            } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
                return 'Firefox';
            } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
                return 'IE';//crap
            } else {
                return 'Unknown';
            }
        } 

        if (detectBrowser() == 'Safari'){
            if (convertedFromMovToMp4) {
                return (
                    <div className={'video a'+ detectBrowser()}>
                        <p>{videoUrl.substr(0, videoUrl.lastIndexOf("."))+'mov'}</p>
                        <video controls src={videoUrl.substr(0, videoUrl.lastIndexOf("."))+'.mov'} poster={poster}  autoPlay={autoPlay}>
                            <source src={videoUrl.substr(0, videoUrl.lastIndexOf("."))+'.mov'} type="video/mp4"/>
                            <source src={videoUrl.substr(0, videoUrl.lastIndexOf("."))+'.mov'} type="video/webm" />
                            <source src={videoUrl.substr(0, videoUrl.lastIndexOf("."))+'.mov'} type="video/ogg" />
                        </video>
                    </div>
                )
            }
            else{
                return (
                    <div className={'video b'+ detectBrowser()}>
                        <video controls src={videoUrl} poster={poster}  autoPlay={autoPlay}>
                            <source src={videoUrl} type="video/mp4"/>
                            <source src={videoUrl} type="video/webm" />
                            <source src={videoUrl} type="video/ogg" />
                        </video>
                    </div>
                )
            }
        }
        else{
            return (
                <div className={'video c'+ detectBrowser()} >
                    <video controls src={videoUrl} poster={poster}  autoPlay={autoPlay}>
                        <source src={videoUrl} type="video/mp4"/>
                        <source src={videoUrl} type="video/webm" />
                        <source src={videoUrl} type="video/ogg" />
                    </video>
                </div>
            )
        }
    }
}
