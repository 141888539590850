import Axios from 'axios';
import React, { Component } from 'react'
import { Link, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import configs from '../../inc/helpers'
import SHA1 from 'sha1'
import Global from '../../inc/Global';
import BeatLoader from "react-spinners/BeatLoader";
import { Popover } from 'react-tiny-popover' 
import ChatList from '../header/Chat'
import ReactTooltip from 'react-tooltip';

class CommunityRightSidebar extends Component {

    constructor(props){
        super(props);
        this.state = {
            communityId: this.props.match.params.id,
            GrpSujet: '',
            communityData: {},
            otherUser:{},
            loading: false,
            loadingAdmin: false,
            loadingUser: false,

        }
    }

    componentDidMount(){
        // this.getCurrentCommunity()
        this.getUser()
        this.getAdmin()
        this.getSujet()
    }
    componentDidUpdate(prevProps) {
        if( (prevProps.location.pathname === "/communaute/"+this.props.match.params.id+"/modifier" && this.props.location.pathname !== prevProps.location.pathname ) ||  this.props.match.params.id !== this.state.communityId){ 
            this.setState({
                communityId : this.props.match.params.id
            }, ()=>{
                // this.getCurrentCommunity()
                this.getUser()
                this.getAdmin()
                this.getSujet()
            })
        } 
    }

    getCurrentCommunity(){ 
        const data = {
            id : this.state.communityId,
            nb:5,
            start:this.state.communityData.length
        };
        this.setState({
            loading : true
        })
        Axios
        .post(Global.API_URL+"/api/getgroupspecifique/admin?c="+SHA1(new Date()), data)
        .then((res) => {
            this.setState({
                communityData : res.data[0],
                loading : false
            })
        })
    }
    getAdmin(newStart = true){
        const data = {
            id : this.state.communityId,
            nb:newStart ? 7 : 20,
            start: !newStart ? this.state.communityData.useradmin.length : 0
        };
        if (newStart) {
            this.setState({
                loading : true
            })
        }
        else{
            this.setState({
                loadingAdmin : true
            })
        }
        Axios
        .post(Global.API_URL+"/api/getgroupspecifique/admin?c="+SHA1(new Date()), data)
        .then((res) => {
            
                this.setState({
                    communityData : res.data[0],
                    loading : false,
                    loadingAdmin:false
                })
            
           
        })
    }
    getUser(newStart = true){
        const data = {
            id : this.state.communityId,
            nb: newStart ? 5 : 20,
            start: !newStart ? this.state.otherUser.users.length : 0
        };
        if (newStart) {
            this.setState({
                loading : true
            })
        } 
        else{
            this.setState({
                loadingUser : true
            })
        }
        Axios
        .post(Global.API_URL+"/api/getgroupspecifique/user?c="+SHA1(new Date()), data)
        .then((res) => {
            this.setState({
                otherUser : res.data[0],
                loading : false,
                loadingUser : false
            })
        })
    }
    getSujet(){
        const data = {
            id : this.state.communityId,
        };
       
        Axios
        .post(Global.API_URL+"/api/getgroupspecifique/sujet?c="+SHA1(new Date()), data)
        .then((res) => {
            if(res.data[0].status){       
                this.setState({
                    GrpSujet:res.data[0].status? res.data[0].sujet  : ''
                })
            }
            else{
                this.props.history.push('/404')
            }
        })
    }
    render() {

        const CustomPopHoverContent = ({user})=>{
            return(
                <div className="d-flex">
                    <figure 
                        style={(user.images && user.images.image) ?
                            {backgroundImage: 'url('+ Global.API_URL +  user.images.image + ')'}
                            : 
                            {backgroundColor: 'var(--primary-color)'}
                        }>
                        {!(user.images && user.images.image) && 
                            <span>{user?.profile?.nom?.charAt(0)+user.profile?.prenom?.charAt(0)}</span>
                        } 
                    </figure> 
                    <div>
                        <h3 className="user-name">{user.profile.prenom.toLowerCase()} {user.profile.nom.toLowerCase()}</h3>
                        <div className="user-infos">
                            <ul>
                                {
                                    user.profile.fonction &&
                                    <li><i className="fa fa-briefcase"></i> {user.profile.fonction}</li>
                                }
                                {
                                    user.email &&
                                    <li><i className="fa fa-envelope"></i> <a target="_blank" href={`mailto:${user.email}`}>{user.email}</a></li>
                                } 
                                {
                                    user.profile.telephone &&
                                    <li><i className="fa fa-phone"></i> <a target="_blank" href={`tel:${user.profile.telephone}`}>{user.profile.telephone}</a></li>
                                } 
                            </ul> 

                            <button  data-tip data-for="chatBtnRightSidebar" className="start-chat" onClick={(e)=>{
                                ChatList.startChatByUser(e, user)
                                user.popopen = false; this.setState({})
                            }}><i className="fa fa-comments"></i></button>  
                            <ReactTooltip id="chatBtnRightSidebar" place="bottom" type="dark" effect="solid" className="chatBtnHome">
                                Envoyer&nbsp;un&nbsp;message
                            </ReactTooltip> 
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <aside className="right-sidebar community" id="right-sidebar">
                <div className="wrapper">
                    {/* Temporary */}
                    <div className="widget widget-community">
                        {
                            this.state.loading &&
                            <center style={{padding: 20, fontSize: 20}}>
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            </center>
                        }
                        {
                            !this.state.loading &&
                            <div className="widget-content">
                                {
                                    this.state?.GrpSujet && this.state?.GrpSujet!='' &&
                                    <div>
                                        <h4>À propos</h4>
                                        <p>{this.state?.GrpSujet}</p>
                                    </div>
                                }
                                
                                <div>
                                    {
                                        this.state?.communityData?.useradmin && 
                                        <div>
                                            <h4>Administrateur{ this.state?.communityData?.countadmin > 1? `s (${this.state?.communityData?.countadmin})`: ''}</h4>
                                            <ul className="users-list">
                                                {this.state?.communityData?.useradmin?.map((user, i)=>{ 
                                                    return (
                                                        <li id={`user-id-${user.id}`} key={i}> 
                                                            <Popover 
                                                                containerClassName="user-community-popHover" 
                                                                positions={['left']} // if you'd like, you can limit the positions  
                                                                onClickOutside={() => {user.popopen = false; this.setState({})}} // handle click events outside of the popover/target here! 
                                                                isOpen={user.popopen} content={()=>{
                                                                return(
                                                                    <CustomPopHoverContent user={user} />
                                                                )
                                                            }}>
                                                                <a href="#" 
                                                                    onClick={(e) => {e.preventDefault(); user.popopen = !user.popopen; this.setState({})}}
                                                                    title="Cliquez ici pour voir plus d'informations"
                                                                    >
                                                                    <figure 
                                                                        className="" 
                                                                        style={(user.images && user.images.image) ?
                                                                            {backgroundImage: 'url('+ Global.API_URL  + user.images.image + ')'}
                                                                            : 
                                                                            {}
                                                                        }>
                                                                            
                                                                            {!(user.images && user.images.image) && 
                                                                                <span>{user?.profile?.prenom?.charAt(0)+user.profile?.nom?.charAt(0)}</span>
                                                                            } 
                                                                    </figure>
                                                                    <div>
                                                                        {/* <h3 className="user-name">{user.profile.prenom.toLowerCase()} {user.profile.nom.toLowerCase()}</h3>  */}
                                                                        <div className="userInfoChatList">
                                                                            <h3>{user.profile.prenom.toLowerCase()} {user.profile.nom.toLowerCase()}</h3>
                                                                            { user?.profile?.fonction && user?.profile?.fonction != '' &&
                                                                                <abbr>{user?.profile?.fonction}</abbr>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </Popover>
                                                        </li> 
                                                    )
                                                })}
                                            </ul>
                                            {
                                                this.state?.communityData?.countadmin > this.state?.communityData?.useradmin?.length &&
                                                <ul className="users-list showMore">
                                                    <li> 
                                                        <a href="#" onClick={(e) => {e.preventDefault();this.getAdmin(false)}}>
                                                            {/* <figure className="" style={{display: 'none'}}>
                                                                        {
                                                                            this.state.loadingAdmin ?
                                                                            <center style={{padding: 0}}>
                                                                                <BeatLoader color={"#fff"} loading={true} size="5"/>
                                                                            </center>
                                                                            :
                                                                            <span>
                                                                                    <ion-icon name="chevron-down-outline"></ion-icon>
                                                                            </span>

                                                                        }
                                                            </figure> */}
                                                            <div>
                                                                {
                                                                    this.state.loadingAdmin ?
                                                                    <center style={{padding: 0}}>
                                                                        <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                                                    </center>
                                                                    :
                                                                    <h3 className="user-name">Afficher plus</h3> 

                                                                }
                                                            </div>
                                                        </a>
                                                    </li> 
                                                </ul>
                                            }
                                        </div>
                                    } 
                                    
                                    <div style={{height: 15}}></div>

                                    <h4>Membres ({this.state?.otherUser?.countother})</h4>
                                    <ul className="users-list">
                                        {this.state?.otherUser?.users?.map((user, i)=>{ 
                                            return (
                                                <li id={`user-id-${user.id}`} key={i}> 
                                                        <Popover 
                                                            containerClassName="user-community-popHover" 
                                                            positions={['left']} // if you'd like, you can limit the positions  
                                                            onClickOutside={() => {user.popopen = false; this.setState({})}} // handle click events outside of the popover/target here! 
                                                            isOpen={user.popopen} content={()=>{
                                                            return(
                                                                <CustomPopHoverContent user={user} />
                                                            )
                                                        }}>
                                                            <a href="#" 
                                                                onClick={(e) => {e.preventDefault(); user.popopen = !user.popopen; this.setState({})}}
                                                                title="Cliquez ici pour voir plus d'informations"
                                                                >
                                                                <figure 
                                                                    className="" 
                                                                    style={(user.images && user.images.image) ?
                                                                        {backgroundImage: 'url('+ Global.API_URL + user.images.image + ')'}
                                                                        : 
                                                                        {}
                                                                    }>
                                                                        
                                                                        {!(user.images && user.images.image) && 
                                                                            <span>{user?.profile?.prenom?.charAt(0)+user.profile?.nom?.charAt(0)}</span>
                                                                        } 
                                                                </figure>
                                                                <div>
                                                                    {/* <h3 className="user-name">{user.profile.prenom.toLowerCase()} {user.profile.nom.toLowerCase()}</h3>  */}
                                                                    <div className="userInfoChatList">
                                                                        <h3>{user.profile.prenom.toLowerCase()} {user.profile.nom.toLowerCase()}</h3>
                                                                        { user?.profile?.fonction && user?.profile?.fonction != '' &&
                                                                            <abbr>{user?.profile?.fonction}</abbr>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </Popover>
                                                    
                                                </li> 
                                            )
                                        })}
                                    </ul>
                                    {
                                        this.state?.otherUser?.countother > this.state?.otherUser?.users?.length &&
                                        <ul className="users-list showMore">
                                            <li> 
                                                <a href="#" onClick={(e) => {e.preventDefault();this.getUser(false)}}>
                                                    {/* <figure className="">
                                                    {
                                                        this.state.loadingUser ?
                                                        <center style={{padding: 0}}>
                                                            <BeatLoader color={"#fff"} loading={true} size="5"/>
                                                        </center>
                                                        :
                                                        <span>
                                                                <ion-icon name="chevron-down-outline"></ion-icon>
                                                        </span>

                                                    }
                                                    </figure> */}
                                                    <div>
                                                        {
                                                            this.state.loadingUser ?
                                                            <center style={{padding: 0}}>
                                                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                                            </center>
                                                            :
                                                            <h3 className="user-name">Afficher plus</h3> 
                                                        }
                                                        
                                                    </div>
                                                </a>
                                            </li> 
                                        </ul>
                                    }
                                </div>
                            </div>
                        } 
                    </div> 
                </div>
            </aside>
        )
    }
}

export default CommunityRightSidebar;