var timeout;

export const flashTitle = (original, newMsg, howManyTimes = 20) => {
    
    function step() {
        window.document.title = (window.document.title == original) ? newMsg : original; 
        if (--howManyTimes > 0) {
            timeout = setTimeout(step, 1000);
        };
    };

    howManyTimes = parseInt(howManyTimes);

    if (isNaN(howManyTimes)) {
        howManyTimes = 5;
    };

    cancelFlashTitle(timeout);
    step();
}


export const cancelFlashTitle = (original) => {
    clearTimeout(timeout);
    window.document.title = original;
} 