import React, { Component } from 'react'

export default class True extends Component {
    render() {
        return (
            <span className={this.props.className ? this.props.className : ''}>
                <svg 
                    version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xlink="http://www.w3.org/1999/xlink" 
                    x="0px" y="0px" 
                    viewBox="0 0 1000 1000" 
                    enableBackground="new 0 0 1000 1000" 
                    space="preserve"
                    style={{height: this.props.height, fill: this.props.color}}>
                    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                    <g>
                        <path d="M187.6,443l200.5,305.3c0,0,57.3-356.4,562.1-727c-18.8,55-49.9,192.2,39.8,277.9c-77.8,20.1-432.2,282-587,679.6c-27-96.4-289-365.3-393-376C91.1,574.9,187.6,443,187.6,443L187.6,443z"/>
                    </g>
                </svg>
            </span>
        )
    }
}
