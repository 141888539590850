import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import configs from '../../inc/helpers.js'
import AddUser from './Users/AddUser'
import EditUser from './Users/EditUser'
import DeleteUser from './Users/DeleteUser'
import NotFound from '../../NotFound';
import CommunityList from './Users/CommunityList.js'
import UsersList from '../admin/Users/UsersList';


class Admin extends Component {
    render() {
        
        if(!configs.isAdmin()){
            this.props.history.push('/')
            return "";
        }
        return (
            <div className="content">
                <Switch>
                    <Route exact path="/admin/utilisateurs/attribuer-des-communautes" component={UsersList} />
                    <Route exact path="/admin/utilisateur/ajouter" component={UsersList} />  

                    <Route exact path="/admin/utilisateur/modifier" component={EditUser} />
                    <Route exact path="/admin/utilisateur/delete" component={DeleteUser} />
                    <Route path="*" component={NotFound} />
                </Switch>


                <Switch>
                    <Route exact path="/admin/utilisateur/ajouter" component={AddUser} />

                    <Route exact path="/admin/utilisateurs/attribuer-des-communautes" component={CommunityList} />
                </Switch> 
            </div>
        )
    }
}


export default Admin;