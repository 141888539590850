import React, { Component } from 'react'
import LinkPreview from './LinkPreview'
import BarLoader from "react-spinners/BarLoader";






export default class CustomLinkPreview extends Component {
    
    constructor (props){
        super(props); 
        const {link, loader} = this.props;   
        this.state = {
            link : link,
            showLoading: loader
        } 
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            link : nextProps.link,
            showLoading: nextProps.loader
        })
    } 

    render() {
        const {link} = this.state;
        const {showLoading} = this.state;
        const domain = link.split("/")[2];
         
        const CustomComponent = ({ loading, preview })=>{
            
            if( !loading &&  preview && preview){
                return(
                    <div>
                        {
                            preview.image && preview.title &&
                            <a href={link} target="_blank" className="custom-link-preview d-flex">
                                <div className="thumbnail" style={{backgroundImage: `url('${preview.image}')`}}></div>
                                <div className="content"> 
                                    <h3 dangerouslySetInnerHTML={{__html: preview.title}} ></h3>
                                    <p dangerouslySetInnerHTML={{__html: preview?.description}}></p>
                                    <small>{ domain }</small>
                                </div>
                            </a>
                        }
                    </div>
                )
            }else{
                return (
                    <div>
                        {
                            showLoading &&
                            <div className="loader-link-preview">
                                <BarLoader color={"#aaa"} loading={true} height="4" width="100%"/>
                            </div> 
                        }
                    </div>
                )
            }
        }
        return (
            <div>
                <LinkPreview url={link}  render={CustomComponent}/> 
            </div>
        )
    }
}