import React, { Component } from 'react'
import ReactDOM from "react-dom";
import configs from '../../inc/helpers.js'
import { Link, withRouter } from 'react-router-dom'
//import InputField from '../InputField.js';
import CreatePost from './CreatePost.js';
import axios from 'axios'
import Global from '../../inc/Global.js';
import Gallery from './template-parts/Gallery.js';
import LinkView from './template-parts/LinkView.js';
import FilesView from './template-parts/FilesView.js';
import debounce from "lodash.debounce";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import GifView from './template-parts/GifView.js';
import VideoView from './template-parts/VideoView.js';
import 'react-image-lightbox/style.css';
import UpdatePost from './UpdatePost.js';
import Applaud from '../icons/Applaud.js';
import Like from '../icons/Like.js';
import True from '../icons/True.js';
import Share from '../icons/Share.js';
import Comments from '../icons/Comments.js';
import CreateComment from './CreateComment.js';
//import { post } from 'superagent';
import UpdateComment from './UpdateComment.js';
import Linkify from 'react-linkify';
import BeatLoader from "react-spinners/BeatLoader";
import SHA1 from 'sha1'
import Poll from './Poll.js';
import PostEvent from './PostEvent.js';
import { motion } from "framer-motion";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Headband from '../home/Headband.js';
import LikesDetailOnHover from './template-parts/likesDetailOnHover.js';
import AudioView from './template-parts/AudioView.js';
import CustomPopHoverUser from './template-parts/CustomPopHoverUser.js';
const SweetAlert = withReactContent(Swal) 





class Posts extends Component {
    
    constructor(props){
        super(props);  
        this.myRef = React.createRef();

        this.state = {
            posts : [],
            limit : 3,
            startpoint : 0,
            allLoaded : false,
            community : this.props.community || 0,
            role : this.props.role || false,
            searchResults: this.props.searchResults || [],
            postToUpdate : false,
            postToDelete : null,
            postToShare : null,
            userPostToDelete : null,
            userCommunities : [],
            isPostBack : true,
            CommentToUpdate : null,
            CommentToDelete : null,
            responseToUpdate : null,
            responseToDelete : null,
            openedGalleryID : {
                id : false, 
                postId : false
            },
            showReactionPopin: false,
            reactions: [],
            reactionsType : 1,
            reactionActiveTab: 1,
            defaultLanguage: "Francais"
        }

        this.handleOpenGallery = this.handleOpenGallery.bind(this);
        this.handleCloseGallery = this.handleCloseGallery.bind(this);
        this.handleUpdatePost = this.handleUpdatePost.bind(this); 
        this.handleDeletePost = this.handleDeletePost.bind(this); 
        this.handleLikeResponse = this.handleLikeResponse.bind(this); 
        this.handleLikeComment = this.handleLikeComment.bind(this); 
        this.handleLikePost = this.handleLikePost.bind(this); 
        this.handleApplaudPost = this.handleApplaudPost.bind(this); 
        this.handleCheckPost = this.handleCheckPost.bind(this); 
        this.handleSharePost = this.handleSharePost.bind(this); 
        this.onUpdatePost = this.onUpdatePost.bind(this); 
        this.handleShareToCommunity = this.handleShareToCommunity.bind(this); 
        this.handleCloseUpdateModal = this.handleCloseUpdateModal.bind(this); 
        this.onCreateComment = this.onCreateComment.bind(this); 
        this.onCreateResponse = this.onCreateResponse.bind(this); 
        this.handleLoadComments = this.handleLoadComments.bind(this); 
        this.handleLoadResponses = this.handleLoadResponses.bind(this); 
        this.getPublications = this.getPublications.bind(this); 
        this.handleSavePost = this.handleSavePost.bind(this); 
        this.handleUnSavePost = this.handleUnSavePost.bind(this); 
        this.handlePingPost = this.handlePingPost.bind(this); 
        this.handleShowLikesCommentDetails = this.handleShowLikesCommentDetails.bind(this); 
        this.handleHideLikesCommentDetails = this.handleHideLikesCommentDetails.bind(this); 
        this.handleShowMoreLikeDetails = this.handleShowMoreLikeDetails.bind(this); 
        this.checkIfBigScrean = this.checkIfBigScrean.bind(this); 


        this.DeleteBtn = React.createRef();
        this.DeleteCommentBtn = React.createRef();
        this.DeleteResponseBtn = React.createRef();

        window.onscroll = debounce(() => {
            if(this.state.isPostBack){
                return;
            }
       

            if (
                Math.round(window.innerHeight + document.documentElement.scrollTop) * 2 >=  Math.round(document.documentElement.offsetHeight)
            ) {
                this.setState({
                    ...this.state,
                    isPostBack : true
                })

                let data = null; 
                switch(this.props.parent){
                    case "saved_posts": 
                            data = new FormData();
                            data.append('nbpost', this.state.limit);
                            data.append('start', this.state.startpoint); 
                            break;
                    case "my_posts": 
                            data = {
                                OnlyCurrentUser: true,
                                email : this.props.auth.user.username,
                                groupid : this.state.community,
                                nbpost : this.state.limit,
                                startpoint : this.state.startpoint
                            }
                            break;
                    case "page_sondage": 
                            data = {
                                idgroup : this.state.community,
                                nb : this.state.limit,
                                start : this.state.startpoint
                            }
                            break;
                        default:
                            data = {
                                email : this.props.auth.user.username,
                                groupid : this.state.community,
                                nbpost : this.state.limit,
                                startpoint : this.state.startpoint
                            } 
                        break;
                }
                
                
                if(this.state.allLoaded === false){
                    axios
                    .post(Global.API_URL+this.props.api_url, data)
                    .then((res) => {
                        let ResultKey
                        switch(this.props.parent){
                            case "saved_posts":
                                ResultKey = "enregistrements"
                                break;
                            case "page_sondage":
                                ResultKey = "sondage"
                                break;
                            default:
                                ResultKey = "publications"
                                break;
                        }
                        var posts = [];
                        for(var $i = 0; $i < res.data[ResultKey].length; $i++){
                            posts.push( res.data[ResultKey][$i] );
                            // for(var $j = 0; $j < res.data[$i].groups.postnormale.length; $j++){
                            //     posts.push( res.data[$i].groups.postnormale[$j] );
                            // }

                            posts[$i].commentnormale = []
                            posts[$i].lastcommentRepense = []
                            // posts[$i]['lastcomment']['responses'] = [];
                            posts[$i]['nbComments'] = 5;
                            posts[$i]['startPoint'] = 0;
                        }

                        var NewPosts = this.state.posts.concat(posts);

                        if(res.data[ResultKey].length > 0){
                            this.setState({
                                posts: NewPosts,
                                startpoint : this.state.startpoint + this.state.limit,
                                isPostBack : false
                            });
                        }else{
                            this.setState({
                                allLoaded : true,
                                isPostBack : false
                            })
                        }
            
                        // if(res.data[ResultKey].length < this.state.limit){
                        //     this.setState({
                        //         allLoaded : true,
                        //         posts: NewPosts,
                        //         isPostBack : false
                        //     })
                        // }else{
                        //     this.setState({
                        //         posts: NewPosts,
                        //         startpoint : this.state.startpoint + this.state.limit,
                        //         isPostBack : false
                        //     });
                        // }
            
                        
                        
                    })
                    .catch((err) => {
                        // console.log('Error : '+err.message)
                    });
                    
                }
            }
        }, 100); 
    }
    
    isURL(str) {
        var pattern = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
        '(?:\\S+(?::\\S*)?@)?' + // authentication
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locater 
        return pattern.test(str)
    };

    getUserLangague(){
        axios
        .get(Global.API_URL+"/api/usercurrent/"+ SHA1(new Date()))
        .then((res) => { 
            if(res.data.langue){
                this.setState({ 
                    defaultLanguage: res.data.langue || "Francais"
                }) 
            }
        })
    }

    getReactionsAndShowPopin(idpost = null, type = 1){
        if(idpost === null) return;

        this.setState({
            showReactionPopin: true,
            reactionActiveTab: 1,
            reactionsType: type
        })

        let formData = new FormData();
        formData.append('idpost',idpost)
        formData.append('type',type)
        formData.append('nb',99999999)
        formData.append('start',0)
        
        axios
        .post(Global.API_URL+"/api/likelist", formData)
        .then((res) => {
            if(res.data){
                this.setState({
                    reactions: res.data
                })
            }
        })
    }
    checkIfBigScrean(){
        if (
            Math.round(window.innerHeight + document.documentElement.scrollTop) <=  Math.round(document.documentElement.offsetHeight)
        ) {
            this.setState({
                ...this.state,
                isPostBack : true
            })

            let data = null; 
            switch(this.props.parent){
                case "saved_posts": 
                        data = new FormData();
                        data.append('nbpost', this.state.limit);
                        data.append('start', this.state.startpoint); 
                        break;
                case "my_posts": 
                        data = {
                            OnlyCurrentUser: true,
                            email : this.props.auth.user.username,
                            groupid : this.state.community,
                            nbpost : this.state.limit,
                            startpoint : this.state.startpoint
                        }
                        break;
                case "page_sondage": 
                        data = {
                            idgroup : this.state.community,
                            nb : this.state.limit,
                            start : this.state.startpoint
                        }
                        break;
                    default:
                        data = {
                            email : this.props.auth.user.username,
                            groupid : this.state.community,
                            nbpost : this.state.limit,
                            startpoint : this.state.startpoint
                        } 
                    break;
            }
            
            
            if(this.state.allLoaded === false){
                axios
                .post(Global.API_URL+this.props.api_url, data)
                .then((res) => {
                    let ResultKey
                    switch(this.props.parent){
                        case "saved_posts":
                            ResultKey = "enregistrements"
                            break;
                        case "page_sondage":
                            ResultKey = "sondage"
                            break;
                        default:
                            ResultKey = "publications"
                            break;
                    }
                    var posts = [];
                    for(var $i = 0; $i < res.data[ResultKey].length; $i++){
                        posts.push( res.data[ResultKey][$i] );
                        // for(var $j = 0; $j < res.data[$i].groups.postnormale.length; $j++){
                        //     posts.push( res.data[$i].groups.postnormale[$j] );
                        // }

                        posts[$i].commentnormale = []
                        posts[$i].lastcommentRepense = []
                        // posts[$i]['lastcomment']['responses'] = [];
                        posts[$i]['nbComments'] = 5;
                        posts[$i]['startPoint'] = 0;
                    }

                    var NewPosts = this.state.posts.concat(posts);

                    if(res.data[ResultKey].length < this.state.limit){
                        this.setState({
                            allLoaded : true,
                            posts: NewPosts,
                            isPostBack : false
                        })
                    }else{
                        this.setState({
                            posts: NewPosts,
                            startpoint : this.state.startpoint + this.state.limit,
                            isPostBack : false
                        });
                    }
        
                    
                    
                })
                .catch((err) => {
                    // console.log('Error : '+err.message)
                });
                
            }
        }
    }
    componentDidMount(){ 
        // console.log('componentDidMount');

        this.getPublications();
        this.getUserLangague();
        return;
    }
    // componentWillUnmount(){
    //     console.log('componentWillUnmount');
    // }
    findTaguSpanAddEvBind(){
        let spanTague = document.querySelectorAll('.tagueName')
        spanTague.forEach((tag,key)=>{
            if (!tag.classList.contains('hasFunction')) {
                var d = new Date();
                tag.addEventListener('mouseover', this.showUser.bind(this));
                tag.addEventListener('mouseleave', this.HideUser.bind(this));
                tag.classList.add('hasFunction')
                tag.classList.add(`post-${tag.id}-${d.getTime()}${key}-${d.getMilliseconds()}`)
            }
        })
    }
    componentDidUpdate(prevProps) { 
        this.findTaguSpanAddEvBind();

        if (prevProps.community !== this.props.community && this.state.role !== this.props.role) {
            this.setState({
                community : this.props.community || 0,
                role : this.props.role || false,
                searchResults: this.props.searchResults || [],
                startpoint : 0,
                allLoaded : false
            }, ()=>{
                this.getPublications();
        // console.log('update1');

            }) 
        }
        
        // if( prevProps.location.pathname !== this.props.location.pathname ){
        //     // this.getPublications();
        //     console.log('a',prevProps.location.pathname , this.props.location.pathname );
        // console.log('update2');

        // }
        // console.log('update');
    }
   
    showUser=(e)=>{
        let userId= e.target.id.replace('userId-','')
        if (e.target.classList[2]) {
            let parentDiv = document.querySelector(`.${e.target.classList[2]}`);
            if (parentDiv.querySelector("div.user-community-popClick") != null) {
                parentDiv.querySelector("div.user-community-popClick").classList.remove('hide')
            }else{
                
                axios.get(`${Global.API_URL}/api/singleuser/${userId}`)
                .then((res) => {  
                    if(res.data){
                        let user = res.data;
                        user.popopen=true;
                       
        
                            ReactDOM.render(
                                (
                                    <>
                                        <CustomPopHoverUser user={user} />
                                    <>
                                        @{user.profile.prenom}
                                    </>
                               </>
                            )
                            ,
                            e.target
                            );
                        
                    }
                })
                .catch((err) => {
                    // console.log('Error : '+err.message)
                });
            }
        }
    }
    HideUser=(e)=>{
        let parentDiv = document.querySelector(`.${e.target.classList[2]}`);

        if (parentDiv.querySelector("div.user-community-popClick") != null) {
            setTimeout(() => {
                parentDiv.querySelector("div.user-community-popClick").classList.add('hide')
            }, 200);
        }
    }
    scrollToPost(postId){
        if( document.getElementsByClassName("display-comments")[0] ){
            document.getElementsByClassName("display-comments")[0].click()
        }
        setTimeout(()=>{   
            let post = document.getElementsByClassName("post-"+postId)[0]
            window.scrollBy({
                top: post.getBoundingClientRect().top - 80,
                left: 0,
                behavior: 'smooth'
            });
            let postClassName = post.className;
            post.className = postClassName+" bounce";
            setTimeout(()=>{
                post.className = postClassName;
            },1500) 
        }, 1000) 
    }

    getSinglePost(){
        axios
        .get(Global.API_URL+"/api/postsingle/"+this.props.match.params.postId, {})
        .then((res) => {  
            this.setState({
                posts: [{
                    ...res.data.post,
                    nbComments: 5,
                    startPoint: 0,
                    commentnormale: []
                }],
                allLoaded : true,
                isPostBack: false
            }, ()=>{ 
                this.scrollToPost(res.data.post.id) 
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }

    getSearchResults(){
        this.state.searchResults.map(p=>{
            p.commentnormale = [];
            p.nbComments = 5;
            p.startPoint = 0;
        })
        this.setState({
            posts: this.state.searchResults,
            allLoaded : true,
            isPostBack: false
        });
    }

    getPublications () {
        if( this.props.match.params.postId ){
            this.getSinglePost();
            return;
        }
        if( this.state.searchResults.length > 0 ){ 
            this.getSearchResults();
            return;
        }

        
        let data
        switch(this.props.parent){
            case "saved_posts": 
                    data = new FormData();
                    data.append('nbpost', this.state.limit);
                    data.append('start', this.state.startpoint);
                    break;
            case "my_posts": 
                    data = {
                        OnlyCurrentUser: true,
                        email : this.props.auth.user.username,
                        groupid : this.state.community,
                        nbpost : this.state.limit,
                        startpoint : this.state.startpoint
                    }
                    break;

            case "page_sondage": 
                    data = {
                        idgroup : this.state.community,
                        nb : this.state.limit,
                        start : this.state.startpoint
                    }
                    break;
                default:
                    data = {
                        email : this.props.auth.user.username,
                        groupid : this.state.community,
                        nbpost : this.state.limit,
                        startpoint : this.state.startpoint
                    }
                break;
        } 
        let urlApiPost = (this.props.api_url=='/api/publication') ? '/api/publicats':this.props.api_url;
        axios
        .post(Global.API_URL + this.props.api_url, data)
        // .post(Global.API_URL + turlApiPost, data)
        .then((res) => { 
            // console.log(res);
            if(res.data.status){
                let ResultKey
                switch(this.props.parent){
                    case "saved_posts":
                        ResultKey = "enregistrements"
                        break;
                    case "page_sondage":
                            ResultKey = "sondage"
                            break;
                    default:
                        ResultKey = "publications"
                        break;
                }

                var posts = [];


                for(var $i = 0; $i < res.data[ResultKey].length; $i++){


                        posts.push( res.data[ResultKey][$i] );
                        // for(var $j = 0; $j < res.data[$i].groups.postnormale.length; $j++){
                        //     posts.push( res.data[$i].groups.postnormale[$j] );
                        // }
                        posts[$i].commentnormale = [];
                        // posts[$i]['lastcomment']['loadDetail'] = false;
                        posts[$i]['nbComments'] = 5;
                        posts[$i]['startPoint'] = 0;

                }

                if(posts.length < this.state.limit){
                    this.setState({
                        posts: posts,
                        allLoaded : true,
                        isPostBack: false
                    });
                }else{
                    this.setState({
                        posts: posts,
                        startpoint : this.state.startpoint + this.state.limit,
                        isPostBack : false
                    });
                }

                this.checkIfBigScrean()
            }
            else{
                // this.props.history.push('/404')
                console.log('Error : shit')

            }

        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleSelectChange (e) {
       
    }
    getImageSize ({target : img}){
        
    }
    handleNewPost = (res) => {
        let posts = [];
        res.data.forEach(function(item, ind){
            
            item.post["commentnormale"] = [];
            item.post["nbComments"] = 5;
            item.post["startPoint"] = 0;
            posts.push(item.post);
        });

        var NewPost = posts.concat(this.state.posts);
        
        this.setState({
            posts : NewPost
        }, ()=>{
            window.scrollTo(0,0)
            setTimeout(() => {
                SweetAlert.fire({ 
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000
                })
            }, 200);
        }); 
    }
    handleOpenGallery(ID, postID){
       
        this.setState({
            openedGalleryID : {id : ID, postId : postID}
        })
    }
    handleCloseGallery (){
        this.setState({
            openedGalleryID : {id : false, postId : false}
        })
    }
    handleUpdatePost (ev, Post){
        ev.preventDefault();
        this.setState({
            postToUpdate : Post
        });
    }
    handleDeletePost (ev, postID, userId){
        ev.preventDefault();

        if(userId === this.props.auth.user.user_id || configs.isAdmin()  || this.props.role == "ROLE_ADMIN" ){
            this.setState({
                postToDelete : postID,
                userPostToDelete : userId,
            });
        }
    }
    confirmDeletion (ev) {
        ev.preventDefault();
        var btn = this.DeleteBtn;
        btn.current.classList.add('on');

        if(this.state.userPostToDelete === this.props.auth.user.user_id || configs.isAdmin() || this.props.role == "ROLE_ADMIN" ){

            let data = {
                idpost : this.state.postToDelete,
                iduser : this.props.auth.user.user_id
            };
            
            axios
            .post(Global.API_URL+"/deletepost", data)
            .then((res) => {
                btn.current.classList.remove('on');
                
                var Posts = [...this.state.posts];
                
                var currentPost = null;
                
                Posts.forEach(function(el, ind){
                    
                    if(el.id === parseInt(this.state.postToDelete)){
                        currentPost = ind;
                    }
                }.bind(this));
                
                
                Posts.splice(currentPost, 1)
                
                this.setState({
                    postToDelete : null,
                    userPostToDelete : null,
                    posts : Posts,
                });
            })
            .catch((err) => {
                // console.log('Error : '+err.message)
                
                this.setState({
                    delete : false
                });
            });
        }
    }
    handleSavePost(ev, postId){
        ev.preventDefault()
        // console.log(postId);
        const data =new FormData();

        data.append('idpost', postId);

        axios
        .post(Global.API_URL+"/api/postsave", data)
        .then((res) => {
            
            var Posts = [...this.state.posts];
            var ind = configs.getIndexWidthProperty(Posts, 'id', postId);
            // console.log(Posts[ind].enregistrer)

            if(res.data.status){
                
                Posts[ind].enregistrer.push({saved: true, email: this.props.auth.user.username})
                this.setState({
                    posts: Posts
                }, ()=>{
                    setTimeout(() => {
                        SweetAlert.fire({ 
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }, 200);
                }); 
            }
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });

    }
    handleUnSavePost(ev, postId){
        ev.preventDefault()
        // console.log(postId);
        const data =new FormData();

        data.append('idpost', postId);

        axios
        .post(Global.API_URL+"/api/supsavedpost", data)
        .then((res) => {
            
            var Posts = [...this.state.posts];
            var ind = configs.getIndexWidthProperty(Posts, 'id', postId);
            var indSavedPost = configs.getIndexWidthProperty(Posts[ind].enregistrer, 'email', this.props.auth.user.username);
            // console.log(indSavedPost)

            if(res.data.status){
                
                Posts[ind].enregistrer.splice(indSavedPost, 1)
                this.setState({
                    posts: Posts
                }, ()=>{
                    setTimeout(() => {
                        SweetAlert.fire({ 
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }, 200);
                }); 
            }
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });

    }
    confirmDeletionComment (ev) {
        ev.preventDefault();
        var btn = this.DeleteCommentBtn;
        btn.current.classList.add('on');

        let data = {
            CommentID : this.state.CommentToDelete.cmt.id,
            groupID : this.state.CommentToDelete.groupid,
            userEmail : this.props.auth.user.username
        };
        
        axios
        .post(Global.API_URL+"/api/comment/delete", data)
        .then((res) => {
            btn.current.classList.remove('on');
            
            var Posts = [...this.state.posts];
            
            
            Posts[this.state.CommentToDelete.postIndex].commentnormale.splice(this.state.CommentToDelete.commentIndex, 1);
            Posts[this.state.CommentToDelete.postIndex].nbrecommentaire = Posts[this.state.CommentToDelete.postIndex].nbrecommentaire - 1;

            //Posts.splice(currentPost, 1)
            
            this.setState({
                CommentToDelete : null,
                posts : Posts,
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            
            this.setState({
                delete : false
            });
        });
    
    }
    confirmDeletionReponse (ev) {
        ev.preventDefault();
        var btn = this.DeleteResponseBtn;
        btn.current.classList.add('on');
        var commentIndex = this.state.responseToDelete.commentIndex;
        var responseIndex = this.state.responseToDelete.responseIndex;
        var postIndex = this.state.responseToDelete.postIndex;

        let data = {
            ReponseID : this.state.responseToDelete.cmt.id,
            groupID : this.state.responseToDelete.groupid,
            userEmail : this.props.auth.user.username
        };
        
        axios
        .post(Global.API_URL+"/api/response/delete", data)
        .then((res) => {
            btn.current.classList.remove('on');
            
            var Posts = [...this.state.posts];
            
            
            if ( Posts[postIndex].commentnormale.length>0) {
                Posts[postIndex].commentnormale[commentIndex].responses.splice(responseIndex, 1);
                Posts[postIndex].commentnormale[commentIndex].nbResponses = Posts[postIndex].commentnormale[commentIndex].nbResponses - 1;
                
                Posts[postIndex].commentnormale[commentIndex].nbreponse = Posts[postIndex].commentnormale[commentIndex].nbreponse - 1;
            }else{
                Posts[postIndex].lastcommentRepense.splice(responseIndex, 1);
                Posts[postIndex].lastcomment.nbResponses = Posts[postIndex].lastcomment.nbResponses - 1;
                
                Posts[postIndex].lastcomment.nbreponse = Posts[postIndex].lastcomment.nbreponse - 1;
            }
            
            // lastcomment.responses
            //Posts.splice(currentPost, 1)
            
            this.setState({
                responseToDelete : null,
                posts : Posts,
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            
            this.setState({
                delete : false
            });
        });
    
    }
    cancel (ev){
        ev.preventDefault();
        this.setState({
            postToDelete : null,
            userPostToDelete : null,
            CommentToDelete: null,
            responseToDelete: null,
        });
    }
    handleCloseUpdateModal (ev){
        ev.preventDefault();

        this.setState({
            postToUpdate : false,
            CommentToUpdate : null,
            responseToUpdate : null
        });
    }
    handleLikeResponse(ev, Response, groupid, postIndex, commentIndex, responseIndex){
        ev.preventDefault();
        const data = {
            email : this.props.auth.user.username,
            idgroup : groupid,
            idreponse : Response.id,
        };

        axios
        .post(Global.API_URL+"/api/buttonlikereponse", data)
        .then((res) => {
            if( res.data.state ) {
                let Posts = [...this.state.posts];

                // Posts[postIndex].commentnormale[commentIndex].responses[responseIndex].nblike = res.data.nblike;

                // if(res.data.action){
                //     document.querySelector('#nblike-resp-' + Response.id).classList.add('active');
                // }else{
                //     document.querySelector('#nblike-resp-' + Response.id).classList.remove('active');
                // }

                // this.setState({
                //     posts : Posts
                // });



                

                if ( Posts[postIndex].commentnormale.length > 0 ) {
                    
                    if (Posts[postIndex].commentnormale[commentIndex].responses[responseIndex]) {
                        
                        Posts[postIndex].commentnormale[commentIndex].responses[responseIndex].nblike = res.data.nblike;
                    }
                }else{
                   console.log(                    Posts[postIndex].lastcommentRepense);
                    Posts[postIndex].lastcommentRepense[responseIndex].nblike = res.data.nblike;
                }
                if(res.data.action){
                    document.querySelector('#nblike-resp-' + Response.id).classList.add('active');
                }else{
                    document.querySelector('#nblike-resp-' + Response.id).classList.remove('active');
                }

                this.setState({
                    posts : Posts
                });





            } else {
                // console.log(res.data.message);
            }
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleLikeComment(ev, Comment, groupid, postIndex, commentIndex){
        ev.preventDefault();

        const data = {
            email : this.props.auth.user.username,
            idgroup : groupid,
            idcomment : Comment.id,
        };
            

        axios
        .post(Global.API_URL+"/api/buttonlikecomment", data)
        .then((res) => {
            
            

            if(res.data.state) {
                
                let Posts = [...this.state.posts];

                // Posts[postIndex].commentnormale[commentIndex].nblike = res.data.nblike;
                if ( Posts[postIndex].commentnormale.length > 0 ) {
                    if (Posts[postIndex].commentnormale[commentIndex]) {
                        Posts[postIndex].commentnormale[commentIndex].nblike = res.data.nblike;
                    }
                }else{
                    Posts[postIndex].lastcomment.nblike = res.data.nblike;
                }
                if(res.data.action){
                    document.querySelector('#nblike-' + Comment.id).classList.add('active');
                }else{
                    document.querySelector('#nblike-' + Comment.id).classList.remove('active');
                }

                this.setState({
                    posts : Posts
                });


            } else {
                // console.log(res.data.message);
            }
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleShowLikesCommentDetails(ev,typOfRequest,ComId,postId,idSection,nblike){
        
        setTimeout(() => {
        const nbLikes=nblike ?  nblike : 0;
        if (nbLikes!=0) {
            ReactDOM.render(
                <LikesDetailOnHover commentId={ComId} postId={postId}  typOfRequest={typOfRequest} handleShowMoreLikeDetails={this.handleShowMoreLikeDetails}/>,
                document.getElementById(idSection)
            );
        }
       }, 500);
    }
    handleHideLikesCommentDetails(ev,typOfRequest,ComId,postId,idSection,nblike){
        const nbLikes=nblike ?  nblike : 0;
        if (nbLikes!=0) {
            const classNameSection = `nblikeDetail-${ComId}`;
            ReactDOM.unmountComponentAtNode(
                document.getElementById(idSection)
             );
        }
    }
    handleShowMoreLikeDetails(value){
        this.setState({
            showReactionPopin: true,
            reactionActiveTab: 1,
            reactionsType: 3
        })

        let formData = new FormData();
        formData.append('idpost',value.id)
        formData.append('type',value.type)
        formData.append('nb',99999999)
        formData.append('start',0)
        
        axios
        .post(Global.API_URL+"/api/likelist", formData)
        .then((res) => {
            if(res.data){
                this.setState({
                    reactions: res.data
                })
            }
        })
    }
    handleLikePost(ev, Post){
        ev.preventDefault();
        const data = {
            userEmail : this.props.auth.user.username,
            postID : Post.id,
            groupID : Post.groups.id,
            postType : Post.type,
            likeType : 0,
            partageID: null
        };

        axios
        .post(Global.API_URL+"/api/dolike", data)
        .then((res) => {
            let index = configs.getIndexWidthProperty(this.state.posts, "id", Post.id);
            let Posts = [...this.state.posts];

            Posts[index].cmptokey = res.data.valeur.cmptokey;
            Posts[index].cmptmain = res.data.valeur.cmptmain;
            Posts[index].cmptcoche = res.data.valeur.cmptcoche;

            document.querySelector('#statistics-' + Post.id + ' .likes.okey').classList.remove('active');
            document.querySelector('#statistics-' + Post.id + ' .likes.applaude').classList.remove('active');
            document.querySelector('#statistics-' + Post.id + ' .likes.check').classList.remove('active');
            
            if(res.data.action){
                document.querySelector('#statistics-' + Post.id + ' .likes.okey').classList.add('active');
            }

            this.setState({
                posts : Posts
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleApplaudPost(ev, Post){
        ev.preventDefault();
        const data = {
            userEmail : this.props.auth.user.username,
            postID : Post.id,
            groupID : Post.groups.id,
            postType : Post.type,
            likeType : 1,
            partageID: null
        };

        axios
        .post(Global.API_URL+"/api/dolike", data)
        .then((res) => {
            let index = configs.getIndexWidthProperty(this.state.posts, "id", Post.id);
            let Posts = [...this.state.posts];

            Posts[index].cmptokey = res.data.valeur.cmptokey;
            Posts[index].cmptmain = res.data.valeur.cmptmain;
            Posts[index].cmptcoche = res.data.valeur.cmptcoche;

            document.querySelector('#statistics-' + Post.id + ' .likes.okey').classList.remove('active');
            document.querySelector('#statistics-' + Post.id + ' .likes.applaude').classList.remove('active');
            document.querySelector('#statistics-' + Post.id + ' .likes.check').classList.remove('active');
            
            if(res.data.action){
                document.querySelector('#statistics-' + Post.id + ' .likes.applaude').classList.add('active');
            }

            this.setState({
                posts : Posts
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleCheckPost(ev, Post){
        ev.preventDefault();
        const data = {
            userEmail : this.props.auth.user.username,
            postID : Post.id,
            groupID : Post.groups.id,
            postType : Post.type,
            likeType : 2,
            partageID: null
        };

        axios
        .post(Global.API_URL+"/api/dolike", data)
        .then((res) => {
            let index = configs.getIndexWidthProperty(this.state.posts, "id", Post.id);
            let Posts = [...this.state.posts];

            Posts[index].cmptokey = res.data.valeur.cmptokey;
            Posts[index].cmptmain = res.data.valeur.cmptmain;
            Posts[index].cmptcoche = res.data.valeur.cmptcoche;

            document.querySelector('#statistics-' + Post.id + ' .likes.okey') && document.querySelector('#statistics-' + Post.id + ' .likes.okey').classList.remove('active');
            document.querySelector('#statistics-' + Post.id + ' .likes.applaude') && document.querySelector('#statistics-' + Post.id + ' .likes.applaude').classList.remove('active');
            document.querySelector('#statistics-' + Post.id + ' .likes.check') && document.querySelector('#statistics-' + Post.id + ' .likes.check').classList.remove('active');
            
            if(res.data.action){
                document.querySelector('#statistics-' + Post.id + ' .likes.check').classList.add('active');
            }

            this.setState({
                posts : Posts
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    handleSharePost(ev, Post){
        ev.preventDefault();
         
        if(this.state.userCommunities.length === 0){
            axios
            .post(Global.API_URL + "/murgroupid", { 'email': this.props.auth.user.username })
            .then((res) => {
                let items = [];
                for(let $i = 0; $i < res.data.length; $i++ ){
                    items.push({id: res.data[$i].groups.id, communityName : res.data[$i].groups.titre, logo : res.data[$i].groups.logo, color : res.data[$i].groups.couleur})
                }
                 
                this.setState({
                    userCommunities : items
                });
            }).catch((err) => {
                // console.log(err.message)
            });
        }
        this.setState({
            postToShare : Post
        })

        return false;
    }
    onUpdatePost (data){
        let index = configs.getIndexWidthProperty(this.state.posts, "id", this.state.postToUpdate.id);
        let Posts = [...this.state.posts];

        Posts[index] = data;
        setTimeout(() => {
            this.setState({
                postToUpdate : false, 
                posts : Posts
            });
        }, 1000);
    }

    // async onUpdatePost (data){
    //     let index = configs.getIndexWidthProperty(this.state.posts, "id", this.state.postToUpdate.id);
    //     let Posts = [...this.state.posts];

    //     Posts[index] = data;
    //     await this.setState({
    //         postToUpdate : false, 
    //         posts : Posts
    //     });
    // }

    // async onUpdatePost (data){
        

    //     let index = configs.getIndexWidthProperty(this.state.posts, "id", this.state.postToUpdate.id);
    //     let Posts = [...this.state.posts];

    //     data["commentnormale"] = [];
    //     data["nbComments"] = Posts[index].nbComments;
    //     data["startPoint"] = Posts[index].startPoint;
    //     Posts[index].text = data.text;

        
    //     await this.setState({
    //         postToUpdate : false, 
    //         posts : Posts
    //     });
        
    // }
    handleShareToCommunity (ev){
        ev.preventDefault();
        const target = ev.target;
        
        if(!target.getAttribute('disabled') || !target.hasAttribute('disabled')){
            const postId = target.getAttribute('data-post');

            let Posts = [...this.state.posts];
            let index = configs.getIndexWidthProperty(Posts, 'id', parseInt(postId));
            


            const communityId = target.getAttribute('data-community');
            const textarea = document.getElementById('textToShare');
            const text = textarea.value;
            const data = {
                iduser : this.props.auth.user.user_id,
                idgroup : communityId,
                idpost : postId,
                text : text,
            }
            axios
            .post(Global.API_URL + "/partage", data)
            .then((res) => {

                
                Posts[index].nbrepartage = Posts[index].nbrepartage + 1;
                Posts[index]['hasDidShare'] = true;

                this.setState({
                    postToShare: null,
                    posts : Posts
                });
                setTimeout(() => {
                    SweetAlert.fire({ 
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }, 200);  
                target.parentNode.innerHTML = '<div className="post-shared-success">Publication publié !!</div>';
            }).catch((err) => {
                // console.log(err.message)
            });

            
        }
        return;
    }
    onCreateComment (res, postid){
        let index = configs.getIndexWidthProperty(this.state.posts, "id", postid);
        let Posts = [...this.state.posts];

        res.data.comment['responses'] = [];
        res.data.comment['nbResponses'] = 5;
        res.data.comment['startPoint'] = 0;
        
        let comments = [res.data.comment];
        // res.data.forEach(function(item, ind){
        //     comments.push(item.comment);
        // });

        Posts[index].commentnormale = comments.concat(Posts[index].commentnormale);
        Posts[index].showComments = true;
        Posts[index].nbrecommentaire = Posts[index].nbrecommentaire + 1; 
        Posts[index].startPoint = Posts[index].startPoint + 1; 

        
        this.setState({
            posts : Posts
        });
    }
    onCreateResponse (res, postid, commentid){
        console.log(res, postid, commentid);
        let index = configs.getIndexWidthProperty(this.state.posts, "id", postid);
        let Posts = [...this.state.posts];

        let indexCmt = configs.getIndexWidthProperty(Posts[index].commentnormale, "id", commentid);
        if (indexCmt>=0) {
            Posts[index].commentnormale[indexCmt].nbreponse = Posts[index].commentnormale[indexCmt].nbreponse + 1; 
            Posts[index].commentnormale[indexCmt].startPoint = Posts[index].commentnormale[indexCmt].startPoint + 1; 
            
            
            let comments = [res.data.comment];
    
            Posts[index].commentnormale[indexCmt].responses = comments.concat(Posts[index].commentnormale[indexCmt].responses);
    
            
            this.setState({
                posts : Posts
            });
        }else{
            // let indexCmt = configs.getIndexWidthProperty(Posts[index].lastcomment, "id", lastcomment);


            Posts[index].lastcomment.nbreponse = Posts[index].lastcomment.nbreponse + 1; 
            Posts[index].lastcomment.startPoint = Posts[index].lastcomment.startPoint + 1; 
            
            
            let comments = [res.data.comment];
    
            Posts[index].lastcommentRepense = comments.concat(Posts[index].lastcommentRepense);
    
            
            this.setState({
                posts : Posts
            });
        }
    }
    handleLoadResponses (ev, post, index, comment, indexCmt, showComments = false){
        
        if( !comment.nbreponse ){
            return;
        }
        if( showComments && this.state.posts[index].commentnormale[indexCmt].startPoint > 0 ){
            return;
        }

        document.getElementById('response-cmt-'+comment.id).classList.add('on');

        const data = {
            userEmail : this.props.auth.user.username,
            groupID : post.groups.id,
            commentID : comment.id,
            nbComments : comment.nbResponses,
            startPoint : comment.startPoint,
        }
        axios
        .post(Global.API_URL+"/api/reponses/list", data)
        .then((res) => {
            const comments = res.data.List;
            
            let posts = this.state.posts
            posts[index].commentnormale[indexCmt].responses = posts[index].commentnormale[indexCmt].responses.concat(comments);
            posts[index].commentnormale[indexCmt].startPoint = posts[index].commentnormale[indexCmt].startPoint + posts[index].commentnormale[indexCmt].nbResponses;
            
            this.setState({
                posts : posts
            });

            document.getElementById('response-cmt-'+comment.id).classList.remove('on');
        })
        .catch((err) => {
            // console.log('Load Comments Error : '+err.message)
        });
        return;
    }
    handleLoadResponsesLastComent (ev, post, index, comment, indexCmt, showComments = false,newAffichage= false){
        
        if( !comment.nbreponse ){
            return;
        }
        if( showComments && this.state.posts[index].lastcomment.startPoint > 0 ){
            return;
        }
        if( newAffichage  && this.state.posts[index].lastcommentRepense && this.state.posts[index].lastcommentRepense.length > 0 ){
            this.state.posts[index].lastcommentRepense.length =[]
            return;
        }

        document.getElementById('response-cmt-'+comment.id).classList.add('on');

        const data = {
            userEmail : this.props.auth.user.username,
            groupID : post.groups.id,
            commentID : comment.id,
            nbComments : 5,
            startPoint : this.state.posts[index].lastcomment.startPoint ? this.state.posts[index].lastcomment.startPoint : 0,
        }
        axios
        .post(Global.API_URL+"/api/reponses/list", data)
        .then((res) => {
            const comments = res.data.List;
            
            let posts = this.state.posts
            posts[index].lastcommentRepense =  posts[index].lastcommentRepense &&  posts[index].lastcommentRepense.length>0  ? posts[index].lastcommentRepense.concat(comments) : comments;
            // if (posts[index].lastcommentRepense &&  posts[index].lastcommentRepense.length>0 ) {
            //     for (let ind = 0; ind < comments.length; ind++) {
            //         const rep = comments[ind];
            //         console.log('rep',rep);
            //         if(posts[index].lastcommentRepense.some(res => res.id!== rep.id)){
            //             posts[index].lastcommentRepense.push(rep)
            //         }
            //     }
            // }
            // posts[index].lastcommentRepense = comments.concat(Posts[index].lastcommentRepense);

            posts[index].lastcomment.startPoint = posts[index].lastcomment.startPoint + posts[index].lastcomment.nbResponses;
            
            this.setState({
                posts : posts
            });

            document.getElementById('response-cmt-'+comment.id).classList.remove('on');
        })
        .catch((err) => {
            // console.log('Load Comments Error : '+err.message)
        });
        return;
    }
    handleLoadComments(ev, post, index, showComments = false){
        ev.preventDefault();
        ev.stopPropagation();
        if( post.showComments ) ev.target.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});  
        
        if(!post.nbrecommentaire){
            return;
        }
        if(showComments && this.state.posts[index].startPoint > 0){
            return;
        }
        document.getElementById('comments-area-'+post.id).classList.add('on');
        const data = {
            userEmail : this.props.auth.user.username,
            postID : post.id,
            partageID : 0,
            postType : post.type,
            groupID : post.groups.id,
            nbComments : post.nbComments,
            startPoint : post.startPoint,
        }
        axios
        .post(Global.API_URL+"/api/comments/list", data)
        .then((res) => {
            const comments = res.data.List;
            for(var $i = 0; $i < res.data.List.length; $i++){
                comments[$i]['responses'] = [];
                comments[$i]['nbResponses'] = 5;
                comments[$i]['startPoint'] = 0;
            }

            let posts = this.state.posts
            posts[index].commentnormale = posts[index].commentnormale.concat(comments);
            posts[index].startPoint = posts[index].startPoint + posts[index].nbComments;

            this.setState({
                posts : posts
            });

            document.getElementById('comments-area-'+post.id).classList.remove('on');
        })
        .catch((err) => {
            // console.log('Load Comments Error : '+err.message)
        });
        return;
    }

    translatePostText(post, index){  
        let LangCode = configs.getLanguages().find(lang=>{return lang.value === this.state.defaultLanguage})?.code || 'fr' ;
        this.state.posts[index].translatingText = true;
        this.setState({},()=>{
            configs.translatePostText(post.text.trim().replace(/\n/g, '<br>'), LangCode).then(translatedText=>{
                this.state.posts[index].translatedText = translatedText;
                this.state.posts[index].translatingText = false;
                this.setState({});
            })
        });
    }
    handlePingPost(ev, postSelected){
        ev.preventDefault()
        
        const data =new FormData();
        let param = postSelected.epingle == 1 ? 0 : 1 
        data.append('idpost',postSelected.id)
        data.append('idgroup',postSelected.groups.id)
        data.append('param',param)

        axios
        .post(Global.API_URL+"/api/epingledeplingle", data)
        .then((res) => {
            
            var Posts = [...this.state.posts];
            var ind = configs.getIndexWidthProperty(Posts, 'id', postSelected.id);
          
            if(res.status){
                Posts.map(a=>a.epingle=null);
                Posts[ind].epingle = param ;
                if (param == 1) {
                    Posts.sort((x,y)=>{ return x.id === postSelected.id ? -1 : y.id === postSelected.id ? 1 : 0; });
                }else{
                    this.setState({
                        startpoint : 0,
                        allLoaded : false
                    })
                    this.getPublications();
                }

                this.setState({
                    posts: Posts
                })
            }
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });

    }
    
    render() { 
        function checkIfHasLink(text){
            var returnText=text
            if(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text)) {
                let url = text.match(/(https?\:\/\/)?([^\.\s]+)?[^\.\s]+\.[^\s]+/gi);
                url.forEach((link,index) => {
                    if (link.includes("https") || link.includes("http") || link.includes("www.")){
                        let extraLink=`<a target="blank" href=${link} key=${index} style="color: var(--secondary-color)">
                                            ${link}
                                        </a>`
                        returnText=text.replace(link,extraLink)
                    }
                })
            }
            return {__html: returnText}
        }
        let reactionsCounter = {
            likeokey: 0,
            likemain: 0,
            likecoche: 0
        } 
        this.state.reactions.map((reaction)=>{
            reactionsCounter.likeokey += reaction.likeokey;
            reactionsCounter.likemain += reaction.likemain;
            reactionsCounter.likecoche += reaction.likecoche;
        }) 
        
        return (
            <section className={` ${this.props.parent}`}>
                <div className="wrap">
                    {
                        this.props.parent == 'page_home' &&
                        <Headband />
                    }

                    {
                        (this.state.role !== "ROLE_LECTEUR") && !this.props.match.params.postId && this.props.formAddPost &&
                        <CreatePost onCreatePost={this.handleNewPost} community={this.state.community} />
                    } 

                    {this.state.posts.map((post, index) => {
 
                        return(
                            // {"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}
                            <div key={"post-"+post.id} index={index} className={`post-area post-type-${post.type} post-${post.id} ${((post.epingle ==1 && this.props.parent === 'page_community')  ? 'pinged' : '')}` }>
                                <div className="wrap">
                                    <div className="publishedIn">
                                        {
                                            post.type === 3 &&
                                            <>
                                                {/* <i className="fa fa-bar-chart"></i>&nbsp; */}
                                                <span className='postTypeTitle'>Sondage</span>&nbsp;
                                            </>
                                        }
                                        {
                                            post.type === 2 &&
                                            <>
                                                {/* <i className="fa fa-calendar"></i>&nbsp; */}
                                                <span className='postTypeTitle'>Événement</span>&nbsp;
                                            </>
                                        }
                                        Publié dans <Link to={"/communaute/" + post.groups.id}>{post.groups.titre}</Link>
                                    </div>
                                    <div className="header-post">
                                        <div className="rm-list">
                                            <input type="checkbox" id={"post-"+post.id} />
                                            <label className="toggle" htmlFor={"post-"+post.id}>...</label>
                                            <div className="rm-list-item">
                                                <ul>
                                                    {/* {post.users.id === this.props.auth.user.user_id &&
                                                        <li><Link to="" onClick={(e) => {this.handleUpdatePost(e, post)}}>Modifier</Link></li>
                                                    } */}

                                                    {   
                                                        (post.users.id === this.props.auth.user.user_id || configs.isAdmin()) &&   this.props.parent === 'page_community' &&
                                                        <li>
                                                            <Link to="" onClick={(e) => {this.handlePingPost(e, post)}} ><i className="fa fa-thumb-tack" aria-hidden="true"></i> 
                                                                {
                                                                    post.epingle==1?
                                                                        "Désépingler"
                                                                    :
                                                                        "Epingler"
                                                                    
                                                                }
                                                            </Link>
                                                        </li>
                                                    }
                                                    {
                                                        (post.type !== 2 ) && (post.users.id === this.props.auth.user.user_id || configs.isAdmin() || (this.state.role == 'ROLE_ADMIN' && this.props.parent == 'page_community' )) &&
                                                        <li><Link to="" onClick={(e) => {this.handleUpdatePost(e, post)}}><i className="fa fa-edit"></i>
                                                            {
                                                                (post.type == 3) ? 
                                                                    <>
                                                                        Modifier le texte
                                                                    </>
                                                                :
                                                                    <>
                                                                         Modifier
                                                                    </>
                                                            }
                                                        </Link></li>
                                                    }
                                                    { post.enregistrer && configs.getIndexWidthProperty(post.enregistrer, 'email', this.props.auth.user.username) >= 0 ?
                                                        <li>
                                                            <Link to="" onClick={(e) => this.handleUnSavePost(e, post.id)} >
                                                                <i className="fa fa-bookmark"></i>Supp. l'enregistrement
                                                            </Link>
                                                        </li>
                                                        :
                                                        <li>
                                                            <Link to="" onClick={(e) => this.handleSavePost(e, post.id)} >
                                                                <i className="fa fa-bookmark-o"></i>Enregistrer
                                                            </Link>
                                                        </li>
                                                    } 
                                                    {
                                                        (post.users.id === this.props.auth.user.user_id || configs.isAdmin() || this.props.role == "ROLE_ADMIN" ) &&
                                                        <li><Link to="" onClick={(e) => {this.handleDeletePost(e, post.id, post.users.id)}}><i className="fa fa-trash-o"></i>Supprimer</Link></li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                       {
                                           post.epingle==1 && this.props.parent == 'page_community' &&
                                           <div className="pingedIcon" >
                                               {
                                                   (post.users.id === this.props.auth.user.user_id || configs.isAdmin()) ?
                                                   <Link to="" onClick={(e) => {this.handlePingPost(e, post)}} >
                                                        <i class="fa fa-thumb-tack" aria-hidden="true"></i>
                                                    </Link>
                                                    :
                                                    <i class="fa fa-thumb-tack" aria-hidden="true"></i>
                                               }
                                               
                                            </div> 
                                           
                                        }
                                        {
                                            post.type !== 2 &&
                                            <div className="user-publisher">
                                                <figure className="userimage" style={{backgroundImage: post?.users?.images ? 'url('+ Global.API_URL + post?.users?.images?.image +')' : 'none' }}>
                                                    {
                                                        !post?.users?.images &&
                                                        <span>{post?.users?.profile?.prenom?.charAt(0)}{post?.users?.profile?.nom?.charAt(0)}</span>
                                                    }
                                                     
                                                </figure>
                                                <div className="">
                                                    <div className="username">
                                                        {post.users.profile.prenom.toLowerCase() + " " + post.users.profile.nom.toLowerCase()}
                                                        <CustomPopHoverUser user={post?.users} />
                                                    </div>
                                                    <div className="p">
                                                        <span>{post.users.profile.fonction}</span><br />
                                                        <abbr>{configs.timeSince(post.createdAt)}</abbr>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div> 

                                    <div className="content-post">

                                        {
                                            post.type === 3  &&
                                            <div className="p">  
                                                <div className="attachments">
                                                    <Gallery 
                                                        images={post.imag}
                                                        handleOpenGallery={this.handleOpenGallery} 
                                                        handleCloseGallery={this.handleCloseGallery} 
                                                        OpenedGalleryID={this.state.openedGalleryID} 
                                                        PostID={post.id} /> 
                                                </div>
                                            </div>
                                        }
                                        
                                        {
                                            post.text && post.type !== 2 &&
                                            <p>
                                                {post.text && post.text.trim().split("\n").map(function(item, idx) {
                                                    return (
                                                        <>
                                                            {item.includes('class="tagueName"') ?
                                                                <>
                                                                    <span key={idx} dangerouslySetInnerHTML={checkIfHasLink(item)}>
                                                                    </span>
                                                                    <br/>
                                                                </>
                                                            :
                                                                <>
                                                                    {/* <span key={idx}>
                                                                        <Linkify
                                                                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                    {decoratedText}
                                                                                </a>
                                                                            )}> 
                                                                            {item}
                                                                        </Linkify>
                                                                    </span>
                                                                    <br/> */}

                                                                    {
                                                                        item.length ==0?
                                                                        <br/> 
                                                                            :
                                                                        <>
                                                                            <span key={idx}>
                                                                                <Linkify
                                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                        <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                            {decoratedText}
                                                                                        </a>
                                                                                    )}> 
                                                                                    {item}
                                                                                </Linkify>
                                                                            </span>
                                                                            <br/>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        // <span key={idx}>
                                                        //     <Linkify
                                                        //         componentDecorator={(decoratedHref, decoratedText, key) => (
                                                        //             <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                        //                 {decoratedText}
                                                        //             </a>
                                                        //         )}> 
                                                        //         {item}
                                                        //     </Linkify>
                                                        // <br/>
                                                        // </span>
                                                        )
                                                    })
                                                }
                                            </p>
                                        } 
                                        {
                                            post.text && !post.translatedText && !this.isURL(post.text.trim()) &&  post.type !== 3 &&  post.type !== 2 &&
                                            <a onClick={(e)=>{e.preventDefault(); this.translatePostText(post, index)}} href="#translatePostText" className="translatePostText">
                                                <img src={configs.GetImage('icon-world.png')} alt="" />
                                                <span>&nbsp;Traduction</span>
                                            </a>
                                        }
                                        {
                                            post.translatingText && post.text && !post.translatedText && !this.isURL(post.text.trim()) && post.type !== 2 &&
                                            <div style={{marginTop: 10}}><BeatLoader color={"#aaa"} loading={true} size="10"/></div>
                                        }

                                        {
                                            post.translatedText &&
                                            <p className="translatdText">
                                                {post.translatedText.trim().split("<br>").map(function(item, idx) {
                                                    return (
                                                        <span key={idx}>
                                                            <Linkify
                                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                    <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--primary-color)'}}>
                                                                        {decoratedText}
                                                                    </a>
                                                                )}> 
                                                                <span dangerouslySetInnerHTML={{__html: item}}></span> 
                                                            </Linkify>
                                                        <br/>
                                                        </span>
                                                        )
                                                    })
                                                }
                                            </p>
                                        }

                                        {
                                            post.type === 3 &&
                                            <Poll post={post} auth={this.props.auth} />
                                        }
                                        {
                                            post.type === 2 &&
                                            <PostEvent post={post} auth={this.props.auth} />
                                        }

                                        
                                    </div>
                                    <div className={`content-post ${post.prenomold ? 'shared': ''}`}>
                                        
                                        {
                                            post.prenomold &&  
                                            <div>
                                                <div className="header-post">
                                                    <div className="user-publisher">
                                                        <figure className="userimage" style={{backgroundImage: 'url('+ Global.API_URL + post?.profileold +')' }}></figure>
                                                        <div className="">
                                                            <div className="username">{post.prenomold.toLowerCase() + " " + post.auteurold[0]}</div>
                                                            <div className="p">
                                                                <span>{post.fonctionold}</span><br />
                                                                <abbr>{configs.timeSince(post.olddate)}</abbr>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                               




                                                {
                                                    post.textold &&
                                                    <p>
                                                        {post.textold && post.textold.split("\n").map(function(item, idx) {
                                                            return (
                                                                <>
                                                                    {item.includes('class="tagueName"') ?
                                                                        <>
                                                                            <span key={idx} dangerouslySetInnerHTML={checkIfHasLink(item)}>
                                                                            </span>
                                                                        </>
                                                                        :
                                                                        <>
                                                                           <span key={idx}>
                                                                                <Linkify
                                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                        <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--primary-color)'}}>
                                                                                            {decoratedText}
                                                                                        </a>
                                                                                    )}> 
                                                                                    {item}
                                                                                </Linkify>
                                                                            <br/>
                                                                            </span>
                                                                        </>
                                                                    }
                                                                </>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                }





                                                
                                                {/* {
                                                    post.textold &&
                                                    <p>
                                                        {post.textold && post.textold.split("\n").map(function(item, idx) {
                                                            return (
                                                                <span key={idx}>
                                                                    <Linkify
                                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                            <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--primary-color)'}}>
                                                                                {decoratedText}
                                                                            </a>
                                                                        )}> 
                                                                        {item}
                                                                    </Linkify>
                                                                <br/>
                                                                </span>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                } */}




                                            </div>
                                            
                                        
                                        }

                                        {
                                            post.type !== 3 && post.type !== 2 &&
                                            <div className="p postNorml">  
                                                <div className="attachments">
                                                    <FilesView files={post.doc} filesName={post.docreelname} />
                                                    <AudioView files={post.doc} />

                                                    <Gallery 
                                                        images={post.imag}
                                                        handleOpenGallery={this.handleOpenGallery} 
                                                        handleCloseGallery={this.handleCloseGallery} 
                                                        OpenedGalleryID={this.state.openedGalleryID} 
                                                        PostID={post.id} /> 
                                                
                                                    <GifView gif={post.gif} />
                                                    <VideoView 
                                                        videoUrl={post.upload ? Global.API_URL +"/" + post.upload : ""} 
                                                        poster={post.thumbs ? Global.API_URL + "/" + post.thumbs : ""} 
                                                        convertedFromMovToMp4={post.videoconvert}
                                                    />

                                                    <LinkView link={ post.text.includes('http') ? post.text : (post.textold ? post.textold : "") } />
                                                </div>
                                            </div>
                                        }
                                    
                                    </div>
                                    {
                                        (Number(post.cmptokey) > 0 || Number(post.cmptmain) > 0 || Number(post.cmptcoche) > 0 ||Number(post.nbrecommentaire) > 0 ||Number(post.nbrepartage) > 0 ) &&
                                        <div className="reactions-recaps">
                                            <div >
                                                <div onClick={()=>{this.getReactionsAndShowPopin(post.id)}}>
                                                    {
                                                        Number(post.cmptokey) > 0 &&
                                                        <Like height="15px" className="likes okey" color="var(--secondary-color)" />
                                                    }
                                                    {
                                                        Number(post.cmptmain) > 0 &&
                                                        <Applaud height="15px" className="likes applaude" color="var(--secondary-color)" />
                                                    }
                                                    {
                                                        Number(post.cmptcoche) > 0 &&
                                                        <True height="15px" className="likes check" color="var(--secondary-color)" />
                                                    }
                                                    <strong>
                                                    {
                                                        (Number(post.cmptokey) + Number(post.cmptmain) + Number(post.cmptcoche)) > 0 &&
                                                        Number(post.cmptokey) + Number(post.cmptmain) + Number(post.cmptcoche)
                                                    }
                                                    </strong>   
                                                </div>  
                                            </div>
                                            <div>
                                                <div onClick={()=>{this.getReactionsAndShowPopin(post.idpostold ? post.idpostold : post.id, 2)}}>
                                                    {
                                                        Number(post.nbrecommentaire) > 0 &&
                                                        <span>
                                                            <b>{post.nbrecommentaire} </b>
                                                            <a href="#display-comments" className="display-comments" onClick={(e) => {post.showComments = !post.showComments; this.setState({});this.handleLoadComments(e, post, index, true);}}> 
                                                                {post.nbrecommentaire === 1 ? 'Commentaire' : 'Commentaires'} 
                                                            </a>
                                                        </span> 
                                                    }
                                                    {
                                                        Number(post.nbrecommentaire) > 0 && Number(post.nbrepartage) > 0 &&
                                                        <em>&nbsp;&nbsp;‧&nbsp;&nbsp;</em>
                                                    }
                                                    {
                                                        Number(post.nbrepartage) > 0 &&
                                                        <strong>
                                                            <Share height="15px" className="share" color="var(--secondary-color)" />
                                                            {Number(post.nbrepartage)}
                                                        </strong>
                                                    } 
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="reactions">
                                        <div className="statistics" id={"statistics-" + post.id}>
                                            {
                                                post.type !== 3 &&  post.type !== 2 && 
                                                    <motion.span
                                                    whileHover={{scale : 1.1}}
                                                    whileTap={{scale : 0.9}}
                                                    className={"likes okey " + (configs.getIndexWidthManyProperties(post.action, ['auteur', 'likeokey'], [this.props.auth.user.username, 1], 2) !== -1 ? 'active' : '')} onClick={(e) => {this.handleLikePost(e, post)}}>
                                                        <Like height="20px" color="#bbb" />&nbsp; J'aime
                                                        {/* {Number(post.cmptokey) > 0 ? 
                                                            configs.kFormatter(Number(post.cmptokey))
                                                        : 0} */}
                                                        </motion.span>
                                            }
                                            {
                                                post.type !== 2 && 
                                                    <>
                                                        <b> | </b>
                                                        <motion.span
                                                        whileHover={{scale : 1.1}}
                                                        whileTap={{scale : 0.9}}
                                                        className={"likes applaude " + (configs.getIndexWidthManyProperties(post.action, ['auteur', 'likemain'], [this.props.auth.user.username, 1], 2) !== -1 ? 'active' : '')} onClick={(e) => {this.handleApplaudPost(e, post)}}>
                                                            <Applaud height="20px" color="#bbb" />&nbsp; Bravo
                                                            {/* {Number(post.cmptmain) > 0 ? 
                                                                configs.kFormatter(Number(post.cmptmain))
                                                                : 0
                                                            } */}
                                                            </motion.span>
                                                    </>
                                            }
                                            {
                                                post.type !== 2 && 
                                                    <>
                                                        <b> | </b>
                                                        <motion.span 
                                                        whileHover={{scale : 1.1}}
                                                        whileTap={{scale : 0.9}}
                                                        className={"likes check " + (configs.getIndexWidthManyProperties(post.action, ['auteur', 'likecoche'], [this.props.auth.user.username, 1], 2) !== -1 ? 'active' : '')} onClick={(e) => {this.handleCheckPost(e, post)}}>
                                                            <True height="20px" color="#bbb" />&nbsp; Ok
                                                            {/* {Number(post.cmptcoche) > 0 ? 
                                                                configs.kFormatter(Number(post.cmptcoche))
                                                                : 0} */}
                                                            </motion.span>
                                                    </>
                                            }
                                            {
                                                post.type === 2 && 
                                                    <>
                                                        <center style={{display: 'block', width: '100%', padding: '0px'}}>
                                                            <motion.span 
                                                            whileHover={{scale : 1.1}}
                                                            whileTap={{scale : 0.9}}
                                                            className={"likes check " + (configs.getIndexWidthManyProperties(post.action, ['auteur', 'likecoche'], [this.props.auth.user.username, 1], 2) !== -1 ? 'active' : '')} onClick={(e) => {this.handleCheckPost(e, post)}}>
                                                                <True height="20px" color="#bbb" />&nbsp; Ok
                                                                {/* {Number(post.cmptcoche) > 0 ? 
                                                                    configs.kFormatter(Number(post.cmptcoche))
                                                                    : 0} */}
                                                                </motion.span>
                                                        </center>
                                                    </>
                                            }
                                            {
                                                post.type !== 3 &&  post.type !== 2 && 
                                                <>
                                                    <b> | </b>
                                                    <motion.span 
                                                     whileHover={{scale : 1.1}}
                                                     whileTap={{scale : 0.9}}
                                                     className={"share " + (post.hasDidShare ? ' active ' : '' )} onClick={(e) => {this.handleSharePost(e, post)}}>
                                                        <Share height="20px" color="#bbbbbb" />&nbsp; Partager
                                                        {/* {post.nbrepartage ? configs.kFormatter(post.nbrepartage) : 0} */}
                                                    </motion.span>
                                                </>
                                            }
                                            
                                            {/* <b> | </b>
                                            <span className={`comment ${post.commentnormale.length > 0 ? 'active' : ''}`} onClick={(e) => {this.handleLoadComments(e, post, index, true)}}>
                                                <Comments height="20px" color={post.commentnormale.length > 0 ? "#34a5f8" : "#bbb"} />&nbsp; commentaires 
                                                {
                                                    Number(post.nbrecommentaire) > 0 &&
                                                    <small> ({post.nbrecommentaire})</small>
                                                }
                                                
                                            </span> */}
                                        </div>
                                        {/* <div className="d-flex like-share-action">
                                            <div className="like"><span className="fa fa-thumbs-up"></span>Like</div>
                                            <div className="share"><span className="fa fa-share"></span>Partager</div>
                                        </div> */}
                                    </div>
                                    <div className="comments">


                                        <CreateComment postid={post.id} groupid={post.groups.id} onCreateComment={this.onCreateComment} />
                                        
                                        {/* // last Comment */}
                                        {
                                            post.lastcomment &&
                                            <div className="comments-area" id={"comments-area-" + post.lastcomment.id}  style={{display: !post.showComments ? 'block' : 'none'}}>
                                                <div>
                                                    <ul> 
                                                        <li key={"cmt-" + post.lastcomment.id} className={"cmt-item-" + post.lastcomment.id}>
                                                            <figure className="_cthum">
                                                                <div 
                                                                    className="img" 
                                                                        style={post.lastcomment.users.images ? 
                                                                                {backgroundImage : 'url(' + Global.API_URL  + post.lastcomment.users.images.image + ')'}
                                                                                :
                                                                                {}
                                                                            }>
                                                                        {!post.lastcomment.users.images && 
                                                                            post.lastcomment.users.profile.prenom.charAt(0) + post.lastcomment.users.profile.nom.charAt(0)
                                                                        }
                                                                    </div>
                                                            </figure>
                                                            <div className="response-data">
                                                                {post.lastcomment.text &&
                                                                    <div className="reposnse">
                                                                        <div className="resp-username">
                                                                            {post.lastcomment.users.profile.prenom?.toLowerCase()} {post.lastcomment.users.profile.nom.toLowerCase()}
                                                                        </div> 
                                                                        {/* {post.lastcomment.text} */}
                                                                        {
                                                                            post.lastcomment.text && post.lastcomment.text.trim().split("\n").map(function(item, idx) {
                                                                                return (
                                                                                    <>
                                                                                        {item.includes('class="tagueName"') ?
                                                                                            <>
                                                                                                <span key={idx} dangerouslySetInnerHTML={checkIfHasLink(item)}>
                                                                                                </span>
                                                                                            </>
                                                                                        :
                                                                                            <>
                                                                                            <span key={idx}> 
                                                                                                    <Linkify
                                                                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                                            <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                                                {decoratedText}
                                                                                                            </a>
                                                                                                        )}> 
                                                                                                        {item}
                                                                                                    </Linkify>
                                                                                                    <br/>
                                                                                                </span>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                                
                                                                {post.lastcomment.filename &&
                                                                    <div className="attachments">
                                                                        {
                                                                            !post.lastcomment.text &&
                                                                            <div className="resp-username">
                                                                                {post.lastcomment.users.profile.prenom?.toLowerCase()} {post.lastcomment.users.profile.nom.toLowerCase()}
                                                                            </div>
                                                                        }
                                                                        <div className="single-image">
                                                                            <img src={ Global.API_URL+"/" + post.lastcomment.filename} alt="" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {post.lastcomment.gif !== null && post.lastcomment.gif !== 'null'  && post.lastcomment.gif !== ''  && 
                                                                    <div className="attachments">
                                                                        {
                                                                            !post.lastcomment.text &&
                                                                            <div className="resp-username">
                                                                                {post.lastcomment.users.profile.prenom?.toLowerCase()} {post.lastcomment.users.profile.nom.toLowerCase()}
                                                                            </div>
                                                                        }
                                                                        <div className="single-image">
                                                                            <img src={post.lastcomment.gif} alt="" />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <input 
                                                                            type="checkbox" 
                                                                            className="checkbox-sub-comment" 
                                                                            id={"checkbox-sub-last-comment-" + post.lastcomment.id} 
                                                                            onChange={(e) => {this.handleLoadResponsesLastComent(e, post, index, post.lastcomment, post.lastcomment.id,false,true)}} />
                                                                                                                                
                                                                <div className="_rlcd d-flex jc-space-b">
                                                                    <div className="">
                                                                    
                                                                        <span 
                                                                            
                                                                            onMouseEnter={(e) => {this.handleShowLikesCommentDetails(e, 'com', post.lastcomment.id,post.id,`nblikeLastComDetail-${post.lastcomment.id}`,post.lastcomment.nblike)}}
                                                                            // onMouseLeave={(e) => {this.handleHideLikesCommentDetails(e, 'com', post.lastcomment.id,post.id,`nblikeLastComDetail-${post.lastcomment.id}`,post.lastcomment.nblike)}}
                                                                            className={"_lan nblike " + (configs.getIndexWidthManyProperties(post.lastcomment.action, ['auteur', 'isLikeByUser'], [this.props.auth.user.username, true], 2) !== -1 ? 'active' : '')} 
                                                                            id={"nblike-" + post.lastcomment.id}>
                                                                                <div className="likeComDetail" id={"nblikeLastComDetail-" + post.lastcomment.id} ref= {"nblikeDetail-" + post.lastcomment.id}></div>
                                                                                <span onClick={(e) => {this.handleLikeComment(e, post.lastcomment, post.groups.id, index, 0)}}>
                                                                                    <Like   height="22px" color="#bbb" />  {post.lastcomment.nblike ? post.lastcomment.nblike : 0}
                                                                                </span>
                                                                        </span>
                                                                        &nbsp;‧&nbsp;&nbsp;
                                                                        <span className="_lan comments"><Comments height="15px" color="#bbb" /> {post.lastcomment.nbreponse ? post.lastcomment.nbreponse : 0}</span>
                                                                        &nbsp;‧&nbsp;&nbsp;
                                                                        <a href className="_lan" onClick={()=>{post.lastcomment.showReplyForm = !post.lastcomment.showReplyForm; this.setState({})}}>Repondre</a>
                                                                        &nbsp;‧&nbsp;&nbsp;
                                                                        <abbr className="comment-date">{configs.timeSince(post.lastcomment.createdAt)}</abbr>
                                                                    </div>
                                                                        {post.lastcomment.nbreponse &&
                                                                            <div className="_rtgle">
                                                                                <label htmlFor={"checkbox-sub-last-comment-" + post.lastcomment.id}>
                                                                                    <div className="hide-response">
                                                                                        <span className="label-md">Cacher les réponses</span>
                                                                                        <span className="label-xs">Réponses</span>
                                                                                        <span className="fa fa-chevron-up"></span>
                                                                                    </div>
                                                                                    <div className="display-response">
                                                                                        <span className="label-md">Afficher les réponses</span>
                                                                                        <span className="label-xs">Réponses</span>
                                                                                        <span className="fa fa-chevron-down"></span>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        post.lastcomment.showReplyForm && 
                                                                        <CreateComment placeholder="Repondre..." postid={post.id} commentid={post.lastcomment.id} groupid={post.groups.id} onCreateResponse={this.onCreateResponse}  isResponse={true} />
                                                                    }
                                                                </div>
                                                                <div className="response-cmt" id={"response-cmt-" + post.lastcomment.id}>
                                                                    {post.lastcommentRepense?.length > 0 && 
                                                                        <div>
                                                                            <ul>
                                                                                {post.lastcommentRepense.map(function(respon, responInd){
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                respon &&
                                                                                                    <li key={"resp" + responInd}>
                                                                                                        <figure className="_cthum">
                                                                                                            <div 
                                                                                                                className="img" 
                                                                                                                style={respon.users?.images ? 
                                                                                                                        {backgroundImage : 'url(' + Global.API_URL  + respon.users?.images?.image + ')'}
                                                                                                                        :
                                                                                                                        {}
                                                                                                                    }>
                                                                                                                {!respon.users.images && 
                                                                                                                    respon.users.profile.prenom.charAt(0) + respon.users.profile.nom.charAt(0)
                                                                                                                }
                                                                                                            </div>
                                                                                                        </figure>
                                                                                                        <div className="response-data">
                                                                                                        {(respon.users.id === this.props.auth.user.user_id) && 
                                                                                                            <div className="rm-list">
                                                                                                                <input type="checkbox" id={"response-id-" + respon.id} />
                                                                                                                <label className="toggle" htmlFor={"response-id-" + respon.id}>...</label>
                                                                                                                <div className="rm-list-item">
                                                                                                                    <ul>
                                                                                                                        {/* <li>
                                                                                                                            <Link 
                                                                                                                                to="" 
                                                                                                                                onClick={(e) => {
                                                                                                                                    e.preventDefault(); 
                                                                                                                                    this.setState({
                                                                                                                                        responseToUpdate : {
                                                                                                                                            cmt : respon, 
                                                                                                                                            groupid : post.groups.id,
                                                                                                                                            postIndex : index,
                                                                                                                                            commentIndex : responInd
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                }} >
                                                                                                                                Modifier
                                                                                                                            </Link>
                                                                                                                        </li> */}

                                                                                                                        <li>
                                                                                                                            <Link 
                                                                                                                                to="" 
                                                                                                                                onClick={(e) => {
                                                                                                                                    e.preventDefault(); 
                                                                                                                                    this.setState({
                                                                                                                                        responseToDelete : {
                                                                                                                                            cmt : respon, 
                                                                                                                                            groupid : post.groups.id,
                                                                                                                                            postIndex : index,
                                                                                                                                            commentIndex : post.lastcomment.id,
                                                                                                                                            responseIndex : responInd
                                                                                                                                        }
                                                                                                                                    })
                                                                                                                                }} >
                                                                                                                                Supprimer
                                                                                                                            </Link>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                            
                                                                                                            
                                                                                                            {respon.text &&
                                                                                                                <div className="reposnse">
                                                                                                                    <div className="resp-username">
                                                                                                                        {respon.users.profile.prenom.toLowerCase() + " " + respon.users.profile.nom.toLowerCase()}
                                                                                                                    </div>
                                                                                                                    {/* {respon.text}   */}
                                                                                                                    {
                                                                                                                        respon.text && respon.text.trim().split("\n").map(function(item, idx) {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    {item.includes('class="tagueName"') ?
                                                                                                                                        <>
                                                                                                                                            <span key={idx} dangerouslySetInnerHTML={checkIfHasLink(item)}>
                                                                                                                                            </span>
                                                                                                                                        </>
                                                                                                                                    :
                                                                                                                                        <>
                                                                                                                                        <span key={idx}>
                                                                                                                                                <Linkify
                                                                                                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                                                                                        <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                                                                                            {decoratedText}
                                                                                                                                                        </a>
                                                                                                                                                    )}> 
                                                                                                                                                    {item}
                                                                                                                                                </Linkify>
                                                                                                                                                <br/>
                                                                                                                                            </span>
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    }
                                                                                                                </div>
                                                                                                            } 
                                                                                                            {respon.filename &&
                                                                                                                <div className="attachments">
                                                                                                                    {
                                                                                                                        !respon.text &&
                                                                                                                        <div className="resp-username">
                                                                                                                            {respon.users.profile.prenom?.toLowerCase()} {respon.users.profile.nom.toLowerCase()}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="single-image">
                                                                                                                        <img src={Global.API_URL +"/" + respon.filename} alt="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {respon.gif &&
                                                                                                                <div className="attachments">
                                                                                                                    {
                                                                                                                        !respon.text &&
                                                                                                                        <div className="resp-username">
                                                                                                                            {respon.users.profile.prenom?.toLowerCase()} {respon.users.profile.nom.toLowerCase()}
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <div className="single-image">
                                                                                                                        <img src={respon.gif} alt="" />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                            
                                                                                                            <div className="_rlcd d-flex jc-space-b">
                                                                                                                <div className="">
                                                                                                                    <span 
                                                                                                                        className={"_lan " + (configs.getIndexWidthManyProperties(respon.action, ['auteur', 'isLikeByUser'], [this.props.auth.user.username, true], 2) !== -1 ? 'active' : '')}
                                                                                                                        onMouseEnter={(e) => {this.handleShowLikesCommentDetails(e, 'rep' , respon.id , post.id,`nbRepOfComDetail-${respon.id}`,respon.nblike)}}
                                                                                                                        // onMouseLeave={(e) => {this.handleHideLikesCommentDetails(e, 'rep' , respon.id,post.id,`nbRepOfComDetail-${respon.id}`,respon.nblike)}}
                                                                                                                        id={"nblike-resp-" + respon.id}
                                                                                                                        onClick={(e) => {this.handleLikeResponse(e, respon, post.groups.id, index, post.lastcomment.id, responInd)}} >
                                                                                                                            <span className="nblike">
                                                                                                                                <div id={"nbRepOfComDetail-" + respon.id} ref= {"nblikeDetail-" + respon.id}></div> 
                                                                                                                                <Like height="22px" color="#bbb" /> {respon.nblike ? respon.nblike : 0}
                                                                                                                            </span>
                                                                                                                        
                                                                                                                    </span>
                                                                                                                    &nbsp;‧&nbsp;&nbsp;
                                                                                                                    <abbr className="comment-date">{configs.timeSince(respon.createdAt)}</abbr>
                                                                                                                </div>
                                                                                                                <div className="_rtgle">

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </li>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }.bind(this))}
                                                                            </ul>
                                                                            
                                                                            {(post.lastcomment.nbreponse > post.lastcommentRepense.length) &&
                                                                                <span className="loadMoreComments" onClick={(e) => {this.handleLoadResponsesLastComent(e, post, index, post.lastcomment, post.lastcomment.id)}}>+ Afficher plus de commentaires</span>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        }
                                        
                                        <div className="comments-area" id={"comments-area-" + post.id}  style={{display: post.showComments ? 'block' : 'none'}}>
                                            {(post?.commentnormale?.length > 0) && 
                                                <div>
                                                    <ul>
                                                        {post.commentnormale.map(function(cmt, ind){
                                                            return (
                                                                <li key={"cmt-" + cmt.id} className={"cmt-item-" + cmt.id}>
                                                                    <figure className="_cthum">
                                                                        <div 
                                                                            className="img" 
                                                                                style={cmt.users.images ? 
                                                                                        {backgroundImage : 'url(' + Global.API_URL  + cmt.users.images.image + ')'}
                                                                                        :
                                                                                        {}
                                                                                    }>
                                                                                {!cmt.users.images && 
                                                                                    cmt.users.profile.prenom.charAt(0) + cmt.users.profile.nom.charAt(0)
                                                                                }
                                                                            </div>
                                                                    </figure>
                                                                    <div className="response-data">

                                                                        {(cmt.users.id === this.props.auth.user.user_id || this.props.role == "ROLE_ADMIN" ) &&
                                                                            <div className="rm-list">
                                                                                <input type="checkbox" id={"comment-id-" + cmt.id} />
                                                                                <label className="toggle" htmlFor={"comment-id-" + cmt.id}>...</label>
                                                                                <div className="rm-list-item">
                                                                                    <ul>
                                                                                        {/* <li>
                                                                                            <Link 
                                                                                                to="" 
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault(); 
                                                                                                    this.setState({
                                                                                                        CommentToUpdate : {
                                                                                                            cmt : cmt, 
                                                                                                            groupid : post.groups.id,
                                                                                                            postIndex : index,
                                                                                                            commentIndex : ind
                                                                                                        }
                                                                                                    })
                                                                                                }} >
                                                                                                Modifier
                                                                                            </Link>
                                                                                        </li> */}

                                                                                        <li>
                                                                                            <Link 
                                                                                                to="" 
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault(); 
                                                                                                    this.setState({
                                                                                                        CommentToDelete : {
                                                                                                            cmt : cmt, 
                                                                                                            groupid : post.groups.id,
                                                                                                            postIndex : index,
                                                                                                            commentIndex : ind
                                                                                                        }
                                                                                                    })
                                                                                                }} >
                                                                                                Supprimer
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        
                                                                        
                                                                        {cmt.text &&
                                                                            <div className="reposnse">
                                                                                <div className="resp-username">
                                                                                    {cmt.users.profile.prenom?.toLowerCase()} {cmt.users.profile.nom.toLowerCase()}
                                                                                </div> 
                                                                                {/* {cmt.text} */}
                                                                                {
                                                                                    cmt.text && cmt.text.trim().split("\n").map(function(item, idx) {
                                                                                        return (
                                                                                            <>
                                                                                                {item.includes('class="tagueName"') ?
                                                                                                    <>
                                                                                                        <span key={idx} dangerouslySetInnerHTML={checkIfHasLink(item)}>
                                                                                                        </span>
                                                                                                    </>
                                                                                                :
                                                                                                    <>
                                                                                                    <span key={idx}>
                                                                                                            <Linkify
                                                                                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                                                    <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                                                        {decoratedText}
                                                                                                                    </a>
                                                                                                                )}> 
                                                                                                                {item}
                                                                                                            </Linkify>
                                                                                                            <br/>
                                                                                                        </span>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }
                                                                        
                                                                        {cmt.filename &&
                                                                            <div className="attachments">
                                                                                {
                                                                                    !cmt.text &&
                                                                                    <div className="resp-username">
                                                                                        {cmt.users.profile.prenom?.toLowerCase()} {cmt.users.profile.nom.toLowerCase()}
                                                                                    </div>
                                                                                }
                                                                                <div className="single-image">
                                                                                    <img src={ Global.API_URL + cmt.filename} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {cmt.gif !== null && cmt.gif !== 'null'  &&
                                                                            <div className="attachments">
                                                                                {
                                                                                    !cmt.text &&
                                                                                    <div className="resp-username">
                                                                                        {cmt.users.profile.prenom?.toLowerCase()} {cmt.users.profile.nom.toLowerCase()}
                                                                                    </div>
                                                                                }
                                                                                <div className="single-image">
                                                                                    <img src={cmt.gif} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    
                                                                        <input 
                                                                            type="checkbox" 
                                                                            className="checkbox-sub-comment" 
                                                                            id={"checkbox-sub-comment-" + cmt.id} 
                                                                            onChange={(e) => {this.handleLoadResponses(e, post, index, cmt, ind, true)}} />
                                                                        <div className="_rlcd d-flex jc-space-b">
                                                                            <div className="">
                                                                                <span 
                                                                                    
                                                                                    onMouseEnter={(e) => {this.handleShowLikesCommentDetails(e,'com',cmt.id,post.id,`nblikeDetail-${cmt.id}`,cmt.nblike)}}
                                                                                    // onMouseLeave={(e) => {this.handleHideLikesCommentDetails(e,'com',cmt.id ,post.id,`nblikeDetail-${cmt.id}`,cmt.nblike)}}
                                                                                    className={"_lan nblike " + (configs.getIndexWidthManyProperties(cmt.action, ['auteur', 'isLikeByUser'], [this.props.auth.user.username, true], 2) !== -1 ? 'active' : '')} 
                                                                                    id={"nblike-" + cmt.id}>
                                                                                        <div className="normalCom likeComDetail" id={"nblikeDetail-" + cmt.id} ref={"nblikeDetail-" + cmt.id}></div>
                                                                                       <span onClick={(e) => {this.handleLikeComment(e, cmt, post.groups.id, index, ind)}} >
                                                                                            <Like height="22px" color="#bbb" /> {cmt.nblike ? cmt.nblike : 0}
                                                                                       </span>
                                                                                </span>
                                                                                &nbsp;‧&nbsp;&nbsp;
                                                                                <span className="_lan comments"><Comments height="15px" color="#bbb" /> {cmt.nbreponse ? cmt.nbreponse : 0}</span>
                                                                                &nbsp;‧&nbsp;&nbsp;
                                                                                <a href className="_lan" onClick={()=>{cmt.showReplyForm = !cmt.showReplyForm; this.setState({})}}>Repondre</a>
                                                                                &nbsp;‧&nbsp;&nbsp;
                                                                                <abbr className="comment-date">{configs.timeSince(cmt.createdAt)}</abbr>
                                                                            </div>
                                                                            {cmt.nbreponse &&
                                                                                <div className="_rtgle">
                                                                                    <label htmlFor={"checkbox-sub-comment-" + cmt.id}>
                                                                                        <div className="hide-response">
                                                                                            <span className="label-md">Cacher les réponses</span>
                                                                                            <span className="label-xs">Réponses</span>
                                                                                            <span className="fa fa-chevron-up"></span>
                                                                                        </div>
                                                                                        <div className="display-response">
                                                                                            <span className="label-md">Afficher les réponses</span>
                                                                                            <span className="label-xs">Réponses</span>
                                                                                            <span className="fa fa-chevron-down"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div>
                                                                            {
                                                                                cmt.showReplyForm &&
                                                                                <CreateComment placeholder="Repondre..." postid={post.id} commentid={cmt.id} groupid={post.groups.id} onCreateResponse={this.onCreateResponse} isResponse={true} />
                                                                            }
                                                                        </div>
                                                                        <div className="response-cmt" id={"response-cmt-" + cmt.id}>
                                                                            {cmt.responses.length > 0 && 
                                                                                <div>
                                                                                    <ul>
                                                                                        {cmt.responses.map(function(respon, responInd){
                                                                                            return (
                                                                                                <li key={"resp" + responInd}>
                                                                                                    <figure className="_cthum">
                                                                                                        <div 
                                                                                                            className="img" 
                                                                                                            style={respon.users.images ? 
                                                                                                                    {backgroundImage : 'url(' + Global.API_URL + respon.users.images.image + ')'}
                                                                                                                    :
                                                                                                                    {}
                                                                                                                }>
                                                                                                            {!respon.users.images && 
                                                                                                                respon.users.profile.prenom.charAt(0) + respon.users.profile.nom.charAt(0)
                                                                                                            }
                                                                                                        </div>
                                                                                                    </figure>
                                                                                                    <div className="response-data">
                                                                                                    {(respon.users.id === this.props.auth.user.user_id) &&
                                                                                                        <div className="rm-list">
                                                                                                            <input type="checkbox" id={"response-id-" + respon.id} />
                                                                                                            <label className="toggle" htmlFor={"response-id-" + respon.id}>...</label>
                                                                                                            <div className="rm-list-item">
                                                                                                                <ul>
                                                                                                                    {/* <li>
                                                                                                                        <Link 
                                                                                                                            to="" 
                                                                                                                            onClick={(e) => {
                                                                                                                                e.preventDefault(); 
                                                                                                                                this.setState({
                                                                                                                                    responseToUpdate : {
                                                                                                                                        cmt : respon, 
                                                                                                                                        groupid : post.groups.id,
                                                                                                                                        postIndex : index,
                                                                                                                                        commentIndex : responInd
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            }} >
                                                                                                                            Modifier
                                                                                                                        </Link>
                                                                                                                    </li> */}

                                                                                                                    <li>
                                                                                                                        <Link 
                                                                                                                            to="" 
                                                                                                                            onClick={(e) => {
                                                                                                                                e.preventDefault(); 
                                                                                                                                this.setState({
                                                                                                                                    responseToDelete : {
                                                                                                                                        cmt : respon, 
                                                                                                                                        groupid : post.groups.id,
                                                                                                                                        postIndex : index,
                                                                                                                                        commentIndex : ind,
                                                                                                                                        responseIndex : responInd
                                                                                                                                    }
                                                                                                                                })
                                                                                                                            }} >
                                                                                                                            Supprimer
                                                                                                                        </Link>
                                                                                                                    </li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                        
                                                                                                        
                                                                                                        {respon.text &&
                                                                                                            <div className="reposnse">
                                                                                                                <div className="resp-username">
                                                                                                                    {respon.users.profile.prenom.toLowerCase() + " " + respon.users.profile.nom.toLowerCase()}
                                                                                                                </div>
                                                                                                                {/* {respon.text}   */}
                                                                                                                {
                                                                                                                    respon.text && respon.text.trim().split("\n").map(function(item, idx) {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                {item.includes('class="tagueName"') ?
                                                                                                                                    <>
                                                                                                                                        <span key={idx} dangerouslySetInnerHTML={checkIfHasLink(item)}>
                                                                                                                                        </span>
                                                                                                                                    </>
                                                                                                                                :
                                                                                                                                    <>
                                                                                                                                    <span key={idx}>
                                                                                                                                            <Linkify
                                                                                                                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                                                                                    <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                                                                                        {decoratedText}
                                                                                                                                                    </a>
                                                                                                                                                )}> 
                                                                                                                                                {item}
                                                                                                                                            </Linkify>
                                                                                                                                            <br/>
                                                                                                                                        </span>
                                                                                                                                    </>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </div>
                                                                                                        } 
                                                                                                        {respon.filename &&
                                                                                                            <div className="attachments">
                                                                                                                {
                                                                                                                    !respon.text &&
                                                                                                                    <div className="resp-username">
                                                                                                                        {respon.users.profile.prenom?.toLowerCase()} {respon.users.profile.nom.toLowerCase()}
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <div className="single-image">
                                                                                                                    <img src={Global.API_URL +"/" + respon.filename} alt="" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {respon.gif &&
                                                                                                            <div className="attachments">
                                                                                                                {
                                                                                                                    !respon.text &&
                                                                                                                    <div className="resp-username">
                                                                                                                        {respon.users.profile.prenom?.toLowerCase()} {respon.users.profile.nom.toLowerCase()}
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <div className="single-image">
                                                                                                                    <img src={respon.gif} alt="" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        
                                                                                                        <div className="_rlcd d-flex jc-space-b">
                                                                                                            <div className="">
                                                                                                                <span 
                                                                                                                    className={"_lan " + (configs.getIndexWidthManyProperties(respon.action, ['auteur', 'isLikeByUser'], [this.props.auth.user.username, true], 2) !== -1 ? 'active' : '')}
                                                                                                                    onMouseEnter={(e) => {this.handleShowLikesCommentDetails(e, 'rep' , respon.id , post.id,`nbRepOfComDetail-${respon.id}`,respon.nblike)}}
                                                                                                                    // onMouseLeave={(e) => {this.handleHideLikesCommentDetails(e, 'rep' , respon.id,post.id,`nbRepOfComDetail-${respon.id}`,respon.nblike)}}
                                                                                                                    id={"nblike-resp-" + respon.id}
                                                                                                                    onClick={(e) => {this.handleLikeResponse(e, respon, post.groups.id, index, ind, responInd)}} >
                                                                                                                        <span className="nblike">
                                                                                                                            <div id={"nbRepOfComDetail-" + respon.id} ref= {"nblikeDetail-" + respon.id}></div> 
                                                                                                                            <Like height="22px" color="#bbb" /> {respon.nblike ? respon.nblike : 0}
                                                                                                                        </span>
                                                                                                                    
                                                                                                                </span>
                                                                                                                &nbsp;‧&nbsp;&nbsp;
                                                                                                                <abbr className="comment-date">{configs.timeSince(respon.createdAt)}</abbr>
                                                                                                            </div>
                                                                                                            <div className="_rtgle">

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }.bind(this))}
                                                                                    </ul>
                                                                                    
                                                                                    {(cmt.nbreponse > cmt.responses.length) &&
                                                                                        <span className="loadMoreComments" onClick={(e) => {this.handleLoadResponses(e, post, index, cmt, ind)}}>+ Afficher plus de commentaires</span>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className="loader">
                                                                                <img src={configs.GetImage('spinner.svg')} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                
                                                            )
                                                        }.bind(this))}
                                                        
                                                    </ul>

                                                    {(post.nbrecommentaire > post.commentnormale.length) &&
                                                        <span className="loadMoreComments" onClick={(e) => {this.handleLoadComments(e, post, index)}}>+ Afficher plus de commentaires</span>
                                                    }
                                                </div>
                                            }
                                            <div className="loader">
                                                <img src={configs.GetImage('spinner.svg')} alt="" />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        
                        )
                    })}
                </div>
                <div ref={this.LoadMore} className="loadMore">
                    {this.state.allLoaded ?
                        <span className="no-more-data">
                            {
                                !this.props.match.params.postId &&
                                <span>il n'y a plus de publications</span>

                            }
                        </span>
                        :
                        <BeatLoader color={"#aaa"} loading={true} size="10"/>
                    }

                </div>

                {this.state.postToUpdate &&
                    <div className="_modal updatePost" id="UpdatePost">
                        <div className="_overlay" onClick={this.handleCloseUpdateModal}></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-body">
                                    <UpdatePost onUpdatePost={this.onUpdatePost} post={this.state.postToUpdate} handleCloseUpdateModal={this.handleCloseUpdateModal} />
                                </div>
                            </div>
                        </div>
                    </div> 
                }
                {this.state.CommentToUpdate &&
                    <div className="_modal updatePost" id="UpdatePost">
                        <div className="_overlay" onClick={this.handleCloseUpdateModal}></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-body">
                                    <UpdateComment onUpdateComment={this.onUpdateComment} comment={this.state.CommentToUpdate} handleCloseUpdateModal={this.handleCloseUpdateModal} />
                                </div>
                            </div>
                        </div>
                    </div> 
                }
                {this.state.postToDelete && 
                    <div className="_modal DeletePost confirmation" id="DeletePost">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer cette publication&nbsp;?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                    <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Supprimer
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.CommentToDelete && 
                    <div className="_modal DeletePost confirmation" id="DeletePost">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer ce commentaire&nbsp;?</h2>
                                </div>
                                <div className="_modal-body">
                                    <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                        <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                        <button onClick={this.confirmDeletionComment.bind(this)} ref={this.DeleteCommentBtn} className="button lg">
                                            <span className="fa fa-spin fa-spinner"></span> Supprimer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.responseToDelete && 
                    <div className="_modal DeletePost confirmation" id="DeletePost">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer cette réponse&nbsp;?</h2>
                                </div>
                                <div className="_modal-body">
                                    <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                        <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                        <button onClick={this.confirmDeletionReponse.bind(this)} ref={this.DeleteResponseBtn} className="button lg">
                                            <span className="fa fa-spin fa-spinner"></span> Supprimer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.postToShare && 
                    <div className="_modal SharePost" id="SharePost">
                        <div className="_overlay" onClick={(e) => {this.setState({postToShare : null})}}></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Partager</h2>
                                    {/* <h2>Cliquez sur la communauté où vous souhaitez partager cette publication</h2> */}
                                </div>
                                <div className="_modal-body">
                                    <div className={"communitiesList " + (this.state.userCommunities.length === 0 ? "loading" : "")}>
                                        <textarea className="textToShare" id={"textToShare"} placeholder="Tapez un texte..."></textarea>
                                        <i className="fa fa-spin fa-spinner"></i>
                                        <ul>
                                            {this.state.userCommunities.map(function(item, index){
                                                return (
                                                    <li key={"cmt-to-share-" + index} className="d-flex jc-space-b align-center">
                                                        <figure 
                                                            className="figure" 
                                                            style={item.logo ? 
                                                                    {backgroundImage : 'url(' + Global.API_URL  + item.logo + ')'}
                                                                    :
                                                                    {backgroundColor : item.color}
                                                                }>
                                                            {!item.logo && 
                                                                item.communityName.charAt(0) + item.communityName.charAt(1)
                                                            }
                                                        </figure>

                                                        <div className="d-flex jc-space-b align-center">
                                                            <Link to="" className="label">{item.communityName}</Link>

                                                            <Link   
                                                                to="" 
                                                                data-post={this.state.postToShare.id} 
                                                                data-community={item.id} 
                                                                onClick={this.handleShareToCommunity} 
                                                                className="button" >
                                                                    Partager
                                                            </Link>
                                                        </div>
                                                        
                                                    </li>
                                                )
                                            }, this)}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.showReactionPopin && this.state.reactionsType === 1 &&
                    <div className="_modal reactions">
                        <div className="_overlay" onClick={()=>this.setState({showReactionPopin: false, reactions: [] })} ></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Réactions</h2>
                                </div>
                                <div className="_modal-body">
                                    {
                                        this.state.reactions.length === 0 &&
                                        <center style={{display: 'block', width: '100%', padding: '20px'}}>
                                            <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                        </center>
                                    }
                                    {
                                        this.state.reactions.length > 0 &&
                                        <div> 
                                            <ul className="tabs">
                                                <li className={this.state.reactionActiveTab === 1 ? 'active' : ''}  onClick={()=>{this.setState({reactionActiveTab: 1})}}>
                                                    Tous ({this.state.reactions.length})
                                                </li>
                                                <li className={this.state.reactionActiveTab === 2 ? 'active' : ''} onClick={()=>{this.setState({reactionActiveTab: 2})}}>
                                                    <Like height="15px" color="#bbb" />&nbsp; J'aime
                                                    {
                                                        reactionsCounter.likeokey > 0 &&
                                                        <span> ({reactionsCounter.likeokey})</span>
                                                    } 
                                                </li>
                                                <li className={this.state.reactionActiveTab === 3 ? 'active' : ''} onClick={()=>{this.setState({reactionActiveTab: 3})}}>
                                                    <Applaud height="15px" color="#bbb" />&nbsp; Bravo
                                                    {
                                                        reactionsCounter.likemain > 0 &&
                                                        <span> ({reactionsCounter.likemain})</span>
                                                    } 
                                                </li>
                                                <li className={this.state.reactionActiveTab === 4 ? 'active' : ''} onClick={()=>{this.setState({reactionActiveTab: 4})}}>
                                                    <True height="15px" color="#bbb" />&nbsp; Ok
                                                    {
                                                        reactionsCounter.likecoche > 0 &&
                                                        <span> ({reactionsCounter.likecoche})</span>
                                                    } 
                                                </li>
                                            </ul>
                                            <ul className="users-list">
                                                {this.state.reactions.map(reaction=>{
                                                    if( 
                                                        this.state.reactionActiveTab === 1||
                                                        (this.state.reactionActiveTab === 2 && reaction.likeokey === 1) ||
                                                        (this.state.reactionActiveTab === 3 && reaction.likemain === 1) ||
                                                        (this.state.reactionActiveTab === 4 && reaction.likecoche === 1)
                                                    ){
                                                        return(
                                                            <li>
                                                                <a href>
                                                                    <figure className="" style={reaction.image ? {
                                                                        backgroundImage: 'url('+ Global.API_URL + reaction.image + ')'
                                                                    } : {}}>
                                                                    {reaction.image ? '': reaction.nom.charAt(0)}
                                                                    </figure>
                                                                    <div>
                                                                        <h3 className="user-name">{reaction.nom}</h3>
                                                                        <p>{reaction.fonction}</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        )
                                                    } else{
                                                        return ''
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    } 
                                </div>
                            </div>
                        </div>
                    </div> 
                }

                {
                    this.state.showReactionPopin && this.state.reactionsType === 2 &&
                    <div className="_modal reactions">
                        <div className="_overlay" onClick={()=>this.setState({showReactionPopin: false, reactions: [] })} ></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Partage ({this.state.reactions.length})</h2>
                                </div>
                                <div className="_modal-body"> 
                                    <div>
                                        {
                                            this.state.reactions.length === 0 &&
                                            <center style={{display: 'block', width: '100%', padding: '20px'}}>
                                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                            </center>
                                        }
                                        {
                                            this.state.reactions.length > 0 &&
                                            <ul className="users-list share">
                                                    {this.state.reactions.map(reaction=>{
                                                        return(
                                                            <li>
                                                                <a href>
                                                                    <figure className="" style={reaction.image ? {
                                                                        backgroundImage: 'url('+ Global.API_URL + reaction.image + ')'
                                                                    } : {}}>
                                                                    {reaction.image ? '': reaction.nom.charAt(0)}
                                                                    </figure>
                                                                    <div>
                                                                        <h3 className="user-name">{reaction.nom}</h3>
                                                                        <p>{reaction.fonction}</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        )
                                                    })}
                                            </ul>
                                        } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                }
                {
                    this.state.showReactionPopin && this.state.reactionsType === 3 &&
                    <div className="_modal reactions">
                        <div className="_overlay" onClick={()=>this.setState({showReactionPopin: false, reactions: [] })} ></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Réactions ({this.state.reactions.length})</h2>
                                </div>
                                <div className="_modal-body"> 
                                    <div>
                                        {
                                            this.state.reactions.length === 0 &&
                                            <center style={{display: 'block', width: '100%', padding: '20px'}}>
                                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                            </center>
                                        }
                                        {
                                            this.state.reactions.length > 0 &&
                                            <ul className="users-list share">
                                                    {this.state.reactions.map(reaction=>{
                                                        return(
                                                            <li>
                                                                <a href>
                                                                    <figure className="" style={reaction.image ? {
                                                                        backgroundImage: 'url('+ Global.API_URL + reaction.image + ')'
                                                                    } : {}}>
                                                                    {reaction.image ? '': reaction.nom.charAt(0)}
                                                                    </figure>
                                                                    <div>
                                                                        <h3 className="user-name">{reaction.nom}</h3>
                                                                        <p>{reaction.fonction}</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        )
                                                    })}
                                            </ul>
                                        } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                }
                
            </section>
        )
    }
}
 
Posts.propTypes = {
    auth: PropTypes.object.isRequired
};
  
const mapStateToProps = (state) => ({
    auth: state.auth
});
  
export default connect(mapStateToProps, {})(withRouter(Posts));
