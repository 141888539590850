import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Global from '../../inc/Global.js'
import debounce from "lodash.debounce";
import InviteMemberItem from './InviteMemberItem'
import configs from '../../inc/helpers'
import BeatLoader from "react-spinners/BeatLoader";
const simpleTextSearch = require('simple-text-search')

class InviteMembers extends Component {
    constructor(props){
        super(props)
        
        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }
        
        const params = this.props.location.state;

        this.state = {
            groupId : params.id,
            newCommunity : params.newCommunity || false,
            users : [],
            AllUsers : [],
            limit : 10,
            step : 10,
            loaded : false,
            allLoaded : false,
            InvitationRejetedId : null,
            belongTo : 1
        }

        this.LoadMore = React.createRef();
        this.DeleteBtn = React.createRef();
        this.searchB = React.createRef();
        
        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                // // console.log(this.state.limit);
                if(this.state.limit >= this.state.users.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }
    
    componentDidMount(){

        let data = {
            nbUsers : 0,
            startPoint : 0,
            idCommunity : this.state.groupId,
            belongto : this.state.belongTo
        }
        axios
        .post(Global.API_URL+"/api/gettusers", data) 
        .then((res) => {
            // // console.log(res.data)
            var users = [];
            
            for (let item of res.data) { 
                users.push({
                    ID : item.id,
                    Email : item.email,
                    Name : item.profile ? item.profile.nom : "",
                    LastName : item.profile ? item.profile.prenom : "",
                    Photo : item.images ? item.images.image : "",
                    Job : item.profile ? item.profile.fonction : "",
                    PhoneNum : item.profile ? item.profile.telephone : "",
                });

            };
            this.setState({
                users : users,
                AllUsers : users,
                loaded : true
            })
            
            if(this.state.limit >= users.length){
                this.setState({
                    allLoaded : true
                });
            }else{
                this.setState({
                    allLoaded : false
                });
            }
        })
        .catch((err) => {
            // // console.log(err.message);
        });
    }
  
    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);

        const users = this.state.AllUsers.filter(item =>{
            let get = simpleTextSearch([item.Name+" "+item.LastName, item.Job, item.Email])
            return get(event.target.value).length > 0
        });
        //await this.setState({ users });
        await this.setState({
            // limit : 10,
            users : users
        })

        if(this.state.limit >= this.state.users.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    
    handleRoleChange = (ev) => {

    }
    handleInvite = (ev) => {
        ev.preventDefault();
        ev.target.classList.add('on');
        
        var targetid = ev.target.getAttribute('data-id');
        var targetemail = ev.target.getAttribute('data-email');
        var role = document.querySelector('#_role'+targetid+ ' [name="role"]').value;
        
        
        const data = {
            email : targetemail,
            id : this.state.groupId,
            role : role
        };

        axios
        .post(Global.API_URL+"/api/invitation", data)
        .then((res) => {
           
            var users = [...this.state.users]; 
            var AllUsers = [...this.state.AllUsers];
            
            var indexFromUsersList = configs.getIndexWidthProperty(users, 'ID', parseInt(targetid));
            var indexFromAllUsersList = configs.getIndexWidthProperty(AllUsers, 'ID', parseInt(targetid));
            // // console.log(users,targetid, AllUsers)

            users.splice(indexFromUsersList, 1)
            AllUsers.splice(indexFromAllUsersList, 1)
            
            this.setState({
                users : users,
                AllUsers : AllUsers
            });
        })
        .catch((err) => {
            // // console.log('Error : '+err.message)
        });
    }
    render() {
        if(typeof this.props.location.state === "undefined" ){
            this.props.history.push(`/communaute/${this.state.groupid}/parametres`)
            return "";
        }else if(typeof this.props.location.state.id === "undefined"){
            this.props.history.push(`/communaute/${this.state.groupid}/parametres`)
            return "";
        }

        return (
            <section className="content">
                <div className="wrap">
                    { 
                        !this.state.newCommunity &&
                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3 className="h3"> 
                                    <center>
                                    <Link onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                            <span className="fa fa-long-arrow-left"></span>
                                        </Link> 
                                        Liste utilisateurs
                                    </center>
                                </h3>  
                            </div>
                        </div> 
                    }
                    
                    <div className="users-list"> 

                        <div className="_srs-users">
                            <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Rechercher un utilisateur"/>
                            <Link to="" onClick={(e) => {
                                    e.preventDefault();
                                    this.searchB.current.setAttribute('val', ""); 
                                    this.searchB.current.value = ""; 
                                    this.setState({users : this.state.AllUsers
                                })}} className="button">Annuler</Link>
                        </div>

                        {
                            !this.state.loaded &&
                            <center style={{padding: 0, fontSize: 20}}>
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            </center>
                        }
                        
                        {this.state.userToReject && 
                            <div className="_modal" id="CropImageModal">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Voulez-vous vraiment refuser cette invitation&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Supprimer
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="_ursl">
                            <ul>
                            {this.state.users.slice(0, this.state.limit).map((invitation, i) =>
                        
                                <InviteMemberItem 
                                    key={invitation.ID}
                                    ID={invitation.ID}
                                    Name={invitation.Name}
                                    LastName={invitation.LastName}
                                    Photo={invitation.Photo}
                                    Job={invitation.Job}
                                    PhoneNum={invitation.PhoneNum}
                                    Email={invitation.Email}
                                    RoleSelect={{
                                        items : [
                                            { value: 'ROLE_ADMIN', label: 'Administrateur' },
                                            { value: 'ROLE_MODERATEUR', label: 'Rédacteur'},
                                            { value: 'ROLE_LECTEUR', label: 'Lecteur' }
                                        ],
                                        selectedRole : { value: 'ROLE_MODERATEUR', label: 'Rédacteur' },
                                        selectedValue : { value: 'ROLE_MODERATEUR', label: 'Rédacteur' },
                                        handleSelectChange : this.handleRoleChange,
                                    }}
                                    handleInvite={this.handleInvite}
                                    />
                            )}
                            </ul>
                        </div>

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de données</span>
                                :
                                ""
                            }

                        </div>
                    </div>
                </div> 
            </section>
        )
    }
}

export default InviteMembers;