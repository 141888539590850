import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/white-logo.png'
import InputField from '../InputField.js'
import classnames from "classnames";
import axios from 'axios'
import Global from '../../inc/Global'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Validation from '../../inc/Validation'


class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            errors: {},
            HasNotLoggedIn: false,
            EmailSent : false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });

        if(!Validation.Email(e.target.value)){
            this.setState({
                errors : {
                    [e.target.name] : true
                }
            })
        }else{
            this.setState({
                errors : {
                    [e.target.name] : false
                }
            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        // console.log(e);
        if(Validation.Email(this.state.email)){
            this.setState({
                errors: {
                    email : false
                }
            });
        }else{
            this.setState({
                errors: {
                    email : true
                }
            });
            return;
        }
        const user = {
            email: this.state.email
        };

        axios
        .post(Global.API_URL+"/forget", user)
        .then((res) => {
            if(res.data.status === "okey"){
                this.setState({
                    HasNotLoggedIn: false,
                    EmailSent: true
                });
            }      
        })
        .catch((err) => {
            this.setState({
                HasNotLoggedIn: true,
                EmailSent: false
            });
        });
    }

    render() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/');
            return "";
        }

        const {errors, HasNotLoggedIn, EmailSent} = this.state; 

        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <form id="_login_Form" onSubmit={this.handleSubmit}>
                                                <center className="logo-with-hashtag">
                                                    <a href="javascript:void(0)">
                                                        <img src={Global.APP_LOGO} alt="" />
                                                    </a>
                                                </center>
                                                <div className="bg-white-transparent text-center"> 
                                                    <div className="entry-welcome">
                                                        {EmailSent ? 
                                                            <div className="p">
                                                                <p>Un e-mail a été envoyé avec un lien pour réinitialiser le mot de passe</p>
                                                                <br />
                                                            </div>
                                                            :
                                                            <div>
                                                                <div className="p">
                                                                    <p>Entrez l'adresse e-mail vérifiée de votre compte utilisateur et nous vous enverrons un lien de réinitialisation de mot de passe</p>
                                                                </div>
                                                                
                                                                <InputField 
                                                                    type="email" 
                                                                    id="_email" 
                                                                    name="email" 
                                                                    label="Votre email adresse" 
                                                                    fixedLabel="false" 
                                                                    classInput="" 
                                                                    classContainer={classnames({
                                                                        "hasError redFieldError": errors.email,
                                                                    })} 
                                                                    value={this.state.email} 
                                                                    oninput={this.handleInputChange} 
                                                                    errorMsg={classnames({
                                                                        "Email est Incorrect": errors.email,
                                                                    })} /> 
                                                            </div>
                                                        }   
                                                        <br />  
                                                    </div>
                                                    {
                                                        !EmailSent ?
                                                        <div className="d-flex jc-space-b footer-fixed icon">
                                                            <Link to="/login" className="button lg m-t-10 wt">
                                                                <i className="fa fa-chevron-left"></i>
                                                                ANNULER
                                                            </Link>
                                                            <button type="submit" className="button lg m-t-10 icon">
                                                                ENVOYER <i className="fa fa-chevron-right"></i>
                                                            </button>
                                                        </div>
                                                        :
                                                        <Link to="/login" className="button full lg m-t-10" >Se connecter</Link>
                                                    }
                                                </div> 
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ForgetPassword.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {  })(ForgetPassword);