import React, { Component } from 'react'
import Global from '../../../inc/Global';
import Lightbox from 'react-image-lightbox';


class Gallery extends Component {
    constructor(props){
        super(props)
        
        var images = this.props.images;

        if(!Array.isArray(images)){
            return;
        }

        const index = images.indexOf("NULL");
        
        if(index > -1){
            images.splice(index, 1);
        }

        this.state = {
            images : images,
            length : images.length,
            photoIndex : this.props.OpenedGalleryID.id || 0,
            currentPostId : this.props.OpenedGalleryID.postId || 0,
        }
        
        this.onLoadFirstImage = this.onLoadFirstImage.bind(this);
        this.handleShowGallery = this.handleShowGallery.bind(this);
        this.handleCloseGallery = this.handleCloseGallery.bind(this);
        this.onLoadImage = this.onLoadImage.bind(this);
    }

    onLoadImage(ev){
        // if(ev.target.naturalWidth >= ev.target.naturalHeight){
        //     ev.target.style.height = "100%";    
        //     ev.target.style.maxWidth = "none";    
        // }else{
        //     ev.target.style.width = "100%";
        // }
    }

    onLoadFirstImage(ev){
        if(ev.target.naturalWidth >= ev.target.naturalHeight){
            ev.target.style.height = "100%";
            ev.target.style.maxWidth = "none";
        }else{
            ev.target.style.width = "100%";
        }
    }
    handleShowGallery (ev){
        let ID = ev.target.getAttribute('data-target') || ev.target.parentNode.getAttribute('data-target');
        
        this.props.handleOpenGallery(ID, this.props.PostID)
    }
    handleCloseGallery (ev){
        this.props.handleCloseGallery()
    }
    componentWillReceiveProps(nextProps) {
        var images = nextProps.images;

        if(!Array.isArray(images)){
            return;
        }

        const index = images.indexOf("NULL");
        
        if(index > -1){
            images.splice(index, 1);
        }

        this.setState ({
            images : images,
            length : images.length
        });
        
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.photoIndex !== this.state.photoIndex) {
          this.setState({ photoIndex: nextProps.OpenedGalleryID.id });
        }
        if (nextProps.currentPostId !== this.state.currentPostId) {
          this.setState({ currentPostId: nextProps.OpenedGalleryID.postId });
        }
      }
    render() {
        if(!Array.isArray(this.props.images)){
            return "";
        }
        var imgs = this.props.images;
        
        const index = imgs.indexOf("NULL");

        if(index > -1){
            imgs.splice(index, 1);
        }
        if( imgs.length === 0 ){
            return "";
        }
        const {images, length, photoIndex, currentPostId} = this.state;
        
         
        
        const BaseURL = Global.API_URL ;
        return (
            <div className="multi-images">
                <div className={"items nb-"+(length <= 4 ? length : 4)} style={length === 1 ? {height: 'auto'} : {}} >
                    {/* <div className={length === 1 ? "_mphoto" : "_mphoto one"}>
                        <div className="_it" onClick={this.handleShowGallery} data-target={0}>
                            <img onLoad={this.onLoadFirstImage} src={BaseURL + images[0]} style={length === 1 ? {width: '100%',maxHeight: 'none'} : {}} alt="" />
                        </div>
                    </div> */}
                    
                    {length > 0 && 
                        <div className="_rphotos">
                            {images.map(function(img, index){
                                if( index >= 0 && index < 4){
                                    const imgSrc = BaseURL + img;
                                    //const imgDim = configs.getImageSize(imgSrc);
                                    //// console.log(imgDim);
                                    if(index === 3 && length > 4){
                                        return  <div key={"img-"+index} className="_it"  onClick={this.handleShowGallery} data-target={index} style={{backgroundImage: 'url(\"' + imgSrc + '\")'}}>
                                                    <img  onLoad={this.onLoadImage} src={imgSrc} alt="" /><span>{(length - 4)}+</span>
                                                </div>
                                    }else{
                                        return  <div key={"img-"+index} className={length ==1 ? '_it SinglePicClass' : '_it'} onClick={this.handleShowGallery} data-target={index} style={{backgroundImage: 'url(\"' + imgSrc + '\")'}}>
                                                    <img  onLoad={this.onLoadImage} src={imgSrc} alt="" />
                                                </div>
                                    }

                                }
                                return "";
                            }, this)}
                        </div>
                    }
                </div>
                {(photoIndex >= 0 && currentPostId === this.props.PostID ) && 
                    <div>
                        {length > 1 && 

                            <Lightbox
                                mainSrc={BaseURL + images[photoIndex]}
                                nextSrc={BaseURL + images[(photoIndex + 1) % images.length]}
                                prevSrc={BaseURL + images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={this.handleCloseGallery}
                                reactModalStyle={{zIndex:100000}}
                                //imageCaption={(<div><h1>Heelo from here </h1><p>Call me</p></div>)}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length,
                                    })
                                }
                                animationDisabled={true}
                            />
                        }
                        {length === 1 && 

                            <Lightbox
                                mainSrc={BaseURL + images[photoIndex]}
                                nextSrc={BaseURL + images[(photoIndex + 1) % images.length]}
                                prevSrc={BaseURL + images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={this.handleCloseGallery}
                                reactModalStyle={{
                                    content : {
                                      zIndex: 10000
                                    }
                                }}
                                animationDisabled={true}
                                //imageCaption={(<div><h1>Heelo from here </h1><p>Call me</p></div>)}
                            />
                        }
                    </div>
                }
            </div>
        )
    }
}
export default  Gallery;