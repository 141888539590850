import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import setAuthToken from "../inc/SetAuthToken";
import jwt_decode from "jwt-decode";
import Global from '../inc/Global'
import SHA1 from 'sha1'
// FireBase
import { getTokenDevice } from '../inc/FireBase';

export const registerUser = (user, history) => (dispatch) => {
  axios
    .post(Global.API_URL+"/api/register", user)
    .then((res) => history.push("/login"))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
}; 

export const loginUser =(user) => (dispatch) => { 
    axios
    .post(Global.API_URL+"/api/login_check", user)
    .then((res) => {
        
        if(res.status === 200){
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            axios
            .get(Global.API_URL + "/api/checkprofil/" + SHA1(new Date()))
            .then((response) => {
                // console.log(decoded);
                decoded['profil'] = response.data.image;
                decoded['name'] = response.data.prenom;
                decoded['lastname'] = response.data.nom;
                decoded['user_id'] = response.data.user_id;
                decoded['create_communities'] = response.data.casecoche == '1' ? true : false;
                localStorage.setItem("jwtUd", JSON.stringify(decoded) )
                dispatch(setCurrentUser(decoded));

            }).catch((err) => {
                // console.log(err.message);
            });
            
        }else{
            dispatch({
                type: GET_ERRORS,
                payload: 'Error',
            });
        }
    })
    .catch((err) => {
        dispatch({
            type: GET_ERRORS,
            payload: err.message,
        });
    });
};

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};
 
export const IsLoggedInForFirstTime = (email, _props, isFromLogin = false) => async (dispatch) => { 
    let data = new FormData()
    let getToken = await getTokenDevice()
    if( getToken.success ){ 
        data.append('token',getToken.token)
        data.append('device',2)
    }   
    axios
    .post(Global.API_URL+"/api/first", data)
    .then((res) => {
        
        if(res.data.message === 'premier log!'){
            // _props.history.push('/terminer-inscription/acceptez-les-conditions')
            _props.history.push('/terminer-inscription/profil')
        }else{ 
            if(isFromLogin){
                _props.history.push('/')
            }
        }
        
    })
    .catch((err) => {
        if(isFromLogin){
            _props.history.push('/bad')
        }
    });
    return false;
};

export const logoutUser = (history) => (dispatch) => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("jwtUd");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    history.push("/login");
};

export const logoutUserCach = (history) => (dispatch) => {
    console.log('aaa');
    localStorage.clear();
    localStorage.removeItem("jwtUd");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    history.push("/login");
};

export const LoginExpired = () => (dispatch) => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("jwtUd");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};
