import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import Select from 'react-select';
import Global from '../../inc/Global.js'
import InputField from '../InputField.js';
//import 'react-select/dist/react-select.css';

class InviteMemberItem extends Component {
    
    render() {
        const { 
            ID,
            Name,
            LastName,
            Photo,
            Job,
            PhoneNum,
            Email,
            RoleSelect,
            handleInvite
        } = this.props;
        
        const APIURL = Global.API_URL;
        
        const RandColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        return (
            <li className="_ursl-listitem">
                <div className="header-post">
                    <br />  
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Photo ?
                                {backgroundImage: 'url('+ APIURL + Photo + ')'}
                                : 
                                {}
                            } >
                            <Link className="a"   to="" onClick={(e) => e.preventDefault()}>{!Photo && Name.charAt(0)+LastName.charAt(0) }</Link>
                        </figure>
                        <div className="user-infos">
                            <div className="username">{LastName.toLowerCase()} {Name.toLowerCase()}</div>
                            <div className="d-flex">
                                <ul>
                                    {
                                        Job &&
                                        <li><i className="fa fa-briefcase"></i> {Job}</li>
                                    }
                                    {
                                        Email &&
                                        <li><i className="fa fa-envelope"></i> <a target="_blank" href={`mailto:${Email}`}>{Email}</a></li>
                                    } 
                                    {
                                        PhoneNum &&
                                        <li><i className="fa fa-phone"></i> <a target="_blank" href={`tel:${PhoneNum}`}>{PhoneNum}</a></li>
                                    } 
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="d-flex jc-space-b align-center">
                    <div className="circle-list"> 
                    </div>

                    <div className="footer-invitation d-flex align-center">
                        <InputField 
                            type="select" 
                            id={"_role"+ID} 
                            name="role" 
                            label="" 
                            fixedLabel="true" 
                            placeholder=""
                            items={RoleSelect.items}
                            classInput="" 
                            classContainer="" 
                            value={RoleSelect.selectedRole} 
                            selectedValue={RoleSelect.selectedValue} 
                            default={RoleSelect.selectedRole}
                            oninput={RoleSelect.handleSelectChange} 
                            errorMsg="" />
                        <Link to="" className="button" id={"_inviter_"+ID} data-id={ID} data-email={Email} onClick={handleInvite}>
                            <span className="fa fa-spin fa-spinner"></span> Inviter
                        </Link>
                    </div>
                </div>
            </li>
        )
    }
}
export default InviteMemberItem;