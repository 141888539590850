import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Item from './MyCommunities/Item'
import axios from 'axios'
import Global from '../../inc/Global.js'
import debounce from "lodash.debounce";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BeatLoader from "react-spinners/BeatLoader";



class MyCommunities extends Component {
    constructor(props){
        super(props)

        this.state = {
            communities : [],
            AllCommunities : [],
            limit : 10 ,
            step : 10,
            loaded : false,
            allLoaded : false,
            itemToDelete : null,
            search : ""
        }

        this.LoadMore = React.createRef();
        this.DeleteBtn = React.createRef();
        this.SearchB = React.createRef();
        
        window.onscroll = debounce(() => {
            
            if (
              window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight
            ) {
                this.setState({
                    limit : this.state.limit + this.state.step
                })
                if(this.state.limit >= this.state.communities.length){
                    this.setState({
                        allLoaded : true
                    })
                }else{
                    this.setState({
                        allLoaded : false
                    })
                }
            }
        }, 100);
    }
    
    componentDidMount(){
        const data = {
            nbCommunity : 10000000000,
            startPoint : 0,
            idUser : this.props.auth.user.user_id,
            type : "",
            hasNotif : false
        };
        const _self = this;
        axios
        .post(Global.API_URL+"/api/getcommunities", data)
        .then((res) => {
            
            
            var communities = [];
            if(Array.isArray(res.data.private)){
                res.data.private.forEach(function(item, key) {
                    communities.push({
                        ID : item.id,
                        Color : item.couleur,
                        //Invitation : item.invitation,
                        Logo : item.logo,
                        //NBAdmin : item.nb_admin,
                        NBUser : item.users.length,
                        Cover : item.photocouverture,
                        Subject : item.sujet,
                        Title : item.titre,
                        Type : item.typegroupe,
                        UserEmail : _self.props.auth.user.username,
                        UserID : _self.props.auth.user.user_id,
                        Role : _self.props.auth.user.roles[0],
                    });
                });
            }
            if(Array.isArray(res.data.public)){
                res.data.public.forEach(function(item, key) {
                    communities.push({
                        ID : item.id,
                        Color : item.couleur,
                        //Invitation : item.invitation,
                        Logo : item.logo,
                        //NBAdmin : item.nb_admin,
                        NBUser : item.users.length,
                        Cover : item.photocouverture,
                        Subject : item.sujet,
                        Title : item.titre,
                        Type : item.typegroupe,
                        UserEmail : _self.props.auth.user.username,
                        UserID : _self.props.auth.user.user_id,
                        Role : _self.props.auth.user.roles[0],
                    });
                });
            }

            communities.sort(function(a, b){
                return parseInt(b.ID) <= parseInt(a.ID) ? 1 : -1;
            })

            this.setState({
                communities : communities,
                AllCommunities : communities,
                loaded : true
            })
           
            if(this.state.limit >= communities.length){
                this.setState({
                    allLoaded : true
                })
            }else{
                this.setState({
                    allLoaded : false
                })
            }
            
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }

    handleSearchInput= async (event) => {   
        console.log("event", event)
        const communities = this.state.AllCommunities.filter(item =>
            item.Title.toLowerCase().includes(event.target.value.toLowerCase()) ||
            item.Subject.toLowerCase().includes(event.target.value.toLowerCase()) ||
            item.UserEmail.toLowerCase().includes(event.target.value.toLowerCase())
        );
        
        await this.setState({
            communities : communities,
            limit : 10,
            search : event.target.value
        })

        if(this.state.limit >= this.state.communities.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    handleDelete (e) {
        e.preventDefault();
        this.setState({
            itemToDelete : e.target.getAttribute('id')
        });
    }
    cancel (e){
        e.preventDefault();
        this.setState({
            itemToDelete : null
        });
    }
    confirmDeletion (e){
        e.preventDefault();
        this.DeleteBtn.current.classList.add('on');
        
        
        axios
        .delete(Global.API_URL+"/deletegroup/"+this.state.itemToDelete)
        .then((res) => {
            this.DeleteBtn.current.classList.remove('on');

            var communities = [...this.state.communities]; 
            var allCommunities = [...this.state.AllCommunities];
    
            var currentComm = null;
            var currentAllComm = null;
            
            communities.forEach(function(el, ind){
                
                if(el.ID === parseInt(this.state.itemToDelete)){
                    currentComm = ind;
                }
            }.bind(this));
    
            allCommunities.forEach(function(el, ind){
    
                if(el.ID === parseInt(this.state.itemToDelete)){
                    currentAllComm = ind; 
                }
            }.bind(this));
            communities.splice(currentComm, 1)
            allCommunities.splice(currentAllComm, 1)
            
            this.setState({
                itemToDelete : null,
                communities : communities,
                AllCommunities : allCommunities
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.DeleteBtn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
        
        
    }
    render() {
        
        return (
            <section className="content">
                <div className="wrap">
                    <div className="communaute-list">
                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3>Liste communautés</h3>
                                <div className="">
                                    <ul>
                                        <li>
                                            <Link to="/communautes/ajouter"><span className="fa fa-users"><span className="fa fa-plus"></span></span></Link>
                                        </li>
                                        <li>
                                            <Link to="#"><label htmlFor="searchB" className="fa fa-search"></label></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="_srs-users">
                            <input ref={this.SearchB} id="searchB" type="search" value={this.state.search} name="s"  onChange={this.handleSearchInput} placeholder="Rechercher un communauté" />
                            <span to={false} className="button" onClick={(e) => {this.setState({search : ""}); }} >Annuler</span>
                        </div>

                        {this.state.itemToDelete && 
                            <div className="_modal confirmation" id="CropImageModal">
                                <div className="_overlay"></div>
                                <div className="_modal-wrap">
                                    <div className="_modal-content">
                                        <div className="_modal-header">
                                            <h2>Êtes-vous sûr de vouloir supprimer cette communauté&nbsp;?</h2>
                                        </div>
                                        <div className="_modal-body">
                                        <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                            <button onClick={this.cancel.bind(this)}  className="button wt lg">Annuler</button>
                                            <button onClick={this.confirmDeletion.bind(this)} ref={this.DeleteBtn} className="button lg">
                                                <span className="fa fa-spin fa-spinner"></span> Supprimer
                                            </button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                        <div className="_ursl _cmlt">
                            <ul>
                                {this.state.communities.slice(0, this.state.limit).map((community, i) =>
                                    <Item 
                                        key         ={community.ID}
                                        ID          ={community.ID}
                                        Color       ={community.Color}
                                        Invitation  ={community.Invitation}
                                        Logo        ={community.Logo}
                                        NBAdmin     ={community.NBAdmin}
                                        NBUser      ={community.NBUser}
                                        Cover       ={community.Cover}
                                        Subject     ={community.Subject} 
                                        Title       ={community.Title} 
                                        Type        ={community.Type} 
                                        UserEmail   ={community.UserEmail} 
                                        UserID      ={community.UserID} 
                                        Role        ={community.Role} 
                                        handleDelete={this.handleDelete.bind(this)} />
                                )}
                            </ul>
                        </div>
                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de communautés</span>
                                :
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            }

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
 
MyCommunities.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {} )(MyCommunities);