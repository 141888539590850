import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import UploadPhoto from '../UploadPhoto'
import ResetPassword from './ResetPassword'
import Congratulation from './Congratulation'
import AcceptTheConditions from './AcceptTheConditions'
import Annuler from './Annuler'
import FinishProfil from './FinishProfil'

class FirstLogin extends Component {
    componentDidMount(){
        if ( !this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
    }
    render() {
        if ( !this.props.auth.isAuthenticated) {
            return "";
        };
        
        return (
            <div>
                <Switch>
                    <Route exact path="/terminer-inscription/acceptez-les-conditions" component={AcceptTheConditions} />
                    <Route exact path="/terminer-inscription/annuler" component={Annuler} />
                    <Route exact path="/terminer-inscription/profil" component={FinishProfil} />
                    <Route exact path="/terminer-inscription/photo" component={UploadPhoto} />
                    <Route exact path="/terminer-inscription/reinitialiser-motdepasse" component={ResetPassword} />
                    <Route exact path="/terminer-inscription/felicitations" component={Congratulation} />
                    <Route path="*" component={AcceptTheConditions} />
                </Switch>
            </div>
        )
    }
}

FirstLogin.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(FirstLogin);