import Axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Global from '../../inc/Global.js'
import configs from '../../inc/helpers.js';
import InputField from '../InputField.js';

class UserItem extends Component { 
    constructor(props){
        super(props)
        this.state = {
            props: props,
            changed: false
        }
    }
    render() { 
        let { 
            ID,
            Name,
            LastName,
            Photo,
            Job,
            PhoneNum,
            Email,
            handleDelete,
            RoleSelect
        } = this.props;

        const APIURL = Global.API_URL;

        const handleChangeRole = ()=>{
            this.setState({
                changed: false
            }, ()=>{
                let data = new FormData();
                data.append('email',this.state.props.Email);
                data.append('groupid',this.state.props.GroupId);
                data.append('role',this.state.props.RoleSelect.selectedRole.value);

                Axios
                .post(Global.API_URL+"/api/updateroleuser", data)
                .then((res) => {
                    
                })
                .catch((err) => {
                    this.setState({
                        changed: true
                    })
                }); 
            }) 
        }
    
        const handleSelectChange = (role)=>{
            this.state.props.RoleSelect.selectedRole = role;
            this.state.props.RoleSelect.selectedValue = role; 
            this.setState({
                changed: true
            })
        }

        return (
            <li className="_ursl-listitem">
                <div className="header-post">
                    <div className="rm-list">
                        <input type="checkbox" id={"user-"+ID} />
                        <label className="toggle" htmlFor={"user-"+ID}>...</label>
                        <div className="rm-list-item">
                            <ul>
                                <li><a href onClick={handleDelete} id={ID} >Désabonner</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Photo ?
                                {backgroundImage: 'url('+ APIURL + Photo + ')'}
                                : 
                                {}
                            } >
                            <Link  to="" onClick={(e) => e.preventDefault()} className="a">{!Photo && Name.charAt(0)+LastName.charAt(0) }</Link>
                        </figure>
                        <div className="user-infos">
                            <div className="username">
                                {LastName.toLowerCase()} {Name.toLowerCase()}
                            </div>
                            <div className="d-flex">
                                <ul>
                                    {
                                        Job &&
                                        <li><i className="fa fa-briefcase"></i> {Job}</li>
                                    }
                                    {
                                        Email &&
                                        <li><i className="fa fa-envelope"></i> <a target="_blank" href={`mailto:${Email}`}>{Email}</a></li>
                                    } 
                                    {
                                        PhoneNum &&
                                        <li><i className="fa fa-phone"></i> <a target="_blank" href={`tel:${PhoneNum}`}>{PhoneNum}</a></li>
                                    } 
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="d-flex jc-space-b align-center">
                    <div className="circle-list"> 
                    </div>

                    <div className="footer-invitation d-flex align-center">
                        <InputField 
                            type="select" 
                            id={"_role"+ID} 
                            name="role" 
                            label="" 
                            fixedLabel="true" 
                            placeholder=""
                            items={RoleSelect.items}
                            classInput="" 
                            classContainer="" 
                            value={RoleSelect.selectedRole} 
                            selectedValue={RoleSelect.selectedValue} 
                            default={RoleSelect.selectedRole}
                            oninput={handleSelectChange} 
                            errorMsg="" />
                        {
                            this.state.changed &&
                            <button className="button" onClick={handleChangeRole}>
                                <span className="fa fa-spin fa-spinner"></span> Enregistrer
                            </button>
                        } 
                    </div>
                </div>
            </li>
        )
    }
}
export default UserItem;