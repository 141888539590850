import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import Posts from '../publications/Posts';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class Polls extends Component {
    
    constructor(props){ 
        super(props)
    } 
    render() {
        
        return (
            <>
                <div className="header-list">
                    <div className="d-flex jc-space-b align-center">
                        <h3 className="h3"> 
                            <center>
                                <Link onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                        <span className="fa fa-long-arrow-left"></span>
                                </Link> 
                                Sondages
                            </center>
                        </h3>  
                    </div>
                </div> 
                <div className="saved_posts"> 
                    <Posts role={this.props.role} parent="page_sondage" api_url="/api/pollingroup" community={this.props.communityid}  formAddPost={false} />
                </div>
            </>
        )
    }
}
 
Posts.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(Polls));
