import React, { Component } from 'react'
//import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../../inc/ImageLib';
//import InputField from '../InputField';
import {Link} from 'react-router-dom'
import configs from '../../inc/helpers'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import axios from 'axios'
import Global from '../../inc/Global';
import Picker from 'react-giphy-component'
import PickerEmoji from 'emoji-picker-react';
import { ReactTinyLink } from 'react-tiny-link'
import { toast, ToastContainer } from 'react-toastify'
// import { ToastContainer, toast } from 'react-toastify';

//import { post } from 'superagent';


//const imageMaxSize = 5242880 // bytes
//const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
//const acceptedFileTypesVideo = 'video/mp4, video/3gp, video/ogg, video/flv, video/avi, video/wmv, video/mov'
//const acceptedFileTypesVideoArray = acceptedFileTypesVideo.split(",").map((item) => {return item.trim()})
//const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class CreatePost extends Component {

    constructor (props){
        super(props);
        if(!this.props.post){
            return;
        }
        const { post } = this.props;

        // console.log(post);

        // Get Images
        let images = post.imag;
        
        if(!Array.isArray(images)){
            images = [];
        }
        
        const imgIndex = images.indexOf("NULL");
        
        if(imgIndex > -1){
            images.splice(imgIndex, 1);
        }
        // End Get Images
        
        // Get Docx
        let docx = post.doc;
        
        if(!Array.isArray(docx)){
            docx = [];
        }
        
        const docIndex = docx.indexOf("NULL");
        
        if(docIndex > -1){
            docx.splice(docIndex, 1);
        }
        // End Get Docx

        let type = "onlyText";
        let fileSelected = false;
        
        if(images.length !== 0){
            type = "images";
            fileSelected = true;    
        }else if(docx.length !==0){
            type = "document";
            fileSelected = true;  
        }else if(post.upload !== "" && post.upload !== null){
            type = "video";
            fileSelected = true;  
        }else if(post.gif !== "" && post.gif !== null){
            type = "gif";
            fileSelected = true;  
        }else if(post.link !== "" && post.link !== null){
            type = "link";
            fileSelected = true;  
        }

        this.state = {
            text : post.text,
            postID : post.id,
            postType : post.type,
            communityID : Number(post.groups.id),
            dragIsActive : false,
            oldimages : images,
            images : [],
            filesImage : [],
            filesToPush : [],
            filesVideo : [],
            files : [],
            filesTodelete : [],
            imageTodelete : [],
            videoTodelete : [],
            oldvideo : post.upload ? [post.upload] : [],
            video : [],
            olddocx : docx,
            docx : [],
            type : type,
            PrevType : type,
            gifOld : (post.gif && post.gif !== "null" && post.gif !== "video") ? post.gif : null,
            gif : (post.gif && post.gif !== "null" && post.gif !== "video") ? post.gif : null,
            gifSmall : post.gif ? post.gif : null,
            link : post.link ? post.link : null,
            uploadPercentage: 0,
            communityHasSelected : true,
            fileSelected : fileSelected,
            error : false,
            disablePost : false,
            isSharingGif : false,
            isSharingEmoji : false,
        }
         
        this.InputImage = React.createRef();
        this.InputVideo = React.createRef();
        this.InputFiles = React.createRef();
        this.shareIconBtn = React.createRef();
        this.shareEmojiBtn = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    handleChange(ev){
        this.setState({
            text : ev.target.value
        })
        
        this.setState({
            error : false
        })
        var testUrl = ev.target.value.match(/(https?:\/\/[^\s]+\.[^\s]+)/),
        onlyUrl = testUrl && testUrl[1];


        if(onlyUrl){
            this.setState({
                link : onlyUrl,
                type: 'link'
            })
        }
 
        
    }
    SubmitUpdatePost(ev){
        ev.preventDefault();
        var formData = new FormData();
        console.log('new');
        
        if(this.state.communityID === 0){
            this.setState({
                communityHasSelected : false
            })
            return;
        }
        if(!this.state.fileSelected && this.state.text === ""){
            this.setState({
                error : true
            })
            return;
        }
        this.setState({
            communityHasSelected : true,
            error : false
        })

        this.setState({
            disablePost : false,
        })

        let data = {
            iduser: this.props.auth.user.user_id,
            idpost: this.state.postID,
            text: this.state.text,
        }
        // formData.append('iduser', this.props.auth.user.user_id);
        // formData.append('idgroup', this.state.communityID);
        // formData.append('idpost', this.state.postID);
        formData.append('text', this.state.text);
        // formData.append('type', this.state.type);
        // formData.append('oldtype', this.state.PrevType);

        console.log(this.state.type);
        
        // if(this.state.type === "images" ){
            if(this.state.filesImage.length != 0 ){    
                for(var $j = 0;$j < this.state.filesImage.length; $j++){
                    formData.append('images[]', this.state.filesImage[$j]);
                }
            }
            if(this.state.imageTodelete.length === 0){
                formData.append('imagesToDelete[]', null);
            }else{
                for(var $i = 0;$i < this.state.imageTodelete.length; $i++){
                    formData.append('imagesToDelete[]', this.state.imageTodelete[$i]);
                }
            }

        // }else if( this.state.type === "document" ){

            if(this.state.filesToPush.length != 0){
                for(var $j = 0;$j < this.state.filesToPush.length; $j++){
                    formData.append('files[]', this.state.files[$j]);
                }
            }

            for(var $i = 0;$i < this.state.filesTodelete.length; $i++){
                formData.append('filesToDelete[]', this.state.filesTodelete[$i]);
            }
            if(this.state.filesTodelete.length === 0){
                formData.append('filesToDelete[]', null);
            }
        // }else if( this.state.type === "video" ){

            if(this.state.filesVideo.length != 0){
                for(var $z = 0;$z < this.state.filesVideo.length; $z++){
                    formData.append('videos', this.state.filesVideo[$z]);
                }
            }
            
            // for(var $y = 0;$y < this.state.filesTodelete.length; $y++){
            // }

            if(this.state.videoTodelete.length === 0){
                formData.append('isVideoDeleted', false);
            }else{
                formData.append('isVideoDeleted', true);
            }
        // }else if(this.state.type === "gif" ){
            if(this.state.gif){
                formData.append('gif', this.state.gif);
            }
            
            if((this.state.filesVideo.length != 0 || this.state.filesImage.length != 0 || this.state.filesToPush.length != 0 )){
                formData.append('isGifDeleted', true);
            }
        // }else if(this.state.type === "link" ){
            if(this.state.link){
                formData.append('link', this.state.link);
            }
            
            if(this.state.filesTodelete.length === 0){
                formData.append('fichierssupprime[]', null);
            }
        // }else if(this.state.type === "onlyText"){
            // formData.append('fichierssupprime[]', null);
        // }
        

        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded * 100) / total )
        
                if( percent < 100 ){
                    this.setState({ uploadPercentage: percent })
                }
            },
            headers: {
                'content-type': 'application/json'
            }
        }

        // .put(Global.API_URL+"/updatepost", data, options)
        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        axios
        .post(`${Global.API_URL}/api/posts/${this.state.postID}/edit`, formData, options)
        .then((res) => {
            if(res.data.status){
                this.getPostDetails(this.state.postID)
            }else{
                this.ToastMsg(res.data.message);
            }
        })
        .catch((err) => {
            this.ToastMsg('erreur de connexion');
        });
       
    } 
    getPostDetails(id){
        axios
        .get(`${Global.API_URL}/api/postsingle/${id}`)
        .then((res) => {
            if(res.data.status){
                let data = res.data.post
                data.iduser= this.props.auth.user.user_id;
                data.idpost= this.state.postID;
                data.text= this.state.text;
                this.props.onUpdatePost(res.data.post);
                this.ToastMsg('Publication modifié avec succès');
            }else{
                this.ToastMsg(res.data.message);
            }
        })
        .catch((err) => {
            this.ToastMsg('erreur de connexion');
        });
    }
    ToastMsg(msg){
        const  Msg = ({ closeToast }) => (
            <div>
                <strong>{msg}</strong>
            </div>
        )

        toast(<Msg/>, {
            position: "bottom-left",
            autoClose: 20000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        }); 
    }
    confirmReplaceVideoByPhotosMessage(){ 
        if( this.state.video.length > 0 || this.state.oldvideo.length > 0){
            if(window.confirm(`Vous ne pouvez pas publier des photos et des vidéos sur un même post. Si vous vous cliquez sur confirmer les videos seront supprimées`)){
                let x = this.state.filesVideo;
                if (this.state.oldimages.length>0) {
                    x = [...x,...this.state.oldvideo]
                }
                this.setState({
                    videoTodelete:x,
                    video: [],
                    filesVideo : [],
                    oldvideo : [],
                });
                return true
            }else{ 
                return false
            }
        }
        return true
    }
    handleImageChange (e){

        if(!this.confirmReplaceVideoByPhotosMessage()) return false;

        var images;
        var files;

        if(this.state.type === "images"){
            images = this.state.images;
            files = this.state.files;
        }else{
            images = [];
            files = [];
        }

        for(var i = 0; i < e.target.files.length; i++){
            images.push(URL.createObjectURL(e.target.files[i]));
            files.push(e.target.files[i]);
        }

        this.setState({
            images: images,
            filesImage : files,
            // files : files,
            video : [],
            // docx : [],
            gif : null,
            gifSmall : null,
            type : 'images',
            fileSelected : true
        })
        console.log(this.state);

        this.InputImage.current.value = null;
    }
    confirmReplacePhotosByVideoMessage(){  
        if( this.state.images.length > 0 || this.state.oldimages.length > 0){
            if(window.confirm(`Vous ne pouvez pas publier des photos et des vidéos sur un même post. Si vous vous cliquez sur confirmer les photos seront supprimées`)){
                let y = this.state.filesImage;
                if (this.state.oldimages.length>0) {
                    y = [...y,...this.state.oldimages]
                }
                this.setState({
                    imageTodelete:y,
                    images: [],
                    filesImage : [],
                    oldimages : [],
                })
                return true
            }else{ 
                return false
            }
        }
        return true
    }
    handleVideoChange(e){
        if(!this.confirmReplacePhotosByVideoMessage()) return false; 
        
        var video = [];
        var files = [];


        for(var i = 0; i < e.target.files.length; i++){
            video.push(e.target.files[i].name);
            files.push(e.target.files[i]);
        }

        this.setState({
            video: video,
            filesVideo : files,
            files : files,
            images : [],
            // docx : [],
            gif : null,
            gifSmall : null,
            type : 'video',
            fileSelected : true,
            filesTodelete : this.state.oldvideo,
            oldvideo : []
        })

        this.InputVideo.current.value = null;
    }
    handleDocxChange(e){
        var docx;
        var files;

        if(this.state.type === "document"){
            docx = this.state.docx;
            files = this.state.files;
        }else{
            docx = [];
            files = [];
        }

        for(var i = 0; i < e.target.files.length; i++){
            docx.push(e.target.files[i].name);
            files.push(e.target.files[i]);
        }

        this.setState({
            docx: docx,
            files : files,
            filesToPush : files,
            // images : [],
            // video : [],
            gif : null,
            gifSmall : null,
            type : 'document',
            fileSelected : true
        })
        
        this.InputImage.current.value = null;
    }
    confirmReplaceVideoAndPhotosByGifMessage(){ 
        if( this.state.video.length > 0 || this.state.oldvideo.length > 0){
            if(window.confirm(`Vous ne pouvez pas publier des photos et des GIF sur un même post. Si vous vous cliquez sur confirmer les videos seront supprimées`)){
                let x = this.state.filesVideo;
                if (this.state.oldimages.length>0) {
                    x = [...x,...this.state.oldvideo]
                }
                this.setState({
                    videoTodelete:x,
                    video: [],
                    filesVideo : [],
                    oldvideo : [],
                });
                return true
            }else{ 
                return false
            }
        }
        if( this.state.images.length > 0 || this.state.oldimages.length > 0){
            if(window.confirm(`Vous ne pouvez pas publier des GIF et des vidéos sur un même post. Si vous vous cliquez sur confirmer les photos seront supprimées`)){
                let y = this.state.filesImage;
                if (this.state.oldimages.length>0) {
                    y = [...y,...this.state.oldimages]
                }
                this.setState({
                    imageTodelete:y,
                    images: [],
                    filesImage : [],
                    oldimages : [],
                })
                return true
            }else{ 
                return false
            }
        }
        return true
    }
    handleGifChange (gif){
        
        if(!this.confirmReplaceVideoAndPhotosByGifMessage()) return false; 

        this.setState({
            gif : gif.original.url,
            gifOld : gif.original.url,
            gifSmall : gif.preview_gif.url,
            docx: [],
            files : [],
            images : [],
            video : [],
            type : 'gif',
            fileSelected : true,
            isSharingGif : false
        })
         
        this.shareIconBtn.current.classList.remove('on')
    }
    handleShareIcon(ev){
        ev.preventDefault();
        this.setState({
            isSharingGif : true
        });
        this.shareIconBtn.current.classList.toggle('on');
        // console.log(ev.target)
    }
    removeImage(ev){
        var target = ev.target.getAttribute('data-target');
        var isold = ev.target.hasAttribute('isold'); 
        var images
        if(isold){
            // return;
            let deletedImgs = this.state.imageTodelete;
            images = this.state.oldimages;
            deletedImgs.push(images[target])
            images.splice(target, 1);

            this.setState({
                imageTodelete : deletedImgs,
                oldimages : images
            });
            
            
            if(images.length === 0){
                this.setState({
                    fileSelected : false,
                    type : "onlyText"
                });
            }
            console.log(this.state.imageTodelete);

        }else{
            target = target - this.state.oldimages.length;

            images = this.state.images;
            var files = this.state.files;
            images.splice(target, 1);
            files.splice(target, 1);
            
            this.setState({
                images : images,
                files : files
            })
            if(images.length === 0){
                this.setState({
                    fileSelected : false,
                    type : "onlyText"
                });
            }
        }
        console.log(this.state);
    }
    removeVideo(ev){
        var target = ev.target.getAttribute('data-target');
        var isold = ev.target.hasAttribute('isold'); 
        var video
        if(isold){
            let deletedVideo = this.state.videoTodelete;
            video = this.state.oldvideo;
            deletedVideo.push(video[target])
            video.splice(target, 1);

            this.setState({
                videoTodelete : deletedVideo,
                oldvideo : video
            });
            
            if(video.length === 0){
                this.setState({
                    fileSelected : false,
                    type : "onlyText"
                });
            }
        }else{
            target = target - this.state.oldvideo.length;

            video = this.state.video;
            var files = this.state.files;
            video.splice(target, 1);
            files.splice(target, 1);
            
            this.setState({
                video : video,
                files : files
            })
            if(video.length === 0){
                this.setState({
                    fileSelected : false,
                    type : "onlyText"
                });
            }
        }
        console.log(this.state);

    }
    removeDoc(ev){
        var target = ev.target.getAttribute('data-target');
        var isold = ev.target.hasAttribute('isold'); 
        
        var docx
        if(isold){
            let deletedDocx = this.state.filesTodelete;
            docx = this.state.olddocx;
            deletedDocx.push(docx[target])
            docx.splice(target, 1);

            this.setState({
                filesTodelete : deletedDocx,
                olddocx : docx
            });
            
            if(docx.length === 0){
                this.setState({
                    fileSelected : false,
                    type : "onlyText"
                });
            }
        }else{
            target = target - this.state.olddocx.length;

            docx = this.state.docx;
            var files = this.state.files;
            docx.splice(target, 1);
            files.splice(target, 1);
            
            this.setState({
                docx : docx,
                files : files
            })
            if(docx.length === 0){
                this.setState({
                    fileSelected : false,
                    type : "onlyText"
                });
            }
        }
        console.log(this.state);

    }
    removeGif (ev){
        ev.preventDefault();
        this.setState({
            gif : null,
            fileSelected : false,
            type : "onlyText"
        })
    }
    handleClickOutside(event) {
        
        // if (this.shareIconBtn && !this.shareIconBtn.current.contains(event.target) && document.querySelector('.share-icon .icons-list .sc-bdVaJa') && !document.querySelector('.share-icon .icons-list .sc-bdVaJa').contains(event.target)) {
        //     this.setState({
        //         isSharingGif : false
        //     });
        //     this.shareIconBtn.current.classList.remove('on');
        // }
        // if (this.shareEmojiBtn && !this.shareEmojiBtn.current.contains(event.target) && document.querySelector('.share-icon .emoji-list .emoji-picker-react') && !document.querySelector('.share-icon .emoji-list .emoji-picker-react').contains(event.target)) {
        //     this.setState({
        //         isSharingEmoji : false
        //     });
        //     this.shareEmojiBtn.current.classList.remove('on');
        // }
        // this.shareEmojiBtn.current.classList.remove('on');
    }
    handleEmoji (ev){
        ev.preventDefault();
        this.setState({
            isSharingEmoji : true
        })
        ev.target.classList.toggle('on');
        // console.log(ev.target);
    }
    onEmojiClick = (event, emojiObject) => {
         
        // console.log(this.state.text);
        this.setState({
            text: this.state.text + emojiObject.emoji,
            isSharingEmoji : false
        })
    };
    componentDidMount() {
        
        document.addEventListener('mouseup', this.handleClickOutside);

        
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    render() {
        if(!this.props.post){
            return "";
        }
        
        return (
            <div className={"updatePost create-post active" + (this.state.disablePost ? "disable" : "")}>
                <ToastContainer />

                <div className="title-create-post">
                    <div className="d-flex jc-space-b align-center f-wrap-xs">
                        <div className="">
                            <h2>Modifier le texte de la publication</h2>
                        </div>
                        <div className="">
                            
                        </div>
                        <div className="text-right">
                            <span to={false} onClick={this.props.handleCloseUpdateModal} className="button"><span>Annuler</span></span>
                            &nbsp;&nbsp;&nbsp;
                            <Link to={false} onClick={this.SubmitUpdatePost.bind(this)} className="button"><span>Modifier</span></Link>
                        </div>
                    </div>
                </div>
                
                <div className={"textarea " + (this.state.error ? "error" : "")}>
                    <div className="wrapper">
                        <span ref={this.txtVal} className="txtVal">
                            {this.state.text && this.state.text.split("\n").map(function(item, idx) {
                                return (
                                    <span key={"_txt"+idx}>
                                    {item}
                                    <br/>
                                    </span>
                                    )
                                })
                            }
                        </span>
                        <textarea autoFocus={true}
                            onFocus={function(e) {
                                var val = e.target.value;
                                e.target.value = '';
                                e.target.value = val+" ";
                            }}
                        placeholder="Modification de texte" onChange={this.handleChange.bind(this)} name="text-post" id="text-post" className="text-post " value={this.state.text}></textarea>
                        <span className="figure" style={{backgroundImage: 'url('+ Global.API_URL  + this.props.auth.user.profil +')' }}></span>
                    </div>
                </div>
                
                {
                    (this.state.postType != 3) &&
                    <div>
                        {this.state.link !== null &&
                        <div className="link-preview">        
                            <ReactTinyLink
                            cardSize="small"
                            showGraphic={true}
                            maxLine={2}
                            minLine={1}
                            url={this.state.link}/>
                        </div>
                    }
                    
                    {(this.state.images.length > 0 || this.state.oldimages.length > 0) && 
                        <div className="UploadedImagesDisplay">
                            {this.state.oldimages.map((image, index) => (
                                <span key={"image-"+index} style={{backgroundImage : 'url(' + Global.API_URL + image + ')'}} >
                                    <span className="remove" ><i isold="isold"  data-target={index} onClick={this.removeImage.bind(this)}>X</i></span>
                                </span>
                            ))}

                            {this.state.images.map((image, index) => (
                                <span key={"image-"+index} style={{backgroundImage : 'url(' + image + ')'}} >
                                    <span className="remove" ><i  data-target={index + this.state.oldimages.length} onClick={this.removeImage.bind(this)}>X</i></span>
                                </span>
                            ))}
                        </div>
                    }
                    {this.state.gif && 
                        <div className="UploadedGifDisplay">
                            <span>
                                <span className="remove" >
                                    <i onClick={this.removeGif.bind(this)}>X</i>
                                    <img src={this.state.gifSmall} className="gif" alt=""/>
                                </span>
                            </span>
                        </div>
                    }
                    {(this.state.video.length > 0 || this.state.oldvideo.length > 0) && 
                        <div className="UploadedVideoName">
                            {this.state.oldvideo.map((vid, index) => (
                                <span key={"vid-"+index}>
                                    <span className="remove" ><i isold="isold"  data-target={index} onClick={this.removeVideo.bind(this)}>X</i>&nbsp; <b className="fa fa-file-video-o"></b> &nbsp;&nbsp;{vid}</span>
                                </span>
                            ))}
                            {this.state.video.map((vid, index) => (
                                <span key={"vid-"+index}>
                                    <span className="remove" ><i  data-target={this.state.oldvideo.length + index} onClick={this.removeVideo.bind(this)}>X</i>&nbsp; <b className="fa fa-file-video-o"></b> &nbsp;&nbsp;{vid}</span>
                                </span>
                            ))}
                        </div>
                    }
                    {(this.state.docx.length > 0 || this.state.olddocx.length > 0) && 
                        <div className="UploadedFilesName">
                            {this.state.olddocx.map((file, index) => (
                                <span key={"docx-"+index}>
                                    <span className="remove" ><i isold="isold"  data-target={index} onClick={this.removeDoc.bind(this)}>X</i>&nbsp; <b className="fa fa-file-o"></b> &nbsp;&nbsp;{file}</span>
                                </span>
                            ))}

                            {this.state.docx.map((file, index) => (
                                <span key={"docx-"+index}>
                                    <span className="remove" ><i  data-target={index} onClick={this.removeDoc.bind(this)}>X</i>&nbsp; <b className="fa fa-file-o"></b> &nbsp;&nbsp;{file}</span>
                                </span>
                            ))}
                        </div>
                    }
                    {this.state.uploadPercentage !== 0 && 
                        <div className="loaderProgress">
                            <div className="progressBar">
                                <span style={{width: this.state.uploadPercentage + "%"}}></span>
                            </div>
                        </div>
                    }
                    </div>
                }
                {
                    (this.state.postType != 3)  &&
                    <div className="ft-create-post">
                        <ul style={{justifyContent: 'space-between'}}>
                            <li>
                                <a href>
                                    <label htmlFor="uploadImagesUpdate">
                                        <span className="fa fa-picture-o"></span>
                                        <input 
                                            ref={this.InputImage}
                                            type="file" 
                                            name="uploadImagesUpdate[]" 
                                            multiple 
                                            id="uploadImagesUpdate" 
                                            accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                            onChange={this.handleImageChange.bind(this)} />
                                        Photos
                                    </label>
                                </a>
                            </li>
                            <li>
                                <a href>
                                    <label htmlFor="uploadVideoUpdate">
                                        <span className="fa fa-video-camera"></span> 
                                        <input 
                                            ref={this.InputVideo}
                                            type="file" 
                                            name="uploadVideoUpdate" 
                                            id="uploadVideoUpdate" 
                                            accept="video/mp4, video/3gp, video/ogg, video/flv, video/avi, video/wmv, video/mov" 
                                            onChange={this.handleVideoChange.bind(this)} />
                                        Vidéo
                                    </label>
                                </a>
                            </li>
                            <li>
                                <a href>
                                    <label htmlFor="uploadFilesUpdate">
                                        <span className="fa fa-files-o"></span> 
                                        <input 
                                            ref={this.InputFiles}
                                            type="file" 
                                            name="uploadFilesUpdate" 
                                            multiple 
                                            id="uploadFilesUpdate" 
                                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                                            onChange={this.handleDocxChange.bind(this)} />
                                        Fichiers
                                    </label>
                                </a>
                            </li>
                            {/* <li><Link to={false}><span className="fa fa-bar-chart"></span> Créer un sondage</Link></li>
                            <li><Link to={false}><span className="fa fa-calendar"></span> Créer un évènement</Link></li> */}
                            <li>
                                <Link to={false}  className="share-icon">
                                    <span ref={this.shareIconBtn} onClick={this.handleShareIcon.bind(this)} className=""><img src={configs.GetImage('gif.png')} alt=""/></span>

                                    {this.state.isSharingGif &&
                                        <div className="icons-list">
                                            <Picker placeholder="Recherche" apiKey="u3PkS4gInlk051bpDTIbPo7Ga9H6ZDc3" onSelected={this.handleGifChange.bind(this)} style={{position : 'fixed'}} />
                                        </div>
                                    }
                                </Link>
                            </li>
                            <li>
                                <Link to={false}  className="share-icon">
                                    <span ref={this.shareEmojiBtn} onClick={this.handleEmoji.bind(this)} className="fa fa-smile-o"></span>

                                    {this.state.isSharingEmoji &&
                                        <div className="emoji-list">
                                            <PickerEmoji onEmojiClick={this.onEmojiClick} style={{position : 'fixed'}} />
                                        </div>
                                    }
                                </Link>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

CreatePost.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {} )(CreatePost);