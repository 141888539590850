import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Global from '../../../inc/Global'
//import Invitations from '../../community/Invitations';
import InputField from '../../InputField';

class AddToCommunityItem extends Component {
    
    render() {
        const { 
            ID,
            userEmail,
            groupID,
            Title,
            Type,
            Subject,
            NBUsers,
            Color,
            Logo,
            RoleSelect,
            handleAccept
        } = this.props;
        
        const APIURL = Global.API_URL;
        
        
        return (
            <li className="_ursl-listitem">
                <br />  
                <div className="header-post">
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Logo ?
                                {backgroundImage: 'url('+ APIURL + Logo + ')'}
                                : 
                                {}
                            } >
                            {!Logo && Title.charAt(0)+Title.charAt(1) }
                        </figure>
                        <div className="">
                            <div className="username group-name">{Title}</div>
                            <div className="p">
                                <span>{Subject}</span><br/>
                            </div>
                            <div className="_cmitem-ft">
                                <span className="custom-icon custom-icon-users"></span>
                                <span className="_nburs">{NBUsers}</span>&nbsp;&nbsp;
                                {Type=== "private" && 
                                    <span className="custom-icon custom-icon-private"></span>
                                }
                                {Type=== "secret" && 
                                    <span className="custom-icon custom-icon-secret"></span>
                                }
                            </div>
                        </div> 
                    </div>
                </div> 
                <div className="_cmitem-ft d-flex jc-space-b align-center">
                    
                    <div></div>
                    <div className="footer-invitation d-flex align-center">
                        <InputField 
                            type="select" 
                            id={"_role"+groupID} 
                            name="role" 
                            label="" 
                            fixedLabel="true" 
                            placeholder=""
                            items={RoleSelect.items}
                            classInput="" 
                            classContainer="" 
                            value={RoleSelect.selectedRole} 
                            selectedValue={RoleSelect.selectedValue} 
                            default={RoleSelect.selectedRole}
                            oninput={RoleSelect.handleSelectChange} 
                            errorMsg="" />
                        <Link to="" className="button" id={"_accept_"+groupID} data-id={ID} data-group-id={groupID} data-email={userEmail} data-invitation={1} onClick={handleAccept}>
                            Ajouter
                        </Link>
                    </div>
                </div>
            </li>
        )
    }
}
export default AddToCommunityItem;