import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';


class SearchForm extends Component {

    constructor(props){
        super(props)  
        this.state = {
            value: this.props.value
        } 
    }   

    onChangeSearch(e){ 
        this.setState({
            value: e.target.value
        })
        
    }

    onKeyUp(e){
        if( e.nativeEvent.key == 'Enter' ){
            this.props.history.push("/search/"+e.target.value);
        }
    }

    render() {
        if( this.props.location.pathname.includes('/search/') &&  this.props.referer == 'header' ) return ''
        return (
            <div className="search-bar">
                <div className="input">
                    <label className="fa fa-search" htmlFor="search-input"></label>
                    <input id="search-input" autoFocus={this.props.referer == 'results'} type="search" value={this.state.value} onKeyUp={(e)=>{this.onKeyUp(e)}} onChange={(e)=>{this.onChangeSearch(e)}} className="input-field" placeholder="Rechercher..." />
                </div>
            </div>
        )
    }
}

export default withRouter (SearchForm);
