import Axios from 'axios';
import React, { useEffect, useState } from 'react' 

import Marquee from "react-fast-marquee";
import Global from '../../inc/Global';

import './_breaking-news.scss'

var _getBreakingNews
const BreakingNews = (props) => { 

    const [content, setContent] = useState(null)  

    useEffect(() => { 
        getBreakingNews()
        _getBreakingNews = getBreakingNews
    }, []); 

    const getBreakingNews = ()=>{
        Axios.get(Global.API_URL + "/getbreaking").then((res) => {
            if( res.data.status ){ 
                setContent(res.data.result.breakingnews)  
            }else{
                setContent(null)
            }
        })
    } 
    if( !content ){
        document.body.classList.remove("breaking-news-on");
        return ''
    }

    document.body.classList.add("breaking-news-on");
    return(
        <>  
            <div className="breaking-news">
                <Marquee gradient={false} speed={45}>{content}</Marquee>
            </div>
        </>
    )
}

BreakingNews._getBreakingNews = ()=>{ 
    _getBreakingNews()
}
export default BreakingNews