import React, { Component } from 'react'
import InputField from '../InputField'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Global from '../../inc/Global'
import { connect } from 'react-redux'
import axios from "axios";
import { Link } from 'react-router-dom'
import logo from '../../assets/img/white-logo.png'
import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../../inc/ImageLib';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import configs from '../../inc/helpers'
import SHA1 from 'sha1'
import InviteMembers from './InviteMembers'


const imageMaxSize = 5242880 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})



class AddCommunity extends Component {
    constructor(props){
        super(props)

        this.state = {
            title : "",
            subject : "",
            type : "public",
            typeObj : { value: 'public', label: 'Communauté publique' },
            email : this.props.auth.user.username,
            selectedColor : "#d6d6d6",
            step : 0,
            communityID : false,
            communityLogo : false,
            userProfil : "",
            username : "",
            userLastname : "",
            errors : {},
            logo : {
                imgSrc : null,
                dragIsActive : false,
                imgSrcExt: null,
                profilSrc : null,
                imgFile : null,
                orgWidthAspect: null,
                crop :{ 
                    aspect: 1/1,
                    unit: '%'
                }
            },
            coverImg : {
                imgSrc : null,
                dragIsActive : false,
                imgSrcExt: null,
                profilSrc : null,
                imgFile : null,
                orgWidthAspect: null,
                crop :{ 
                    aspect: 16/9,
                    unit: '%'
                }
            }
        }

        this.canvasLogoRef = React.createRef(null);
        this.canvasCoverImgRef = React.createRef(null);
        this.CreateBtn = React.createRef(null);
        
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedColor: event.target.value
        });
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleSelectChange = (type) => {
        // console.log(type.value)
        this.setState({ 
            type: type.value,
            typeObj: type,
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var Valid = true;
        var errors = {
            title : false,
            type : false,
            selectedColor : false,
        };

        if(!this.state.title){
            Valid = false;
            errors['title'] = true;
        }else{
            errors['title'] = false;
        }
        // if(!this.state.subject){
        //     Valid = false;
        //     errors['subject'] = true;
        // }else{
        //     errors['subject'] = false;
        // }
        if(!this.state.type){
            Valid = false;
            errors['type'] = true;
        }else{
            errors['type'] = false;
        }
        
        this.setState({
            errors : errors
        })
        if(Valid){
            this.setState({
                step : 1
            });
            
            return;
        }
    }
    handleCreateCommunity (event){
        event.preventDefault(); 
        const btn = this.CreateBtn.current;
        
        btn.classList.add('on');
        var data = new FormData();
        data.append('email' ,this.state.email);
        data.append('titre' ,this.state.title);
        data.append('sujet' ,this.state.subject);
        data.append('type' ,this.state.type);
        data.append('couleur' ,this.state.selectedColor);
        data.append('cover',this.state.coverImg.imgFile);
        data.append('logo' ,this.state.logo.imgFile); 
        if(!this.state.communityID){

            axios
            .post(Global.API_URL+"/creategroupall", 
            data, 
            { headers: {
                'content-type': 'multipart/form-data'
                    }
                }
            )
            .then((res) => {
                // console.log(res);
                if(res.data.id){
                    this.setState({
                        step : this.state.step + 1,
                        communityID : res.data.id
                    });
                    btn.classList.remove('on');
                }
               
            })
            .catch((err) => {
                // console.log(err.message);
                btn.classList.remove('on');
            });
        }else{
            
            const clr = this.state.selectedColor.replace("#", "%23");
            axios
            .post(Global.API_URL+"/allupdate", 
                data, 
                { headers: {
                    'content-type': 'multipart/form-data'
                    }
                }
            )
            .then((res) => {
                // console.log(res);
                this.setState({
                    step : this.state.step + 1
                });
                btn.classList.remove('on');
               
            })
            .catch((err) => {
                // console.log(err.message);
                btn.classList.remove('on');
            });
            //this.CreateBtn.current.classList.remove('on')
        }
    } 
    goBack = (event) => {
        event.preventDefault();
        this.setState({
            step : this.state.step - 1
        })
    }
    // Upload Logo Functons
    handleOnCropLogoChange(crop){
        crop.aspect = 1;
        var logo = {...this.state.logo}
            logo.crop = crop;
            
        this.setState({
            logo : logo
        });
    }
    handleSaveLogoClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state.logo
        if (imgSrc) {
        
            const canvasRef = this.canvasLogoRef.current
        
            const {imgSrcExt} =  this.state.logo
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            
            const myFilename = "logo-community-"+ SHA1(new Date()) +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            var logo = {...this.state.logo}
                logo.profilSrc = imageData64;
                logo.imgFile = myNewCroppedFile;
                logo.imgSrc = null;
                logo.dragIsActive = false;
            await this.setState({
                logo : logo
            }); 
           
        }
    }

    handleOnCropLogoComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasLogoRef.current
        
        const {imgSrc}  = this.state.logo
        // console.log(this.state.logo.orgWidthAspect)
        const newCrop = {
          axpect : 1,
          x: (crop.x * this.state.logo.orgWidthAspect),
          y: (crop.y * this.state.logo.orgWidthAspect),
          width: (crop.width * this.state.logo.orgWidthAspect),
          height: (crop.height * this.state.logo.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    handleImageLogoLoaded = image => {
        var __Logo = {...this.state.logo}
            __Logo.orgWidthAspect = image.naturalWidth / image.width;
        
        // console.log(__Logo);
        

        if(image.width > image.height){
            
            __Logo.crop = {width: (image.height - 50), height: (image.height - 50), x :25, y: 25 }
        }else{
            __Logo.crop = {width: (image.width - 50), height: (image.width - 50), x :25, y: 25 }
        }
        
        const canvasRef = this.canvasLogoRef.current
        const {imgSrc}  = __Logo
        
        const newCrop = {
            axpect : 1,
            x: (__Logo.crop.x * __Logo.orgWidthAspect),
            y: (__Logo.crop.y * __Logo.orgWidthAspect),
            width: (__Logo.crop.width * __Logo.orgWidthAspect),
            height: (__Logo.crop.height * __Logo.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        // console.log(__Logo);
        this.setState({
            logo: __Logo
        });
        return false; // Return false when setting crop state in here.
    };
    
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.file.type
            const currentFileSize = currentFile.file.size
            
            if(currentFileSize > imageMaxSize) {
                alert("Ce fichier n'est pas autorisé, " + currentFileSize + " octets est trop grand")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("Ce fichier n'est pas autorisé. Seules les images sont autorisées.")
                return false
            }
            return true
        }
    }
    
    handleOndropLogo = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", ()=>{
                
                const myResult = myFileItemReader.result
                
                var logo = {...this.state.logo}
                    logo.imgSrc = myResult;
                    logo.imgSrcExt = extractImageFileExtensionFromBase64(myResult);
                this.setState({
                    logo : logo
                })

                
            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEnterLogo = (DragEvent) => {
        var logo = {...this.state.logo}
            logo.dragIsActive = true;
        this.setState({logo : logo});
    }
    handleOnDragLeaveLogo = (DragEvent) => {
        var logo = {...this.state.logo}
            logo.dragIsActive = false;
        this.setState({logo : logo});
    }
    handleLogoAcceptButton = (event) => {
        event.preventDefault();
        var errors = {...this.state.errors}

        // if(!this.state.logo.imgFile){
        //     errors.logo = true;
        //     this.setState({errors : errors});
        //     return false;
        // }
        errors.logo = false;
        this.setState({
            errors : errors,
            step : this.state.step + 1
        })
    }
    //---------------------------------------------------

    handleOnCropcoverImgChange(crop){
        crop.aspect = 16 / 9;
        var coverImg = {...this.state.coverImg}
            coverImg.crop = crop;
        this.setState({
            coverImg : coverImg
        });
    }
    handleSavecoverImgClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state.coverImg
        if (imgSrc) {
        
            const canvasRef = this.canvasCoverImgRef.current
        
            const {imgSrcExt} =  this.state.coverImg
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            
            const myFilename = "cover-community-"+ SHA1(new Date()) +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            var coverImg = {...this.state.coverImg}
                coverImg.profilSrc = imageData64;
                coverImg.imgFile = myNewCroppedFile;
                coverImg.imgSrc = null;
                coverImg.dragIsActive = false;
            await this.setState({
                coverImg : coverImg
            }); 
            
           
        }
    }

    handleOnCropcoverImgComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasCoverImgRef.current
        const {imgSrc}  = this.state.coverImg
        
        const newCrop = {
            aspect : 16/9,
            x: (crop.x * this.state.coverImg.orgWidthAspect),
            y: (crop.y * this.state.coverImg.orgWidthAspect),
            width: (crop.width * this.state.coverImg.orgWidthAspect),
            height: (crop.height * this.state.coverImg.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    handleImagecoverImgLoaded = image => {
        var coverImg = {...this.state.coverImg}
            coverImg.orgWidthAspect = image.naturalWidth / image.width;
        
        if(image.width > image.height){
            coverImg.crop = {width: ((image.height * (16/9)) - 50), height: (image.height - 50), x :25, y: 25 }
        }else{
            coverImg.crop = {width: (image.width - 50), height: ((image.width * (9/16)) - 50), x :25, y: 25 }
        }
        
        const canvasRef = this.canvasCoverImgRef.current
        const {imgSrc}  = coverImg
        
        const newCrop = {
            aspect : 16 / 9,
            x: (coverImg.crop.x * coverImg.orgWidthAspect),
            y: (coverImg.crop.y * coverImg.orgWidthAspect),
            width: (coverImg.crop.width * coverImg.orgWidthAspect),
            height: (coverImg.crop.height * coverImg.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        this.setState({
            coverImg: coverImg
        });
        return false; // Return false when setting crop state in here.
    };
    
    handleOndropcoverImg = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", ()=>{
                
                const myResult = myFileItemReader.result
                
                var coverImg = {...this.state.coverImg}
                    coverImg.imgSrc = myResult;
                    coverImg.imgSrcExt = extractImageFileExtensionFromBase64(myResult);
                this.setState({
                    coverImg : coverImg
                })

                
            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEntercoverImg = (DragEvent) => {
        var coverImg = {...this.state.coverImg}
            coverImg.dragIsActive = true;
        this.setState({coverImg : coverImg});
    }
    handleOnDragLeavecoverImg = (DragEvent) => {
        var coverImg = {...this.state.coverImg}
            coverImg.dragIsActive = false;
        this.setState({coverImg : coverImg});
    }
    handlecoverImgAcceptButton = (event) => {
        /////
    }
    render() {
        if(this.state.step === 0){
         
            return (
                <div className="_modal-body">
                    <section className="content">
                    <div className="wrap">
                        <div className="_add-evso">

                            <div className="bg-white _add-evso-form">
                                <form onSubmit={this.handleSubmit} id="_Form">
                                    <InputField 
                                        type="text" 
                                        id="_title" 
                                        name="title" 
                                        label="Titre de la communauté" 
                                        fixedLabel="false" 
                                        classInput="" 
                                        classContainer={classnames({
                                            " hasError ": this.state.errors.title,
                                        })} 
                                        value={this.state.title} 
                                        oninput={this.handleInputChange} 
                                        errorMsg={classnames({
                                            "Le titre est obligatoire": this.state.errors.title,
                                        })} />
                                    

                                    <InputField 
                                        type="textarea" 
                                        id="_subject" 
                                        name="subject" 
                                        label="Sujet" 
                                        fixedLabel="false" 
                                        classInput="" 
                                        classContainer={classnames({
                                            " hasError ": this.state.errors.subject,
                                        })} 
                                        value={this.state.subject} 
                                        oninput={this.handleInputChange} 
                                        errorMsg={classnames({
                                            "Le sujet est obligatoire": this.state.errors.subject,
                                        })} />
                                    

                                    <InputField 
                                        type="select" 
                                        id="_type" 
                                        name="type" 
                                        label="Type" 
                                        fixedLabel="true" 
                                        placeholder=""
                                        items={[
                                            { value: 'public', label: 'Communauté publique' },
                                            { value: 'private', label: 'Communauté privée' },
                                            { value: 'secret', label: 'Communauté secrète' },
                                        ]}
                                        classInput="" 
                                        classContainer={classnames({
                                            " hasError ": this.state.errors.type,
                                        })} 
                                        value={this.state.typeObj} 
                                        selectedValue={{ value: 'public', label: 'Communauté publique' }} 
                                        default={this.state.typeObj}
                                        oninput={this.handleSelectChange} 
                                        errorMsg={classnames({
                                            "Le type est obligatoire": this.state.errors.type,
                                        })} /> 
                                </form>
                            </div>
                            <div className="d-flex jc-space-b _modal-footer m-t-10">
                                <Link to="/communautes" className="button wt lg">Annuler</Link>
                                <button type="submit" form="_Form" className="button lg">Continuer</button>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
            )
           
        }else if(this.state.step === 1){
            const {dragIsActive} = this.state.logo;
            return (
                <div className="_modal-body">
                    <section className="content connexion">
                    <div className="wrap">
                        <div className="_cnu-tabs">
                            {/* <div className="_cnu-logo">
                                <img src={Global.APP_LOGO} alt="" />
                            </div> */}
                            
                            <div className={dragIsActive ? "bg-white p-10 brs-10 dargActive" : "bg-white p-10 brs-10"}>
                                <div className="tab _p-photo d-flex align-center jc-center">
                                    <div className="select-profil">
                                        {this.state.logo.imgSrc && 
                                            <div className="_modal" id="CropImageModal">
                                                <div className="_overlay"></div>
                                                <div className="_modal-wrap">
                                                    <div className="_modal-content">
                                                        <div className="_modal-header">
                                                            <h2>Recadrer l'image</h2>
                                                        </div>
                                                        <div className="_modal-body">
                                                            <div className="d-flex f-wrap"> 
                                                                <div className="rightSidebar">
                                                                    <canvas ref={this.canvasLogoRef}></canvas>
                                                                </div>
                                                                <div className="_modal-body-wrap crop">
                                                                    <ReactCrop 
                                                                        src={this.state.logo.imgSrc} 
                                                                        crop={this.state.logo.crop} 
                                                                        onChange={this.handleOnCropLogoChange.bind(this)} 
                                                                        onComplete={this.handleOnCropLogoComplete} 
                                                                        onImageLoaded={this.handleImageLogoLoaded}
                                                                        />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="_modal-footer text-right">
                                                            <span onClick={this.handleSaveLogoClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                        }
                                        
                                        <Dropzone 
                                            accept={acceptedFileTypes} 
                                            onDrop={this.handleOndropLogo} 
                                            multiple={false} 
                                            maxSize={imageMaxSize}
                                            onDragEnter={this.handleOnDragEnterLogo}
                                            onDragLeave={this.handleOnDragLeaveLogo} >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <span className="img" 
                                                        style={this.state.logo.profilSrc ? 
                                                            {backgroundImage: 'url('+this.state.logo.profilSrc+')' } : 
                                                            {}
                                                        }>
                                                            { !this.state.logo.profilSrc ? this.state.title?.charAt(0) : ''}
                                                    </span>
                                                    <label className="label">Télecharger le logo</label>
                                                    {this.state.errors.logo && 
                                                        <div className="_alert _alert-danger">Il faut choisir un logo pour la communauté</div>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                        </Dropzone>
                                                
                                        
                                        {/* <input type="file" className="ph-profil" id="ph-profil" name="ph-profil" onChange={this.onImageSelected.bind(this)} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="_cnu-footer-tabs m-t-10">
                                <div className="d-flex jc-space-b _modal-footer">
                                    <Link to="" onClick={this.goBack.bind(this)} className="button lg wt">Retour</Link>
                                    <Link to="" onClick={this.handleLogoAcceptButton.bind(this)} className="button lg">
                                        Continuer <span className="fa fa-spinner fa-spin"></span> 
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>                
            )
        }else if(this.state.step === 2){
            const { dragIsActive } = this.state.coverImg;
            return (
                <div className="_modal-body">
                    <section className="content connexion">
                    <div className="wrap">
                        <div className="_cnu-tabs">
                            {/* <div className="_cnu-logo">
                                <img src={Global.APP_LOGO} alt="" />
                            </div> */}
                            <div className={dragIsActive ? "bg-white p-10 brs-10 dargActive" : "bg-white p-10 brs-10"}>
                                <div className="tab _p-photo d-flex jc-center">
                                    <div className="select-profil" style={{width: '100%'}}>
                                        {this.state.coverImg.imgSrc && 
                                            <div className="_modal" id="CropImageModal">
                                                <div className="_overlay"></div>
                                                <div className="_modal-wrap">
                                                    <div className="_modal-content">
                                                        <div className="_modal-header">
                                                            <h2>Recadrer l'image</h2>
                                                        </div>
                                                        <div className="_modal-body">
                                                            <div className="d-flex f-wrap"> 
                                                                <div className="rightSidebar">
                                                                    <canvas ref={this.canvasCoverImgRef}></canvas>
                                                                </div>
                                                                <div className="_modal-body-wrap crop">
                                                                    <ReactCrop 
                                                                        src={this.state.coverImg.imgSrc} 
                                                                        crop={this.state.coverImg.crop} 
                                                                        onChange={this.handleOnCropcoverImgChange.bind(this)} 
                                                                        onComplete={this.handleOnCropcoverImgComplete} 
                                                                        onImageLoaded={this.handleImagecoverImgLoaded}
                                                                        />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="_modal-footer text-right">
                                                            <span onClick={this.handleSavecoverImgClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                        }
                                        
                                        <Dropzone 
                                            accept={acceptedFileTypes} 
                                            onDrop={this.handleOndropcoverImg} 
                                            multiple={false} 
                                            maxSize={imageMaxSize}
                                            onDragEnter={this.handleOnDragEntercoverImg}
                                            onDragLeave={this.handleOnDragLeavecoverImg} >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                        <span className="img cover" 
                                                            style={this.state.coverImg.profilSrc ? 
                                                                {backgroundImage: 'url('+this.state.coverImg.profilSrc+')' } : {}
                                                            }>
                                                    </span>
                                                    <label className="label">Télécharger la couverture</label>
                                                    {this.state.errors.coverImg && 
                                                        <div className="_alert _alert-danger">Il faut choisir la couveture pour la communauté</div>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                        </Dropzone>
                                                
                                        
                                        {/* <input type="file" className="ph-profil" id="ph-profil" name="ph-profil" onChange={this.onImageSelected.bind(this)} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="_cnu-footer-tabs m-t-10">
                                <div className="d-flex jc-space-b _modal-footer">
                                    <Link to="" onClick={this.goBack.bind(this)} className="button lg wt">Retour</Link>
                                    <button ref={this.CreateBtn} onClick={this.handleCreateCommunity.bind(this)} className="button lg">
                                        Continuer <span className="fa fa-spinner fa-spin"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div>
            )
        }else if(this.state.step === 3){
            return (
                <div className="_modal-body">
                    <section className="content connexion">
                    <div className="wrap">
                        <div className="_cnu-tabs">
                            <div className="bg-white p-10 brs-10">
                                <div className="tab _mp-congratulations d-flex align-center jc-center">
                                    <div className="text-center">
                                        <div className="aplaude">
                                            <img width="100" src={configs.GetImage('aplaude.png')} alt="" />
                                        </div>
                                        <h2>Félicitations</h2>

                                        <div className="p">
                                            <p>votre communauté a été créée avec succès</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="_cnu-footer-tabs m-t-10">
                                <div className="d-flex jc-space-b _modal-footer">
                                    <Link to={`/communaute/${this.state.communityID}`} className="button lg wt"> Plus tard </Link>
                                    <button onClick={()=>{this.setState({step: 4})}} className="button lg"> Ajouter des membres </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </div> 
            )
        }else{
            return( 
                <div className="content">
                    <div className="_modal-body">
                        <section style={{position: 'relative',flexGrow: 1}}>
                            <InviteMembers location={{state:{
                                id : this.state.communityID,
                                newCommunity: true
                            }}} />  
                        </section>
                    </div>
                    <div className="_modal-footer align-right">
                        <Link to={`/communaute/${this.state.communityID}`} className="button lg"> Terminer </Link>
                    </div>
                </div> 
            )
        }
    }
}


AddCommunity.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(AddCommunity);