import React ,{useEffect,useState} from 'react';
import Posts from '../publications/Posts';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { withRouter,useHistory } from 'react-router-dom'
import BeatLoader from "react-spinners/BeatLoader";
import Global from '../../inc/Global';
import configs from '../../inc/helpers.js'
import Axios from 'axios';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/animations/scale.css';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import frLocale from '@fullcalendar/core/locales/fr';

import ReactTooltip from 'react-tooltip'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'; 
import 'moment/locale/fr';
moment.locale('fr');





// import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const localizer = momentLocalizer(moment)



const CallenderCom = (props) => {



    const [isLoaded, setLoaded] = useState(false);
    const [ComID, setComID] = useState();
    const [roleUser, setRoleUser] = useState();
    const [eventList, setEventList] = useState([])
    const [cultures, setCultures] = useState(['en', 'en-GB', 'es', 'fr', 'ar-AE'])
    let history = useHistory();
    
    const fullCalendarRef =React.createRef(null);

    useEffect(() => { 
        let comid = props.match.params.id
        setComID(comid);
        getListing(comid)
    }, []); 

    const getListing=(idCom)=>{
        Axios.get(`${Global.API_URL}/api/eventlist/${idCom}`).then((res) => {
            if (res.data.status) {
                let evnt = res.data.events;
                let evntArr =[]
                for (let i = 0; i < evnt.length; i++) {
                    const el = evnt[i];
                    let evntItem={
                        id: el.id,
                        title: el.title,
                        start: new Date(el.startTime),
                        end: new Date(el.endTime),
                        description: el.description,
                        lieu: el.lieu,

                    }
                    evntArr.push(evntItem)
                }
                setEventList(evntArr);
            }
            setLoaded(true)
        })
    }


    const handleEventClick = (ev) => {
        history.push(`/communaute/${ComID}/post/${ev.event.id}`)
    }
    const addZero =(num)=>{
        return num<10? `0${num}` : num 
    }
    const handleMouseEnter = (targEv) => {
        let end = new Date( targEv.event.end);
        let start = new Date( targEv.event.start);

        let DATEend = new Date( targEv.event.end).toISOString().substring(0, 10);
        let DATEstart = new Date( targEv.event.start).toISOString().substring(0, 10);

        let hour ='00:00';

        let hourStart = addZero(start.getHours()) + ":" + addZero(start.getMinutes()) ;
        let hourEnd =  addZero(end.getHours()) + ":" + addZero(end.getMinutes());
       
        let htmlDate =
        `
            <div class="listHoverTyppy">
            <div class="date">${targEv.event.title}</div>
                <div class="dateTime">
                    <div class="date">Debut : ${configs.frenchNumericDateFormat(start)} ${hourStart} </div>
                    <div class="date">Fin :  ${configs.frenchNumericDateFormat(end)} ${hourEnd} </div>
                    ${
                        targEv.event.extendedProps && targEv.event.extendedProps.lieu && targEv.event.extendedProps.lieu !=''  ? `<div class="date">Lieu :  ${targEv.event.extendedProps.lieu} </div>`  : ''
                    }
                </div>
            </div>        
        `;
                    // <div class="date">Debut : ${DATEstart} ${hourStart} </div>
                    // <div class="date">Fin :  ${DATEend} ${hourEnd} </div>
                    if(DATEend == DATEstart){
            hour = addZero(start.getHours()) + ":" + addZero(start.getMinutes()) + " / " +  addZero(end.getHours()) + ":" + addZero(end.getMinutes())
            htmlDate =
            `
                <div class="listHoverTyppy">
                <div class="date">${targEv.event.title}</div>
                    <div class="dateTime">
                        <div class="date">Debut : ${addZero(start.getHours()) + ":" + addZero(start.getMinutes())} </div>
                        <div class="date">Fin :  ${addZero(end.getHours()) + ":" + addZero(end.getMinutes())} </div>
                        ${
                            targEv.event.extendedProps && targEv.event.extendedProps.lieu && targEv.event.extendedProps.lieu !=''  ? `<div class="date">Lieu :  ${targEv.event.extendedProps.lieu} </div>`  : ''
                        }
                    </div>
                </div>        
            `;
        }

        tippy(targEv.el , {
            content:htmlDate,
            allowHTML: true,
        });     
    }
   
    
    function handleEventPositioned(info) {
        info.el.setAttribute("data-tip","some text tip");
         ReactTooltip.rebuild();
    }

    const handleDateClick = dateClickInfo => {
        console.log('aaaa');
        fullCalendarRef.current
            .getApi()
            .changeView('timeGridDay', dateClickInfo.date)
            // .changeView('listWeek', dateClickInfo.date)
    };

    if( !isLoaded ) return (
        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
    )
    return (
        
        <section className="content">

            <div className="wrap">

                <div className="header-list">
                    <div className="d-flex jc-space-b align-center">
                        <h3 className="h3"> 
                            <center>
                                <a onClick={()=>{window.history.back()}}>
                                        <span className="fa fa-long-arrow-left"></span>
                                </a> 
                                Calendrier 
                            </center>
                        </h3>  
                    </div>
                </div> 


                <div className="_adminNotif">
                    <div>
                        {/* <Calendar
                            selectable
                            showMultiDayTimes
                            localizer={localizer}
                            onSelectEvent={event =>{handleEventClick(event)}}
                            events={eventList}
                            views={['month', 'day', 'week']}
                            popup={true}
                            popupOffset={30}
                            messages={{
                                month: 'Mois',
                                next: ">",
                                previous : "<",
                                week : "Semaine",
                                day: 'Jours',
                                today: `Aujourd'hui`,
                                showMore: (target) => <span className="ml-2 calendarPlusMore" role="presentation"> +{target}</span>
                              }}
                            style={{ height: 500 }}


                            defaultView="resourceTimeGridDay"
                            plugins={[timeGridPlugin, interactionPlugin, resourceDayGridPlugin]}
                            eventPositioned={(e)=>handleEventPositioned(e)}
                            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                        /> */}
                         <FullCalendar    
                            ref={fullCalendarRef}     
                            locale={frLocale}         
                            events={eventList}  
                            themeSystem= 'bootstrap'
                            headerToolbar = {
                                {
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                }
                            }
                            plugins={[dayGridPlugin,listPlugin,timeGridPlugin,dayGridPlugin, interactionPlugin, resourceDayGridPlugin]}
                            eventPositioned={(e)=>handleEventPositioned(e)}
                            eventMouseEnter={(event)=>handleMouseEnter(event)}
                            eventClick={event =>{handleEventClick(event)}}
                            dateClick={event =>{handleDateClick(event)}}
                            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                            dayMaxEvents= {true}
                            eventLimit
                            selectable= {true}
                            businessHours= {
                                {
                                    daysOfWeek: [ 0,1, 2, 3, 4, 5, 6, 7 ], // Monday - Thursday
                                    startTime: '08:00', // a start time (10am in this example)
                                    endTime: '23:00', // an end time (6pm in this example)
                                  }
                            }
                            nowIndicator= {true}
                            allDaySlot= {false}
                            displayEventTime= {false}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
//messages={{ showMore: (target) => <span className="ml-2" role="presentation" onClick={() => this.setState({ calendarOverlay: true, currentTitleData: {} })}> ...{target} more</span> }}

// export default CallenderCom;


Posts.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(CallenderCom));