const Validation = {
    Empty : (value) => {
        return !value ? true : false;
    },
    Email : (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(email);
    },
    PhoneNumber : (tel) => {
        let re = /^(\+\d{1,3})??\d{3}?\d{3}\d{4}$/;

        return re.test(tel);
    },
    CfrPassword : (password, cfrPassword) => {
        return password === cfrPassword; 
    },
    Password : (password) => {

        // Validate lower letters
        var lowerCaseLetters = /[a-z]/g;
        if(!password.match(lowerCaseLetters)) {
            return false;
        }
        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(!password.match(upperCaseLetters)) {
            return false;
        }
        // Validate numbers
        var numbers = /[0-9]/g;
        if(!password.match(numbers)) {
            return false;
        }
        // Validate length
        if(password.length < 7) {
            return false;
        }

        return true;
    },
    Url : (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }
}


export default Validation;