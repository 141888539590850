import React, { Fragment, useState,useEffect, useRef } from "react";
import { Route, Switch } from 'react-router-dom';
import DatePicker , { registerLocale, setDefaultLocale } from 'react-datepicker';
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import BeatLoader from "react-spinners/BeatLoader";
import { scroller } from "react-scroll";
import './__stats.scss';
import Global from '../../../../inc/Global';
import Axios from 'axios';  
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



import fr from 'date-fns/locale/fr';
import configs from "../../../../inc/helpers";
import Algolia from "../../../../inc/Algolia";

import { Link } from 'react-router-dom/cjs/react-router-dom.min';

registerLocale('fr', fr)

const SweetAlert = withReactContent(Swal) 

const scrollToRef = (x,ref) => window.scrollTo(x, ref.current.offsetTop) 

const Statistics = (props)=>{
    
    // let userDetailTable = React.createRef();
    const userDetailTableRef = useRef(null);

    const [userSearchAlgoliaInput, setUserSearchAlgoliaInput] = useState('');
    const [isOpenExtern, setIsOpenExtern] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [liveUser, setLiveUser] = useState(0);
    const [conxWeb, setConxWeb] = useState(0);
    const [conxMob, setConxMob] = useState(0);
    const [tauxlive, setTauxlive] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedyear, setSelectedyear] = useState();

    const [profilCree, setProfilCree] = useState(0);
    const [profilNonActif, setProfilNonActif] = useState(0);
    const [profilExtern, setProfilExtern] = useState(0);
    // const [profileTypeAction, setProfileTypeAction] = useState(null);
    const [profileDetail, setProfileDetail] = useState([]);
    const [profileDetailNb, setProfileDetailNb] = useState(10);
    const [profileDetailNbStart, setProfileDetailNbStart] = useState(0);


    const [OldProfileDetailArr, setoldProfileDetailArr] = useState([]);
    const [profileDetailArr, setProfileDetailArr] = useState([]);
    const [profileTypeAction, setProfileTypeAction] = useState(null);



    const [topPostuer, setTopPostuer] = useState([]);
    const [lastCnx, setLastCnx] = useState([]);
    const [topCom, setTopCom] = useState([]);
    const [topPost, setTopPost] = useState([]);
    const [topCompPage, setTopCompPage] = useState(0);
    const [supAdminList, setSupAdminList] = useState([]);
    const [tauxliveParMoi, setTauxliveParMoi] = useState(0);
    const [nombreReaction, setNombreReaction] = useState({
        "status": true,
        "posts": 0,
        "commentaires": 0,
        "reactions": 0,
        "messsages": 0
    }); 
    const [errLastCnx, setErrLastCnx] = useState({
        message:'',
        status: false,
        loading:true
    });
    const [errtopPost, setErrTopPost] = useState({
        message:'',
        status: false,
        loading:true
    });
    const [errTopPosteur, setErrTopPosteur] = useState({
        message:'',
        status: false,
        loading:true
    });
    const [errTopCom, setErrTopCom] = useState({
        message:'',
        status: false,
        loading:true
    });
    const [errSupAdminList, setErrSupAdminList] = useState({
        message:'',
        status: false,
        loading:true
    });
    const [topPostPage, setTopPostPage] = useState({
        nextPage:null,
        page: 1,
        postsPerPage:2
    });

    

    useEffect(() => { 
        setIsOpenExtern(false)



        getMonthNbr();
        var mt = getMonthNbr();
        var yr = getYearNbr ();
        if (startDate) {

            mt = getMonthNbr(startDate);
            yr = getYearNbr (startDate);
        }

        fetchData();
        fetchTauxliveParMoi(mt,yr)
        fetchNombreReactionMoi(mt,yr)
        fetchTopPoster(mt,yr)
        fetchTopCom(mt,yr)
        setSelectedMonth(mt)
        setSelectedyear(yr)
        _fetchProfilesNumbres(0)

        fetchLastCnx();
        fetchSupAdmin();

        
        var livCnxInnterval = setInterval(getLiveCnx, 20000);
        fetchTopPost(mt,yr,0);
        return () => { 
            clearInterval(livCnxInnterval);
        }
    }, [startDate]); 
    
   
    const fetchData = async () => {
        const liveUserReq = await Axios(
          `${Global.API_URL}/statistique/userlive/0`
        );
        const conxReq = await Axios(
            `${Global.API_URL}/statistique/userlive/1`
        );
        const tauxlive = await Axios(
            `${Global.API_URL}/statistique/tauxlive`
        );
        

        (liveUserReq.data[0].status) ?  setLiveUser(liveUserReq.data[0].utilisateurLive) : setLiveUser(0);
        (conxReq.data[0].status) ?  setConxWeb(conxReq.data[0].ConnexionWeb) : setConxWeb(0);
        (conxReq.data[0].status) ?  setConxMob(conxReq.data[0].ConnexionMobile) : setConxMob(0);
        (tauxlive.data[0].status) ?  setTauxlive(conxReq.data[0].TauxEngangement) : setTauxlive(0);
    };  
    const getMonthNbr =  (d =  new Date()) => {
        // var d = new Date();
        var m = d.getMonth() + 1;
        var month =  (m <10) ? `0${m}`: m
        return month;
    }
    const getYearNbr =  (d =  new Date()) => {
        // var d = new Date();
        var m = d.getFullYear() ;
        // var month =  (m <10) ? `0${m}`: m
        return m;
    }
   
    const fetchTauxliveParMoi = async (month,year) => {
        const tauxliveParMoiCnst = await Axios(
        //   `${Global.API_URL}/statistique/tauxlivemonths/${month}/${year}`
          `${Global.API_URL}/statistique/tauxlivemonths/${month}/${year}`
        //   `${Global.API_URL}/statistique/tauxlivemonth/${month}/${year}`
        );
        (tauxliveParMoiCnst.data[0].status) ?  setTauxliveParMoi(tauxliveParMoiCnst.data[0].TauxEngangement) : setTauxliveParMoi(0);

    };
    const fetchNombreReactionMoi = async (month,year) => { ///Nombre des posts, commentaires, reactions, messages par mois

            Axios.get(`${Global.API_URL}/statreacts/${month}/${year}`)
            // Axios.get(`${Global.API_URL}/statreact/${month}/${year}`)
                .then((res) => {
                   {
                    (res.data[0].status) ?  setNombreReaction(res.data[0]) : setNombreReaction({
                        "status": true,
                        "posts": 0,
                        "commentaires": 0,
                        "reactions": 0,
                        "messsages": 0
                    });
                   }
                })
                .catch(err =>{
                    setNombreReaction({
                        "status": true,
                        "posts": 0,
                        "commentaires": 0,
                        "reactions": 0,
                        "messsages": 0
                    });
                })

    };
    const fetchTopPoster = async (month,year) => { 

        const TopPoteterCnst = await Axios(
          `${Global.API_URL}/statposteurs/${month}/${year}`
        //   `${Global.API_URL}/statposteur/${month}/${year}`
        );
        
        if (TopPoteterCnst.data[0].status) {
            setTopPostuer(TopPoteterCnst.data)
            setErrTopPosteur({
                message:'',
                status: false,
                loading:false
            })

        }else{
            setTopPostuer([])
            setErrTopPosteur({
                message: TopPoteterCnst.data[0].message,
                status: true,
                loading:false
            })
           
        }
        
    };
    const fetchSupAdmin = async (type=0,email='null') => { 
        let userS ;
        if (email!=='null') {
            userS = supAdminList.filter(item => item.email === email);
            setSupAdminList( supAdminList.filter(item => item.email !== email) );
        }
        Axios.post( `${Global.API_URL}/api/suprauser/${type}/${email}`,[])
            .then((res) => {
                if(type==0){
                    res.data.forEach(item => item.isChangingStatu = false)
                    if(res.data){
                        setSupAdminList(res.data)
                        setErrTopPosteur({
                            message:'',
                            status: false,
                            loading:false
                        })
                        
                    }else{
                        setSupAdminList([])
                        setErrTopPosteur({
                            message: res.data[0].message ? res.data[0].message : `une erreur inattendue s'est produite. veuillez réessayer ultérieurement.`,
                            status: false,
                            loading:false
                        })
                        let arr = supAdminList;
                        arr.push(userS[0]);
                    }
                }
                else{
                    if(res.data.status){
                        setSupAdminList(supAdminList.filter(item => item.email !== email))
                        setErrTopPosteur({
                            message:'',
                            status: false,
                            loading:false
                        })
                        setTimeout(() => {
                            SweetAlert.fire({ 
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }, 200);
                        
                    }else{
                        let arr = supAdminList;
                        arr.push(userS[0]);
                        setSupAdminList(arr)
                        setErrTopPosteur({
                            message: res.data.message ? res.data.message : `une erreur inattendue s'est produite. veuillez réessayer ultérieurement.`,
                            status: true,
                            loading:false
                        })
                    }
                }
            
            })
            .catch(err =>{
                setSupAdminList([])
                setErrTopPosteur({
                    message:  `une erreur inattendue s'est produite. veuillez réessayer ultérieurement.`,
                    status: false,
                    loading:false
                })
                let arr = supAdminList;
                arr.push(userS[0]);
                setSupAdminList(arr)
            })

        
    };
    const fetchLastCnx = async (start=0,nb=5,type = 1) => { 
        Axios.post(`${Global.API_URL}/lastconnection`,{type:type,nb:nb,start:start})
        .then((res) => {
            if(res.data.length>0){
                if (res.data[0].status) {
                    let arr = res.data.sort((a,b) =>  new Date(b.datelastconnect) - new Date(a.datelastconnect));
                    if(start>0){
                        setLastCnx(lastCnx.concat(arr))
                    }else{
                        setLastCnx(arr)
                    }
                    // setLastCnx(arr)
                    setErrLastCnx({
                        message:'',
                        status: false,
                        loading:false
                    })
    
                }else{

                    setLastCnx([])
                    setErrLastCnx({
                        message: res.data[0].message ? res.data[0].message : `une erreur inattendue s'est produite. veuillez réessayer ultérieurement.`,
                        status: true,
                        loading:false
                    })
                }
            }
           
        })
        .catch(err =>{
            setLastCnx([])
            setErrLastCnx({
                message: `une erreur inattendue s'est produite. veuillez réessayer ultérieurement.`,
                status: true,
                loading:false
            })
        });
    };
    const fetchTopPost = async (month,year,page=topPostPage.nextPage) => { 
        page =  page === 0 ? 1 : topPostPage.nextPage;
        Axios.post(`${Global.API_URL}/api/statistics/top-posts?page=${page}&postsPerPage=5`,{month:`${year.toString().slice(-2)}-${month}`})
        .then((res) => {
            let oldPst = topPost;
            if (res.data.status) {
                if (res.data.paginator.page===1) {
                    setTopPost(res.data.posts);
                }else{
                    setTopPost([...oldPst,...res.data.posts]);
                }
                setTopPostPage(res.data.paginator);
            }
           
        })
        .catch(err =>{
            setTopPost([])
            setErrTopPost({
                message: `une erreur inattendue s'est produite. veuillez réessayer ultérieurement.`,
                status: true,
                loading:false
            })
        });
    };
    const fetchTopCom = async (month,year,plus=0) => { 
        setTopCompPage(plus === 0 ? 1 : 0);
        const TopComCnst = await Axios(
          `${Global.API_URL}/statcomms/${month}/${year}/${plus}`
        //   `${Global.API_URL}/statcomm/${month}/${year}/0`
        );
        if (TopComCnst.data[0].status) {

            let oldcm = topCom;
            if (plus === 0) {
                setTopCom(TopComCnst.data)
            }else{
                setTopCom([...oldcm,...TopComCnst.data])
            }
        
            setErrTopCom({
                message:'',
                status: false,
                loading:false
            })

        }else{
            setTopPostuer([])
            setErrTopCom({
                message: TopComCnst.data[0].message,
                status: true,
                loading:false
            })
        
        }

    };
    const fetchProfilesNumbres = async (type) => { 
        let data = {}; 
        if (type==0) {
            data = {
                action : 0
            }
        }else{
            if(profileTypeAction===type){
                if(profileDetail.length < 0){
                    setProfileDetailNbStart(profileDetail.length+1)
                }
                data={
                    action : type,
                    start: profileDetailNbStart,
                    nb : profileDetailNb
                }
            }else{
                setProfileDetailNbStart(0)
                data={
                    action : type,
                    start: 0,
                    nb : profileDetailNb
                }
            }
        }
        setProfileTypeAction(type)
        
        Axios.post(`${Global.API_URL}/reports`,data)
            .then((res) => {
             
                if(res.data[0].status){
                    if (type==0) {
                        setProfilCree(res.data[0].Countprofilscreer)
                        setProfilNonActif(res.data[0].Countnonactif)
                        setProfilExtern(res.data[0].Countextern)
                    }else{
                        let startV = profileDetailNbStart + profileDetailNb;
                        setProfileDetailNbStart(startV);
                        let dataUser 
                        switch (type) {
                            case 1:
                                dataUser = res.data[0].Profilscreer
                                break;
                            case 2:
                                dataUser = res.data[0].Nonactif
                                break;
                            case 3:
                                dataUser = res.data[0].Profilextern
                                break;
                        
                            default:
                                break;
                        }
                        setProfileDetail([
                            ...profileDetail,
                            dataUser
                        ])
                    }
                    
                }else{
                    setProfileDetail([])
                    setProfilCree(0)
                    setProfilNonActif(0)
                    setProfilExtern(0)
                }
               
            })
            .catch(err =>{
                setProfileDetail([])
                setProfilCree(0)
                setProfilNonActif(0)
                setProfilExtern(0)
            })
    };

    const searchProfileDetailArr = (event)=>{
        let searchText = event.target.value;
        if (searchText=='') {
            setProfileDetailArr(OldProfileDetailArr);
            console.log(OldProfileDetailArr);
            return;
        }
        Algolia.searchUser(searchText).then(res=>{
            console.log("res=>",res);
            setProfileDetailArr(res)
        })
        
    }; 
    
    const downloadList=(type)=>{
        console.log(type);
    }
    
    const handleAdminSwitch = (user)=>{
        const oldVAlue = document.getElementById(`checkedSwitchAdmin-${user.id}`).checked;
        try {
            document.getElementById(`checkedSwitchAdmin-${user.id}`).checked = !oldVAlue ;
            // 
            Axios.get(`${Global.API_URL}/api/users/${user.id}/toggle-admin`)
                .then((res) => {
                    if(res.data ){
                        let arr = profileDetailArr ;
                        arr.forEach((usr)=>{
                            if (usr.id==user.id) {
                                usr.admin=res.data.admin 
                            }
                        })
                        if (res.data.admin == "ROLE_ADMIN") {
                            setSupAdminList([...supAdminList,...[user]])
                            document.getElementById(`checkedSwitchAdmin-${user.id}`).checked = true;
                        }else{
                            setSupAdminList(supAdminList.filter(userAdmin=>user.id!==userAdmin.id))
                            document.getElementById(`checkedSwitchAdmin-${user.id}`).checked = false;
                        }
                    }else{
                        document.getElementById(`checkedSwitchAdmin-${user.id}`).checked = oldVAlue ;
                    }
            })
        } catch(e) {
            document.getElementById(`checkedSwitchAdmin-${user.id}`).checked = oldVAlue ;
        }
    }; 
    
    ///get live conexion
    const getLiveCnx = ()=>{
        try {
            Axios.get(`${Global.API_URL}/statistique/userlive/0`)
                .then((res) => {
                    (res.data[0].status) ?  setLiveUser(res.data[0].utilisateurLive) : setLiveUser(0);
            })
          } catch(e) {
            console.log(e);
          }
    }


    const _fetchProfilesNumbres = async (type,newStart = true) => { 
        if (type==profileTypeAction && newStart) {
            setProfileDetailArr([])
            setProfileTypeAction(null)
            return;
        }
        let nbstart = newStart ? 0 : profileDetailArr.length;
        let data = {
            action : type
        }
        if (type!=0) {
            data={
                action : type,
                nb:10,
                start:nbstart
            }
        }
        Axios.post(`${Global.API_URL}/reports`,data)
            .then((res) => {
                if(res.data[0].status){
                    if (type==0) {
                        setProfilCree(res.data[0].Countprofilscreer)
                        setProfilNonActif(res.data[0].Countnonactif)
                        setProfilExtern(res.data[0].Countextern)
                    }else{
                        let dataUser 
                        switch (type) {
                            case 1:
                                dataUser = res.data[0].Profilscreer
                                break;
                            case 2:
                                dataUser = res.data[0].Nonactif
                                break;
                            case 3:
                                dataUser = res.data[0].Profilextern
                                break;
                        
                            default:
                                break;
                        }
                        dataUser.forEach(item => item.isChangingStatu = false)

                        if (newStart) {
                            setProfileDetailArr(dataUser)
                            setoldProfileDetailArr(dataUser)
                        }else{
                            var objDiv = document.getElementById("userDetailTable");
                            var scrolH = objDiv.scrollHeight;

                            let profileDetailArrOld = profileDetailArr
                            let arrNew = profileDetailArrOld.concat(dataUser)
                            setProfileDetailArr(arrNew)
                            document.getElementById("userDetailTable").scrollTo({
                                top: (scrolH-350),
                              }) 
                            

                        }
                    }


                }else{
                    if (type==0){
                        setProfilCree(0)
                        setProfilNonActif(0)
                        setProfilExtern(0)
                    }
                    else{
                        setProfileDetailArr([])
                    }
                }
                setProfileTypeAction(type)
                
            })
            .catch(err =>{
                if (type==0){
                    setProfilCree(0)
                    setProfilNonActif(0)
                    setProfilExtern(0)
            }else{
                    setProfileDetailArr([])
                }
            })
    };
    
    
    const __fetchProfilesNumbres = () =>{
        let data = {
            action : 0
        }
        Axios.post(`${Global.API_URL}/reports`,data)
            .then((res) => {
                if(res.data[0].status){
                    setProfilCree(res.data[0].Countprofilscreer)
                    setProfilNonActif(res.data[0].Countnonactif)
                    setProfilExtern(res.data[0].Countextern)
                }
            })

    }
    const changeExternToIntern = (user)=>{
        let data = {
            action: '2',
            email:user.email
        }      
        profileDetailArr.forEach(item=>{
            if (item.id==user.id) {
                item.isChangingStatu=true
            }
        })
        Axios.post(`${Global.API_URL}/api/userswitch`,data)
            .then((res) => {
                if (res.data[0].status) {
                    setProfileDetailArr (profileDetailArr.filter(res=>res.email!=user.email))
                    __fetchProfilesNumbres()
                   
                    setTimeout(() => {
                        SweetAlert.fire({ 
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        setProfileTypeAction(3)

                    }, 200);
                }
            })
    }
    const sendUserInvit = (action,emailUser="") => {
        let data = {
            action : action,
            email : emailUser
        }
        Axios.post(`${Global.API_URL}/api/sendemail`,data)
            .then((res) => {
                if(res.data.status){
                    SweetAlert.fire({ 
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
            .catch(e=>{
                console.log(e);
            })
    }








    return(
        <>
            <div className="_stats" > 
                <Switch>
                    <Route exact path="/administration/stats" component={()=>{
                        return(
                            <div className="container-fluid statsSection " id="statsSection">
                                {
                                    errTopPosteur.loading || errTopCom.loading ?
                                    <center style={{padding: 0}}>
                                        <BeatLoader color={"#aaa"} loading={true} size="20"/>
                                    </center>
                                    :
                                    
                                    <>
                                        <div className="row">
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Live connexion</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {liveUser}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <motion.div 
                                                        className={profileTypeAction==1?  "card cursorPointer active" :  "card cursorPointer " }
                                                        data-tip data-for="toggleProfilcree"
                                                        onClick={()=>{_fetchProfilesNumbres(1)}}
                                                        whileHover={{scale : 1.1,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                        whileTap={{scale : 0.9,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                        whileFocus={{scale : 0.9,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}>
                                                    <div className="card-body p-3 "
                                                        
                                                    >
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Profils créés</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {profilCree}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </motion.div>
                                                <ReactTooltip id="toggleProfilcree" place="top" type="info" effect="solid">
                                                    Afficher la liste
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <motion.div
                                                        className={profileTypeAction==2?  "card cursorPointer active" :  "card cursorPointer " }
                                                        data-tip data-for="toggleProfilNonActid"
                                                        onClick={()=>{_fetchProfilesNumbres(2)}}
                                                        whileHover={{scale : 1.1,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                        whileTap={{scale : 0.9,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                        whileFocus={{scale : 0.9,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}>
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Profils non actifs</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {profilNonActif}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </motion.div>
                                                <ReactTooltip id="toggleProfilNonActid" place="top" type="info" effect="solid">
                                                    Afficher la liste
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab" >
                                                <motion.div 
                                                    className={profileTypeAction==3?  "card cursorPointer active" :  "card cursorPointer " } 
                                                    data-tip data-for="toggleProfilExterAdmin"
                                                    onClick={()=>{_fetchProfilesNumbres(3)}}
                                                    whileHover={{scale : 1.1,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                    whileTap={{scale : 0.9,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                    whileFocus={{scale : 0.9,boxShadow: "0 20px 27px 0 rgbA(51 ,166, 247,0.31)",border: '1px solid rgb(51 166 247)'}}
                                                >
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Profils externes</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {profilExtern}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </motion.div>
                                                
                                                <ReactTooltip id="toggleProfilExterAdmin" place="top" type="info" effect="solid">
                                                    Afficher la liste
                                                </ReactTooltip>
                                            </div>
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Connexion web</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {conxWeb}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Connexion mobile</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {conxMob}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                        <div className="row">
                                                <div className="container-fluid py-4 statsSection">
                                                    <div className="row">
                                                        {
                                                            profileDetailArr.length > 0 &&
                                                            <motion.div
                                                            // initial={{height:0,opacity:0}}
                                                            // animate={{height:400,opacity:1}}
                                                            // exit={{height:0,opacity:0}}
                                                            className="col-xl-12 col-sm-12 mb-xl-0"
                                                            ref={userDetailTableRef}
                                                            >
                                                            <div className="card mb-4 hiddenTable">
                                                                <div className={profileTypeAction==1 ? "card-header pb-0 searchCnt" : "card-header pb-0 "}>
                                                                {
                                                                    profileTypeAction==1  &&
                                                                    <div className="WithOpt" >
                                                                         <div className="rowTb">
                                                                            <h6>Profils créés</h6>
                                                                            <div className="input">
                                                                                {/* <input 
                                                                                    type="search" 
                                                                                    name="s"  
                                                                                    value={userSearchAlgoliaInput} 
                                                                                    onChange={ (data) => searchProfileDetailArr(data) } 
                                                                                    placeholder="Rechercher un utilisateur.."
                                                                                /> */}
                                                                                <input
                                                                                    type="search"
                                                                                    id="search"
                                                                                    value={userSearchAlgoliaInput}
                                                                                    placeholder="Rechercher un utilisateur.."
                                                                                    onChange={e => {searchProfileDetailArr(e) ; setUserSearchAlgoliaInput(e.target.value)}}
                                                                                    autoFocus
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="rowTb">
                                                                            <div className="optionList">
                                                                        
                                                                                <div className="download"
                                                                                    onChange={e => {downloadList('profilcreer')}}
                                                                                    data-tip data-for="toggleDonload_1"
                                                                                >
                                                                                    <motion.a 
                                                                                        whileHover={{scale : 1.1,border: '1px solid rgb(51 166 247)'}}
                                                                                        whileTap={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                        whileFocus={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                        href={Global.API_URL+'/inactive_users?token=' + configs.getTokenCurrentUser()} target="_blank" rel="noopener noreferrer"
                                                                                        className="text-secondary font-weight-bold text-xs btnActionTable" data-toggle="tooltip" data-original-title="Edit user">
                                                                                        <ion-icon name="download-outline"></ion-icon>
                                                                                        Exporter la liste 
                                                                                    </motion.a>
                                                                                </div>
                                                                                <div className="input">
                                                                                  
                                                                                    <input
                                                                                        type="search"
                                                                                        id="search"
                                                                                        value={userSearchAlgoliaInput}
                                                                                        placeholder="Rechercher un utilisateur.."
                                                                                        onChange={e => {searchProfileDetailArr(e) ; setUserSearchAlgoliaInput(e.target.value)}}
                                                                                        autoFocus
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                }
                                                                {
                                                                    profileTypeAction==2  && 
                                                                    <div className="WithOpt" >
                                                                         <div className="rowTb">
                                                                            <h6>Profils non actifs</h6>
                                                                        </div>
                                                                        {/* <div className="rowTb">
                                                                            <div className="optionList">
                                                                        
                                                                                <div className="download"
                                                                                    onChange={e => {downloadList('profilcreer')}}
                                                                                    data-tip data-for="toggleDonload_1"
                                                                                >
                                                                                     <motion.a 
                                                                                        whileHover={{scale : 1.1,border: '1px solid rgb(51 166 247)'}}
                                                                                        whileTap={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                        whileFocus={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                        href={Global.API_URL+'/created_users?token=' + configs.getTokenCurrentUser()} target="_blank" rel="noopener noreferrer"
                                                                                        className="text-secondary font-weight-bold text-xs btnActionTable" data-toggle="tooltip" data-original-title="Edit user">
                                                                                        <ion-icon name="download-outline"></ion-icon>
                                                                                        Exporter la liste 
                                                                                    </motion.a>
                                                                                </div>
                                                                                <div className="input">
                                                                                   
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                    </div>

                                                                    

                                                                }
                                                                {
                                                                    profileTypeAction==3 && 
                                                                    <h6>Profiles externes</h6>
                                                                }
                                                                </div>
                                                                {/* {
                                                                    errTopPosteur.status &&
                                                                        <div className="tableAlert">
                                                                            {errTopPosteur.message}
                                                                        </div>
                                                                } */}
                                                                
                                                                <div className="card-body px-0 pt-0 pb-2 userDetailTableClass" id="userDetailTable" >
                                                                <div className="table-responsive p-0" >
                                                                <table className="table align-items-center mb-0" >
                                                                {/* ref={this.userDetailTable} */}
                                                                    <thead>
                                                                        <tr>
                                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Fonction</th>
                                                                        
                                                                        {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> */}
                                                                        {/* <th className="text-secondary opacity-7"></th> */}
                                                                        {
                                                                            profileTypeAction==1  &&  
                                                                                <th className="text-secondary opacity-7">Supra admin</th> 
                                                                        }
                                                                        {
                                                                            profileTypeAction==3  &&  
                                                                                <th className="text-secondary opacity-7"></th> 
                                                                        }
                                                                        {
                                                                            profileTypeAction==2 &&  
                                                                            <th className="align-middle text-center">
                                                                                <motion.a 
                                                                                    whileHover={{scale : 1.1,border: '1px solid rgb(51 166 247)'}}
                                                                                    whileTap={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                    whileFocus={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                    onClick={()=>sendUserInvit(2)}
                                                                                    href="#" className="text-secondary font-weight-bold text-xs btnActionTable" data-toggle="tooltip" data-original-title="Edit user">
                                                                                    Renvoyer l'invitation à tous
                                                                                </motion.a>    
                                                                            </th>
                                                                        }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            profileDetailArr.map((user,key)=>{
                                                                                return (
                                                                                    
                                                                                    <tr key={key}>
                                                                                            <td>
                                                                                                <div className="d-flex px-2 py-1">
                                                                                                <div>
                                                                                                    {
                                                                                                        user?.images && user?.images?.image && user?.images?.image!='' ? 
                                                                                                            <>
                                                                                                                <img src={`${Global.API_URL}${user?.images?.image}`} className="avatar avatar-sm me-3" alt="user1"/>
                                                                                                            </>
                                                                                                        :
                                                                                                            <>
                                                                                                                <span className="avatar avatar-sm avatar-Text">
                                                                                                                    {user?.profile?.prenom.slice(0,1)} {user?.profile?.nom.slice(0,1)}
                                                                                                                </span>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                                    <h6 className="mb-0 text-sm">{user?.profile?.prenom} {user?.profile?.nom}</h6>
                                                                                                    <p className="text-xs text-secondary mb-0">{user.email}</p>

                                                                                                </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className='text-xs font-weight-bold mb-0'>
                                                                                                    {user?.profile?.fonction } 
                                                                                                </p>
                                                                                                {/* <p className="text-xs text-secondary mb-0">Organization</p> */}
                                                                                            </td>
                                                                                            
                                                                                            {
                                                                                                profileTypeAction==2 &&  
                                                                                                <td className="align-middle text-center">
                                                                                                    <motion.a 
                                                                                                        whileHover={{scale : 1.1,border: '1px solid rgb(51 166 247)'}}
                                                                                                        whileTap={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                                        whileFocus={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                                        onClick={()=>sendUserInvit(1,user.email)}
                                                                                                        href="#" className="text-secondary font-weight-bold text-xs btnActionTable" data-toggle="tooltip" data-original-title="Edit user">
                                                                                                    {user.isChangingStatu && <BeatLoader color={"#344767"} loading={true} size="4"/> } Renvoyer l'invitation </motion.a>
                                                                                                </td>
                                                                                            }
                                                                                            {
                                                                                                profileTypeAction==3 &&  
                                                                                                <td className="align-middle text-center">
                                                                                                    <motion.a 
                                                                                                        whileHover={{scale : 1.1,border: '1px solid rgb(51 166 247)'}}
                                                                                                        whileTap={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                                        whileFocus={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                                        onClick={()=>changeExternToIntern(user)}
                                                                                                        href="#" className="text-secondary font-weight-bold text-xs btnActionTable" data-toggle="tooltip" data-original-title="Edit user">
                                                                                                    {user.isChangingStatu && <BeatLoader color={"#344767"} loading={true} size="4"/> } Passer en interne</motion.a>
                                                                                                </td>
                                                                                            }
                                                                                            {
                                                                                                profileTypeAction==1 &&  
                                                                                                <td className="align-middle text-center checkBoxCenter">
                                                                                                    <div className="check-box" id='droit-switch'>
                                                                                                        <input 
                                                                                                            type="checkbox"    
                                                                                                            id={`checkedSwitchAdmin-${user.id}`}
                                                                                                            checked={ user?.admin=="ROLE_ADMIN"? true : false }  
                                                                                                            onChange={(e)=>handleAdminSwitch( user )}
                                                                                                        />
                                                                                                    </div>
                                                                                                </td>
                                                                                            }
                                                                                        
                                                                                        </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="row footerTable-cnt">        
                                                                    <div className="col-12 cntF">
                                                                        {
                                                                            profileTypeAction==1 && profilCree > profileDetailArr.length &&
                                                                            <motion.div
                                                                            whileHover={{scale : 1.1}}
                                                                            whileTap={{scale : 0.9}}
                                                                            className="tableFooter" onClick={ ()=>_fetchProfilesNumbres(profileTypeAction,false)}>
                                                                                <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                            </motion.div>
                                                                        }
                                                                        {
                                                                            profileTypeAction==2 && profilNonActif > profileDetailArr.length && 
                                                                            <motion.div
                                                                            whileHover={{scale : 1.1}}
                                                                            whileTap={{scale : 0.9}}
                                                                            className="tableFooter" onClick={ ()=>_fetchProfilesNumbres(profileTypeAction,false)}>
                                                                                <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                            </motion.div>
                                                                        }
                                                                        {
                                                                            profileTypeAction==3 && profilExtern > profileDetailArr.length && 
                                                                            <motion.div
                                                                            whileHover={{scale : 1.1}}
                                                                            whileTap={{scale : 0.9}}
                                                                            className="tableFooter" onClick={ ()=>_fetchProfilesNumbres(profileTypeAction,false)}>
                                                                                <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                            </motion.div>
                                                                        }
                                                                        
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </motion.div>
                                                        }
                                                    </div>
                                                </div>
                                        </div>
                                        
                                        <div className="row">
                                                <div className="container-fluid py-4 statsSection">
                                                    <div className="row">
                                                        {
                                                            !errSupAdminList.status &&
                                                            <div
                                                            className="col-xl-6 col-sm-12 mb-xl-0">
                                                                <div className="card mb-4">
                                                                <div className="card-header pb-0">
                                                                <h6>Liste des supera administrateurs</h6>
                                                                </div>
                                                                {/* {
                                                                    errTopPosteur.status &&
                                                                        <div className="tableAlert">
                                                                            {errTopPosteur.message}
                                                                        </div>
                                                                } */}
                                                                
                                                                <div className="card-body px-0 pt-0 pb-2">
                                                                <div className="table-responsive p-0">
                                                                    <table className="table align-items-center mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Fonction</th>
                                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                                                        {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> */}
                                                                        {/* <th className="text-secondary opacity-7"></th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            supAdminList.map((user,key)=>{
                                                                                return (
                                                                                    <tr key={key}>
                                                                                        <td>
                                                                                            <div className="d-flex px-2 py-1">
                                                                                            <div>
                                                                                                {
                                                                                                    user?.images && user?.images?.image && user?.images?.image!='' ? 
                                                                                                        <>
                                                                                                            <img src={`${Global.API_URL}${user?.images?.image}`} className="avatar avatar-sm me-3" alt="user1"/>
                                                                                                        </>
                                                                                                    :
                                                                                                        <>
                                                                                                            <span className="avatar avatar-sm avatar-Text">
                                                                                                                {user?.profile?.prenom.slice(0,1)} {user?.profile?.nom.slice(0,1)}
                                                                                                            </span>
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                                <h6 className="mb-0 text-sm">{user?.profile?.prenom} {user?.profile?.nom}</h6>
                                                                                            </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <p className="text-xs font-weight-bold mb-0">{user?.profile?.fonction}</p>
                                                                                            {/* <p className="text-xs text-secondary mb-0">Organization</p> */}
                                                                                        </td>
                                                                                        <td className="align-middle text-center text-sm">
                                                                                                <motion.a 
                                                                                                    whileHover={{scale : 1.1,border: '1px solid rgb(51 166 247)'}}
                                                                                                    whileTap={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                                    whileFocus={{scale : 0.9,border: '1px solid rgb(51 166 247)'}}
                                                                                                    onClick={()=>fetchSupAdmin(1,user.email)}
                                                                                                    href="#" className="text-secondary font-weight-bold text-xs btnActionTable" data-toggle="tooltip" data-original-title="Edit user">
                                                                                                {user.isChangingStatu && <BeatLoader color={"#344767"} loading={true} size="4"/> } Supprimer de la liste</motion.a>
                                                                                        </td>
                                                                                    {/*  <td className="align-middle text-center">
                                                                                            <span className="text-secondary text-xs font-weight-bold">23/04/18</span>
                                                                                        </td>
                                                                                        <td className="align-middle">
                                                                                            <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                                                            Edit
                                                                                            </a>
                                                                                        </td> */}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                    </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        }

                                                        {
                                                            !errLastCnx.status &&
                                                            <div
                                                                className="col-xl-6 col-sm-12 mb-xl-0">
                                                                    <div className="card mb-4">
                                                                        <div className="card-header pb-0">
                                                                            <h6>Dernières connexions </h6>
                                                                        <div className="refreshBTn" onClick={()=>{fetchLastCnx()}}>   
                                                                            <ion-icon  name="refresh-outline"></ion-icon>
                                                                        </div>
                                                                        </div>

                                                                    {/* {
                                                                        errTopPosteur.status &&
                                                                            <div className="tableAlert">
                                                                                {errTopPosteur.message}
                                                                            </div>
                                                                    } */}
                                                                    
                                                                    <div className="card-body px-0 pt-0 pb-2">
                                                                    <div className="table-responsive p-0">
                                                                        <table className="table align-items-center mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                                                            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Fonction</th>
                                                                            <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Dernière connexion</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                lastCnx.map((user,key)=>{
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td>
                                                                                                <div className="d-flex px-2 py-1">
                                                                                                <div>
                                                                                                    {
                                                                                                        user?.users?.images && user?.users?.images?.image && user?.users?.images?.image!='' ? 
                                                                                                            <>
                                                                                                                <img src={`${Global.API_URL}${user?.users?.images?.image}`} className="avatar avatar-sm me-3" alt="user1"/>
                                                                                                            </>
                                                                                                        :
                                                                                                            <>
                                                                                                                <span className="avatar avatar-sm avatar-Text">
                                                                                                                    {user?.users?.profile?.prenom.slice(0,1)} {user?.users?.profile?.nom.slice(0,1)}
                                                                                                                </span>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                                    <h6 className="mb-0 text-sm">{user?.users?.profile?.prenom} {user?.users?.profile?.nom}</h6>
                                                                                                </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="text-xs font-weight-bold mb-0">{user?.users?.profile?.fonction}</p>
                                                                                            </td>
                                                                                            <td className="align-middle text-center text-sm">
                                                                                                <span className="text-secondary text-xs font-weight-bold">{configs.timeSince(user.datelastconnect)}</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="row footerTable-cnt">        
                                                                        <div className="col-12 cntF">
                                                                            {
                                                                                // profileTypeAction==3 && profilExtern > profileDetailArr.length && 
                                                                                <motion.div
                                                                                whileHover={{scale : 1.1}}
                                                                                whileTap={{scale : 0.9}}
                                                                                className="tableFooter" onClick={ ()=>fetchLastCnx(lastCnx.length)}>
                                                                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                                </motion.div>
                                                                            }
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                        </div>
                                        {/* topPost.length > 0 */}
                                     
                                        
                                        
                                        <div className="row">
                                            <div className="col-4" >
                                            <DatePicker
                                                locale="fr"
                                                disabledKeyboardNavigation
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                dateFormat="MMMM yyyy"
                                                showMonthYearPicker
                                                minDate={new Date("2021-08-01")}
                                                maxDate={new Date()}
                                                className="adminSelectMonthAndYear font-weight-bolder"
                                            />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Taux d’engagement</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {tauxliveParMoi}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Posts</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {nombreReaction.posts}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Commentaires</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {nombreReaction.commentaires}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Réactions</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {nombreReaction.reactions}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-sm-3 mb-xl-0 mb-4 ab">
                                                <div className="card">
                                                    <div className="card-body p-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="numbers">
                                                                <p className="text-sm mb-0 text-capitalize font-weight-bold">Messages</p>
                                                                <h5 className="font-weight-bolder mb-0">
                                                                    {nombreReaction.messsages}
                                                                    {/* <span className="text-success text-sm font-weight-bolder"> Voir plus</span> */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-4 text-end">
                                                            <div className="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                                                                <i className="fa fa-bell" aria-hidden="true"></i>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                         topPost.length > 0  &&
                                            <div className="row">
                                                    <div className="container-fluid py-4 statsSection">
                                                    
                                                        <div className="row">
                                                            <div className="col-xl-12 col-sm-12 mb-xl-0">
                                                                <div className="card mb-4">
                                                                <div className="card-header pb-0">
                                                                    <h6>Top posts</h6>
                                                                    {/* <div className="refreshBTn" onClick={(e)=>{fetchTopPost(selectedMonth,selectedyear)}}>   
                                                                        <ion-icon  name="refresh-outline"></ion-icon>
                                                                    </div> */}
                                                                </div>
                                                                <div className="card-body px-0 pt-0 pb-2">
                                                                    <div className="table-responsive p-0">
                                                                        <table className="table align-items-center mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Communauté</th>
                                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date de creation</th>
                                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Utilisateur</th>
                                                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Nbr. action</th>
                                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ACTION</th>
                                                                                </tr>
                                                                            </thead>
                                                                            
                                                                            <tbody>
                                                                           { 
                                                                            topPost?.map((post,key)=>{
                                                                                return (
                                                                                    <tr key={key}>
                                                                                            <td>
                                                                                                <div className="d-flex px-2 py-1">
                                                                                                <div>
                                                                                                <span className="avatar avatar-sm avatar-Text">
                                                                                                    {post[0]?.groups?.titre.slice(0,1)} 
                                                                                                </span>
                                                                                                </div>
                                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                                    <h6 className="mb-0 text-sm">{post[0]?.groups?.titre}</h6>
                                                                                                </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        <td className="align-middle">
                                                                                            <p className="text-secondary text-xs font-weight-bold _m-0" >{configs.timeSince(post[0].createdAt)}</p>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className="d-flex px-2 py-1">
                                                                                            <div>
                                                                                             {
                                                                                                    post[0]?.users?.images?.image && post[0]?.users?.images?.image!='' ? 
                                                                                                        <>
                                                                                                            <img src={`${Global.API_URL}/${post[0]?.users?.images?.image}`} className="avatar avatar-sm me-3" alt="user1"/>
                                                                                                        </>
                                                                                                    :
                                                                                                        <>
                                                                                                            <span className="avatar avatar-sm avatar-Text">
                                                                                                                {post[0]?.users?.profile.nom.slice(0,1)} 
                                                                                                                {post[0]?.users?.profile.prenom.slice(0,1)} 
                                                                                                            </span>
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                                <h6 className="mb-0 text-sm">
                                                                                                    {post[0]?.users?.profile.prenom}  {post[0]?.users?.profile.nom}  
                                                                                                </h6>
                                                                                            </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="align-middle text-center text-sm">
                                                                                            <span className="badge badge-sm bg-gradient-success">{post?.nbrAction}</span>
                                                                                        </td>
                                                                                       
                                                                                        <td className="align-middle text-center text-sm">
                                                                                            <Link
                                                                                                to={`/communaute/${post[0]?.groups.id}/post/${post[0]?.id}`} className="text-secondary font-weight-bold text-xs btnActionTable btnnomotion" >

                                                                                                Voir le post

                                                                                            </Link>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            }
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                
                                                                    { 
                                                                        topPostPage.nextPage &&
                                                                        <div className="row footerTable-cnt">        
                                                                            <div className="col-12 cntF">
                                                                                {
                                                                                    // profileTypeAction==3 && profilExtern > profileDetailArr.length && 
                                                                                    <motion.div
                                                                                    whileHover={{scale : 1.1}}
                                                                                    whileTap={{scale : 0.9}}
                                                                                    className="tableFooter" onClick={(e)=>{fetchTopPost(selectedMonth,selectedyear)}}>
                                                                                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                                    </motion.div>
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                      }  
                                        <div className="row">
                                                <div className="container-fluid py-4 statsSection">
                                                    <div className="row">
                                                        {
                                                            !errTopPosteur.status &&
                                                            <div
                                                            className="col-xl-6 col-sm-12 mb-xl-0">
                                                                <div className="card mb-4">
                                                                <div className="card-header pb-0">
                                                                <h6>Top posteurs</h6>
                                                                </div>
                                                                {/* {
                                                                    errTopPosteur.status &&
                                                                        <div className="tableAlert">
                                                                            {errTopPosteur.message}
                                                                        </div>
                                                                } */}
                                                                
                                                                <div className="card-body px-0 pt-0 pb-2">
                                                                <div className="table-responsive p-0">
                                                                    <table className="table align-items-center mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Fonction</th>
                                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nbr. publications</th>
                                                                        {/* <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Employed</th> */}
                                                                        {/* <th className="text-secondary opacity-7"></th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            topPostuer.map((user,key)=>{
                                                                                return (
                                                                                    <tr key={key}>
                                                                                        <td>
                                                                                            <div className="d-flex px-2 py-1">
                                                                                            <div>
                                                                                                {
                                                                                                    user?.user?.images && user?.user?.images?.image && user?.user?.images?.image!='' ? 
                                                                                                        <>
                                                                                                            <img src={`${Global.API_URL}${user?.user?.images?.image}`} className="avatar avatar-sm me-3" alt="user1"/>
                                                                                                        </>
                                                                                                    :
                                                                                                        <>
                                                                                                            <span className="avatar avatar-sm avatar-Text">
                                                                                                                {user?.user?.profile?.prenom.slice(0,1)} {user?.user?.profile?.nom.slice(0,1)}
                                                                                                            </span>
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="d-flex flex-column justify-content-center">
                                                                                                <h6 className="mb-0 text-sm">{user?.user?.profile?.prenom} {user?.user?.profile?.nom}</h6>
                                                                                            </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <p className="text-xs font-weight-bold mb-0">{user?.user?.profile?.fonction}</p>
                                                                                            {/* <p className="text-xs text-secondary mb-0">Organization</p> */}
                                                                                        </td>
                                                                                        <td className="align-middle text-center text-sm">
                                                                                            <span className="badge badge-sm bg-gradient-success">{user?.nbpost}</span>
                                                                                        </td>
                                                                                    {/*  <td className="align-middle text-center">
                                                                                            <span className="text-secondary text-xs font-weight-bold">23/04/18</span>
                                                                                        </td>
                                                                                        <td className="align-middle">
                                                                                            <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                                                            Edit
                                                                                            </a>
                                                                                        </td> */}
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                    </table>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        }

                                                        <div className="col-xl-6 col-sm-12 mb-xl-0">
                                                            <div className="card mb-4">
                                                            <div className="card-header pb-0">
                                                                <h6>Top communautés</h6>
                                                                <div className="refreshBTn" onClick={(e)=>{fetchTopCom(selectedMonth,selectedyear,0)}}>   
                                                                    <ion-icon  name="refresh-outline"></ion-icon>
                                                                </div>
                                                            </div>
                                                            {/* {
                                                                errTopCom.status &&
                                                                    <div className="tableAlert">
                                                                        {errTopCom.message}
                                                                    </div>
                                                            } */}
                                                            <div className="card-body px-0 pt-0 pb-2">
                                                                <div className="table-responsive p-0">
                                                                    <table className="table align-items-center mb-0">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Titre</th>
                                                                                {/* <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Type</th> */}
                                                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nbr. action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        
                                                                        <tbody>

                                                                            {
                                                                                topCom?.map((Com,key)=>{
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td>
                                                                                                <div className="d-flex px-2 py-1">
                                                                                                <div>
                                                                                                <span className="avatar avatar-sm avatar-Text">
                                                                                                    {Com?.group?.titre.slice(0,1)} 
                                                                                                </span>
                                                                                                    {/* {
                                                                                                        Com?.images && Com?.Com?.images?.image && Com?.Com?.images?.image!='' ? 
                                                                                                            <>
                                                                                                                <img src={`${Global.API_URL}`} className="avatar avatar-sm me-3" alt="user1"/>
                                                                                                            </>
                                                                                                        :
                                                                                                            <>
                                                                                                                <span className="avatar avatar-sm avatar-Text">
                                                                                                                    AB
                                                                                                                </span>
                                                                                                            </>
                                                                                                    } */}
                                                                                                </div>
                                                                                                <div className="d-flex flex-column justify-content-center">
                                                                                                    <h6 className="mb-0 text-sm">{Com?.group?.titre}</h6>
                                                                                                </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            {/* <td>
                                                                                                <p className="text-xs font-weight-bold mb-0">{Com?.group?.type}</p>
                                                                                                <p className="text-xs text-secondary mb-0">Organization</p>
                                                                                            </td> */}
                                                                                            <td className="align-middle text-center text-sm">
                                                                                                <span className="badge badge-sm bg-gradient-success">{Com?.totalacts}</span>
                                                                                            </td>
                                                                                        {/*  <td className="align-middle text-center">
                                                                                                <span className="text-secondary text-xs font-weight-bold">23/04/18</span>
                                                                                            </td>
                                                                                            <td className="align-middle">
                                                                                                <a href="javascript:;" className="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">
                                                                                                Edit
                                                                                                </a>
                                                                                            </td> */}
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                                { 
                                                                    topCompPage === 1 &&
                                                                    <div className="row footerTable-cnt">        
                                                                        <div className="col-12 cntF">
                                                                            {
                                                                                // profileTypeAction==3 && profilExtern > profileDetailArr.length && 
                                                                                <motion.div
                                                                                whileHover={{scale : 1.1}}
                                                                                whileTap={{scale : 0.9}}
                                                                                className="tableFooter" onClick={(e)=>{fetchTopCom(selectedMonth,selectedyear,1)}}>
                                                                                    <i className="fa fa-arrow-down" aria-hidden="true"></i>
                                                                                </motion.div>
                                                                            }
                                                                            
                                                                        </div>
                                                                    </div>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                        </div>
                                        
                                    </>
                                }
                            
                            </div>
                        )
                    }} />
                </Switch> 
            </div>
        </>
    )
}
export default Statistics