import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import Posts from '../publications/Posts';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import Global from '../../inc/Global';
import Lightbox from 'react-image-lightbox';
import InfiniteScroll from "react-infinite-scroll-component";

class Gallerie extends Component {
    
    constructor(props){ 
        super(props)
        this.state = {
            data:[],
            images:[],
            photoIndex: 0,
            isOpen: false,
            status:false,
            currenPost:null,
            paginator:{
                nextPage:1
            }
        }
        this.openGallrie = this.openGallrie.bind(this); 

    } 


    componentDidMount(){
        this.getDataGallerie(1,12);
    }


    openGallrie(id,imgArr){
        console.log(id);
        this.setState({ 
            images : imgArr,
            isOpen: true,
            currenPost:id
        })
    }

    getDataGallerie(_page = 1 ,nb = 12){
        let page =  this.state.paginator.nextPage ; 
        console.log(this.state);
        axios
        .get(`${Global.API_URL}/api/groups/${this.props.communityid}/photos?page=${page}&postsPerPage=${nb}`)
        .then((res) => {
            if (res.data.status) {
                let data = res.data.posts.map((post) => {
                    return {
                        postId: post.id,
                        img : post.imag,
                        multiple :  post.imag.length>1 ? true : false 
                    }
                })

                this.setState({
                    data : [...this.state.data, ...data],
                    status:res.data.status,
                    paginator:res.data.paginator
                })
                console.log(this.state);
            }

        });
    }

    getPostUrlgl(){
       let currenPost = this.state.currenPost;
       console.log(currenPost);

        // return (<a href='#'></a>)
        // dangerouslySetInnerHTML={{__html: notification.Description}}
        return (
            <Link to={`/communaute/${this.props.communityid}/post/${currenPost}`} className="voirPostGal" >
                <div>
                    <p>
                        <i class="fa fa-external-link" aria-hidden="true"></i> Voir le post
                    </p>
                </div>
            </Link>
        )
    }

    render() {
        const { status, photoIndex, isOpen, images } = this.state;
        const captions = [
            'Cat in the snow',
            '',
            <a href='#'>link</a>,
            '',
          ];
          
        return (
            <>
                <section className="content">

                    <div className="wrap">

                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3 className="h3"> 
                                    <center>
                                        <a onClick={()=>{window.history.back()}}>
                                                <span className="fa fa-long-arrow-left"></span>
                                        </a> 
                                        Galerie 
                                    </center>
                                </h3>  
                            </div>
                        </div> 


                        <div className="_adminNotif">
                            <div>

                                    <InfiniteScroll
                                    dataLength={this.state.data.length}
                                    next={(e)=>this.getDataGallerie()}
                                    hasMore={this.state.paginator.nextPage ? true : false }
                                    loader={<h4>Loading...</h4>}
                                    >
                                        <section id='galleriesection' className="photos">   
                                
                                        {this.state.data.map((dt,id)=>(
                                            <div className="photosGal"  
                                                onClick={(e)=>this.openGallrie(dt.postId,dt.img)} 
                                                style={{backgroundImage: `url(${Global.API_URL}/${dt.img[0]})`}}
                                            >
                                                {
                                                    dt.img.length>1 && (
                                                        <div className='multy'>
                                                            <svg aria-label="Carousel" class="_ab6-" color="#ffffff" fill="#ffffff" height="22" role="img" viewBox="0 0 48 48" width="22"><path d="M34.8 29.7V11c0-2.9-2.3-5.2-5.2-5.2H11c-2.9 0-5.2 2.3-5.2 5.2v18.7c0 2.9 2.3 5.2 5.2 5.2h18.7c2.8-.1 5.1-2.4 5.1-5.2zM39.2 15v16.1c0 4.5-3.7 8.2-8.2 8.2H14.9c-.6 0-.9.7-.5 1.1 1 1.1 2.4 1.8 4.1 1.8h13.4c5.7 0 10.3-4.6 10.3-10.3V18.5c0-1.6-.7-3.1-1.8-4.1-.5-.4-1.2 0-1.2.6z"></path></svg>
                                                        </div>
                                                    )
                                                }
                                                {/* <img key={id} src={`${Global.API_URL}/${dt.img[0]}`} alt="Image Alt" loading="lazy"  /> */}
                                            </div>
                                        ))}
                                </section>

                                    </InfiniteScroll>   
                                
                            </div>
                        </div>
                    </div>
                </section>  

                {isOpen && images.length > 1 && (
                    <Lightbox 
                        mainSrc={`${Global.API_URL}/${images[photoIndex]}`}
                        nextSrc={`${Global.API_URL}/${images[(photoIndex + 1) % images.length]}`}
                        prevSrc={`${Global.API_URL}/${images[(photoIndex + images.length - 1) % images.length]}`}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        imageCaption={this.getPostUrlgl(photoIndex)}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                        })
                        }
                    />
                )}
                
                {isOpen && images.length == 1 && (
                    <Lightbox
                        mainSrc={`${Global.API_URL}/${images[photoIndex]}`}
                        nextSrc={`${Global.API_URL}/${images[(photoIndex + 1) % images.length]}`}
                        prevSrc={`${Global.API_URL}/${images[(photoIndex + images.length - 1) % images.length]}`}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        imageCaption={this.getPostUrlgl(photoIndex)}
                    />
                )}


            </>
        )
    }
}
 
Posts.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(Gallerie));
