import React, { useEffect ,useState} from 'react'  
import Axios from 'axios';
import { BeatLoader } from 'react-spinners'
import Global from '../../../inc/Global';

const LikesDetailOnHover = (props)=> {
    const [listing, setListing] = useState([]) 

    useEffect(() => { 
        getListing()
    }, []); 
    const getListing = ()=>{
        let type = getTypeForeRequest();
        let formData = new FormData();
        formData.append('idpost',props.commentId)
        formData.append('type',type)
        formData.append('nb',6)
        formData.append('start',0)

        Axios
        .post(Global.API_URL+"/api/likelist", formData)
        .then((res) => {
            if(res.data){
                setListing(res.data)
            }
        })
    } 
    const getTypeForeRequest = () =>{
        let type=0

        switch (props.typOfRequest) {
            case 'com':
                type=3
                break;
            case 'rep':
                type=4
                break;
        
            default:
                break;
        }

        return type;
    }

    return (
        <div className="likeListCom poll-users-list">
            {
                listing.length>0 ?
                    <>
                        {/* <ul>
                        {
                                listing.slice(0,1).map((item,key)=>{
                                    return(
                                        <li key={key}>
                                            {item.nom}
                                        </li>
                                    )
                            })
                        }
                        </ul> */}
                        <ul className="users-list">
                                { listing.slice(0,6).map((user,key)=>{
                                    return(
                                        <li id={`user-id-${user.id}`}>
                                            <a href="#" onClick={(e) => {e.preventDefault();}}>
                                                <figure 
                                                    className="" 
                                                    style={ user.image ? {backgroundImage: 'url('+ Global.API_URL + user.image + ')'}
                                                        : 
                                                        {}
                                                    }> 
                                                        {!user.image && 
                                                            // <span>{user?.prenom?.charAt(0)+user.nom?.charAt(0)}</span>
                                                            <span>{user?.nom?.split(' ')[0].charAt(0)+user?.nom?.split(' ')[1].charAt(0)}</span>
                                                        } 
                                                </figure>
                                                <div>
                                                    <h3 className="user-name">{user.nom.toLowerCase()}</h3> 
                                                </div>
                                            </a>
                                        </li> 
                                    )
                                })} 
                            </ul>
                        {listing.length>6 &&
                            <span  className="more" onClick= { (e) =>
                                props.handleShowMoreLikeDetails({
                                    id: props.commentId,
                                    type:getTypeForeRequest()
                                })
                            }>
                                Afficher plus
                            </span>
                        }
                    </>
                :
                    <center><BeatLoader color={"#aaa"} loading={true} size="9"/></center>
            }
            
        </div>
    )
}

export default LikesDetailOnHover
