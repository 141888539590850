import Axios from 'axios'
import React, { Component } from 'react'
import {Link} from 'react-router-dom' 
import Global from '../../inc/Global'
import configs from '../../inc/helpers'
import BeatLoader from "react-spinners/BeatLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Lightbox from 'react-image-lightbox';
import VideoView from '../publications/template-parts/VideoView'
import TextareaAutosize from 'react-textarea-autosize';
import Picker from 'react-giphy-component'
import PickerEmoji from 'emoji-picker-react';
import Pusher from 'pusher-js' 
import LinkView from '../publications/template-parts/LinkView' 
import PlusCircle from '../icons/PlusCircle'
import Linkify from 'react-linkify';
import { motion } from "framer-motion"
import ReactTooltip from 'react-tooltip';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import { flashTitle, cancelFlashTitle } from './FlashTitle'
import GlobalContext from '../../context/GlobalContext'
const simpleTextSearch = require('simple-text-search')

const SweetAlert = withReactContent(Swal) 



// const scrollToRef = (x,ref) => window.scrollTo(x, ref.current.offsetTop)


var self;




class ChatList extends Component {
    static contextType = GlobalContext;
    
    chatContainer = React.createRef();
    constructor(props){
        super(props)
        this.state = {
            currentUser: this.props.auth.user,
            showChatList: false, 
            loadingChatList: false,
            chatCounter: 0,
            chatList: [],
            showActionsButtons: false,
            showUsersList: false,
            newChatType: 'single',
            usersList: [],
            showChatRoom: false,
            showPanelControl: false,
            chat: {
                messages: [],
                users: [],
            }, 
            loadingMessages: false,
            lightBox:{
                open: false
            },
            textMessage: "",
            gif: null,

            file:null,
            progressUpload: 0,

            showGifPicker: false,
            showEmojiPicker: false,
            showReactionPopin: false,
            reactions: [],
            reactionsType : 1,
            loadReaction:false
        }
        // this.getNotificationsCounter();
        // this.initPusher();
        // setTimeout(()=>{
        //     this.getUsersList()
        // })

        this.textarea = React.createRef();

        self = this
    }

    static startChatByUser(e, user){ 
        self.getChatList(true, true)
        console.log(user);
        self.createSignleChat(e, user)
    }

    static startChatByConversationId(conversationId){ 
        self.setState({
            showChatList: false
        },()=>{
            self.getChatList(true, true).then(conversations=>{
                let conversation = conversations.find(c=> c.conversationId == conversationId)
                self.loadConversation(null, conversation)
            }) 
        }) 
    }

    initPusher(){
        let pusherClient = new Pusher(Global.PUSHER.app_key, { cluster: 'eu' }); 
        const channel = pusherClient.subscribe(Global.PUSHER.channel);
        channel.bind(Global.PUSHER.event,(data) => {
            if (data.pusheraction[0]==="chat"){
                    let chatList = this.state.chatList
                    let activeChat= chatList.filter((chat)=>{return chat.conversationId == data.conversationid});
                    if (activeChat[0]?.active && !data.chat.mine && data.receveur.includes(this.state.currentUser.username) ) {
                        Axios
                        .post(Global.API_URL+"/api/pushview",{
                            id:data.chat.id,
                            type:"msg"
                            })
                        .then((res) => {
                            this.getNotificationsCounter().then(nombreconversation=>{
                                console.log("nombreconversation", nombreconversation);
                                if( nombreconversation == 1 ){
                                    flashTitle( Global.APP_NAME,"Nouveau message")
                                }
                                if( nombreconversation > 1 ){
                                    flashTitle(Global.APP_NAME, `(${nombreconversation}) Nouveaux messages`)
                                }
                            });
                        })
                    }else{
                        this.getNotificationsCounter().then(nombreconversation=>{
                            if( nombreconversation == 1 ){
                                flashTitle( Global.APP_NAME,"Nouveau message")
                            }
                            if( nombreconversation > 1 ){
                                flashTitle(Global.APP_NAME, `(${nombreconversation}) Nouveaux messages`)
                            }
                        });
                    }

            }
            else if (data.pusheraction[0]==="vue"){
                if (data.sendto==this.state.currentUser.username) {
                    this.getNotificationsCounter(data).then(nombreconversation=>{
                        console.log("nombreconversation", nombreconversation);
                        if( nombreconversation == 1 ){
                            flashTitle( Global.APP_NAME,"Nouveau message")
                        }
                        if( nombreconversation > 1 ){
                            flashTitle(Global.APP_NAME, `(${nombreconversation}) Nouveaux messages`)
                        }
                    });
                }
            }
            // else{
            //     this.getNotificationsCounter().then(nombreconversation=>{
            //         console.log("nombreconversation", nombreconversation);
            //         if( nombreconversation == 1 ){
            //             flashTitle( Global.APP_NAME,"Nouveau message")
            //         }
            //         if( nombreconversation > 1 ){
            //             flashTitle(Global.APP_NAME, `(${nombreconversation}) Nouveaux messages`)
            //         }
            //     });
            // }
        });

    }

//   getMsgViewer(idmessage) {
//     return this.http.post(`${this.url}/api/messread/${idmessage}`,[]).pipe(
//       catchError(e => {
//         const status = e.status;
//         if (status === 401) {
//           this.auth.logout();
//         }
//         console.log(e);
//         throw new Error(e);
//       })
//     );
//   }
    getNotificationsCounter(){
        return new Promise((resolve, reject)=>{
            Axios
            .get(Global.API_URL+"/api/notif/count", {})
            .then((res) => {
                if( res.data.state ){
                    this.setState({
                        chatCounter: res.data.nombreconversation 
                    }, ()=>{
                        this.getChatList(false, false)
                        if(this.state.showChatRoom) this.loadConversation(null, this.state.chat);
                    }) 
                    resolve(res.data.nombreconversation )
                }
            })
        })
    }
    getNotificationsCounterNoLoad(NewData){
        return new Promise((resolve, reject)=>{
            Axios
            .get(Global.API_URL+"/api/notif/count", {})
            .then((res) => {
                if( res.data.state ){
                    this.setState({
                        chatCounter: res.data.nombreconversation 
                    }, ()=>{
                        // this.getChatList(false, false)
                        // if(this.state.showChatRoom) this.loadConversation(null, this.state.chat);


                        // /message.lastmessagesender == this.state.currentUser.username
                        let messagesData = this.state.chat.messages
                        if (messagesData) {
                            const idconversation = this.state.chat.messages[0].conversation.id
                            if (NewData.idconversation==idconversation) {
                                for (let index = 0; index < messagesData.length; index++) {
                                    const element = messagesData[index];
                                    if (element.mine) {
                                        element.view=0
                                    }
                                }
                                messagesData.data.sort(function(a, b) {
                                    return (a.id - b.id);
                                })
                                this.setState({ 
                                    chat: {
                                        ...this.state.chat,
                                        messages:messagesData
                                    },
                                    loadingMessages: false
                                }, ()=>{
                                    this.scrollToBottom()
                                })
                            }
                        }
                       
                    }) 
                    resolve(res.data.nombreconversation )
                }
            })
        })
    }
    getChatList(showLoader = true, showChatList = true, ){
        cancelFlashTitle(Global.APP_NAME);

        return new Promise((resolve, reject)=>{
            this.setState({
                showChatList: showChatList ? !this.state.showChatList : this.state.showChatList,
                showActionsButtons: false,
                loadingChatList: showLoader
            }, ()=>{
                if( this.state.showChatList ){
                    // document.getElementById('notifications').scrollTop()
                    let data = new FormData();
                    data.append("nb", 999999);
                    data.append("start", 0);
                    Axios
                    .post(Global.API_URL+"/api/loadconvers", data)
                    .then((res) => {
                        if( Array.isArray(res.data) ){
                            this.setState({
                                loading: false,
                                chatList: res.data,
                                chatCounter: 0,
                                loadingChatList: false
                            })
                            resolve(res.data)
                        }
                    })
                }
            })
        }) 
    }

    loadConversation(e, message){
        document.body.style.overflow = "hidden";

        if(e) e.preventDefault();
        let chatList = this.state.chatList
        chatList.map(m=>{m.active = message.conversationId === m.conversationId ? true : false})
        this.setState({
            chatList: chatList,
            showChatRoom: true,
            showUsersList: false,
            showPanelControl: false,
            chat: {users: [], messages: [], ...message},
            loadingMessages: true,
            searchUsersInput: ""
        },()=>{
            let data = new FormData();
            data.append("nb", 600);
            data.append("start", 0);
            Axios
            .post(Global.API_URL+"/api/chat/"+message.conversationId+"/messageload", data)
            .then((res) => {
                res.data.sort(function(a, b) {
                    return (a.id - b.id);
                })
                this.setState({ 
                    chat: {
                        ...this.state.chat,
                        messages: res.data
                    },
                    loadingMessages: false
                }, ()=>{
                    this.scrollToBottom()
                })
            })
        })
    }
    marcCnvAsRead(message){
        Axios
            .post(Global.API_URL+"/api/pushview",{
                id:message.conversationId,
                type:"conv"
                })
            .then((res) => {

            })
    }
    scrollToBottom = (stopScrolling = false) => {
        if( this.chatContainer.current && this.chatContainer.current.scrollHeight ){
            const scroll = this.chatContainer.current.scrollHeight - this.chatContainer.current.clientHeight;
            this.chatContainer.current.scrollTo(0, scroll);
            if(!stopScrolling){
                setTimeout(()=>{
                    this.scrollToBottom(true)
                    setTimeout(()=>{
                        this.scrollToBottom(true)
                    }, 1000)
                }, 1000)
            }
        }
        
    };

    keyDown(e){ 
        e.persist()  
        setTimeout(()=>{
            if( e.keyCode === 13 && !e.shiftKey ){
                if( this.state.textMessage.trim() === '' ) return;
                
                this.sendMessage()
            }
        })  
    }

    sendMessage(){ 
        let data = {
            content: this.state.textMessage.trim(),
            gif: this.state.gif ? this.state.gif.url : ''
        }; 

        let messages = this.state.chat.messages; 

        if( this.state.file ){
            data = new FormData();
            data.append("content", this.state.textMessage.trim()); 
            data.append("gif", ""); 

            switch(this.state.file.type){
                case'image':
                    this.state.file.dataFile.forEach((file, index)=>{
                        data.append(`image[]`, file);
                    });
                    break;
                case'video':
                    this.state.file.dataFile.forEach((file, index)=>{
                        data.append(`video`, file);
                    });
                    break;
                case'file':
                    this.state.file.dataFile.forEach((file, index)=>{
                        data.append(`file`, file);
                    });
                    break;
            }
            
        }else{
            messages.push({
                content: data.content,
                gif: data.gif,
                createdAt: new Date(),
                user:{
                    id: this.state.currentUser.user_id
                },
                type: 'null'
            })
        }   
        
        this.setState({
            textMessage: "",
            gif: "",
            chat: {
                ...this.state.chat,
                messages: messages
            },
            showEmojiPicker: false
        },()=>{
            this.scrollToBottom() 
            let options = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if( this.state.file ){
                options.onUploadProgress = (progressEvent) => {
                    const {loaded, total} = progressEvent;
                    let percent = Math.floor( (loaded * 100) / total )
                    this.setState({ progressUpload: percent })
                }
            }

            Axios
            .post(Global.API_URL+"/api/messagecreate/"+this.state.chat.conversationId+"/"+ (this.state.file ? 'file' : 'noFile'), data, options)
            .then((res) => {
                if( res.data ){
                    let messages = this.state.chat.messages; 
                    messages.fill(res.data, -1)
                    this.setState({ 
                        chat: {
                            ...this.state.chat,
                            messages: messages 
                        },
                        // textMessage: "",
                        progressUpload: 0,
                        file: null
                    }, ()=>{

                        if(this.textarea.current){
                            this.textarea.current.focus(); 
                        }
                        
                        setTimeout(()=>{
                            this.scrollToBottom()
                            this.getChatList(false, false)
                        })
                    })
                }
            })
        })  
    }

    getUsersList(){
        Axios
        .get(Global.API_URL+"/api/users/0/1000000000")
        .then((res) => {
            this.setState({
                usersList: res.data
            })
        })
    }

    newChat(){

        if(this.state.usersList.length == 0){
            this.getUsersList()
        }
        this.setState({
            showActionsButtons: false,
            showUsersList: true,
            newChatType: 'single'
        })
    }

    newChatGroup(){
        if(this.state.usersList.length == 0){
            this.getUsersList()
        }
        this.setState({
            showActionsButtons: false,
            showUsersList: true,
            newChatType: 'group'
        })
    }

    createSignleChat(e, user){
        Axios
        .post(Global.API_URL+"/conversation/create",{
            titre: "",
            emails:[{
                email: user.email
            },{
                email: this.state.currentUser.username
            }]
        })
        .then((res) => {
            if(res.data.id){
                this.loadConversation(e, {
                    conversationId: res.data.id,
                    titre:"",
                    users: [{
                        prenom: user.profile.prenom,
                        nom: user.profile.nom,
                        image: user.images ? user.images.image : null
                    }]
                });
                this.state.usersList.map(u=>u.showLoader = false);
                this.setState({
                    usersList: this.state.usersList
                })
            }
        }) 
    }

    createGroupChat(e){ 
        let usersEmails = [{
            email: this.state.currentUser.username
        }]
        let selectedUsers = []
        this.state.usersList.map(user=>{
            if(user.selected){
                selectedUsers.push({
                    prenom: user.profile.prenom,
                    nom: user.profile.nom,
                    image: user.images ? user.images.image : null
                })
                usersEmails.push({
                    email: user.email
                })
            }
        })

        if(usersEmails.length === 1) return;
        if(usersEmails.length > 10) {
            setTimeout(() => {
                SweetAlert.fire({ 
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 2000
                })
            }, 200);
            return;
        };

        this.setState({
            startNewGroupeChat: true
        })

        Axios
        .post(Global.API_URL+"/conversation/create",{
            titre: this.state.conversationTitle || "",
            emails: usersEmails
        })
        .then((res) => {
            if(res.data.id){
                this.loadConversation(e, {
                    conversationId: res.data.id,
                    titre: this.state.conversationTitle || "",
                    users: selectedUsers
                });
                this.state.usersList.map(u=>u.selected = false);
                this.setState({
                    usersList: this.state.usersList,
                    startNewGroupeChat: false
                })
            }
        }) 
    }

    handleImageChange(e){
        let files = []
        let thumbs = []
        for(var i = 0; i < e.target.files.length; i++){
            files.push(e.target.files[i]);
            thumbs.push(URL.createObjectURL(e.target.files[i]));
        }
        this.setState({
            file: {
                dataFile: files,
                thumbs: thumbs,
                type: 'image'
            },
            attachementsOptions: false
        })
    }

    handleVideoChange(e){
        let files = []
        let thumbs = []
        for(var i = 0; i < e.target.files.length; i++){
            files.push(e.target.files[i]);
            thumbs.push(e.target.files[i].name);
        }
        this.setState({
            file: {
                dataFile: files,
                thumbs: thumbs,
                type: 'video'
            },
            attachementsOptions: false
        })
    }

    handleFileChange(e){
        let files = []
        let thumbs = []
        for(var i = 0; i < e.target.files.length; i++){
            files.push(e.target.files[i]);
            thumbs.push(e.target.files[i].name);
        }
        this.setState({
            file: {
                dataFile: files,
                thumbs: thumbs,
                type: 'file'
            },
            attachementsOptions: false
        })
    }

    removeSelectedFile(index){
        this.state.file.thumbs.splice(index, 1);
        this.state.file.dataFile.splice(index, 1);
        if(this.state.file.thumbs.length == 0) {
            this.state.file = null;
        }
        this.setState({})
    }

    selectGif(gif){ 
        this.setState({
            gif: {
                url: gif.original.url,
                thumbs: gif.preview_gif.url
            },
            showGifPicker: false,
            attachementsOptions: false
        }, ()=>{
            this.textarea.current.focus(); 
        });
    };

    selectEmogi(event, data){
        event.preventDefault(); 
        this.setState({
            textMessage: this.state.textMessage + data.emoji
        }, ()=>{
            this.textarea.current.focus(); 
        });
    };

    getChatInfo(){
        Axios
        .get(Global.API_URL+"/api/infoconvert/"+this.state.chat.conversationId,{})
        .then((res) => {
            if(res.data.status)
            this.setState({
                chat:{
                    ...this.state.chat,
                    infos: res.data
                }
            })
        })
    }

    getConversationUsers(){
        let data = new FormData();
        data.append('id', this.state.chat.conversationId)
        Axios
        .post(Global.API_URL+"/api/listparticipants",data)
        .then((res) => {
            if(res.data.status)
            this.setState({
                chat:{
                    ...this.state.chat,
                    members: res.data.participants
                }
            }, ()=>{
                let users = []
                res.data.participants.map((o)=>{
                    let user = o.user
                    users.push({
                        email: user.email,
                        image: user.images ? user.images.image : null,
                        nom: user.profile.nom,
                        prenom: user.profile.prenom,
                    })
                })  
                this.setState({
                    chat:{
                        ...this.state.chat,
                        users: users
                    }
                })
            })
        })
    }
    
    showSettings(){
        this.setState({showPanelControl: !this.state.showPanelControl}, ()=>{
            this.getChatInfo();
            this.getConversationUsers()
        })
    }

    removeUserFromDiscussion(user){
        Axios
        .post(Global.API_URL+"/delete/conversation",{
            conversationid: this.state.chat.conversationId,
            email: user.email
        })
        .then((res) => {
            this.getConversationUsers()
        })
    }

    addUserToDiscussion(user){
        let data = new FormData();
        data.append('conversationid', this.state.chat.conversationId);
        data.append('email', user.email);
        Axios
        .post(Global.API_URL+"/api/chatuser", data)
        .then((res) => {
            if(res.data.status){
                let members = this.state.chat.members;
                let users = this.state.chat.users;
                members.push({
                    user: user
                })
                users.push({
                    email: user.email,
                    image: user.images ? user.images.image : null,
                    nom: user.profile.nom,
                    prenom: user.profile.prenom,
                })
                this.setState({
                    ...this.state.chat,
                    users: users,
                    members: members
                })
            }
        })
    }

    changeNotificationStatus(){
        this.setState({
            chat:{
                ...this.state.chat,
                infos:{
                    ...this.state.chat.infos,
                    statutnotif: !this.state.chat.infos.statutnotif
                }
            }
        },()=>{
            Axios
            .post(Global.API_URL+"/api/silentnotifpush/"+this.state.chat.conversationId,{})
            .then((res) => {})
        }) 
    }
    
    removeMessage(messageid, index){
        let messages =  this.state.chat.messages;
        messages.splice(index, 1);
        this.setState({
            chat:{
                ...this.state.chat,
                messages: messages
            }
        },()=>{
            let data = new FormData();
            data.append('messageid', messageid)
            Axios
            .post(Global.API_URL+"/api/deletemessage",data)
            .then((res) => {
                this.getChatList(false, false)
            })
        }) 
    }
    messageSeen(messageId){
        this.setState({
            showReactionPopin: true,
            reactionActiveTab: 1,
            reactionsType: 1,
            loadReaction: true
        })
        Axios
        .post(`${Global.API_URL}/api/messread/${messageId}`, [])
        .then((res) => {
            console.log(res);
            if(res.data && res.data.nom && res.data.nom.length>0){
                this.setState({
                    reactions: res.data.nom,
                    loadReaction: false
                })
            }
            else {
                this.setState({
                    reactions: [],
                    loadReaction: false
                })
            }

        })
    }
    deleteConversation(e){
        Axios
        .post(Global.API_URL+"/api/conversdeletebyauteur",{
            conversationid: this.state.chat.conversationId
        })
        .then((res) => {
            let chatList = this.state.chatList;
            let index = chatList.findIndex(item => {return item.conversationId == this.state.chat.conversationId})
            chatList.splice(index, 1);
            this.setState({
                chatList: chatList,
                deleteConversationConfirmation:false
            }, ()=>{
                if(this.state.chatList.length > 1){
                    this.loadConversation(e, this.state.chatList[0]);
                } 
            }) 
        })
    }

    leaveConversation(e){
        Axios
        .post(Global.API_URL+"/delete/conversation",{
            conversationid: this.state.chat.conversationId,
            email: this.state.currentUser.username
        })
        .then((res) => {
            let chatList = this.state.chatList;
            let index = chatList.findIndex(item => {return item.conversationId == this.state.chat.conversationId})
            chatList.splice(index, 1);
            this.setState({
                chatList: chatList,
                leaveConversationConfirmation:false
            }, ()=>{
                if(this.state.chatList.length > 1){
                    this.loadConversation(e, this.state.chatList[0]);
                } 
            }) 
        })
    }

    selectUser(user, key){ 
        const selectedUsers = this.state.usersList.filter(function(user){return user.selected === true}).length 
        if( selectedUsers < 19 || user.selected ){ 
            this.state.usersList.map(u => {
                if(user.id == u.id) user.selected = !user.selected;
            })
            this.setState({})
        }
    }

    componentDidMount(){ 
       
    }
    puserHendler(data){

        if (data && data.pusheraction.length>0) {
            const {notif,getCount} = this.context;
            if ( data.pusheraction[0]==="chat"){
                let chatList = this.state.chatList
                let activeChat= chatList.filter((chat)=>{return chat.conversationId == data.conversationid});
                if (activeChat[0]?.active && !data.chat.mine && data.receveur.includes(this.state.currentUser.username) ) {
                    Axios
                    .post(Global.API_URL+"/api/pushview",{
                        id:data.chat.id,
                        type:"msg"
                        })
                    .then((res) => {
                        console.log('push vue');
                    })
                }
                getCount()
                this.setState({
                    chatCounter: notif.nombreconversation 
                }, ()=>{
                    this.getChatList(false, false)
                    if(this.state.showChatRoom) this.loadConversation(null, this.state.chat);
                }) 
                if( notif.nombreconversation == 1 ){
                    flashTitle( Global.APP_NAME,"Nouveau message")
                }
                if( notif.nombreconversation > 1 ){
                    flashTitle(Global.APP_NAME, `(${notif.nombreconversation}) Nouveaux messages`)
                }
    
            }
            else if (data.pusheraction[0]==="vue"){
                if (data.sendto==this.state.currentUser.username) {
                    getCount()
                    this.setState({
                        chatCounter: notif.nombreconversation 
                    }, ()=>{
                        this.getChatList(false, false)
                        if(this.state.showChatRoom) this.loadConversation(null, this.state.chat);
                    }) 
                    if( notif.nombreconversation == 1 ){
                        flashTitle( Global.APP_NAME,"Nouveau message")
                    }
                    if( notif.nombreconversation > 1 ){
                        flashTitle(Global.APP_NAME, `(${notif.nombreconversation}) Nouveaux messages`)
                    }
                }
            }
        }
    }
    render() {
        const {notif,dataPush,loadCount,setloadCountToFalse} = this.context;
      
        
        if (loadCount && dataPush && dataPush.pusheraction.length>0)  {

            this.puserHendler(dataPush);
            setloadCountToFalse();
        }

        return (
            <div className={`notifications chat-sidebar ${this.state.showChatRoom ? 'chat-room' : ''} ${this.state.showPanelControl ? 'panel-control' : ''}`}>
                {
                    this.state.showChatRoom &&
                    <div className="notifications-overley" onClick={()=>{this.setState({showChatRoom: false, showChatList: false, showActionsButtons: false,attachementsOptions:false}); document.body.style.overflow = "visible";}}></div>
                } 
                <button data-tip data-for="toggleChat" className={`toggle-notifications ${this.state.showChatList ? 'active' : ''}`} onClick={()=>{this.getChatList()}}>
                    <i className="fa fa-comments" aria-hidden="true"></i> 
                    {
                        notif.nombreconversation > 0 &&
                        <span className="badge">{notif.nombreconversation}</span>
                        // this.state.chatCounter > 0 &&
                        // <span className="badge">{this.state.chatCounter}</span>
                    } 
                </button>
                <ReactTooltip id="toggleChat" place="bottom" type="dark" effect="solid">
                    Discussions
                </ReactTooltip>
                <div className={`notifications-content d-flex ${this.state.showChatList ? 'active' : ''}`}>
                    <div className="chat-left-sidebar">
                        <h2>
                            Discussions 
                            <motion.button 
                            whileHover={{scale : 1.1}}
                            whileTap={{scale : 0.9}}
                            onClick={()=>{this.setState({showActionsButtons: !this.state.showActionsButtons})}}><PlusCircle /></motion.button>  
                            {
                                this.state.showActionsButtons &&
                                <ul className="actions">
                                    <li onClick={()=>{this.newChat()}}>
                                        <i className="fa fa-user-o" aria-hidden="true"></i> 
                                        {/* <i className="fa fa-user" aria-hidden="true"></i>  */}
                                        Nouvelle conversation
                                    </li>
                                    {/* <li onClick={()=>{this.newChat()}}><i className="fa fa-commenting-o" aria-hidden="true"></i> Nouvelle conversation</li> */}
                                    <li onClick={()=>{this.newChatGroup()}}>
                                        {/* <i className="fa fa-users" aria-hidden="true"></i>  */}
                                        <img  className='logo' src={require('../../assets/img/chategrp.png')} alt="group" />
                                        Nouveau groupe
                                    </li>
                                </ul>
                            }
                        </h2>

                        {
                            this.state.showUsersList && this.state.newChatType == 'group' &&
                            <div className="start-group-conversation d-flex">
                                <div>
                                    <button onClick={()=>{this.state.usersList.map(u=>u.selected = false);this.setState({showUsersList: false})}} className="button cancel">Annuler</button>
                                </div>
                                <div>
                                    <button onClick={(e)=>{this.createGroupChat(e)}} className="button">
                                        Démarrer
                                        {
                                            this.state.startNewGroupeChat &&
                                            <ClipLoader className="loader" color={"#fff"} loading={true} size={15} />
                                        }
                                    </button>
                                </div>
                            </div>
                        } 
                        {
                            this.state.showUsersList && this.state.newChatType == 'single' &&
                            <div className="start-group-conversation d-flex">
                                <div>
                                    <button onClick={()=>{this.state.usersList.map(u=>u.selected = false);this.setState({showUsersList: false})}} className="button cancel">Annuler</button>
                                </div>
                                <div>
                                   
                                </div>
                            </div>
                        } 

                        {
                            !this.state.showUsersList && 
                            <div className="search-bar">
                                <div className="input">
                                    <label className="fa fa-search" htmlFor="search-input"></label>
                                    <input type="search" className="input-field" placeholder="Rechercher..." onChange={(e)=>{this.setState({searchConversationInput: e.target.value})}} value={this.state.searchConversationInput} />
                                </div>
                            </div>
                        }
                        
                        {  
                            this.state.chatList.length > 0 && !this.state.showUsersList && 
                            <ul className="notifications-list conv">
                                {this.state.chatList.filter( (conversation)=>{ 
                                        let get = simpleTextSearch([conversation.titre, conversation.users, , conversation.content]) 
                                        let getFilter = simpleTextSearch(conversation.users, 'fullName' )
                                        return get(this.state.searchConversationInput).length > 0 || getFilter(this.state.searchConversationInput).length > 0
                                    }).map((message, key)=>{
                                    return(
                                        <li key={key} className={`${message.active ? 'active' : ''} ${message.nbvue > 0 ? 'new-message' : ''}`}>
                                            <Link to={'#'} onClick={(e)=>{this.loadConversation(e, message); message.nbvue = 0;this.marcCnvAsRead(message)}}>
                                                {
                                                    message.users.length == 1 &&
                                                    <figure 
                                                        style={message.users[0].image ? {backgroundImage: 'url('+ Global.API_URL + message.users[0].image+')'} : {}}>
                                                        {!message.users[0].image && message.users[0].prenom ? message.users[0].prenom.charAt(0)+message.users[0].nom.charAt(0) : ''}
                                                    </figure>
                                                } 
                                                {
                                                    message.users.length > 1 &&
                                                    <figure className='chateList'>
                                                        {/* <i className="fa fa-comments"></i> */}
                                                        <img  className='logo' src={require('../../assets/img/chategrp.png')} alt="group" />
                                                    </figure>
                                                } 
                                                <div>
                                                    <h3>  
                                                        
                                                        {
                                                            message.titre 
                                                            ?
                                                                message.titre 
                                                            :
                                                                (() => {
                                                                    let usersNames = []
                                                                    let usersNamesObject = []
                                                                    message.users.map(user=>{
                                                                        usersNames.push(user.prenom ? user.prenom.toLowerCase()+' '+user.nom.toLowerCase() : '')
                                                                        user.fullName = user.prenom ? user.prenom.toUpperCase()+user.nom.toUpperCase() : ''
                                                                    }) 
                                                                    return usersNames.join(', ')
                                                                })()
                                                        }
                                                    </h3>
                                                    {
                                                        message.lastmessagetype === "null"  &&
                                                        <p dangerouslySetInnerHTML={{__html: message.content}}></p>
                                                    }
                                                    
                                                    {
                                                        message.lastmessagetype !== "null"  &&
                                                        <p>
                                                            {
                                                                message.lastmessagetype == 'image' &&
                                                                <i className="fa fa-picture-o" aria-hidden="true"></i> 
                                                            }
                                                            {
                                                                message.lastmessagetype == 'video' &&
                                                                <i className="fa fa-video-camera" aria-hidden="true"></i>
                                                            }
                                                            {
                                                                message.lastmessagetype == 'document' &&
                                                                <i className="fa fa-file-o" aria-hidden="true"></i>
                                                            }
                                                        </p>
                                                    }
                                                    {
                                                        message.nbvue > 0 && !message.active &&
                                                        <span className="badge">{ message.nbvue }</span>
                                                    }
                                                    <abbr>{configs.timeSince(message.createdAt)}</abbr>
                                                    {
                                                        message.view==0 && message.lastmessagesender == this.state.currentUser.username &&
                                                        <div className="msg-checkd">
                                                            <abbr> <ion-icon name="checkmark-done-outline"></ion-icon></abbr>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            </Link>
                                        </li> 
                                    )
                                })}
                            </ul> 
                            
                        }  
                        {
                            this.state.chatList.length === 0 && !this.state.loadingChatList &&
                            <center className="no-result">Aucun message trouvé</center>
                        }
                        { 
                            this.state.showUsersList &&
                            <>
                                <div className="new-chat-users-list"> 
                                    {
                                        this.state.newChatType == 'group' &&
                                        <div className="conversation-title">
                                            <label>Nom du groupe</label>
                                            <input type="text" className="input-field" placeholder="..." onChange={(e)=>{this.setState({conversationTitle: e.target.value})}} value={this.state.conversationTitle} />
                                        </div>
                                    }
                                    {
                                        this.state.newChatType == 'group' &&
                                        <label className="select-users-label">Ajouter des contacts <button onClick={()=>{this.setState({showSearchInput: !this.state.showSearchInput})}}><i className="fa fa-search"></i></button></label>
                                    }
                                    
                                    {
                                        (this.state.newChatType === 'single' || (this.state.newChatType === 'group' && this.state.showSearchInput)) && 
                                        <div className="search-bar">
                                            <div className="input">
                                                <label className="fa fa-search" htmlFor="search-input"></label>
                                                <input type="search" className="input-field" placeholder="Rechercher..." onChange={(e)=>{this.setState({searchUsersInput: e.target.value})}} value={this.state.searchUsersInput} />
                                            </div>
                                        </div>
                                    }
                                    <ul className="notifications-list">
                                        {
                                             this.state.usersList.length == 0 && 
                                             <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
                                        }
                                        {this.state.usersList.filter( (user)=>{ 
                                            if(user.id === this.state.currentUser.user_id) return;
                                            let get = simpleTextSearch([user.profile?.prenom+" "+user?.profile?.nom, user.email]) 
                                            return get(this.state.searchUsersInput).length > 0
                                        }).map((user, key)=>{
                                            if(user.profile && this.state.newChatType == 'single'){
                                                return( 
                                                    <li>
                                                        <Link to={'#'} onClick={(e)=>{this.createSignleChat(e, user); user.showLoader = true; this.setState({})}}>
                                                            <figure 
                                                                style={user.images ? {backgroundImage: 'url('+ Global.API_URL + user.images.image+')'} : {}}>
                                                                {!user.images ? user?.profile?.prenom?.charAt(0)+user?.profile?.nom?.charAt(0) : ''}
                                                            </figure>
                                                            <div className="userInfoChatList">
                                                                <h3>{user?.profile?.prenom} {user?.profile?.nom} </h3>
                                                                { user?.profile?.fonction && user?.profile?.fonction != '' &&
                                                                    <abbr>{user?.profile?.fonction}</abbr>
                                                                }
                                                            </div>
                                                            {/* <div className="userInfoChatList">
                                                                <h3>{user?.profile?.prenom} {user?.profile?.nom} </h3>
                                                                { user?.profile?.fonction && user?.profile?.fonction != '' &&
                                                                    <p>{user?.profile?.fonction}</p>
                                                                }
                                                            </div> */}
                                                            {
                                                                user.showLoader &&
                                                                <ClipLoader className="loader" color={"#aaa"} loading={true} size={15} />
                                                            } 
                                                        </Link>
                                                    </li>
                                                )
                                            }else if(user.profile && this.state.newChatType == 'group'){
                                                return( 
                                                    <li>
                                                        <Link to={'#'} onClick={(e)=>{e.preventDefault(); this.selectUser(user, key)}}>
                                                            <figure 
                                                                style={user.images ? {backgroundImage: 'url('+ Global.API_URL + user.images.image+')'} : {}}>
                                                                {!user.images ? user.profile.prenom.charAt(0)+user.profile.nom.charAt(0) : ''}
                                                                {
                                                                    user.selected ? <i className="fa fa-check-circle"></i> : ''
                                                                }
                                                            </figure>
                                                            <h3>{user.profile.prenom} {user.profile.nom}</h3> 
                                                            {
                                                                user.showLoader &&
                                                                <ClipLoader className="loader" color={"#aaa"} loading={true} size={15} />
                                                            } 
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                        })} 
                                    </ul> 
                                    
                                </div>
                                {
                                    this.state.usersList.filter(function(user){return user.selected === true}).length >= 9 &&
                                    <div className="alert-select-max-users">
                                        Un groupe de discussion ne doit pas dépasser 10 participants.
                                    </div>
                                }
                            </>
                        }
                        {
                            this.state.loadingChatList &&
                            <center style={{marginTop: 10}}><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
                        }
                    </div>
                    {
                        this.state.showChatRoom &&
                        <div className="chat-right-sidebar">
                            <div className="chat-room-head d-flex">
                                <div className="back-button-container" style={{display: 'none'}}> 
                                     <button  onClick={()=>{this.setState({showChatRoom: false,showActionsButtons: false})}}>
                                         <i className="fa fa-chevron-left"></i>
                                     </button>
                                </div>
                                {
                                    this.state?.chat?.users?.length == 1 && this.state?.chat.users[0].image &&
                                    <div 
                                        className="img-profile" 
                                        style={this.state?.chat.users[0].image ? {backgroundImage: 'url('+ Global.API_URL + this.state?.chat.users[0].image+')'} : {}}>
                                        {!this.state?.chat.users[0].prenom ? this.state?.chat.users[0].prenom.charAt(0)+this.state?.chat.users[0].nom.charAt(0) : ''}
                                    </div>
                                } 

                                <div className="profile-name">
                                {
                                    this.state?.chat?.titre 
                                    ?
                                        this.state?.chat?.titre 
                                    :
                                        (() => {
                                            let usersNames = []
                                            this.state.chat.users.map(user=>{
                                                usersNames.push(user.prenom ? user.prenom.toLowerCase()+" "+user.nom.toLowerCase() : '')
                                            }) 
                                            return usersNames.join(', ')
                                        })()
                                }
                                </div>
                                
                                
                                <div className="settings">
                                    {
                                        !this.state.showPanelControl &&
                                        <button onClick={()=>{this.showSettings()}}> 
                                                <i className="fa fa-cog" aria-hidden="true"></i>  
                                        </button>
                                    }
                                </div>
                            </div>
                            <div ref={this.chatContainer} className="chat-room-body">

                                {
                                    this.state.loadingMessages &&
                                    <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
                                }
                                
                                {
                                    this.state.chat.messages.length > 0 &&
                                    <ul className="messages">
                                        {this.state.chat.messages.map((message, index)=>{
                                            const ext = message.file ? message.file.substring(message.file.lastIndexOf(".")+1).toLowerCase() : null;
                                            return(
                                                <li 
                                                className={`
                                                    ${ message.user.id === this.state.currentUser.user_id ? 'sent' : 'replies'} 
                                                    ${(message.type !== 'null' || message.gif || message.content.includes('className="like"')) ? 'attachment' : ''}
                                                    ${message.content && message.content.match(/(https?:\/\/[^\s]+)/g) ? 'attachment linkPreview' : ''}
                                                `}>
                                                        {   this.state.chat.users.length > 1 &&  message.user.id !== this.state.currentUser.user_id &&
                                                            <div>
                                                                <div className="img-profile" style={{backgroundImage: 'url('+Global.API_URL + message.photo+')'}}></div>
                                                                <span>{ message?.prenom?.toLowerCase() } { message?.nom?.toLowerCase() }</span>
                                                            </div>
                                                        }
                                                        <p  className={ message.content && (message.content.includes('className="like"') || message.content.includes('class="like"')) ?  `singl-im`: ''}  >
                                                            {
                                                                message.content &&
                                                                <>
                                                                    {
                                                                        message.content.includes('className="like"') || message.content.includes('class="like"') ?
                                                                            <span className={`content-message like-icon`} dangerouslySetInnerHTML={{__html: message.content}}></span>
                                                                        :
                                                                            <span className={`content-message`} >

                                                                                <Linkify
                                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                        <a target="blank" href={decoratedHref} key={key} style={{color: 'var(--secondary-color)'}}>
                                                                                            {decoratedText}
                                                                                        </a>
                                                                                    )}> 
                                                                                    {message.content}
                                                                                </Linkify>
                                                                            </span>

                                                                    }
                                                                    {/* <span className={`content-message ${message.content.includes('className="like"') ? 'like-icon' : ''}`} dangerouslySetInnerHTML={{__html: message.content}}></span> */}
                                                                    <LinkView link={ message.content } />
                                                                </>
                                                            }
                                                            
                                                            {
                                                                message.type === 'document' &&
                                                                <a href={Global.API_URL +"/" + message.file} target="_blank" className="chatedocument">  
                                                                    <img src={configs.GetImage(configs.GetFileIcon(ext))} alt={message.file} />    
                                                                    <span className="fileName">{message.filereelname}</span>     
                                                                </a>
                                                            }

                                                            {
                                                                message.type === 'image' &&
                                                                <div className={`images ${message.image.length > 1 ? 'multiple' : ''}`} >
                                                                    {message.image.map((img, key)=>{
                                                                        if( key <= 3 )
                                                                            return(
                                                                                <div className="img" style={{backgroundImage: 'url('+Global.API_URL  + img+')'}} onClick={()=>{this.setState({lightBox:{open: true, type: 'image', images: message.image, index: key}})}}>
                                                                                    {
                                                                                        key == 3 && message.image.length - 4 > 0 &&
                                                                                        <span className="more">+{ message.image.length - 4 }</span>
                                                                                    }
                                                                                </div>         
                                                                            )
                                                                        else return
                                                                    })}
                                                                </div>
                                                            }

                                                            {
                                                                message.type === 'null' && message.gif &&
                                                                <div className="images gif" >
                                                                    <img className="img" src={message.gif} style={{height: 'auto'}} />
                                                                </div>
                                                            }

                                                            {
                                                                message.type === 'video' &&
                                                                <div className="video">
                                                                    {
                                                                        !message.showVideo &&
                                                                        <div onClick={()=>{message.showVideo = true; this.setState({}); setTimeout(()=>this.scrollToBottom())}} className="poster" style={{backgroundImage: 'url('+Global.API_URL + message.thumbs+')'}}>
                                                                            <img src={configs.GetImage('play-icon.png')} />
                                                                        </div>
                                                                    } 
                                                                    {
                                                                        message.showVideo &&
                                                                        <VideoView autoPlay={true} videoUrl={Global.API_URL  + message.video} poster={Global.API_URL + message.thumbs} />     
                                                                    }
                                                                </div>
                                                            }
                                                            {/* <abbr className="message-date">{configs.timeSince(message.createdAt)}</abbr> */}
                                                           
                                                            <div className="msg-info-in-room">
                                                                <abbr className="message-date">{configs.timeSince(message.createdAt)}</abbr>
                                                                {
                                                                    message.view==0 && message.mine &&
                                                                        <abbr> <ion-icon name="checkmark-done-outline"></ion-icon></abbr>
                                                                }
                                                            </div>
                                                            {
                                                                message.user.id == this.state.currentUser.user_id &&
                                                                <span className="delete-message">
                                                                    <span className="dots"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></span>
                                                                    <div className="delete-btn-section">
                                                                       <div className="btnCnt">
                                                                            <button onClick={()=>{this.removeMessage(message.id, index)}} className="delete-btn-new">Supprimer</button>
                                                                            <button onClick={(e)=>{this.messageSeen(message.id)}} className="delete-btn-new">Lu par</button>
                                                                       </div>
                                                                    </div>
                                                                </span>
                                                            } 
                                                        </p> 
                                                          
                                                    </li> 
                                            )
                                        })} 
                                        
                                    </ul>
                                }
                                
                            </div>
                            
                            <div className="chat-room-footer">
                                <div className={`attachements-options ${this.state.attachementsOptions ? 'active': ''}`}>
                                    <ul>
                                        <li data-tip data-for="chatImgUpload" >
                                            <label htmlFor="uploadImages">
                                                <span className="fa fa-picture-o"></span>
                                                <input 
                                                    ref={this.InputImage}
                                                    type="file" 
                                                    name="uploadImages[]" 
                                                    multiple 
                                                    id="uploadImages" 
                                                    accept="image/x-png, image/png, image/jpg, image/jpeg, image/gif" 
                                                    onChange={(e)=>{this.handleImageChange(e)}} />
                                                <span className="button-label">Photos</span>
                                            </label>
                                        </li>
                                        <ReactTooltip id="chatImgUpload" place="top" type="dark" effect="solid">
                                            Image
                                        </ReactTooltip>
                                        <li data-tip data-for="chatVdoUpload" >
                                            <label htmlFor="uploadVideo">
                                                <span className="fa fa-video-camera"></span> 
                                                <input 
                                                    ref={this.InputVideo}
                                                    type="file" 
                                                    name="uploadVideo" 
                                                    id="uploadVideo" 
                                                    accept="video/mp4, video/3gp, video/ogg, video/flv, video/avi, video/wmv, video/quicktime" 
                                                    onChange={(e)=>{this.handleVideoChange(e)}} /> 
                                                <span className="button-label">Vidéo</span>
                                            </label>
                                        </li>
                                        <ReactTooltip id="chatVdoUpload" place="top" type="dark" effect="solid">
                                            Video
                                        </ReactTooltip>
                                        <li data-tip data-for="chaFileUpload">
                                            <label htmlFor="uploadFiles">
                                                <span className="fa fa-files-o"></span> 
                                                <input 
                                                    ref={this.InputFiles}
                                                    type="file" 
                                                    name="uploadFiles" 
                                                    id="uploadFiles" 
                                                    accept=".ppt,.pptx,.xls,.xlsx,.csv,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                                                    onChange={(e)=>{this.handleFileChange(e)}} /> 
                                                <span className="button-label">Fichiers</span>
                                            </label>
                                        </li> 
                                        <ReactTooltip id="chaFileUpload" place="top" type="dark" effect="solid">
                                            Fichier
                                        </ReactTooltip>
                                        <li data-tip data-for="chatGifUpload" onClick={()=>{if(this.state.showGifPicker === false) this.setState({showGifPicker: true})}} className={this.state.showGifPicker ? 'active GifBtnchat' : 'GifBtnchat'}>
                                            <span className="gif">
                                                <span className=""><span className="custom-icon custom-icon-gif"></span></span>
                                                {
                                                    this.state.showGifPicker &&
                                                    <div className="icons-list">
                                                        <div className="overley" onClick={()=>{this.setState({showGifPicker: false})}}></div>
                                                        <Picker placeholder="Rechercher" apiKey="u3PkS4gInlk051bpDTIbPo7Ga9H6ZDc3" onSelected={(gif)=>{this.selectGif(gif)}} style={{position : 'fixed'}} />
                                                    </div>
                                                }
                                            </span>
                                        </li>
                                        <ReactTooltip id="chatGifUpload" place="top" type="dark" effect="solid">
                                            Gif
                                        </ReactTooltip>
                                        <li data-tip data-for="chatEmogieUpload" onClick={()=>{if(this.state.showEmojiPicker === false) this.setState({showEmojiPicker: true})}} className={this.state.showEmojiPicker ? 'active EmojiBtnchat' : 'EmojiBtnchat'}>
                                            <span className="imoticones">
                                                <span className="fa fa-smile-o"></span>

                                                {this.state.showEmojiPicker &&
                                                    <div className="icons-list">
                                                        <div className="overley" onClick={()=>{this.setState({showEmojiPicker: false})}}></div>
                                                        <PickerEmoji onEmojiClick={(e, data)=>{this.selectEmogi(e, data)}} style={{position : 'fixed'}} />
                                                    </div>
                                                }
                                            </span>
                                        </li> 
                                        <ReactTooltip id="chatEmogieUpload" place="top" type="dark" effect="solid">
                                            Emoji
                                        </ReactTooltip>
                                    </ul>
                                </div>
                                
                                <div className="d-flex">
                                    <div className="attachements"> 
                                        <button onClick={()=>{this.setState({attachementsOptions: !this.state.attachementsOptions})}} className={this.state.attachementsOptions ? 'active': ''}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                    <div className={`input-field ${this.state.gif || this.state.file ? 'gif-preview-active' : ''}`}> 
                                                
                                        {
                                            this.state.progressUpload > 0 &&
                                            <div className="progress-bar">
                                                <div style={{width: this.state.progressUpload + '%'}}>
                                                    <span>{this.state.progressUpload + '%'}</span>
                                                </div>
                                            </div>
                                        }
                                        <div className="attachements-preview">
                                            {
                                                this.state.gif &&
                                                <div className="image-preview">
                                                    <span onClick={()=>{this.setState({gif: null})}} className="fa fa-times remove"></span> 
                                                    <div className="img" style={{backgroundImage: `url(${this.state.gif.thumbs})`}}></div>
                                                </div>
                                            }
                                            {
                                                this.state.file && this.state.file.type == 'image' &&
                                                <div>
                                                    {this.state.file.thumbs.map((imageUrl, index)=>{
                                                        return(
                                                            <div className="image-preview">
                                                                <span onClick={()=>{this.removeSelectedFile(index)}} className="fa fa-times remove"></span> 
                                                                <div className="img" style={{backgroundImage: `url(${imageUrl})`}}></div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {
                                                this.state.file && this.state.file.type == 'video' &&
                                                <div>
                                                    {this.state.file.thumbs.map((videoName, index)=>{
                                                        return(
                                                            <div className="image-preview">
                                                                <span onClick={()=>{this.removeSelectedFile(index)}} className="fa fa-times remove"></span>  
                                                                <img src={configs.GetImage('icon-video.png')} alt="" style={{height: 50}} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }

                                            {
                                                this.state.file && this.state.file.type == 'file' &&
                                                <div>
                                                    {this.state.file.thumbs.map((fileName, index)=>{
                                                        return(
                                                            <div className="image-preview">
                                                                <span onClick={()=>{this.removeSelectedFile(index)}} className="fa fa-times remove"></span>  
                                                                <img src={configs.GetImage(configs.GetFileIcon(fileName.split('.').pop()))} alt="" style={{height: 50}} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                        <TextareaAutosize  ref={this.textarea}  value={this.state.textMessage} onKeyDown={(e)=>{this.keyDown(e)}} onChange={(e)=>{this.setState({textMessage: e.target.value})}} rows="1" autoFocus placeholder="Aa"/>
                                    </div>
                                    <div className="send-btns">
                                            {
                                                (
                                                    this.state.textMessage ||
                                                    this.state.gif ||
                                                    this.state.file
                                                ) ?
                                                <span onClick={()=>{this.sendMessage()}} className="custom-icon custom-sendMessage"></span>
                                                :
                                                <span onClick={()=>{this.setState({textMessage: '<span class="like">👍</span>'}, ()=>{this.sendMessage()})}} className="emoji-like">
                                                    👍
                                                </span>
                                            }
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    }
                    {
                        this.state.showChatRoom && this.state.showPanelControl &&
                        <div className="panel-control">
                            <div className="panel-control-head"> 
                                <span>Paramètres </span>
                                {
                                    this.state.showUsersListToAdd 
                                    ?
                                        <button onClick={()=>{this.setState({showUsersListToAdd: false})}}> 
                                            <i className="fa fa-times" aria-hidden="true"></i>  
                                        </button>
                                    :
                                        <button onClick={()=>{this.setState({showPanelControl: false})}}> 
                                            <i className="fa fa-times" aria-hidden="true"></i>  
                                        </button>
                                }
                                
                            </div>
                            <div className="panel-control-body">
                                {
                                    this.state.chat.infos 
                                    ?
                                        <ul className="settings">
                                            <li className="notifs" onClick={()=>{this.changeNotificationStatus()}}>
                                                Mode silencieux 
                                                {
                                                    this.state.chat.infos.statutnotif
                                                    ?
                                                        <i className="fa fa-bell-o" aria-hidden="true"></i>
                                                    :
                                                        <i className="fa fa-bell-slash-o" aria-hidden="true"></i>
                                                }
                                            </li>
                                            {
                                                this.state.chat?.infos?.adminconvert
                                                ?
                                                    <li onClick={()=>{this.setState({deleteConversationConfirmation: true})}}>
                                                        Supprimer la conversation
                                                    </li>
                                                :
                                                    <li onClick={()=>{this.setState({leaveConversationConfirmation: true})}}>
                                                        Quitter la conversation
                                                    </li>
                                            }
                                            
                                        </ul>
                                    :
                                        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
                                }
                                
                                <h3>
                                    Participants
                                    {
                                        this.state.chat?.infos?.adminconvert && this.state.chat?.members?.length<10 && this.state.chat?.infos?.group &&
                                        <button className="add-contact" onClick={()=>{this.setState({showUsersListToAdd: true})}}>
                                            Ajouter un contact 
                                        </button>
                                    } 
                                </h3>
                                {
                                    this.state.chat.members &&
                                    <ul className="users-list"> 
                                        {this.state.chat.members.map(item=>{
                                            let user = item.user 
                                            return( 
                                                <li>
                                                    <a href onClick={(e)=>{}}>
                                                        <figure 
                                                            style={user.images ? {backgroundImage: 'url('+ Global.API_URL + user.images.image+')'} : {}}>
                                                            {!user.images ? user?.profile?.prenom?.charAt(0)+user?.profile?.nom?.charAt(0) : ''}
                                                        </figure>
                                                        {/* <h3>
                                                            {user?.profile?.prenom} {user?.profile?.nom}
                                                        </h3> */}
                                                        <div className="userInfoChatList">
                                                            <h3>{user?.profile?.prenom} {user?.profile?.nom} </h3>
                                                            { user?.profile?.fonction && user?.profile?.fonction != '' &&
                                                                <abbr>{user?.profile?.fonction}</abbr>
                                                            }
                                                        </div>
                                                        {
                                                            user.showLoader &&
                                                            <ClipLoader className="loader" color={"#aaa"} loading={true} size={15} />
                                                        } 
                                                        {
                                                            this.state.chat?.infos?.adminconvert && user.id !== this.state.currentUser.user_id && this.state.chat?.infos?.group &&
                                                            <button onClick={()=>{this.removeUserFromDiscussion(user)}}>Retirer</button>
                                                        }
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul> 
                                } 
                                {  
                                    this.state.showUsersListToAdd > 0 &&
                                    <div className="add-user-toconversation">
                                        <div className="search-bar">
                                            <div className="input">
                                                <label className="fa fa-search" htmlFor="search-input"></label>
                                                <input type="search" className="input-field" placeholder="Rechercher..." onChange={(e)=>{this.setState({searchUsersInputToAdd: e.target.value})}} value={this.state.searchUsersInputToAdd} />
                                            </div>
                                        </div>
                                        <ul className="users-list"> 
                                            {

                                                this.state.usersList.filter( (user)=>{ 
                                                        const isUserInGroup = ()=>{ 
                                                            return this.state.chat.members.find((u)=>{return u.user.id == user.id })
                                                        }
                                                        let get = simpleTextSearch([user.profile?.prenom+" "+user?.profile?.nom, user.email])
                                                        return get(this.state.searchUsersInputToAdd).length > 0 && !isUserInGroup()
                                                    }).map((user, key)=>{  
                                                    return( 
                                                        <li>
                                                            <a href onClick={(e)=>{}}>
                                                                <figure 
                                                                    style={user.images ? {backgroundImage: 'url('+ Global.API_URL + user.images.image+')'} : {}}>
                                                                    {!user.images ? user?.profile?.prenom?.charAt(0)+user?.profile?.nom?.charAt(0) : ''}
                                                                </figure>
                                                                <h3>{user?.profile?.prenom} {user?.profile?.nom}</h3>
                                                                {
                                                                    user.showLoader &&
                                                                    <ClipLoader className="loader" color={"#aaa"} loading={true} size={15} />
                                                                } 

                                                                {
                                                                    this.state.chat?.members?.length<10?
                                                                    <button onClick={()=>{this.addUserToDiscussion(user)}}>Ajouter</button>
                                                                    :
                                                                    <button className='desableBtn' title='La messagerie chate ne peut contenir que 10 personne'>Ajouter</button>
                                                                } 
                                                            </a>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul> 
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                {
                    this.state.lightBox.type === 'image' && this.state.lightBox.open &&
                    <Lightbox
                        mainSrc={Global.API_URL + this.state.lightBox.images[this.state.lightBox.index]} 
                        nextSrc={Global.API_URL + this.state.lightBox.images[(this.state.lightBox.index + 1) & this.state.lightBox.images.length]}
                        prevSrc={Global.API_URL + this.state.lightBox.images[(this.state.lightBox.index - 1) & this.state.lightBox.images.length]}
                        onMovePrevRequest={() =>
                            this.setState({
                              lightBox:{
                                  ...this.state.lightBox,
                                  index: (this.state.lightBox.index + this.state.lightBox.images.length - 1) % this.state.lightBox.images.length,
                              }
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                lightBox:{
                                    ...this.state.lightBox,
                                    index: (this.state.lightBox.index + this.state.lightBox.images.length + 1) % this.state.lightBox.images.length,
                                }
                              })
                        }
                        onCloseRequest={()=>{this.setState({lightBox:{open: false}})}}
                        reactModalStyle={{zIndex:100000}}  
                        animationDisabled={true}
                    />
                } 

                {
                    this.state.deleteConversationConfirmation && 
                    <div className="_modal confirmation" style={{zIndex: 99999999}}>
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer cette conversation ?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={()=>{this.setState({deleteConversationConfirmation: false})}} className="button wt lg">Annuler</button>
                                    <button onClick={(e)=>{this.deleteConversation(e)}} ref={this.DeleteBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Supprimer
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.leaveConversationConfirmation && 
                    <div className="_modal confirmation" style={{zIndex: 99999999}}>
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir quitter cette conversation ?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={()=>{this.setState({leaveConversationConfirmation: false})}} className="button wt lg">Annuler</button>
                                    <button onClick={(e)=>{this.leaveConversation(e)}} ref={this.DeleteBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Quitter
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
{
                    this.state.showReactionPopin && this.state.reactionsType === 1 &&
                    <div className="_modal reactions Chat">
                        <div className="_overlay" onClick={()=>this.setState({showReactionPopin: false, reactions: [] })} ></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Lu par</h2>
                                </div>
                                <div className="_modal-body"> 
                                    <div>
                                        {
                                            this.state.loadReaction  &&
                                                <center style={{display: 'block', width: '100%', padding: '20px'}}>
                                                    <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                                </center>
                                        }
                                        {
                                            !this.state.loadReaction && this.state.reactions.length == 0 &&
                                                <center style={{display: 'block', width: '100%', padding: '20px'}}>
                                                    Personne n'a vu ton message
                                                </center>
                                        }
                                        {
                                            this.state.reactions.length > 0 &&
                                            <ul className="users-list share">
                                                    {this.state.reactions.map(reaction=>{
                                                        return(
                                                            <li>
                                                                <a href>
                                                                    <figure className="" style={reaction?.images?.image ? {
                                                                        backgroundImage: 'url('+ Global.API_URL + reaction?.images?.image + ')'
                                                                    } : {}}>
                                                                    {reaction?.images?.image ? '': reaction.nom.charAt(0)}
                                                                    </figure>
                                                                    <div>
                                                                        <h3 className="user-name">{reaction.profile.prenom} {reaction.profile.nom}</h3>
                                                                        <p>{reaction.profile.fonction}</p>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        )
                                                    })}
                                            </ul>
                                        } 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                }
            </div>
        )
    }
}

ChatList.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(ChatList));