import React, { useEffect, useState } from 'react'  
import Global from '../../../inc/Global'
import Axios from 'axios';  

//components
import {Form, Row, Col, Button, Dropdown} from 'react-bootstrap'; 
import BeatLoader from "react-spinners/BeatLoader";


const BreakingNews = (props)=>{

    //State 
    const [isActive, setActive] = useState(false) 
    const [content, setContent] = useState([])  
    const [updatingBreakingNews, setUpdatingBreakingNews] = useState(false)  
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => { 
        getBreakingNews()
    }, []); 

    const getBreakingNews = ()=>{
        Axios.get(Global.API_URL + "/getbreaking").then((res) => {
            if( res.data.result ){
                setContent(res.data.result.breakingnews)
            }
            if( res.data.status ){
                setActive(true)   
            } 
            setLoaded(true);
        })
    } 

    const changeBreakingNewsStatus = ()=>{
        setActive(!isActive) 
        Axios.post(Global.API_URL + "/api/desactanimation/3", {}).then((res) => {
            if( res.data.status ){
                getBreakingNews()
                updateBreakingNewsView()
            } 
           
        })
    }

    const onUpdate = (e)=>{
        e.preventDefault()    
        setUpdatingBreakingNews(true)
        Axios.post(Global.API_URL + "/api/admin/breaking",{
            breakingnews: content
        }).then((res) => {
            setUpdatingBreakingNews(false)
            updateBreakingNewsView()
        })
    }  

    const updateBreakingNewsView = ()=>{
        import('../../header/BreakingNews').then(_breakingNews=>{ 
            _breakingNews.default._getBreakingNews()
        })
    }
    if( !isLoaded ) return (
        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
    )
    else return(
        <div className="administration-headband">
            <h2>Breaking-News</h2>
            <p>Permet de défiler des informations.</p>
            
            <div className="activate-option d-flex jc-space-b align-center">
                <label htmlFor="_anonyme-parti">Afficher le Breaking-News</label>

                <div className="param d-flex align-center">
                    <div className="radio-button">
                        <input type="checkbox" onChange={(e) => {changeBreakingNewsStatus()}} name="_anonyme-parti" id="_anonyme-parti" checked={isActive} />
                        <label htmlFor="_anonyme-parti">
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            
            <Form onSubmit={ onUpdate } id="add-headband">  
                <Row> 
                    <Col>
                        <Form.Group>
                            <Form.Label>Contenu</Form.Label> 
                            <Form.Control as="textarea" rows={10} value={content} onChange={(e)=>{setContent(e.target.value)}} />   
                        </Form.Group> 
                    </Col> 
                </Row> 
                <div className="submit-container">  
                    <Button variant="primary" type="submit" disabled={updatingBreakingNews}>
                        Modifier
                        <BeatLoader color={"#aaa"} loading={updatingBreakingNews} size="10"/>
                    </Button>
                </div>
            </Form> 
        </div>
    )
}

export default BreakingNews