import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import '../../assets/css/connexion.css';
import InputField from '../InputField.js'
//import Validation from '../../inc/Validation.js'
import { loginUser, IsLoggedInForFirstTime } from "../../actions/authentication";
import SHA1 from 'sha1';
import Global from '../../inc/Global';
var self
class Connexion extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {},
            HasNotLoggedIn: false,
            appLogo: Global.APP_LOGO,
            appSlogan: Global.LOGIN_TEXT
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        self = this
    }

    static setAppLogo(logo){ 
        if( self ){
            self.setState({
                appLogo: logo
            })
        }
    }
    static setAppSlogan(slogan){ 
        if( self ){
            self.setState({
                appSlogan: slogan
            })
        }
    }
    
    handleInputChange(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        // console.log(e);
        const user = {
            username: this.state.email,
            password: SHA1(this.state.password),
        };

        this.props.loginUser(user);
    }

    componentDidMount() {
        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.IsLoggedInForFirstTime(this.state.email, this.props, true);
        }else{
            this.setState({ HasNotLoggedIn : true});
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }
    }

    render() {
        // console.log(this.props.auth.isAuthenticated);
        if (this.props.auth.isAuthenticated) {
            const decoded = jwt_decode(localStorage.jwtToken);
            console.log('this.props.auth.isAuthenticated',decoded);

            this.props.IsLoggedInForFirstTime(decoded.username, this.props, true);
            return "";
        }

        const { errors, HasNotLoggedIn } = this.state;
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="">
                                            <form id="_login_Form" onSubmit={this.handleSubmit} style={{maxWidth: '440px', margin: 'auto'}}>
                                                <center className="logo-with-hashtag">
                                                    <a href="javascript:void(0)">
                                                        <img  src={this.state.appLogo} alt="" />
                                                    </a>
                                                </center>
                                                <div className="bg-white-transparent text-center"> 
                                                    <div className="entry-welcome">
                                                        <div className="welcome-text">
                                                            <p dangerouslySetInnerHTML={{__html:Global.LOGIN_TEXT}}></p>
                                                        </div>
                                                        
                                                        <InputField 
                                                            type="text" 
                                                            id="_username" 
                                                            name="email" 
                                                            label="Identifiant" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer="" 
                                                            value={this.state.email} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "L'email est obligatoire": errors.email,
                                                            })} />

                                                        <InputField 
                                                            type="password" 
                                                            id="_password" 
                                                            name="password" 
                                                            label="Mot de passe" 
                                                            fixedLabel="false" 
                                                            classInput="" 
                                                            classContainer="" 
                                                            value={this.state.password} 
                                                            oninput={this.handleInputChange} 
                                                            errorMsg={classnames({
                                                                "Le mot de passe est obligatoire": errors.password,
                                                            })} />

                                                        {HasNotLoggedIn && (
                                                            <div className="_alert _alert-danger">E-mail et/ou mot de passe incorrect(s)</div>
                                                        )}
                                                            
                                                        <div className="forgot-password">
                                                            <Link to="/forget">Mot de passe oublié ?</Link>
                                                        </div>
                                                    </div>
                                                    <input type="submit" className="button lg full m-t-10" value="Connexion" />
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Connexion.propTypes = {
    loginUser: PropTypes.func.isRequired,
    IsLoggedInForFirstTime: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, IsLoggedInForFirstTime })(Connexion);