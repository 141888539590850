import React, { Component } from 'react'
import configs from '../../../inc/helpers'
import '../../../assets/css/user.css'
import InputField from '../../InputField'
import Validator from '../../../inc/Validation'
import axios from 'axios'
import Global from '../../../inc/Global.js'
import classnames from 'classnames'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import jwt_decode from 'jwt-decode'
import { base64StringtoFile, extractImageFileExtensionFromBase64, image64toCanvasRef} from '../../../inc/ImageLib';
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import heic2any from "heic2any";
import { BeatLoader } from 'react-spinners'


const imageMaxSize = 5242880 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, image/heic'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

class EditUser extends Component {
    
    constructor(props){
        super(props)

        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.id === "undefined"){
            return;
        }
        
        const params = this.props.location.state;
        console.log(params)
        this.state = {
            id: params.id,
            name: params.name,
            lastname: params.lastname,
            email: params.email,
            isExist: false,
            job: params.job,
            casecoche: params.casecoche,
            phone: params.tel,
            photo: params.photo,
            errors: {},
            HasNotLoggedIn: false,
            dateIsCorrect : "",
            imgSrc : null,
            dragIsActive : false,
            imgSrcExt: null,
            profilSrc : Global.API_URL  + params.photo,
            imgFile : null,
            orgWidthAspect: null,
            crop :{ 
                aspect: 1/1,
                unit: '%'
            },
            pageloading : false
        };

        this.canvasRef = React.createRef(null);
        this.acceptBtn = React.createRef(null);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    handleInputChange (e){
        // console.log(e)
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleSubmit (event){
        event.preventDefault();
        var Valid = true;
        var errors = {
            name : false,
            lastname : false,
            job : false,
            email : false,
            phone : false,
        };

        if(!this.state.name){
            Valid = false;
            errors['name'] = true;
        }else{
            errors['name'] = false;
        }
        if(!this.state.lastname){
            Valid = false;
            errors['lastname'] = true;
        }else{
            errors['lastname'] = false;
        }
         
        if(!Validator.Email(this.state.email)){
            Valid = false;
            errors['email'] = true;
        }else{
            errors['email'] = false;
        }

        this.setState({
            errors : errors
        })


        if(Valid){
            const  data = {
                user:{
                  email: this.state.email,
                  id: this.state.id
                },
                profile:{
                    fonction: this.state.job,
                    nom: this.state.name,
                    prenom: this.state.lastname,
                    telephone: this.state.phone,
                    casecoche: this.state.casecoche,
                }
            }
            axios
            .put(Global.API_URL+"/api/admin/update", data)
            .then((res) => {
                // console.log(res)
                if(res.data.status){ 
                    this.handleAcceptButton(event)
                }else{
                    this.setState({
                        isExist: true
                    })
                }
            })
            .catch((err) => {
                // console.log("has error !!")
            });
        }
    }
    
    handleOnCropChange(crop){
        crop.aspect = 1;
        this.setState({crop:crop});
    }
    handleSaveClick = async event => {
        event.preventDefault()
        event.target.classList.add('on');
        
        const {imgSrc}  = this.state
        if (imgSrc) {
        
            const canvasRef = this.canvasRef.current
        
            const {imgSrcExt} =  this.state
            const imageData64 = canvasRef.toDataURL('image/' + imgSrcExt)

            const decode = jwt_decode(localStorage.jwtToken)
            const myFilename = "profile-"+ decode.username +"." + imgSrcExt

            // file to be uploaded
            const myNewCroppedFile = base64StringtoFile(imageData64, myFilename)
            
            
            await this.setState({
                profilSrc : imageData64,
                imgFile : myNewCroppedFile,
                imgSrc : null,
            }); 
            // console.log(this.state.imgFile);
           
        }
    }

    handleAcceptButton = (event) => {
        event.preventDefault();
        //var $target = event.target
        

        var data = new FormData();
        data.append('image',this.state.imgFile);
        //data.append('check', localStorage.jwtToken);
        
        
        axios
        .post(Global.API_URL+"/api/profil/imagewithemail/"+this.state.email, 
            data, 
            { headers: {
                'content-type': 'multipart/form-data'
                }
            }
        )
        .then((res) => { 

            this.acceptBtn.current.classList.remove('on');
            this.props.history.push({
                pathname: '/utilisateurs',
                state: { modifiedUser: this.state.id }
            });
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.acceptBtn.current.classList.remove('on');
        });
    }
    handleOnCropComplete = (crop, percentCrop) =>{
        const canvasRef = this.canvasRef.current
        const {imgSrc}  = this.state
        
        const newCrop = {
          axpect : 1,
          x: (crop.x * this.state.orgWidthAspect),
          y: (crop.y * this.state.orgWidthAspect),
          width: (crop.width * this.state.orgWidthAspect),
          height: (crop.height * this.state.orgWidthAspect)
        };
        image64toCanvasRef(canvasRef, imgSrc, newCrop)
    }
    handleImageLoaded = async image => {
        await this.setState({
            orgWidthAspect: image.naturalWidth / image.width
        });

        
        if(image.width > image.height){
            await this.setState({ crop: {width: (image.height - 50), height: (image.height - 50), x :25, y: 25 } });
        }else{
            await this.setState({ crop: {width: (image.width - 50), height: (image.width - 50), x :25, y: 25 } });
        }
        
        const canvasRef = this.canvasRef.current
        const {imgSrc}  = this.state
        
        const newCrop = {
            axpect : 1,
            x: (this.state.crop.x * this.state.orgWidthAspect),
            y: (this.state.crop.y * this.state.orgWidthAspect),
            width: (this.state.crop.width * this.state.orgWidthAspect),
            height: (this.state.crop.height * this.state.orgWidthAspect)
        };
        
        image64toCanvasRef(canvasRef, imgSrc, newCrop)

        return false; // Return false when setting crop state in here.
    };
    
    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.file.type
            const currentFileSize = currentFile.file.size
            
            if(currentFileSize > imageMaxSize) {
                alert("Ce fichier n'est pas autorisé, " + currentFileSize + " octets est trop grand")
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                alert("Ce fichier n'est pas autorisé. Seules les images sont autorisées.")
                return false
            }
            return true
        }
    }
    
    handleOndrop  =  (files, rejectedFiles) => {
        this.setState({
            pageloading : true
        })
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }
        
        if (files && files.length > 0){
            //const isVerified = this.verifyFile(files)
        
            // imageBase64Data 
            const currentFile = files[0]
            const myFileItemReader = new FileReader()
            
            myFileItemReader.addEventListener("load", async ()=>{
                
                const myResult = myFileItemReader.result

                let imageNew = myResult;

                if(extractImageFileExtensionFromBase64(myResult) == 'heic'){

                    let blobURL = imageNew;

                    // convert "fetch" the new blob url
                    let blobRes =  await fetch(blobURL)
                    

                    // convert response to blob
                    let blob =  await blobRes.blob()
        
                    // convert to PNG - response is blob
                    var url ;
                    await heic2any({ 
                         blob: blob,
                        toType: "image/jpg",
                     })
                     .then(function (resultBlob) {
                        url = URL.createObjectURL(resultBlob);
                    })
                    .catch(function (x) {
                        console.log(x.code);
                        console.log(x.message);
                    });

                    this.setState({
                        imgSrc: url,
                        imgSrcExt: extractImageFileExtensionFromBase64(myResult),
                        dragIsActive : false,
                        pageloading : false
                    })
        
                } else{
                    this.setState({
                        imgSrc: myResult,
                        imgSrcExt: extractImageFileExtensionFromBase64(myResult),
                        dragIsActive : false,
                        pageloading : false
                    })
                }

            }, false)
            
            myFileItemReader.readAsDataURL(currentFile)
            
        }
    }
    
    handleOnDragEnter = (DragEvent) => {
        this.setState({dragIsActive : true});
    }
    handleOnDragLeave = (DragEvent) => {
        this.setState({dragIsActive : false});
    }

    render() {
        
        if(typeof this.props.location.state === "undefined" ){
            this.props.history.push('/utilisateurs')
            return "";
        }else if(typeof this.props.location.state.id === "undefined"){
            this.props.history.push('/utilisateurs')
            return "";
        }
    
        return (
            <section className="content">
                <div className="wrap">
                    <div className="header-list">
                        <div className="d-flex jc-space-b align-center">
                            <h3 className="h3"> 
                                <center>
                                    <Link onClick={(e) => {e.preventDefault(); this.props.history.goBack()}}>
                                            <span className="fa fa-long-arrow-left"></span>
                                    </Link> 
                                    Modification utilisateur
                                </center>
                            </h3>  
                        </div>
                    </div> 
                    <div className="add-user"> 
                        <form action="" onSubmit={this.handleSubmit}>
                            <div className="form">
                                <InputField 
                                    type="text" 
                                    id="_lastname" 
                                    name="lastname" 
                                    label="Prénom" 
                                    fixedLabel="false" 
                                    classInput="" 
                                    classContainer={classnames({
                                        " hasError ": this.state.errors.lastname,
                                    })} 
                                    value={this.state.lastname} 
                                    oninput={this.handleInputChange} 
                                    errorMsg={classnames({
                                        "Le prénom est obligatoire": this.state.errors.lastname,
                                    })} />
                                <InputField 
                                    type="text" 
                                    id="_name" 
                                    name="name" 
                                    label="Nom" 
                                    fixedLabel="false" 
                                    classInput="" 
                                    classContainer={classnames({
                                        " hasError ": this.state.errors.name,
                                    })} 
                                    value={this.state.name} 
                                    oninput={this.handleInputChange} 
                                    errorMsg={classnames({
                                        "Le nom est obligatoire": this.state.errors.name,
                                    })} />

                                
                                
                                
                                <InputField 
                                    type="text" 
                                    id="_email" 
                                    name="email" 
                                    label="Email" 
                                    fixedLabel="false" 
                                    classInput="" 
                                    classContainer={classnames({
                                        " hasError ": this.state.errors.email || this.state.isExist,
                                    })} 
                                    value={this.state.email} 
                                    oninput={this.handleInputChange} 
                                    errorMsg={classnames({
                                        "l'email est obligatoire et il doit respecter ce format example@email.com": this.state.errors.email,
                                        "Cette adresse email est déjà associée un compte" : !this.state.errors.email && this.state.isExist
                                    })} />
                                
                                <InputField 
                                    type="text" 
                                    id="_job" 
                                    name="job" 
                                    label="Fonction" 
                                    fixedLabel="false" 
                                    classInput="" 
                                    classContainer=""
                                    value={this.state.job} 
                                    oninput={this.handleInputChange} 
                                    errorMsg="" />

                                <InputField 
                                    type="text" 
                                    id="_phone" 
                                    name="phone" 
                                    label="Téléphone" 
                                    fixedLabel="false" 
                                    classInput="" 
                                    classContainer={classnames({
                                        " hasError ": this.state.errors.phone,
                                    })}  
                                    value={this.state.phone} 
                                    oninput={this.handleInputChange} 
                                    errorMsg={classnames({
                                        "Le téléphone est invalide (0X00000000 ou +XXX0000000)": this.state.errors.phone,
                                    })} />

                                    <div className="setting-rad d-flex jc-space-b align-center p-20">
                                        <label htmlFor="_anonyme-parti">Autorisé à créer des communautés</label>

                                        <div className="param d-flex align-center">
                                            <div className="radio-button">
                                                <input 
                                                    type="checkbox" 
                                                    onChange={(e) => {this.setState({casecoche: !this.state.casecoche})}} 
                                                    name="_anonyme-parti" id="_anonyme-parti" checked={this.state.casecoche} test={"--" + this.state.casecoche} />
                                                <label htmlFor="_anonyme-parti">
                                                    <span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                
                                <div className={this.state.dragIsActive ? "tab _p-photo d-flex align-center jc-center on" : "tab _p-photo d-flex align-center jc-center"}>
                                    <div className="select-profil">
                                        {this.state.imgSrc && 
                                            <div className="_modal" id="CropImageModal">
                                                <div className="_overlay"></div>
                                                <div className="_modal-wrap">
                                                    <div className="_modal-content">
                                                        <div className="_modal-header">
                                                            <h2>Recadrer l'image</h2>
                                                        </div>
                                                        <div className="_modal-body">
                                                            <div className="d-flex f-wrap"> 
                                                                <div className="rightSidebar">
                                                                    <canvas ref={this.canvasRef}></canvas>
                                                                </div>
                                                                <div className="_modal-body-wrap crop">
                                                                    <ReactCrop 
                                                                        src={this.state.imgSrc} 
                                                                        crop={this.state.crop} 
                                                                        onChange={this.handleOnCropChange.bind(this)} 
                                                                        onComplete={this.handleOnCropComplete} 
                                                                        onImageLoaded={this.handleImageLoaded}
                                                                        />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="_modal-footer text-right">
                                                            <span onClick={this.handleSaveClick} className="button lg"><span className="fa fa-spinner fa-spin"></span> Recadrer</span>
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                        }
                                        
                                        <Dropzone 
                                            accept={acceptedFileTypes} 
                                            onDrop={this.handleOndrop} 
                                            multiple={false} 
                                            maxSize={imageMaxSize}
                                            onDragEnter={this.handleOnDragEnter}
                                            onDragLeave={this.handleOnDragLeave} >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                 {
                                                    this.state.pageloading &&
                                                    <div className='pageloading'>
                                                        <center><BeatLoader color={"#aaa"} loading={true} size="10"/></center>
                                                    </div>
                                                }
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                        <span className="img" 
                                                            style={this.state.profilSrc ? 
                                                                {backgroundImage: 'url('+this.state.profilSrc+')' } : 
                                                                {backgroundImage: 'url('+configs.GetImage('user.png')+')' }
                                                            }>
                                                    </span>
                                                    <p style={{maxWidth : '320px'}}>Faites glisser et déposez une photo de profil ici, ou cliquez pour la sélectionner</p>
                                                    <label className="label">Charger une photo</label>
                                                </div>
                                            </section>
                                        )}
                                        </Dropzone>
                                    </div>
                                </div>
                                <div style={{padding: '0 15px', marginTop:30}}>
                                    <div className="d-flex jc-space-b m-t-10">
                                        <Link to="/utilisateurs" className="button wt lg" >Annuler</Link>
                                        <input ref={this.acceptBtn} type="submit" className="button lg" value="Modifier" />
                                    </div>
                                </div>  
                            </div>
                            
                        </form>
                    </div>
                </div>
            </section>
        )
    }

}

EditUser.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(EditUser));