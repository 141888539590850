import React, { Component } from 'react'
import { ReactTinyLink } from 'react-tiny-link'
import Validation from '../../../inc/Validation';
import '../../../assets/css/post-type.css'; 
import { Youtube } from 'reactjs-social-embed'
import { Vimeo } from 'reactjs-social-embed'
import { Facebook } from 'reactjs-social-embed'
import { Twitter } from 'reactjs-social-embed'
import CustomLinkPreview from './inc/CustomLinkPreview';






export default class LinkView extends Component {
    
    constructor (props){
        super(props);

        const {link} = this.props; 
        
        this.state = {
            link : link && link.match(/(https?:\/\/[^\s]+)/g) ? link.match(/(https?:\/\/[^\s]+)/g) : null
        } 
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            link : nextProps.link.match(/(https?:\/\/[^\s]+)/g) ? nextProps.link.match(/(https?:\/\/[^\s]+)/g) : null 
        })
    } 

    render() {
        let link = this.state.link; 

        if( link && link.length > 1 ) return "";
        else if(link) link = link[0];
        
        if(!link || !Validation.Url(link)){
            return "";
        }
        let domain = link.replace('http://www.','').replace('https://www.','').replace('http://','').replace('https://','').split(/[/?#]/)[0];
        
        let YoutubeVideoID = false;

        let isVideoIframe = false;
        let VimeoVideoID = false;
        let FacebookLink = false;
        let TwitterVideoId = false;
        let InstagramVideoUrl = false;

        
        if(domain === "youtu.be" || domain === "youtube.com" || domain === "www.youtube.com"){
            //isYoutubeVideo = true;
            if (link.match(/(\?|&)v=([^&#]+)/)) {
                YoutubeVideoID = link.match(/(\?|&)v=([^&#]+)/).pop(); 
                isVideoIframe = true;
    
            } else if (link.match('(.be/)+([^/]+)')) {
                YoutubeVideoID = link.match('(.be/)+([^/]+)').pop(); 
                isVideoIframe = true;
    
            } else if (link.match('(embed/)+([^/]+)')) {
                YoutubeVideoID = link.match('(embed/)+([^/]+)').pop(); 
                isVideoIframe = true;
            }  
        }

        if(domain === "vimeo.com"){
            //isYoutubeVideo = true;
            //review
            if (link.match(/(videos|video|review|channels|\.com)\/([\d]+)/)) { 
                VimeoVideoID = link.match(/(videos|video|review|\.com)\/([\d]+)/)[2] 
                isVideoIframe = true;
            } 
        }

        if(domain === "facebook.com" || domain === "fb.com" || domain === 'fb.watch'){
            if( link.match('(videos|watch)+([/]+)') || domain === 'fb.watch' ){
                FacebookLink = link;
                isVideoIframe = true;
            }
        }

        if(domain === "twitter.com"){
            //isYoutubeVideo = true;
            if (link.match('(/status/)+([^/]+)').pop() > 5) { 
                TwitterVideoId =  link.match('(/status/)+([^/]+)').pop();
                isVideoIframe = true;
            }  
        }

        if(domain === "instagram.com"){
            if( link.match('(/p/)+([^/]+)') ){
                InstagramVideoUrl = link;
                isVideoIframe = true;
            } 
        }
        return (
            <div className="link">
                {link &&
                    <div>
                        {   isVideoIframe && 
                            <div>
                                {
                                    YoutubeVideoID &&
                                    <Youtube width="100%" height="366" parentClass="p-container youtube-iframe" iframeClass="v-container" id={YoutubeVideoID} /> 

                                }
                                {
                                    VimeoVideoID &&
                                    <div className="vimeo-iframe">
                                        <Vimeo width="100%" id={VimeoVideoID} />
                                    </div> 

                                }
                                {
                                    FacebookLink &&
                                    <div className="facebook-iframe">
                                        <Facebook type="video" width="100%" height="366" show_text={true} url={FacebookLink} />
                                    </div> 
                                }
                                {
                                    TwitterVideoId && 
                                    <div className="twitter-iframe">
                                        <Twitter id={TwitterVideoId} width="100%"  />
                                    </div> 
                                }
                                {
                                    InstagramVideoUrl &&	 
                                    <div className="instagram-iframe">
                                        <iframe src={InstagramVideoUrl+"embed"}  width="100%" height="700" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
                                    </div>  
                                }
                            </div>
                        }

                        {/* {
                            !isVideoIframe &&
                            <ReactTinyLink
                                cardSize="small"
                                showGraphic={true}
                                maxLine={2}
                                minLine={1}
                                url={link}/>
                        }  */}
                        {
                            !isVideoIframe &&
                            <div className="link-iframe">
                                <CustomLinkPreview link={link}/>
                            </div>
                        }
                        
                        
                    </div>
                } 
            </div>
        )
    }
}