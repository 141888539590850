import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import Lightbox from 'react-image-lightbox';

//Owl carousel
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css'; 
import 'react-owl-carousel2/src/owl.theme.default.css';

import Global from '../../inc/Global';
import VideoView from '../publications/template-parts/VideoView';


import { motion } from "framer-motion"

//CSS
import './_headband.scss'

const Headband = ()=>{

    //State
    const [listing, setListing] = useState([])   
    const [openLightBox, setOpenLightBox] = useState(false)   
    const [srcLightBox, setSrcLightBox] = useState('')   
    const [isHover, setIsHover] = useState(false)   
    const options = {
        autoplay:true,
        autoplayTimeout:4000,
        autoplayHoverPause:true,
        loop:true,
        autoHeight: true,
        items: 1, 
        nav: true,
        dots: true,
        navText:["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"]
    };  

    useEffect(() => { 
        getListing()
    }, []); 

    const getListing = ()=>{
        Axios.get(Global.API_URL + "/bandeau").then((res) => {
            if( res.data.status ) setListing(res.data.result)  
        })
    }  
    const handleCloseGallery = ()=>{
        setSrcLightBox('')
        setOpenLightBox(false)
    }
    const handleShowGallery = (src)=>{
        console.log('src',src);
        console.log('rcLightBox',srcLightBox);
        setSrcLightBox(src)
        setOpenLightBox(true)
        console.log('rcLightBox2',srcLightBox);
    }
    if (listing.length ==0) return ''
    return (
        <motion.div className="home-headband"
        animate={{
            height: isHover ? "auto" : 340,
            paddingBottom : isHover ? "5px" : 0
          }}
        onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
        >
            {
                listing.length > 0 &&
                <OwlCarousel options={options} events={{}}> 
                    {listing.map((item,key)=>(
                        <motion.div key={key}> 
                            {
                                item.bandeau &&
                                // <a href={item.link || '#'} target="_blank">
                                //     <img src={`${Global.API_URL}${item.bandeau}`} style={{borderRadius: 0}}  alt=""/>
                                // </a> 
                                <>
                                    <div className="bondeauImage"  style= {{backgroundImage : 'url(' + Global.API_URL +item.bandeau + ')'}} onClick={()=>{handleShowGallery( Global.API_URL +item.bandeau)}}>
                                        {
                                            item.link &&
                                                <div className='bondeauImageinfosIconText'>
                                                    <div className='bondeauImageVoirePlus'>
                                                        <a href={item.link} target="_blank">
                                                            <ion-icon name="open-outline"></ion-icon> Voir plus
                                                        </a>
                                                    </div>
                                                </div>
                                            // <div className='bondeauImageinfosIcon'>
                                            //       <div className='bondeauImageVoirePlus'>
                                            //         <a href={item.link}>
                                            //             <ion-icon name="open-outline"></ion-icon>
                                            //         </a>
                                            //     </div>
                                            // </div>
                                            // <div className='bondeauImageinfos'>
                                            //       <div className='bondeauImageVoirePlus'>
                                            //         <a href={item.link}>
                                            //             Voir plus
                                            //         </a>
                                            //     </div>
                                            // </div>
                                        }
                                    </div>
                                    {
                                        openLightBox && Global.API_URL +item.bandeau ==  srcLightBox &&
                                        <Lightbox
                                            mainSrc={srcLightBox}
                                            onCloseRequest={()=>{handleCloseGallery(false)}}
                                            reactModalStyle={{
                                                content : {
                                                zIndex: 10000
                                                }
                                            }}
                                            animationDisabled={true}
                                            // imageCaption={( 
                                            //     item.link &&
                                            //         <div className='bondeauImageVoirePluslightbox'>
                                            //             <div className='bondeauImageVoirePlusContainer'>
                                            //                 <a href={item.link}>
                                            //                     Voir plus
                                            //                 </a>
                                            //             </div>
                                            //         </div>
                                            // )}
                                        />
                                    }
                                </>
                                
                            }
                            {
                                item.video &&
                                <VideoView 
                                    videoUrl={Global.API_URL + item.video} 
                                    poster={item.thumbs ? Global.API_URL + "/" + item.thumbs : ""} 
                                />
                            } 
                            {
                                // key="content"
                                // initial="collapsed"
                                // animate="open"
                                // exit="collapsed"
                                // variants={{
                                //   open: { opacity: 1, height: "auto" },
                                //   collapsed: { opacity: 0, height: 0 }
                                // }}
                                item.text &&
                                <div   className="slide-text" dangerouslySetInnerHTML={{__html: item.text}}></div>   
                            }  
                            
                        </motion.div>
                    ))}
                </OwlCarousel>
            } 
        </motion.div>
    )
}

export default Headband;