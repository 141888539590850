import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//import Select from 'react-select';
import Global from '../../inc/Global.js'
import InputField from '../InputField.js';
//import 'react-select/dist/react-select.css';

class InvitationItem extends Component {
    
    render() {
        const { 
            ID,
            Name,
            LastName,
            Photo,
            Job,
            PhoneNum,
            Email,
            handleReject,
            RoleSelect,
            handleAccept
        } = this.props;
        
        const APIURL = Global.API_URL;
        
        const RandColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        return (
            <li className="_ursl-listitem">
                <div className="header-post">
                    <div className="rm-list">
                        <input type="checkbox" id={"user-"+ID} />
                        <label className="toggle" htmlFor={"user-"+ID}>...</label>
                        <div className="rm-list-item">
                            <ul>
                                <li><Link to="" onClick={handleReject} id={ID} >Refuser</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="user-publisher">
                        <figure 
                            className="userimage" 
                            style={Photo ?
                                {backgroundImage: 'url('+ APIURL + Photo + ')'}
                                : 
                                {}
                            } >
                            {!Photo && Name.charAt(0)+LastName.charAt(0) }
                        </figure>
                        <div className="">
                            <div className="username">{LastName.toLowerCase()} {Name.toLowerCase()}</div>
                            <div className="p">
                                <span>{Job}</span><br/>
                                {PhoneNum &&
                                    <span>Téléphone : {PhoneNum} <br/></span>
                                }
                                {Email && 
                                    <span>Email : {Email}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="d-flex jc-space-b align-center">
                    <div className="circle-list">
                        
                    </div>

                    <div className="footer-invitation d-flex align-center">
                        <InputField 
                            type="select" 
                            id={"_role"+ID} 
                            name="role" 
                            label="" 
                            fixedLabel="true" 
                            placeholder=""
                            items={RoleSelect.items}
                            classInput="" 
                            classContainer="" 
                            value={RoleSelect.selectedRole} 
                            selectedValue={RoleSelect.selectedValue} 
                            default={RoleSelect.selectedRole}
                            oninput={RoleSelect.handleSelectChange} 
                            errorMsg="" />
                        <Link className="button" id={"_accept_"+ID} data-id={ID} data-email={Email} onClick={handleAccept}>
                            Accepter
                        </Link>
                    </div>
                </div>
            </li>
        )
    }
}
export default InvitationItem;