import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import logo from '../../assets/img/white-logo.png'
import Global from '../../inc/Global';
import Cookies from 'universal-cookie';


class AcceptTheConditions extends Component {
    constructor(props) {
        super(props);
        this.acceptEvent = this.acceptEvent.bind(this);
    }
    
    acceptEvent(cguVar,rout){
        const cookies = new Cookies();
        cookies.set('cgu', cguVar, { path: '/' });
        this.props.history.push(rout) ;
    }

    render() {
        return (
            <div className="App">
                <div className="section">
                    <div className="max-width">
                        <div className="wrap">
                            <div className="d-flex jc-space-b section-layout">
                                <section className="content connexion">
                                    <div className="wrap">
                                        <div className="_cnu-tabs">
                                            <center className="logo-with-hashtag">
                                                    <a href="javascript:void(0)">
                                                        <img src={Global.APP_LOGO} alt="" />
                                                    </a>
                                            </center>
                                            <h3>Conditions générales d'utilisation</h3>
                                            <div className="bg-white p-10 brs-10">
                                                <div className="tab entry-cdt-general">
                                                    <strong>1 - Je reconnais avoir pris connaissance de la charte d'utilisation de la plateforme</strong> <a class="hyper" href='https://api.peps.wearelinked.fr/cgu/CHARTE.BON.USAGE.PEPS.GRAIN.DE.MALICE.pdf' target='_blank' >[La charte d'utilisation]</a>
                                                    <br />
                                                    <br />
                                                    <strong>2 - Je reconnais avoir pris connaissance de la politique de protection des données personnelles de la plateforme</strong> <a class="hyper" href='https://api.peps.wearelinked.fr/cgu/cgu.pdf' target='_blank' >[RGPD]</a>
                                                    <br /><br />
                                                    <p className='fz12px'> 
                                                        Ephigea collecte et traite vos données personnelles dans le cadre de l'administration et de l'hébergement de la plateforme du réseau social d'entreprise. Cette plateforme vous permet de suivre les actualités de la société et de partager du contenu avec vos collègues. Vous disposez à tout moment des droits d’accès, de rectification, d’effacement ou de portabilité de vos données personnelles, ainsi que des droits de limitation et d’objection au traitement de celles-ci. Vous pouvez également à tout moment déposer une réclamation auprès de la CNIL. Pour plus d’information sur la manière dont Ephigea traite et protège vos données personnelles et les moyens d’exercer vos droits, nous vous invitons à consulter notre politique de confidentialité
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="_cnu-footer-tabs m-t-10">
                                                <div className="d-flex jc-space-b">
                                                    <button onClick={(e)=>{this.acceptEvent(0,'/terminer-inscription/annuler')}} className="button lg icon wt"> 
                                                        Annuler 
                                                    </button>
                                                    <button onClick={(e)=>{this.acceptEvent(1,'/terminer-inscription/profil')}} className="button lg icon"> 
                                                        Accepter 
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AcceptTheConditions;