import React, { Component } from 'react'
import Validation from '../../../inc/Validation';

export default class GifView extends Component {
    constructor(props){
        super(props)

        if(!this.props.gif || !Validation.Url(this.props.gif)){
            this.state = {
                gif : null
            }
            return;
        }
        this.state = {
            gif : this.props.gif
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            gif : nextProps.gif
        })
    }
    render() {
        if(!this.state.gif || !Validation.Url(this.state.gif)){
            return "";
        }


        return (
            <div className="Gif">
                <img src={this.state.gif} loop="infinite" alt="" />
            </div>
        )
    }
}
