import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Item from './Item'
import axios from 'axios'
import Global from '../../../inc/Global.js'
import debounce from "lodash.debounce"; 
import configs from '../../../inc/helpers'
import Algolia from "../../../inc/Algolia";
import PlusCircle from '../../icons/PlusCircle'
import BeatLoader from "react-spinners/BeatLoader";
const simpleTextSearch = require('simple-text-search')


class UsersList extends Component {
    constructor(props){
        super(props)
        var ModifiedUser = false;
        if(this.props.location && this.props.location.state && this.props.location.state.modifiedUser){
            ModifiedUser = this.props.location.state.modifiedUser
        }

        this.state = {
            users : [],
            AllUsers : [],
            limit : 15 ,
            step : 15,
            ModifiedUser : ModifiedUser ? true : false,
            loaded : false,
            allLoaded : false,
            userSearchAlgoliaInput : '',
            oldProfileDetailArr : [],
            profileDetailArr : [],
            page:0,
            Nextpage:0,
            nbHits : 0
        }

        // const [OldProfileDetailArr, setoldProfileDetailArr] = useState([]);
        // const [profileDetailArr, setProfileDetailArr] = useState([]);
        // const [profileTypeAction, setProfileTypeAction] = useState(null);

        this.LoadMore = React.createRef();
        this.searchB = React.createRef();
        // console.log(jwt_decode(localStorage.jwtToken))
        
        // window.onscroll = debounce(() => {
        //     let {limit } = this.state
        //     // console.log('limit1',limit);
        //     // console.log(' window.innerHeight', window.innerHeight);
        //     // console.log(' Math.round(document.documentElement.scrollTop)', Math.round(document.documentElement.scrollTop));
        //     // console.log(' document.documentElement.offsetHeight', document.documentElement.offsetHeight);
        //     if (
        //       window.innerHeight + Math.round(document.documentElement.scrollTop)
        //       === document.documentElement.offsetHeight
        //     ) {
        //         this.setState({
        //             limit : this.state.limit + this.state.step
        //         })
        //         if(this.state.limit >= this.state.users.length){
        //             this.setState({
        //                 allLoaded : true
        //             })
        //         }else{
        //             this.setState({
        //                 allLoaded : false
        //             })
        //         }
        //     }
        // }, 100);
        window.onscroll = debounce(() => {

            if (
                Math.round(window.innerHeight + document.documentElement.scrollTop) + 100 >=  Math.round(document.documentElement.offsetHeight)
            ) {
                
                if(this.state.allLoaded === false ||  this.searchB.current.value !='' ){
                    this.getUser('',this.state.Nextpage,20);
                }
            }
        }, 100); 
    }
    
    componentDidMount(){

        // axios
        // .get(Global.API_URL+"/api/users/0/100000")
        // .then((res) => {
        //     this.formatUserArray(res.data)
        // })
        // .catch((err) => {
        //     // console.log(err.message);
        // });
        // Algolia.searchUserPagination('').then(res=>{
        //     console.log("res=>",res);
        //     this.formatUserArray(res)
        // })
        this.getUser();
    }
    getUser(searchText='',page=0,hitsPerPage=20){
        Algolia.searchUserPagination(searchText,page,hitsPerPage).then(res=>{
            console.log("res=>",res);
            this.formatUserArray(res.hits)
            this.setState({
                page:res.page,
                Nextpage:res.page+1,
                allLoaded : this.state.allLoaded == res.nbPages,
                nbHits : res.nbHits
            })
        })
    }
    formatUserArray(usersArr,newRecordBool=false){
        var users = [];
        var usersChat = [];
        usersArr.forEach(function(item, key) {
            users.push({
                ID : item.id,
                Email : item.email,
                Name : item.profile ? item.profile.nom : "",
                LastName : item.profile ? item.profile.prenom : "",
                Photo : item.images ? item.images.image : "",
                Job : item.profile ? item.profile.fonction : "",
                PhoneNum : item.profile ? item.profile.telephone : "",
                casecoche : (item.profile && item.profile.casecoche === '1') ? true : false
            })
            usersChat.push({
                "id": item.id,
                "email": item.email,
                "profile": {
                    "nom": item.profile ? item.profile.nom : "",
                    "prenom": item.profile ? item.profile.prenom : "",
                    "fonction": item.profile ? item.profile.fonction : "",
                    "telephone":  item.profile ? item.profile.telephone : "",
                },
                "images": {
                    "image": item.images ? item.images.image : '',
                },
            })
        });
        let newRecord = users;
        let newRecordChat = usersChat;
        if (this.state.Nextpage > 0 && !newRecordBool) {
            newRecord = this.state.users.concat(users);
            newRecordChat = this.state.usersChat.concat(usersChat);
        }

        this.setState({
            users :newRecord,
            usersChat:newRecordChat,
            AllUsers :newRecord,
            loaded : true,
        })
        if(!newRecordBool){
            this.setState({
                oldProfileDetailArr :newRecord,
            })
        }
    }
    handleSearchInput= async (event) => {
        event.target.setAttribute('val', event.target.value);

        const users = this.state.AllUsers.filter(item =>{
            let get = simpleTextSearch([item.Name+" "+item.LastName, item.Job, item.Email])
            return get(event.target.value).length > 0
        });
        await this.setState({ users });
        // await this.setState({
        //     limit : 5
        // })

        if(this.state.limit >= this.state.users.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    searchProfileDetailArr = (event)=>{
        let searchText = event.target.value; 
        if (searchText=='') {
            this.setState({
                users : this.state.oldProfileDetailArr
            })
            console.log( this.state.oldProfileDetailArr);
            return;
        }
        Algolia.searchUser(searchText).then(res=>{
            console.log("res=>",res);
            this.formatUserArray(res,true)
            this.setState({
                page:0,
                Nextpage:0,
                allLoaded : false,
                nbHits : res.length
            })
        })
    }; 
    render() {
        // console.log(this.state.allLoaded, this.state.limit, this.state.AllUsers.length)
        return (
            <section className="content">
                <div className="wrap">
                    <div className="users-list">
                        <div className="header-list">
                            <div className="d-flex jc-space-b align-center">
                                <h3>Contacts ({this.state.nbHits})</h3>
                                <div className="">
                                    <ul>

                                        {configs.isAdmin() &&
                                            <li>
                                                <Link to="/admin/utilisateur/ajouter">Créer un utilisateur <PlusCircle /></Link>
                                            </li>
                                        }
                                        {/* <li>
                                            <Link to="" onClick={(e)=> {e.preventDefault(); this.searchB.current.focus()}}><span className="fa fa-search"></span></Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="_srs-users">
                            <input 
                                val="" 
                                ref={this.searchB} 
                                type="search" 
                                name="s"
                                onChange={e => { this.searchProfileDetailArr(e) ; this.setState({userSearchAlgoliaInput:e.target.value}) }} 
                                placeholder="Rechercher"
                            /> 
                            {/* <input val="" ref={this.searchB} type="search" name="s" onChange={this.handleSearchInput} placeholder="Rechercher"/>  */}
                            <Link to="" onClick={(e) => {
                                    e.preventDefault();
                                    this.searchB.current.setAttribute('val', ""); 
                                    this.searchB.current.value = ""; 
                                    this.setState({users : this.state.AllUsers
                                })}} className="button">Annuler</Link>
                        </div>
                        {this.state.modifiedUser ?
                            <div className="_alert _alert-success">L'utilisateur a été modifié avec succès</div>
                            :
                            ""
                        }
                        <div className="_ursl"  id='smalCardUser'>
                            <ul>
                            {/* .slice(0, this.state.limit) */}
                            {this.state.users.map((user, i) =>
                                <Item 
                                    key={user.ID}
                                    ID={user.ID}
                                    Name={user.Name}
                                    LastName={user.LastName}
                                    Photo={user.Photo}
                                    Job={user.Job}
                                    PhoneNum={user.PhoneNum}
                                    Email={user.Email} 
                                    casecoche={user.casecoche} 
                                    user={ this.state.usersChat.filter( (us)=>us.id === user.ID)[0] }
                                    />
                            )}
                            </ul>
                        </div>

                        <div ref={this.LoadMore} className="loadMore">
                            {this.state.allLoaded ?
                                <span className="no-more-data">il n'y a plus de données</span>
                                :
                                <BeatLoader color={"#aaa"} loading={true} size="10"/>
                            }

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default UsersList;