import React, { Component } from 'react'

export default class Share extends Component {
    render() {
        return (
            <span className={this.props.className ? this.props.className : ''}>
                <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xlink="http://www.w3.org/1999/xlink" 
                version="1.1" 
                id="Layer_1" 
                x="0px" y="0px" 
                viewBox="0 0 512 512" 
                style={{enableBackground:'new 0 0 512 512', height: this.props.height, fill: this.props.color}} 
                space="preserve">
                    <g>
                        <g>
                            <path d="M509.013,206.162L332.09,29.24c-2.917-2.917-7.304-3.789-11.115-2.21c-3.81,1.579-6.296,5.299-6.296,9.423v96.287h-49.705    c-70.777,0-137.318,27.562-187.365,77.609C27.562,260.396,0,326.936,0,397.714v77.833c0,4.209,2.586,7.986,6.51,9.509    c1.199,0.466,2.449,0.691,3.687,0.691c2.81,0,5.561-1.163,7.531-3.32l92.966-101.742c50.333-55.084,121.995-86.677,196.611-86.677    h7.374v96.288c0,4.126,2.486,7.844,6.296,9.423c3.811,1.581,8.198,0.706,11.115-2.21l176.923-176.924    c1.912-1.912,2.987-4.507,2.987-7.212S510.925,208.074,509.013,206.162z M335.078,365.673v-81.864    c0-5.633-4.567-10.199-10.199-10.199h-17.573c-80.331,0-157.482,34.012-211.669,93.316l-75.237,82.339v-51.551    c0-134.859,109.716-244.575,244.576-244.575h59.904c5.632,0,10.199-4.566,10.199-10.199V61.076l152.299,152.299L335.078,365.673z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M255.449,170.326c-2.779,0-5.588,0.057-8.35,0.168c-5.628,0.226-10.008,4.973-9.78,10.601    c0.221,5.489,4.74,9.789,10.184,9.789c0.139,0,0.278-0.002,0.417-0.009c2.49-0.1,5.022-0.151,7.529-0.151    c5.633,0,10.199-4.566,10.199-10.199S261.082,170.326,255.449,170.326z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path d="M221.099,183.281c-1.279-5.485-6.759-8.895-12.249-7.616c-26.861,6.264-51.802,17.74-74.131,34.11    c-4.544,3.33-5.526,9.713-2.196,14.255c1.998,2.725,5.094,4.169,8.234,4.169c2.093,0,4.205-0.641,6.023-1.975    c20.096-14.733,42.54-25.06,66.704-30.696C218.969,194.251,222.378,188.767,221.099,183.281z"/>
                        </g>
                    </g>
                </svg>
            </span>
        )
    }
}
