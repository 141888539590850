import React, { Component } from 'react'
import Community from '../widgets/Community'

class LeftSidebar extends Component {
    
    render() {
        return (
            <aside className="left-sidebar" id="left-sidebar">
                <div className="wrapper">
                    <Community />

                    {/* <Raccourcis /> */}
                </div>
            </aside>
        )
    }
}

export default LeftSidebar;