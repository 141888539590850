import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Global from '../../../inc/Global.js'
import configs from '../../../inc/helpers.js';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import Axios from 'axios';

import ChatList from '../../header/Chat'
import ReactTooltip from 'react-tooltip';
import Lightbox from 'react-image-lightbox';


class Item extends Component {

    constructor(){
        super()
        this.state = {
            userId: null,
            deleteUserConfirmation: false,
            deletedUsed: null,
            images:[],
            photoIndex: 0,
            isOpen: false,
            status:false,
        }

        this.DeleteBtn = React.createRef();
        this.openGallrie = this.openGallrie.bind(this); 
    }

    deleteUser(){
        if(this.state.userId === null) return;

        this.DeleteBtn.current.classList.add('on');
        Axios
        .delete(Global.API_URL+"/api/userdelete/"+this.state.userId)
        .then((res) => {
            // console.log(res
            this.DeleteBtn.current.classList.remove('on');
            this.setState({
                userId: null,
                deleteUserConfirmation: false,
                deletedUsed: this.state.userId
            })
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            this.DeleteBtn.current.classList.remove('on');
        });
    }

    openGallrie(imgArr){
        console.log('aa');
        this.setState({ 
            images : [imgArr],
            isOpen: true
        })
    }

    render() { 

        const { 
            ID,
            Name,
            LastName,
            Photo,
            Job,
            PhoneNum,
            Email,
            casecoche,
            user,
            
        } = this.props;
        const { status, photoIndex, isOpen, images } = this.state;

        
        const APIURL = Global.API_URL;
        
        const RandColor = 'rgb(' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ',' + (Math.floor(Math.random() * 256)) + ')';
        
        if( this.state.deletedUsed == ID ) return "";

        return (
            <li className="_ursl-listitem" >
            <div className="container">

                <div className="header-post">
                    {configs.isAdmin() ?
                        <div className="rm-list">
                            <input type="checkbox" id={"user-"+ID} />
                            <label className="toggle" htmlFor={"user-"+ID}><i className="fa fa-cog" aria-hidden="true"></i></label>
                            {/* <label className="toggle" htmlFor={"user-"+ID}>...</label> */}
                            <div className="rm-list-item">
                                <ul>

                                    <li><Link to={{
                                        pathname : "/admin/utilisateurs/attribuer-des-communautes",
                                        state : {
                                            addedUser : ID,
                                            name : Name,
                                            lastname : LastName,
                                            email : Email,
                                            job : Job,
                                            tel : PhoneNum,
                                            photo : Photo,
                                            casecoche : casecoche
                                        }
                                    }}>Ajouter aux communautés</Link></li>
                                    <li><Link to={{
                                                pathname: '/admin/utilisateur/modifier',
                                                state: { 
                                                    id :  ID,
                                                    name : Name,
                                                    lastname : LastName,
                                                    job: Job,
                                                    email : Email,
                                                    tel : PhoneNum,
                                                    photo : Photo,
                                                    casecoche : casecoche,
                                                }
                                            }}  >Modifier</Link></li>
                                    <li><a href onClick={(e)=>{this.setState({deleteUserConfirmation: true, userId: ID})}} >Supprimer</a></li>
                                </ul>
                            </div>
                        </div>
                        : 
                        ''
                    }
                        <div className="card">
                            <div className="card__border">
                                {/* {Photo ?
                                    // <img src="{APIURL +  Photo}" alt="card image" className="card__img" />
                                    // {backgroundImage: 'url('+ APIURL +  Photo + ')'}
                                    : 
                                    {}
                                } */}

                                {
                                    Photo ? 
                                    <img src={APIURL +  Photo} alt="card image" className="card__img" onClick={(e)=>this.openGallrie(Photo)} />
                                    :
                                    <span className='noimg'>{LastName.charAt(0)+Name.charAt(0) }</span>
                                }
                                
                            </div>

                            <h3 className="card__name">{LastName.toLowerCase()} {Name.toLowerCase()}</h3>

                            
                            {
                                Job &&
                                <span className="card__profession job"> {Job}</span>
                            }
                            {
                                Email &&
                                <span className="card__profession"><a target="_blank" href={`mailto:${Email}`}>{Email}</a></span>
                            } 
                            {
                                PhoneNum &&
                                <span className="card__profession"><a target="_blank" href={`tel:${PhoneNum}`}>{PhoneNum}</a></span>
                            } 
                                <a target="_blank" className="card__social-link" onClick={(e)=>{ 
                                            e.preventDefault()
                                        ChatList.startChatByUser(e, user)
                                        user.popopen = false; this.setState({})
                                    }}>
                                            <i className="fa fa-comments"></i>
                                            Message
                                        </a>
                        </div>
                    {/* </div> */}

                </div>  

                {
                    this.state.deleteUserConfirmation && 
                    <div className="_modal confirmation">
                        <div className="_overlay"></div>
                        <div className="_modal-wrap">
                            <div className="_modal-content">
                                <div className="_modal-header">
                                    <h2>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</h2>
                                </div>
                                <div className="_modal-body">
                                <div className="d-flex jc-space-b m-t-10 p-10 w100">
                                    <button onClick={()=>{this.setState({deleteUserConfirmation: false})}} className="button wt lg">Annuler</button>
                                    <button onClick={(e)=>{this.deleteUser(e)}} ref={this.DeleteBtn} className="button lg">
                                        <span className="fa fa-spin fa-spinner"></span> Supprimer
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {isOpen && images.length == 1 && (
                    <Lightbox
                        mainSrc={`${Global.API_URL}/${images[photoIndex]}`}
                        nextSrc={`${Global.API_URL}/${images[(photoIndex + 1) % images.length]}`}
                        prevSrc={`${Global.API_URL}/${images[(photoIndex + images.length - 1) % images.length]}`}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
            )}
            </li>
        )
    }
}

Item.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth : state.auth
})

export default connect(mapStateToProps, {})(withRouter(Item));