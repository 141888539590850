import React, { Component } from 'react'
import Posts from '../publications/Posts' 

const Home = ()=>{
    return ( 
        <div className="content">
            <Posts role="ROLE_ADMIN" parent="page_home" api_url="/api/publication" community="0" formAddPost={true} /> 
        </div> 
    )
}

export default Home;