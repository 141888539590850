import React, { Component } from 'react'
//import configs from '../../inc/helpers.js'
import {Link, withRouter} from 'react-router-dom'
import Axios from 'axios';
import Global from '../../inc/Global.js';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import BeatLoader from "react-spinners/BeatLoader";
import Pusher from 'pusher-js' 

import { Paper, Text } from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { IconGripVertical } from '@tabler/icons';

import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from '../../context/GlobalContext';


const MY_DATA = {
    group: {
        operator: 'and',
        conditions: [
        {
            ID: uuidv4(),
            source: 'program',
            sourceIds: ['{{program_id}}'],
            data: 'cpp',
            operator: 'lte',
            target: 10,
        },
        {
            ID: uuidv4(),
            group: {
            operator: 'or',
            conditions: [
                {
                ID: uuidv4(),
                source: 'knowledge',
                sourceIds: ['math', 'history'],
                data: 'cpc',
                operator: 'gte',
                target: 5,
                },
            ],
            },
        },
        ],
    },
};

class Community extends Component
 {

    static contextType = GlobalContext;

    constructor(props){
        super(props);

        this.state = {
            communities : [],
            currentCommunityId: this.props.match.params.id || null,
            data : MY_DATA
        };
        // this.initPusher();
        this.handleDragEnd = this.handleDragEnd.bind(this);
    }


    handleDragEnd = (result) => {
       
        const { source, destination } = result;
        console.log(result);

        if (!destination) return;
        let oldData = this.state.data.group.conditions;
        let oldID = this.state.data.group.conditions[source.index].ID;
        const newData = [...this.state.data.group.conditions];
        const [removed] = newData.splice(source.index, 1);
        newData.splice(destination.index, 0, removed);
        let _NewData = { group: { ...this.state.data.group, conditions: newData } };
        this.setState({
            data : _NewData
        })
        //destination.index
        Axios
        .post(`${Global.API_URL}/api/groups/${oldID}/sortable/change-position`, {position:destination.index+1})
        .then((res) => {
            console.log(res);
           if(!res.data.status){
                let _oldData = { group: { ...this.state.data.group, conditions: oldData } };
                this.setState({
                    data : _oldData
                })
           }
        })
    };
    // initPusher(){
    //     let pusherClient = new Pusher(Global.PUSHER.app_key, { cluster: 'eu' }); 
    //     const channel = pusherClient.subscribe(Global.PUSHER.channel);

    //     channel.bind(Global.PUSHER.event,(data) => {
    //         this.getNotificationsCounter();
    //     }); 
    // }

    // getNotificationsCounter(){
    //     Axios
    //     .get(Global.API_URL+"/api/notif/count", {})
    //     .then((res) => {
    //         if( res.data.state && res.data.nombregroupenewpost > 0 ){
    //             this.componentDidMount()
    //         }
    //     })
    // }
    
    async componentDidUpdate(prevProps) {

        await new Promise(resolve => setTimeout(resolve, 1000));
        
        if (prevProps.location.pathname === "/communautes/:id/modifier" || (prevProps.location.pathname !== this.props.location.pathname)) {
            console.log("ROOT CHANGED"); 
            const data = {
                nbCommunity : 99999999999,
                startPoint : 0,
                idUser : this.props.auth.user.user_id,
                type : '',
                hasNotif : true
            }
            Axios
            .post(Global.API_URL+"/api/getcommunities", data)
            .then((res) => {

                // console.log(res.data)
                var communities = [];
                res.data.forEach(function(item, key) {
                    communities.push({
                        ID : item.id_group,
                        Color : item.couleur,
                        Logo : item.logo,
                        Cover : item.photocouverture,
                        Subject : item.sujet,
                        Title : item.titre,
                        Type : item.typegroupe,
                        NbPostNonLu: item.nb_postnonlu
                    });
                });

                let _NewData = {
                    group: {
                        operator: 'and',
                        conditions: communities,
                    },
                };
                this.setState({
                    communities : communities,
                    data : _NewData
                })  
       
                
            })
            .catch((err) => {
                // console.log(err.message);
            });
        }else{

            // const {notif,loadCount,setloadCountToFalse,leftSideCom} = this.context;
            // let nombregroupenewpost = notif.nombregroupenewpost;
            // if (nombregroupenewpost > 0 && loadCount) {
            //     console.log('abdo is seting new com')
            //     this.formatData(leftSideCom);
            //     setloadCountToFalse()
            // }

        }
        if( this.state.currentCommunityId != this.props.match.params.id){
            this.setState({
                currentCommunityId: this.props.match.params.id || null
            })
        }
	}
    
    componentDidMount(){

        const {getCommunities} = this.context;
        getCommunities()
            .then((res) => {
                this.formatData(res);
            })
            .catch((err) => {
                // console.log(err.message);
            });
                


        // const data = {
        //     nbCommunity : 99999999999,
        //     startPoint : 0,
        //     idUser : this.props.auth.user.user_id,
        //     type : '',
        //     hasNotif : true
        // }
        // Axios
        // .post(Global.API_URL+"/api/getcommunities", data)
        // .then((res) => {
        //     this.formatData(res);   
        // })
        // .catch((err) => {
        //     // console.log(err.message);
        // });
    }
    formatData(res){

        if (res) {
            var communities = [];
                res.data.forEach(function(item, key) {
                    communities.push({
                        ID : item.id_group,
                        Color : item.couleur,
                        Logo : item.logo,
                        Cover : item.photocouverture,
                        Subject : item.sujet,
                        Title : item.titre,
                        Type : item.typegroupe,
                        NbPostNonLu: item.nb_postnonlu
                    });
                });
                let _NewData = {
                    group: {
                        operator: 'and',
                        conditions: communities,
                    },
                };
                this.setState({
                    communities : communities,
                    data : _NewData
                }) 
        }
    }
    render() {
        const {notif,loadCount,setloadCountToFalse,leftSideCom} = this.context;
        if (notif.nombregroupenewpost > 0 && loadCount) {
            console.log('abdo is seting new com')
            this.formatData(leftSideCom);
            setloadCountToFalse()
        }
        return (
            <div>
                <div className="widget widget-communaute">
                    <h2 className="title">Mes Communautés</h2>
                    


                    <div className="widget-content communaute">
                        <div className="list-communaute">
                            {
                                this.state.communities.length == 0 &&
                                <center style={{padding: 20, fontSize: 20}}>
                                    <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                </center>
                            }
                            <ul>
                                <DragDropContext onDragEnd={(e)=>{this.handleDragEnd(e)}}>
                                    <Droppable droppableId="myDoppableId">
                                        {(provided, snapshot) => (
                                        <Paper
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            padding="md"
                                            // shadow="md"
                                            radius="md"
                                            
                                        >
                                                {/* <li className={`item ${this.state.currentCommunityId == this.state.data.group.conditions[0].ID ? 'active' : ''}`} >
                                                    <Link to={"/communaute/" + this.state.data.group.conditions[0].ID}>
                                                        <div className="figure">
                                                            <div>
                                                                {!this.state.data.group.conditions[0].Logo &&
                                                                    // this.state.data.group.conditions[0].Title.charAt(0)
                                                                    'A'
                                                                }
                                                                {this.state.data.group.conditions[0].Logo &&
                                                                    <img src={Global.API_URL +"/" + this.state.data.group.conditions[0].Logo} alt="" />
                                                                }
                                                            </div> 
                                                        </div>
                                                        <div className="content-item">
                                                            <h3 className="entry-title">
                                                                {this.state.data.group.conditions[0].Title}
                                                            </h3>
                                                            {
                                                                this.state.data.group.conditions[0].NbPostNonLu > 0 &&
                                                                <span className="badge">{this.state.data.group.conditions[0].NbPostNonLu}</span>
                                                            }
                                                        </div>
                                                        <div className='hover-drug'>
                                                            <IconGripVertical size={18} stroke={1.5} />
                                                        </div>
                                                    </Link>
                                                </li> */}
                                            {this.state.data.group.conditions && this.state.data.group.conditions.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={`com-Wal-${item.ID}`} index={index}  >
                                                {(provided, snapshot) => (
                                                    <Paper
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    padding="xs"
                                                    // radius="sm"
                                                    shadow="md"
                                                    
                                                >
                                                     <li className={`item ${this.state.currentCommunityId == item.ID ? 'active' : ''}`}  key={"widget-cmy-"+index}
                                                      
                                                     >
                                                        <Link to={"/communaute/" + item.ID}>
                                                            <div className="figure">
                                                                <div>
                                                                    {!item.Logo &&
                                                                        item.Title?.charAt(0)
                                                                    }
                                                                    {item.Logo &&
                                                                        <img src={Global.API_URL +"/" + item.Logo} alt="" />
                                                                    }
                                                                </div> 
                                                            </div>
                                                            <div className="content-item">
                                                                <h3 className="entry-title">
                                                                    {item.Title}
                                                                </h3>
                                                                {
                                                                    item.NbPostNonLu > 0 &&
                                                                    <span className="badge">{item.NbPostNonLu}</span>
                                                                }
                                                            </div>
                                                            <div className='hover-drug'>
                                                                <IconGripVertical size={18} stroke={1.5} />
                                                            </div>
                                                        </Link>
                                                    </li>
                                                </Paper>
                                                )}
                                            </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </Paper>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </ul>

                            {/* <ul>
                                {this.state.communities.map((item, index) =>{
                                    return (
                                        <li className={`item ${this.state.currentCommunityId == item.ID ? 'active' : ''}`} key={"widget-cmy-"+index}>
                                            <Link to={"/communaute/" + item.ID}>
                                                <div className="figure">
                                                    <div>
                                                        {!item.Logo &&
                                                            item.Title.charAt(0)
                                                        }
                                                        {item.Logo &&
                                                            <img src={Global.API_URL +"/" + item.Logo} alt="" />
                                                        }
                                                    </div> 
                                                </div>
                                                <div className="content-item">
                                                    <h3 className="entry-title">
                                                        {item.Title}
                                                    </h3>
                                                    {
                                                        item.NbPostNonLu > 0 &&
                                                        <span className="badge">{item.NbPostNonLu}</span>
                                                    }
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                })} 
                            </ul> */}
                        </div>
                    </div>
                    {
                        this.state.communities.length > 0 &&
                            <div className="widget-footer text-center">
                            <Link to="/communautes" className="">
                                toutes les communautés
                                <i className="fa fa-chevron-right"></i>
                            </Link>
                        </div>
                    } 
                </div>
            </div>
        )
    }
}
Community.propTypes = {
    auth: PropTypes.object.isRequired,
};
  
const mapStateToProps = (state) => ({
    auth: state.auth,
});
  
export default connect(mapStateToProps, { })(withRouter(Community));