import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AddToCommunityItem from './AddToCommunityItem'
import axios from 'axios'
import Global from '../../../inc/Global'
import SHA1 from 'sha1'
import BeatLoader from "react-spinners/BeatLoader";
const simpleTextSearch = require('simple-text-search')

class CommunityList extends Component {
    constructor(props){
        super(props)
        
        if(typeof this.props.location.state === "undefined" ){
            return;
        }else if(typeof this.props.location.state.addedUser === "undefined"){
            return;
        }
        
        const params = this.props.location.state;

        this.state = {
            userId : params.addedUser,
            userEmail : params.email,
            profile : {
                ID: params.addedUser, 
                email : params.email,
                name : params.name,
                lastname : params.lastname,
                job: params.job,
                tel : params.tel,
                photo : params.photo,
                casecoche : params.casecoche,
            },
            communities : [],
            AllCommunities : [],
            limit : 10 ,
            step : 10,
            loaded : false,
            allLoaded : false,
            search: ''
        }

        this.LoadMore = React.createRef();
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.SearchB = React.createRef();
        
        // window.onscroll = debounce(() => {
            
        //     if (
        //       window.innerHeight + document.documentElement.scrollTop
        //       === document.documentElement.offsetHeight
        //     ) {
        //         this.setState({
        //             limit : this.state.limit + this.state.step
        //         })
        //         if(this.state.limit >= this.state.communities.length){
        //             this.setState({
        //                 allLoaded : true
        //             })
        //         }else{
        //             this.setState({
        //                 allLoaded : false
        //             })
        //         }
        //     }
        // }, 100);
    }
    
    componentDidMount(){ 
        let _self = this;
        axios
        .get(Global.API_URL+"/api/getgroupadd/"+_self.state.userId)
        .then((res) => {
            
            var communities = [];
            res.data.forEach(function(item, key) {
                if( item.typegroupe !== 'defaut' ){
                    communities.push({
                        ID : _self.state.userId,
                        userEmail : _self.state.userEmail,
                        groupID : item.id_group,
                        Title : item.titre,
                        Type : item.typegroupe,
                        Subject : item.sujet,
                        NBUsers : item.nb_user,
                        Color : item.couleur,
                        Logo : item.logo
                    });
                }
            });
            this.setState({
                communities : communities,
                AllCommunities : communities,
                loaded : true
            })
            // console.log(communities)
            if(this.state.limit >= communities.length){
                this.setState({
                    allLoaded : true
                }) 
            }else{
                this.setState({
                    allLoaded : false
                })
            }
        })
        .catch((err) => {
            // console.log(err.message);
        });
    }
  
    handleSearchInput= async (event) => {
        
        event.preventDefault();
        const communities = this.state.AllCommunities.filter(item =>{
            let get = simpleTextSearch([item.Title, item.Subject, item.userEmail, item.Type])
            return get(event.target.value).length > 0
        });
        //await this.setState({ communities });
        await this.setState({
            limit : 10,
            communities : communities,
            search : event.target.value
        })

        if(this.state.limit >= this.state.communities.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
    }
    confirmRefus (e){
        e.preventDefault();
        var btn = this.RefuseBtn;
        btn.current.classList.add('on');
        
        
        axios
        .post(Global.API_URL+"/userremovegroup", {id : this.state.groupId, iduser : this.state.userToReject})
        .then((res) => {
            btn.current.classList.remove('on');

            var communities = [...this.state.communities]; 
            var AllCommunities = [...this.state.AllCommunities];
    
            var currentUser = null;
            var currentAllUser = null;
            
            communities.forEach(function(el, ind){

                if(el.ID === parseInt(this.state.userToReject)){
                    currentUser = ind;
                }
            }.bind(this));

            AllCommunities.forEach(function(el, ind){
    
                if(el.ID === parseInt(this.state.userToReject)){
                    currentAllUser = ind; 
                }
            }.bind(this));
            communities.splice(currentUser, 1)
            AllCommunities.splice(currentAllUser, 1) 
            
            this.setState({
                userToReject : null,
                communities : communities,
                AllCommunities : AllCommunities
            });
            
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
            btn.current.classList.remove('on');
            this.setState({
                itemToDelete : null
            });
        });
        
        
    }
    handleReject (e) {
        e.preventDefault();
        this.setState({
            userToReject : e.target.getAttribute('id')
        });
    }
    cancel (e){
        e.preventDefault();
        this.setState({
            userToReject : null
        });
    }
    handleRoleChange = (ev) => {

    }
    handleAccept = (ev) => {
        ev.preventDefault();
        var targetid = ev.target.getAttribute('data-id');
        var targetemail = ev.target.getAttribute('data-email');
        var targetgroup = ev.target.getAttribute('data-group-id');
        var role = document.querySelector('#_role'+targetgroup+ ' [name="role"]').value;
        
        const data = {
            id_user : targetid,
            email : targetemail,
            id : targetgroup,
            role : role
        };

        axios
        .post(Global.API_URL+"/api/invitation", data)
        .then((res) => {
            document.querySelector('#_accept_' + targetgroup).setAttribute("disabled", true);
            document.querySelector('#_accept_' + targetgroup).innerHTML = "Bien ajouté";
        })
        .catch((err) => {
            // console.log('Error : '+err.message)
        });
    }
    async handleLoadMore (ev){
        ev.preventDefault();

        await this.setState({
            limit : this.state.limit + this.state.step
        });
        
        if(this.state.limit >= this.state.communities.length){
            this.setState({
                allLoaded : true
            })
        }else{
            this.setState({
                allLoaded : false
            })
        }
        
    }
    render() {
        if(typeof this.props.location.state === "undefined" ){
            this.props.history.push('/utilisateurs')
            return "";
        }else if(typeof this.props.location.state.addedUser === "undefined"){
            this.props.history.push('/utilisateurs')
            return "";
        }

        // console.log(this.state.allLoaded, this.state.limit, this.state.AllCommunities.length)
        return (
            <div className="_modal new-community">
                <div className="_overlay"></div>
                <div className="_modal-wrap">
                    <div className="content">
                        <div className="_modal-content">
                        <div className="_modal-header">
                            <h2>Liste des communautés</h2>
                        </div>
                        <div className="_modal-body">
                            <section className="content">
                                    <div className="wrap">
                                        <div className="users-list">
                                            <div className="header-list">
                                                <div className="d-flex jc-space-b align-center">
                                                    {/* <h3>Création utilisateur</h3> */}
                                                    <div className="">
                                                        {/* <ul>
                                                            <li>
                                                                <Link to=""><span className="fa fa-search"></span></Link>
                                                            </li>
                                                        </ul> */}
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                this.state.loaded &&
                                                <div className="_srs-users">
                                                    <input ref={this.SearchB} type="search" name="s" value={this.state.search} onChange={this.handleSearchInput} placeholder="Rechercher un communauté"/>
                                                    <span className="button" id="foo2" onClick={(e) => {this.setState({search: '', communities : this.state.AllCommunities})}} >Annuler</span>
                                                </div>

                                            }
                                            
                                            {/* <div>
                                                <div className="d-flex jc-space-b m-t-10 m-b-10">
                                                    <Link to="/admin/utilisateurs" className="button wt lg" >Annuler</Link>
                                                    <input type="submit" className="button lg" value="Continuer" />
                                                </div>
                                            </div> */}

                                            <div className="_ursl">
                                                <ul>
                                                {this.state.communities.slice(0, this.state.limit).map((invitation, i) =>
                                                    <AddToCommunityItem 
                                                        key={"cmt-"+invitation.groupID}
                                                        ID={invitation.ID}
                                                        userEmail={invitation.userEmail}
                                                        groupID={invitation.groupID}
                                                        Title={invitation.Title}
                                                        Type={invitation.Type}
                                                        Subject={invitation.Subject}
                                                        NBUsers={invitation.NBUsers}
                                                        Color={invitation.Color}
                                                        Logo={invitation.Logo} 
                                                        RoleSelect={{
                                                            items : [
                                                                { value: 'ROLE_ADMIN', label: 'Administrateur' },
                                                                { value: 'ROLE_MODERATEUR', label: 'Rédacteur'},
                                                                { value: 'ROLE_LECTEUR', label: 'Lecteur' }
                                                            ],
                                                            selectedRole : { value: 'ROLE_MODERATEUR', label: 'Rédacteur' },
                                                            selectedValue : { value: 'ROLE_MODERATEUR', label: 'Rédacteur' },
                                                            handleSelectChange : this.handleRoleChange,
                                                        }}
                                                        handleAccept={this.handleAccept}
                                                        />
                                                )}
                                                </ul>
                                            </div>
                                            
                                            {
                                                !this.state.loaded &&
                                                <center style={{padding:20}}>
                                                    <BeatLoader color={"#aaa"} loading={true} size="10"/>
                                                </center>
                                            }
                                            <div ref={this.LoadMore} className="loadMore">
                                                {
                                                    this.state.communities.length > 0 &&
                                                    <div style={{padding:20}}>
                                                        <Link className="button full lg" onClick={this.handleLoadMore}>Charger plus des communautés <span className="fa fa-spin fa-spinner"></span></Link>
                                                    </div>
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                </section>
                        </div>
                        <div className="_modal-footer">
                            <div className="d-flex jc-space-b m-t-10 ">
                                <Link to={'/utilisateurs'} className="button wt lg" >Annuler</Link>
                                <Link to="/utilisateurs" className="button lg" >Terminer</Link>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div> 
        )
    }
}


export default CommunityList;