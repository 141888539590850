import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const isValidUrlProp = (props, propName, componentName) => {
  if (!props) {
    return new Error(`Required parameter URL was not passed.`)
  }
  if (!isValidUrl(props[propName])) {
    return new Error(
      `Invalid prop '${propName}' passed to '${componentName}'. Expected a valid url.`
    )
  }
}

const isValidUrl = (url) => {
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  const validUrl = regex.test(url)
  return validUrl
}

function LinkPreview(props) {
  const [loading, setLoading] = useState(true)
  const [preview, setPreviewData] = useState({})
  const [isUrlValid, setUrlValidation] = useState(false)

  const {
    url,
    width,
    maxWidth,
    marginTop,
    marginBottom,
    marginRight,
    marginLeft,
    onClick,
    render
  } = props

  const api = 'https://api.linkpreview.net'

  const style = {
    width,
    maxWidth,
    marginTop,
    marginBottom,
    marginRight,
    marginLeft
  }

  useEffect(() => {
    async function fetchData() {
      const fetch = window.fetch
      if (isValidUrl(url)) {
        setUrlValidation(true)
      } else {
        return {}
      }
      setLoading(true)
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            key: "21b1ae5bab80ef9502249ba429505a0f", 
            q: url
        })
      })
      const data = await response.json()
      setPreviewData(data)
      setLoading(false)
    }
    fetchData()
  }, [url])

  if (!isUrlValid) {
    console.error(
      'LinkPreview Error: You need to provide url in props to render the component'
    )
    return null
  }

  // If the user wants to use its own element structure with the fetched data
  return render({
    loading: loading,
    preview: preview
  })
}

LinkPreview.defaultProps = {
  onClick: () => {},
  width: '90%',
  maxWidth: '700px',
  marginTop: '18px',
  marginBottom: '18px',
  marginRight: 'auto',
  marginLeft: 'auto'
}

LinkPreview.propTyps = {
  url: isValidUrlProp,
  onClick: PropTypes.func,
  render: PropTypes.func,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string
}

export default LinkPreview